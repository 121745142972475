import { IPresetItem } from "interface";
import { FILTER_TYPES, IFilterType, IFilterTypes } from "./utils";

function convertArrayOptions(filterValue: any, filterKey: string, { type, isNumeric }: IFilterType) {
  return { [filterKey]: filterValue };
}

const getValues = {
  [FILTER_TYPES.MULTI_SELECT]: convertArrayOptions,
  [FILTER_TYPES.TREE_SELECT]: convertArrayOptions,
  [FILTER_TYPES.SINGLE_SELECT]: (filterValue: any, filterKey: string, { type }: IFilterType) => {
    return { [filterKey]: filterValue[0] };
  },
  [FILTER_TYPES.DATE_RANGE]: (filterValue: any, filterKey: string, { type }: IFilterType) => {
    if (filterValue?.length >= 2) {
      return {
        [filterKey]: filterValue.map((item: any) => new Date(parseInt(item, 10)))
      }
    }
    return undefined;
  },
  [FILTER_TYPES.DATE]: (filterValue: any, filterKey: string, { type }: IFilterType) => {
    return { [filterKey]: new Date(parseInt(filterValue[0], 10)) };
  },
  [FILTER_TYPES.BOOLEAN]: (filterValue: any, filterKey: string, { type }: IFilterType) => {
    return { [filterKey]: filterValue[0] === "true" ? true : false };
  },
  [FILTER_TYPES.DEFAULT]: (filterValue: any, filterKey: string, { type, isNumeric }: IFilterType) => {
    return { [filterKey]: !!isNumeric ? parseInt(filterValue[0], 10) : filterValue[0] };
  }
}


export function transformPresetValueToFormValue(items: IPresetItem[], filterTypes: IFilterTypes): any {
  let filterState: any = {};

  items.forEach(({ key, values }: IPresetItem) => {
    const { type } = filterTypes[key] || {};
    if (type && values.length) {
      const filterKeyValue = getValues[type](values, key, filterTypes[key]);
      filterState = {
        ...filterState,
        ...filterKeyValue
      }
    }
  });
  return filterState;
}