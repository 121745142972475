import classNames from "classnames";
import SvgIcon, { CUSTOM_SVG_ICON, SVGType } from "components/SvgIcon";
import { MutableRefObject, useEffect, useMemo, useRef } from "react";
import { debounce } from "utils";
import { IColumn, IKeyOfString, ISortState, SortOrder } from "./IReactTable";

interface Props {
  header: IColumn;
  direction: string;
  index: number
  sortState: ISortState;
  handleSortChange: (key: string) => void;
  groupingWidth: IKeyOfString;
  toggleFilters: any;
  showFilters: boolean;
  handleFilterChange: (key: string, value: string) => void;
  defaultFilterState: any;

}


function getSortIconName(sortState: ISortState, id: string = ""): CUSTOM_SVG_ICON {
  const { key, sortOrder} = sortState || {};
  if(key !== id) {
    return CUSTOM_SVG_ICON.Sort;
  } else {
    if(sortOrder === SortOrder.ASSCENDING) {
      return CUSTOM_SVG_ICON.SortUp;
    } else {
      return CUSTOM_SVG_ICON.SortDown;
    }
  }
}

function HeaderCell({ header: { label, id, isSearchEnable, isSortable }, direction, index, sortState,
  handleSortChange, groupingWidth, toggleFilters, showFilters, handleFilterChange, defaultFilterState }: Props) {
  const key = `header-cell-${direction}-${index}`;
  const inputRef: MutableRefObject<HTMLInputElement> = useRef(null!);
  const sortIconName: CUSTOM_SVG_ICON = getSortIconName(sortState, id);
  const toggleSorting = () => {
    handleSortChange(id);
  };

  const handleDebouceInput = debounce(({target: {value}}: any) => {
    handleFilterChange(id, value);
  }, 500);

  const showActions: boolean = useMemo(() => {
    return !!(isSearchEnable || isSortable);
  }, [isSearchEnable, isSortable])

  useEffect(() => {
    if(inputRef && inputRef.current) {
      inputRef.current.value = (defaultFilterState && defaultFilterState[id]) || "";
    }
  }, [ defaultFilterState, inputRef?.current ])

  return (
    <div style={{ width: groupingWidth[id] }} id={key} className={classNames(["react-table--header-cell"])}>
      <div className={classNames(["width-100", showActions ? "actions" : ""])}>
        {label}
        {showActions && <div className="flex flex-row header-actions">
          {isSearchEnable && <div className="header-actions--button" onClick={toggleFilters}><SvgIcon baseclassname="text-white-color" svgType={SVGType.CUSTOM} name={CUSTOM_SVG_ICON.SearchIcon} size="small" /></div>}
          {isSortable && <div className="header-actions--button" onClick={toggleSorting}><SvgIcon baseclassname="text-white-color" svgType={SVGType.CUSTOM} name={sortIconName} size="small" /></div>}
        </div>
        }
      </div>
        <div className="width-100 padding-t-2" id={`${key}-input`} style={{"display": (showFilters && isSearchEnable) ? "inherit" : "none"}}>
          <input ref={inputRef} className="header-filter-input" type="text" placeholder="Search" onChange={handleDebouceInput} />
        </div>
    </div>
  )
}

export default HeaderCell;