import CustomButton, { ICON_POSITION } from "components/CustomButton/CustomButton";
import { IColumn, IReactTableComponent } from "components/ReactTable";
import { CUSTOM_SVG_ICON, SVGType } from "components/SvgIcon";
import UsePeople from "HOC/UsePeople";
import Moment from "react-moment";
import { DATE_FORMAT, getUserName } from "utils";

function getParentTopicName(parentTopicId: string, topicName: string, practiceAreaMapping: any): string {
  if (parentTopicId && practiceAreaMapping) {
    return practiceAreaMapping[parentTopicId]
  }
  return topicName;
}

function getTopicName(parentTopicId: string, topicName: string): string {
  if (!parentTopicId) {
    return "";
  }
  return topicName;
}

export const getHeaders = (practiceAreaMapping: any): IColumn[] => [
  {
    id: "parentTopicId",
    label: "Parent Practice Area",
    width: 25,
    CustomRowComponent: ({ data }: IReactTableComponent) => {
      return getParentTopicName(data?.parentTopicId, data?.topicName, practiceAreaMapping);
    }
  },
  {
    id: "topicName",
    label: "Topic/Sector",
    width: 25,
    CustomRowComponent: ({ data }: IReactTableComponent) => {
      return getTopicName(data?.parentTopicId, data?.topicName)
    }
  },
  {
    id: "affiliationSince",
    label: "Affiliated Since",
    width: 25,
    CustomRowComponent: ({ data, header }: IReactTableComponent) => {
      const value = data[header.id];
      return value ? <Moment date={value} format={DATE_FORMAT} /> : ""
    }
  },
  {
    id: "updatedByHrId",
    label: "Updated By",
    width: 25,
    CustomRowComponent: ({ data, header }: IReactTableComponent) => {
      const { user } = UsePeople(data[header.id], true);
      return getUserName(user);
    }
  },
];

export const getAddAffiliationHeaders = (deleteAffilication: (affiliationID: string) => void, practiceAreaMapping: any): IColumn[] => [
  {
    id: "parentTopicId",
    label: "Parent Practice Area",
    width: 25,
    CustomRowComponent: ({ data }: IReactTableComponent) => {
      return getParentTopicName(data?.parentTopicId, data?.topicName, practiceAreaMapping);
    }
  },
  {
    id: "topicName",
    label: "Topic/Sector",
    width: 25,
    CustomRowComponent: ({ data }: IReactTableComponent) => {
      return getTopicName(data?.parentTopicId, data?.topicName)
    }
  },
  {
    id: "affiliationSince",
    label: "Affiliated Since",
    width: 20,
    CustomRowComponent: ({ data, header }: IReactTableComponent) => {
      const value = data[header.id];
      return value ? <Moment date={value} format={DATE_FORMAT} /> : ""
    }
  },
  {
    id: "updatedByHrId",
    label: "Updated By",
    width: 20,
    CustomRowComponent: ({ data, header }: IReactTableComponent) => {
      const { user } = UsePeople(data[header.id], true);
      return getUserName(user);
    }
  },
  {
    id: "actions",
    label: "Actions",
    width: 10,
    CustomRowComponent: ({ data, header }: IReactTableComponent) => {
      return (
        <div className="flex flex-justify-center">
          <CustomButton
            transparent
            primaryButton
            noOutline
            noPadding
            iconPosition={ICON_POSITION.LEFT}
            iconProps={{
              name: CUSTOM_SVG_ICON.Delete,
              svgType: SVGType.CUSTOM,
              size: "huge"
            }}
            handleClick={() => {
              deleteAffilication(data.topicId)
            }} />
        </div>
      )
    }
  },
]