import { IListParams, ActionIdentity, IListAction, IListMeta } from './interface';
import { defineRequestType} from "../common";

export function createIdentityAction(identity: string): ActionIdentity {
  return {
    LIST_FETCH: defineRequestType(`@${identity}CUSTOM_REDUX_LIST_FETCH`),
    MULTIPLE_ADD_ITEMS: defineRequestType(`@${identity}CUSTOM_REDUX_MULTIPLE_ADD_ITEM`),
    DELETE_ITEM: defineRequestType(`@${identity}CUSTOM_REDUX_DELETE_LIST_ITEM`),
    EDIT_ITEM: defineRequestType(`@${identity}CUSTOM_REDUX_EDIT_LIST_ITEM`),
    ADD_ITEM: defineRequestType(`@${identity}CUSTOM_REDUX_ADD_LIST_ITEM`),
    MULTI_EDIT_ITEMS: defineRequestType(`@${identity}CUSTOM_REDUX_MULTI_EDIT_ITEMS`),
  };
}

export const listActionFunctions = {
  request: (meta: IListMeta, params: IListParams, showNotifyMesage?: string): IListAction => {
    const { actions } = meta;
    return {
      type: actions.REQUEST,
      meta,
      payload: {
        params,
        showNotifyMesage
      },
    };
  },
  success: (meta: IListMeta, params: IListParams, items: any, message: string): IListAction => {
    const { actions } = meta;
    return {
      type: actions.SUCCESS,
      meta,
      payload: {
        params,
        items,
        message
      },
    };
  },
  failure: (meta: IListMeta, params: IListParams, message: string): IListAction => {
    const { actions } = meta;
    return {
      type: actions.FAILURE,
      meta,
      payload: {
        params,
        message,
      },
    };
  },
};