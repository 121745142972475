import { Feature } from "utils/Features/Features";
import { ITutorial } from "./Tutorial";

export function getTutorialsViews(userFeatures: Feature, intrestedAffiliationRef: any, headerRef: any): ITutorial[] {
  const tutorials: Array<ITutorial> = [];

  if (userFeatures?.getMyAffiliationFeatures().isAllowed) {
    tutorials.push({
      id: "InterestedAffiliations_action",
      message: <div className="text-align-center">
        Click on "Add Affiliation" to add an <span className="text-bold">Interested</span> affiliations (Individuals can self-select 'INTERESTED' affiliations 
        to indicate a desire to learn more and be added to PA-related communications, etc.)
        <br/>
        <span>Note:</span>
        <li><span className="text-bold">Active</span> affiliation indicates regular contribution to the work or IP development of a Practice Area in a substantial way.
         Individuals cannot self-select.</li>
        <li><span className="text-bold">Lead</span> affiliation indicates the highest level of affiliation at the Practice level, and in some instances,
         at the Sector and Topic levels. Individuals cannot self-select.</li>
      </div>,
      node: intrestedAffiliationRef,
      threshold: 20
    });
  }

  if (userFeatures?.getSearchPaafFeatures().isAllowed) {
    tutorials.push({
      id: "search-paaf-link",
      message: <div className="text-align-center">
        <div>Click on "Search PAAF" to search the PAAF database. You will be able to search by Name, Home Office, Practice Area, Job Titles, Affiliation, etc.</div>
        <br/>
        <div>Search results are shown in a detailed view underneath filter options displayed on the page.
           Data reporting options are available and can be downloaded and exported to excel.</div>
      </div>,
      node: headerRef,
      threshold: 0
    })
  }


  if (userFeatures?.getReportFeatures().isAllowed) {
    tutorials.push({
      id: "report-link",
      message: (<div className="text-align-center">
       Change, Archived Reports and PA/Sector/Topic Mappings are accessible here and are available for download.
      </div>),
      node: headerRef,
      threshold: 0
    })
  }

  if (userFeatures?.getLearnMoreFeatures().isAllowed) {
    tutorials.push({
      id: "learn-more-link",
      message: (<div className="text-align-center">Consult our PAAF Microsite for more information on the Practice Area Affiliation Database tool.
      </div>),
      node: headerRef,
      threshold: 0
    })
  }

  return tutorials;
}