import { forwardRef, useMemo, useState } from "react";
import { CUSTOM_SVG_ICON, SVGType } from "components/SvgIcon";
import CustomButton from "components/CustomButton";
import { ICON_POSITION } from "components/CustomButton/CustomButton";
import ReactTable, { IColumn} from "components/ReactTable";
import classNames from "classnames";

import './AffiliationTab.scss';
import { ApplicationState } from "store/reducers";
import { IRextState } from "baseProvider/rext";
import { useSelector } from "react-redux";
import { IListState } from "baseProvider/list";

interface Props {
  title: string;
  headers: IColumn[];
  link: string;
  emptyDataMessage?: string;
  actionButton?: () => void;
  actionButtonTitle?: string;
  affiliationSelector: (state: ApplicationState)=> IRextState | IListState
}

function AffiliationTab({ title, link, headers, actionButton, emptyDataMessage, actionButtonTitle, affiliationSelector }: Props, ref: any) {
  const [hideTable, setHideTable] = useState(false);
  const { data, error, message, fetching } = useSelector(affiliationSelector);
  const actionId = useMemo(() => {
    return `${title.replaceAll(" ", "")}_action`;
  }, []);

  const toggleTableHide = () => {
    setHideTable(!hideTable)
  }

  return (
    <div ref={ref}>
    <div className={classNames(["background-grey-shade-1 text-lg text-bold width-100 padding-t-5 padding-b-5 padding-l-10 padding-r-10 flex flex-justify-between", {"margin-b-10": hideTable}])}>
        <div className="flex flex-column">
          <div className="flex flex-row flex-align-center">
            {title}
            <div className="affiliations-Count flex flex-align-center flex-justify-center margin-l-2 background-primary-color text-white-color border-radius-100">{data?.length}</div>
          </div>
          <a className="text-sm" target="_blank" href={link}>Click here to learn more about {title}</a>
        </div>
        
        <div className="flex flex-row">
          {
          !!actionButton && <CustomButton
            id={actionId}
            buttonText={actionButtonTitle || "Add"}
            primaryButton
            baseclassname="margin-r-2"
            handleClick={actionButton}
            round
          />
          }

          <CustomButton handleClick={toggleTableHide}
            secondaryButton
            transparent
            iconPosition={ICON_POSITION.LEFT}
            iconProps={{
              svgType: SVGType.CUSTOM,
              name: hideTable ? CUSTOM_SVG_ICON.CaretUp : CUSTOM_SVG_ICON.CaretDown ,
              size: "small"
            }} 
            noOutline
          />
        </div>
      </div>
      {!hideTable &&
        <div className='padding-t-6 padding-b-12 padding-l-10 padding-r-10'>
          <ReactTable baseClassName="affiliation-table"  headers={headers} data={data} isLoading={fetching} noDataMessage={(error ? message : false) || emptyDataMessage}/>
        </div>
      }
    </div>
  )
}
export default forwardRef(AffiliationTab);