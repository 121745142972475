import CustomButton from "components/CustomButton";
import { ICON_POSITION } from "components/CustomButton/CustomButton";
import { FormInput } from "components/FormInputs";
import Loading from "components/Loading";
import ProfilePicture from "components/ProfilePicture";
import { CUSTOM_SVG_ICON, SVGType } from "components/SvgIcon";
import { Fragment, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Modal } from "semantic-ui-react";
import { getAllBCGUsersByName } from "store/rext";
import { getSearchBCGUsers } from "store/selectors";
import { ModalHeader } from "../Common";
import "./SelectUserModal.scss";

interface Props {
  hideModal: () => void;
  onClose: () => void;
  onSave: (user: any) => void;
}

const UserCard = ({ checked, user, handleClicked }: any) => {
  const { globalTitle, preferredName } = user;
  return (
    <div onClick={handleClicked} className="width-49 margin-t-4 background-white-color padding-4 flex flex-row flex-align-center cursor-pointer">
      <div className="flex flex-align-center">
        <input type="radio" checked={checked || false} onChange={handleClicked} />
        <ProfilePicture imageClassName="paaf_grid--avatar_1_5" baseClassName="margin-r-4" profileUrl={user?.profilePicture} />
      </div>
      <div className="flex flex-column">
        <div className="text-md text-bold">{preferredName}</div>
        <div className="text-sm text-grey-shade-2">{globalTitle}</div>
      </div>
    </div>
  )
}


function SelectUserModal(props: Props) {
  const dispatch = useDispatch();
  const [searchInputValue, setSearchInputValue] = useState<string>("");
  const [selectedUser, setSelectedUser] = useState<any>(undefined!);
  const searchUserState = useSelector(getSearchBCGUsers);
  const [firstSearch, setFirstSearch] = useState<boolean>();

  const findUsers = () => {
    setFirstSearch(true);
    dispatch(getAllBCGUsersByName(searchInputValue));
  }

  const handleValueChanges = ({ target: { value } }: any) => {
    setSearchInputValue(value);
  }

  const handleKeyDown = ({ key }: any) => {
    if (key === 'Enter') {
      findUsers();
    }
  }

  const handleUserSelction = (user: any) => {
    setSelectedUser(user);
  }

  const handleAddUser = () => {
    if (selectedUser >= 0) {
      props.onSave(selectedUser);
    }
  }

  const renderUser = (user: any, index: number) => {
    return <UserCard key={`user${user.id}`} user={user} handleClicked={() => {
      handleUserSelction(user.id);
    }}
      checked={user.id === selectedUser}
    />
  }

  return (
    <Modal
      size="tiny"
      dimmer
      open
      closeOnEscape={false}
      closeOnDimmerClick={false}
      className="user-modal"
    >
      <ModalHeader title="Add New User" toggle={props.onClose} />
      <Modal.Content className="nopadding background-grey-shade-7">
        <div className="flex flex-row flex-justify-between background-white-color padding-6">
          <FormInput
            input={{
              value: searchInputValue,
              onChange: handleValueChanges,
              onKeyDown: handleKeyDown,
              placeholder: "Search for Users"
            }}
            roundedCorner
            icon={CUSTOM_SVG_ICON.SearchIcon}
            baseClassName="width-90"
            inputClassName="width-100"
          />
          <CustomButton
            primaryButton
            transparent
            noOutline
            circular
            handleClick={findUsers}
            iconPosition={ICON_POSITION.RIGHT}
            iconProps={{
              name: CUSTOM_SVG_ICON.SearchIcon,
              svgType: SVGType.CUSTOM,
              size: "small",
            }}
          />
        </div>
        {
          searchUserState.fetching && <Loading isGlobal />
        }
        {firstSearch && !searchUserState.fetching && searchUserState.data.length >= 1 && <div className="padding-6 user-content">
          <Fragment>
            <div>{searchUserState.data.length} Search Results</div>
            <div className="flex flex-wrap flex-justify-between">
              {searchUserState?.data.map(renderUser)}
            </div>
          </Fragment>
        </div>
        }

      </Modal.Content>
      {firstSearch && !!searchUserState.data.length && <Modal.Actions>
        <CustomButton round primaryButton buttonText="Add Roles" handleClick={handleAddUser} />
      </Modal.Actions>
}
    </Modal>
  )
}
export default SelectUserModal;