import FilterContainer from "components/FilterContainer";
import { FormCheckbox, FormDateRange } from "components/FormInputs";
import NoFilterBanner from "components/NoFilterBanner";
import { CUSTOM_SVG_ICON } from "components/SvgIcon";
import { Fragment, useEffect, useRef, useState } from "react";
import { Field } from "redux-form";
import { DEFAULT_FILTER_VALUE, FilterTypes, FILTER_IDS, IFilter } from "./IFilter";


export interface Props {
  handleFilterApplied: any; // On submit this will be called
  isFilterApplied: boolean; // is filter applied or not
  showNoFilterBanner: boolean; //show no filter banner
  filterState: IFilter; // current filter state
  handleResetClick: any; // on reset this will called
  hideResetButton?: boolean;
  submitButtonText?: string;
  noFilterMessage?: string;
}

function FilterReportsForm(props: Props) {
  const { handleFilterApplied, filterState, isFilterApplied, showNoFilterBanner, handleResetClick, hideResetButton, submitButtonText, noFilterMessage } = props;
  const itemsRef: any = useRef({});
  const [initialValues, setInitialValues] = useState<any>(undefined!);


  useEffect(() => {
    setInitialValues(filterState);
  }, [])

  return (initialValues ? (
    <Fragment>
      <FilterContainer
        title={"Change Report Options"}
        titleClass="text-lg text-bold"
        handleFilterApplied={handleFilterApplied}
        itemsRef={itemsRef}
        DEFAULT_FILTER_VALUE={DEFAULT_FILTER_VALUE}
        FilterTypes={FilterTypes}
        FILTER_IDS={FILTER_IDS}
        initialValues={filterState}
        handleResetClick={handleResetClick}
        hideResetButton={hideResetButton}
        submitButtonText={submitButtonText}
        showPresetFilters={false}
      >
        <div className="background-white-color flex flex-column ">
          <Field
            name={FILTER_IDS.INCLUDE_CHANGES}
            component={FormCheckbox}
            baseClassName="left-align"
            labelTxt={FilterTypes[FILTER_IDS.INCLUDE_CHANGES].label}
          />
          <Field
            name={FILTER_IDS.ACTIVITY_DATE_RANGE}
            component={FormDateRange}
            placeholder={FilterTypes[FILTER_IDS.ACTIVITY_DATE_RANGE].label}
            label=""
            inputClassName="width-100"
            baseClassName="width-25"
            iconName={CUSTOM_SVG_ICON.Calender}
          />
        </div>
      </FilterContainer>
      {
        showNoFilterBanner && !isFilterApplied && <NoFilterBanner message={noFilterMessage || "Use the above filters to find PAAF Users"} />
      }
    </Fragment>
  ) : null
  )
}
export default FilterReportsForm;

