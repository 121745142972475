import { Fragment, useMemo, useEffect, useState } from "react";
import CheckboxLabel from "components/CheckboxLabel";
import CustomButton from "components/CustomButton";
import ProfilePicture from "components/ProfilePicture";
import UsePeople from "HOC/UsePeople";
import { useDispatch, useSelector } from "react-redux";
import { Modal } from "semantic-ui-react";
import { getAllRolesRequest, getLocationsRequest, getPracticeAreaRequest, getUserRolesRequest } from "store/rext";
import { getAllRolesState, getLocations, getPracticeAreaState, getUserRoleState } from "store/selectors";
import { getUserName, ROLES_LABEL } from "utils";
import { ModalHeader } from "../Common";
import TreeDropdown, { IOption } from "components/TreeDropdown";

import "./SelectRoleModal.scss";
import classNames from "classnames";
import { ICON_POSITION } from "components/CustomButton/CustomButton";
import { CUSTOM_SVG_ICON, SVGType } from "components/SvgIcon";
import { INewUser } from "interface";
import Loading from "components/Loading";
import { IRextState } from "baseProvider/rext";

interface Props {
  hideModal: () => void;
  onClose: () => void;
  onSave: (data: any, isEditable: boolean) => void;
  user: INewUser;
  isEditable: boolean;
}

enum ROLE_VIEW_IDS {
  "PAC" = "PAC",
  "CDS" = "CDS",
  "PAP" = "PAP"
}

const ROLES_VIEW = {
  [ROLE_VIEW_IDS.PAC]: {
    title: "SELECT PRACTICE AREA COORDINATOR",
    buttonText: "ADD"
  },
  [ROLE_VIEW_IDS.CDS]: {
    title: "SELECT CAREER DEVELOPMENT SPECIALIST",
    buttonText: "ADD"
  },
  [ROLE_VIEW_IDS.PAP]: {
    title: "SELECT PA ALLOCATION PLANNING",
    buttonText: "ADD"
  }
}

interface RoleViewDetail {
  title: string;
  buttonText: string;
}

function transformNewUserForAPI({ hrId, offices, officesPap, roles, topics }: INewUser): any {
  const apiBody: any = {};
  apiBody.hrId = hrId;
  apiBody.roles = roles.map(role => {
    let canEdit: Array<string> = [];
    if(role === ROLE_VIEW_IDS.CDS) {
      canEdit =  offices;
    } else if(role === ROLE_VIEW_IDS.PAP) {
      canEdit =  officesPap;
    } else if( role === ROLE_VIEW_IDS.PAC) {
      canEdit = topics;
    }
    return {
      role,
      canEdit
    }
  })
  return apiBody;
}

function SelectRoleModal({ hideModal, isEditable, onSave, user: { hrId } }: Props) {
  const { user: peopleUser } = UsePeople(hrId);
  const dispatch = useDispatch();
  const allRolesState = useSelector(getAllRolesState);
  const [roleView, setRoleView] = useState<ROLE_VIEW_IDS>(undefined!);
  const [isForceEditable, setIsForceEditable] = useState<boolean>(false);
  const [defaultTopicsSelected, setDefaultTopicSelected] = useState<string[]>([]);
  const [defaultOfficesSelected, setDefaultOfficesSelected] = useState<string[]>();
  const [defaultPapOfficesSelected, setDefaultPapOfficesSelected] = useState<string[]>();

  const [topicsSelected, setTopicSelected] = useState<string[]>([]);
  const [officesSelected, setOfficesSelected] = useState<string[]>([]);
  const [officesPapSelected, setOfficesPapSelected] = useState<string[]>([]);

  const [roleSelected, setRoleSelected] = useState<{ [key: string]: boolean }>({});
  const locationState = useSelector(getLocations);
  const practiceAreaState = useSelector(getPracticeAreaState);
  const { data: userState, fetching: userRoleFetching, error: userFetchingError} = useSelector(getUserRoleState);


  const roleViewDetail: RoleViewDetail = useMemo(() => {
    return ROLES_VIEW[roleView] as RoleViewDetail;
  }, [roleView]);


  const locationOptions: IRextState = useMemo(() => {
    const newLocationState = locationState.data?.dropdown || [];
    const checkChildrens = (options: IOption[]) => {
      options?.forEach((option: IOption) => {
        if(option.childrens) {
          option.hideCheckbox = true;
          checkChildrens(option.childrens)
        }
      });
    }
    checkChildrens(newLocationState);

    return {
      ...locationState,
      data: {
        ...locationState.data,
        dropdown: newLocationState
      }
    }
  }, [locationState]);

  const handleUpdateButton = () => {
    if(roleView === ROLE_VIEW_IDS.CDS) {
      setRoleSelected(prevsState => ({
        ...prevsState,
        [ROLE_VIEW_IDS.CDS]: officesSelected.length >= 1
      }))
      setDefaultOfficesSelected([...officesSelected]);
      setRoleView(undefined!)
    } else if(roleView === ROLE_VIEW_IDS.PAP) {
      setRoleSelected(prevsState => ({
        ...prevsState,
        [ROLE_VIEW_IDS.PAP]: officesPapSelected.length >= 1
      }))
      setDefaultPapOfficesSelected([...officesPapSelected]);
      setRoleView(undefined!)
    } else if(roleView === ROLE_VIEW_IDS.PAC) {
      setRoleSelected(prevsState => ({
        ...prevsState,
        [ROLE_VIEW_IDS.PAC]: topicsSelected.length >= 1
      }))
      setDefaultTopicSelected([...topicsSelected]);
      setRoleView(undefined!)
    } else {
      onSave(transformNewUserForAPI(
        {
          roles: Object.keys(roleSelected).filter(role => roleSelected[role]),
          topics: topicsSelected,
          offices: officesSelected,
          officesPap: officesPapSelected,
          hrId: hrId
        }
      ), isEditable || isForceEditable
    );
    }
  }

  const renderRole = (role: string, index: number) => {
    return (
      <div className="flex flex-row flex-justify-between" key={`role${index}`}>
      <CheckboxLabel checked={roleSelected[role] || false} labelTxt={(ROLES_LABEL as any)[role]}  handleChange={({ target: { checked } }: any) => {
        if(checked && (role === ROLE_VIEW_IDS.CDS || role === ROLE_VIEW_IDS.PAC || role === ROLE_VIEW_IDS.PAP)) {
          setRoleView(role as ROLE_VIEW_IDS);
        } else {
          setRoleSelected(prevsState => ({
            ...prevsState,
            [role]: checked
          }))
        }
      }} />
      {
        (role === ROLE_VIEW_IDS.CDS || role === ROLE_VIEW_IDS.PAC || role === ROLE_VIEW_IDS.PAP) && <CustomButton
        primaryButton
        transparent
        noOutline
        noPadding
        iconPosition={ICON_POSITION.LEFT}
        iconProps={{
          name: CUSTOM_SVG_ICON.PlusCircle,
          svgType: SVGType.CUSTOM,
          size: "small",
          baseclassname: "text-primary-color"
        }}
        handleClick={() => {
          setRoleView(role as ROLE_VIEW_IDS)
        }}
        />
      }
      </div>
    )
  }

  useEffect(() => {
    if(!allRolesState.data.length) {
      dispatch(getAllRolesRequest())
    }
    dispatch(getUserRolesRequest(hrId));

  }, [dispatch, hrId]);

  useEffect(() => {
    if(userState) {
      const { offices, roles, topics, officesPap} = userState as INewUser;
      const selectedRole: {[key: string]: boolean} = {};
      roles.forEach((role: any) => {
        selectedRole[role] = true
      });
      if(roles.length) {
        setIsForceEditable(true);
      } else {
        setIsForceEditable(false);
      }
      setRoleSelected(selectedRole);
      setDefaultOfficesSelected(offices || []);
      setDefaultPapOfficesSelected(officesPap || []);
      setOfficesSelected(offices || [])
      setOfficesPapSelected(officesPap || [])
      setDefaultTopicSelected(topics || []);
      setTopicSelected(topics || [])
    }

    if(userFetchingError) {
      setIsForceEditable(false);
      setRoleSelected({});
      setDefaultOfficesSelected([]);
      setDefaultPapOfficesSelected([]);
      setOfficesSelected([])
      setOfficesPapSelected([])
      setDefaultTopicSelected([]);
      setTopicSelected([]);
    }
  }, [userState, userFetchingError])

  const renderView = useMemo(() => {
    if (!roleView) {
      return (
        <Fragment>
          <div className="flex flex-row background-grey-shade-7 padding-6">
            <ProfilePicture imageClassName="paaf_grid--avatar_1_5" baseClassName="margin-r-4" profileUrl={peopleUser?.profilePicture} />
            <div className="flex flex-column">
              <div className="text-ld text-bold">{getUserName(peopleUser)}</div>
              <div className="text-md text-grey-shade-2">{peopleUser?.globalTitle}</div>
              <div className="text-sm text-grey-shade-2">{peopleUser?.homeOffice}, {peopleUser?.hostOfficeRegion}</div>
            </div>
          </div>
          <div className="padding-4">
            {
              allRolesState.data.map(renderRole)
            }
          </div>
        </Fragment>
      )
    } else if(roleView === ROLE_VIEW_IDS.PAC) {
      if (!practiceAreaState.data.dropdown.length) {
        dispatch(getPracticeAreaRequest());
      }
      return (
        <TreeDropdown
          popupPosition={"bottom left"}
          showAppliedFilterIcon
          dropdownOptions={practiceAreaState.data.dropdown}
          searchPlaceholder="Search Practice Area / Topic"
          isMulti
          placeholder={"Home Office"}
          isSearchable={true}
          initializeValue={defaultTopicsSelected}
          isLoading={practiceAreaState.fetching}
          handleValueChange={(data: string []) => {
            setTopicSelected(data);
          }}
          disableBackwardSelection
          disableForwardSelection
          isDirectComponent
        />
      )
    } else if (roleView === ROLE_VIEW_IDS.CDS) {
      if (!locationState.data.dropdown.length) {
        dispatch(getLocationsRequest());
      }
      return (
        <TreeDropdown
          popupPosition={"bottom left"}
          showAppliedFilterIcon
          searchPlaceholder="Search Office"
          dropdownOptions={locationOptions?.data.dropdown}
          isMulti
          placeholder={"Home Office"}
          isSearchable={true}
          initializeValue={defaultOfficesSelected}
          isLoading={locationOptions?.fetching}
          handleValueChange={(data: string []) => {
            setOfficesSelected(data);
          }}
          disableBackwardSelection
          disableForwardSelection
          isDirectComponent
        />
      )
    } else if (roleView === ROLE_VIEW_IDS.PAP) {
      if (!locationState.data.dropdown.length) {
        dispatch(getLocationsRequest());
      }
      return (
        <TreeDropdown
          popupPosition={"bottom left"}
          showAppliedFilterIcon
          searchPlaceholder="Search Office"
          dropdownOptions={locationOptions?.data.dropdown}
          isMulti
          placeholder={"Home Office"}
          isSearchable={true}
          initializeValue={defaultPapOfficesSelected}
          isLoading={locationOptions?.fetching}
          handleValueChange={(data: string []) => {
            setOfficesPapSelected(data);
          }}
          disableBackwardSelection
          disableForwardSelection
          isDirectComponent
        />
      )
    }
    return <></>
  }, [roleView, roleSelected, practiceAreaState.data.dropdown, locationState.data.dropdown,
    defaultTopicsSelected, defaultOfficesSelected, defaultPapOfficesSelected, userState, userFetchingError, userRoleFetching, peopleUser
  ]);


  const buttonText = useMemo(() => {
    if(roleViewDetail) {
      return roleViewDetail.title;
    }

    if(isEditable || isForceEditable) {
      return "Save Changes";
    } else {
      return "Add User";
    }

  }, [ roleViewDetail, isEditable, isForceEditable])

  return (
    <Modal
      size="mini"
      className="role-modal"
      dimmer
      open
      onClose={hideModal}
      closeOnEscape={false}
      closeOnDimmerClick={false}
    >
      <ModalHeader title={"Assign/Edit User Role"} toggle={hideModal} />
      <Modal.Content className={classNames([{"tree-selection": roleView}])}>
        {!userRoleFetching && renderView}
        {userRoleFetching && <Loading isGlobal/>}

      </Modal.Content>
      <Modal.Actions>
        <CustomButton round primaryButton buttonText={buttonText} handleClick={handleUpdateButton} />
      </Modal.Actions>
    </Modal>
  )
}

export default SelectRoleModal;