import { IPaginationAction, IPaginationInfo, IPaginationItems } from './interface';

export const defaultPaginationInfo: IPaginationInfo = {
  hasError: {},
  message: {},
  fetching: {},
  hasFetched: {},
  totalNumberOfPages: 0,
  totalElements: 0,
  currentPageNumber: 0,
  operation: {
    error: false,
    fetching: false,
    message: undefined!
  }
};


export const defaultPaginationItems: IPaginationItems = {
  ids: {},
  raw: {}
};

export const defaultPaginationAction: IPaginationAction = {
  meta: {
    actions: {} as any,
    keys: {} as any
  },
  payload: {},
  type: ""
}