import { IListReducer, IListState, IListInfo, IListItems } from './interface';
export function getListState(listReducer: IListReducer, defaultValue: any): IListState {
  const { list, operation}: IListInfo = listReducer.info || {};
  const items: IListItems = listReducer.items;
  const values = (items.ids|| []).map(item => items.raw[item]);

  return {
    message: list?.message,
    operationMessage: operation?.message,
    fetching: list?.fetching,
    error: list?.error,
    operationError: operation?.error,
    operationProgress: operation?.fetching,
    data: values || defaultValue,
    raw: items.raw
  };
}