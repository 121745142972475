import ProfilePicture from "components/ProfilePicture";
import SvgIcon, { CUSTOM_SVG_ICON, SVGType } from "components/SvgIcon";
import { IPeopleUser } from "store/reducers";
import { getUserName } from "utils";

function Detail({user}: {user: IPeopleUser}) {
    const { email, homeOfficeRegion, globalTitle, preferredName } = user || {};
    return (
        <div className="flex flex-column padding-l-4">
            <div className='text-lg margin-b-1'>{preferredName}</div>
            <div className='text-sm margin-b-2'>{globalTitle}</div>
            <div className='text-sm flex flex-row flex-align-center margin-b-1'><SvgIcon svgType={SVGType.CUSTOM} name={CUSTOM_SVG_ICON.Map} size="small" baseclassname={"margin-r-2"}/> {homeOfficeRegion}</div>
            <div className='text-sm flex flex-row flex-align-center'><SvgIcon svgType={SVGType.CUSTOM} name={CUSTOM_SVG_ICON.Email} size="small" baseclassname={"margin-r-2"}/> {email}</div>
        </div>
    )
}

function UserDetail ({user}: {user: IPeopleUser}){
    return(
        <div className="flex flex-row ">
            {user?.hrId && <ProfilePicture profileUrl={`/picture?hrid=${user.hrId}`} baseClassName="margin-r-4" imageClassName="affiliation-card__details--avatar" />}
            <Detail user={user}/>
        </div>
    )
}
export default UserDetail;