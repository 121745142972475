
import { defineRequestType, StandardAction } from ".";

export const PEOPLE_API = defineRequestType("PEOPLE_API");
export const MULTI_PEOPLE_API = defineRequestType("MULTI_PEOPLE_API");


export const peopleInfoActions = {
  request: (peopleId: string): StandardAction => {
    return {
      type: PEOPLE_API.REQUEST,
      payload: {
        peopleId
      }
    }
  },
  success: (peopleId: string, user: any): StandardAction => {
    return {
      type: PEOPLE_API.SUCCESS,
      payload: {
        peopleId,
        user
      }
    }
  },
  failure: (peopleId: string, error: string): StandardAction => {
    return {
      type: PEOPLE_API.FAILURE,
      payload: {
        peopleId,
        error
      }
    }
  }
}

export const multiPeopleInfoActions = {
  request: (peopleIds: Array<string>): StandardAction => {
    return {
      type: MULTI_PEOPLE_API.REQUEST,
      payload: {
        peopleIds
      }
    }
  },
  success: (peopleId: Array<string>, users: Array<any>): StandardAction => {
    return {
      type: MULTI_PEOPLE_API.SUCCESS,
      payload: {
        peopleId,
        users
      }
    }
  },
  failure: (peopleIds: Array<string>, error: string): StandardAction => {
    return {
      type: MULTI_PEOPLE_API.FAILURE,
      payload: {
        peopleIds,
        error
      }
    }
  }
}