export const URL_OPTTION_SUBFIX: string = ";";

export enum FILTER_TYPES {
  MULTI_SELECT = "MULTI_SELECT",
  SINGLE_SELECT = "SINGLE_SELECT",
  TREE_SELECT = "TREE_SELECT",
  BOOLEAN = "BOOLEAN",
  DEFAULT = "DEFAULT",
  DATE_RANGE = "DATE_RANGE",
  DATE = "DATE"
}

export interface IFilterType {
  label: string;
  type: FILTER_TYPES;
  isNumeric?: boolean;
}

export interface IFilterTypes {
  [key: string]: IFilterType;
}


export function isFilterTypeDropdown(type: FILTER_TYPES): boolean {
  return type === FILTER_TYPES.TREE_SELECT || type === FILTER_TYPES.MULTI_SELECT || type === FILTER_TYPES.SINGLE_SELECT 
}