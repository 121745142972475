import HorizontalDropdown from "./HorizontalDropdown";
import { Input, Label } from 'semantic-ui-react';
import SvgIcon, { CUSTOM_SVG_ICON, SVGType } from "components/SvgIcon";
import Loading from "components/Loading";

export const SearchInput = ({ searchPlaceholder, searchTxt, setSearchTxt, handleSearchClear, isSearchActive }: any) => {
  return (
    <Input value={searchTxt} iconPosition='left' placeholder={searchPlaceholder} className='taxanomy-filter-input' onChange={(e: any, data: any) => {
      setSearchTxt(data.value);
    }}>
      <Label basic>
        <SvgIcon svgType={SVGType.CUSTOM} name={CUSTOM_SVG_ICON.SearchIcon} size="small" baseclassname="text-primary-color" />
      </Label>
      <input />
      {isSearchActive && <Label basic onClick={handleSearchClear}>
        <SvgIcon svgType={SVGType.CUSTOM} name={CUSTOM_SVG_ICON.Cross} size="small" />
      </Label>}
    </Input>
  )
}

export const OptionsView = ({ isLoading, dropdownOptions, checkedOptions, handleOptionChange,
  searchTxt, setSearchTxt, isSearchActive, showHideNodes, inputProps, hirerarchy,
  setHirerarchy, isMulti, isDirectComponent }: any) => {
  return (isLoading ? <div style={{ display:"flex", alignItems: "center", height: "100%", minHeight: 100, width: "100%" }}>
    <Loading inverted={isDirectComponent}/>
  </div> : <HorizontalDropdown
    dropdownOptions={dropdownOptions}
    checkedOptions={checkedOptions}
    handleOptionChange={handleOptionChange}
    searchTxt={searchTxt}
    setSearchTxt={setSearchTxt}
    isSearchActive={isSearchActive}
    showHideNodes={showHideNodes}
    inputProps={inputProps}
    hirerarchy={hirerarchy}
    setHirerarchy={setHirerarchy}
    isMulti={isMulti || false}
  />
  )
}