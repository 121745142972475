import BcgLogoSVG from "./Icons/BcgLogo";
import PaffIConSVG from "./Icons/Paff";
import SearchIconSVG from "./Icons/SearchIcon"
import PlusCircleSVG from "./Icons/PlusCircle";
import ArrowDownSVG from "./Icons/ArrowDown";
import DeleteSVG from "./Icons/Delete";
import EditSVG from "./Icons/Edit";
import TriangleDownSVG from "./Icons/TriangleDown";
import CaretUpSVG from "./Icons/CaretUp";
import CaretDownSVG from "./Icons/CaretDown";
import CaretRightSVG from "./Icons/CaretRight";
import CaretLeftSVG from "./Icons/CaretLeft";
import CrossSVG from "./Icons/Cross";
import GridSVG from "./Icons/Grid";
import HamburgerSVG from "./Icons/Hamburger";
import ReloadSVG from "./Icons/Reload";
import TriangleUpSVG from "./Icons/TriangleUp";
import EmailSVG from "./Icons/Email";
import MapSVG from "./Icons/Map";
import CalenderSVG from "./Icons/Calender";
import SortSVG from "./Icons/Sort";
import SortUpSVG from "./Icons/SortUp";
import SortDownSVG from "./Icons/SortDown";
import ExcelSVG from "./Icons/Excel";
import NewTabSVG from "./Icons/NewTab";
import VectorSVG from "./Icons/Vector";
import FileOutlineSVG from "./Icons/FileOutline";
import HandPointerSVG from "./Icons/HandPointer";




export enum CUSTOM_SVG_ICON {
  Paff = "PaffSVG",
  SearchIcon = "SearchIconSVG",
  BcgLogo = "BcgLogoSVG",
  PlusCircle = "PlusCircleSVG",
  ArrowDown = "ArrowDownSVG",
  Delete = "DeleteSVG",
  Edit = "EditSVG",
  TriangleDown = "TriangleDownSVG",
  TriangleUp = "TriangleUpSVG",
  CaretUp = "CaretUpSVG",
  CaretDown = "CaretDownSVG",
  CaretRight = "CaretRightSVG",
  CaretLeft = "CaretLeftSVG",
  Cross = "CrossSVG",
  Grid = "GridSVG",
  Hamburger = "HamburgerSVG",
  Reload = "ReloadSVG",
  Map = "MapSVG",
  Email = "EmailSVG",
  Calender = "CalenderSVG",
  Sort = "SortSVG",
  SortUp = "SortUpSVG",
  SortDown = "SortDownSVG",
  Excel = "ExcelSVG",
  NewTab = "NewTabSVG",
  Vector = "VectorSVG",
  FileOutline = "FileOutline",
  HandPointer = "HandPointer"
}

export const CustomIconRef: any = {
  [CUSTOM_SVG_ICON.Paff]: PaffIConSVG,
  [CUSTOM_SVG_ICON.SearchIcon]: SearchIconSVG,
  [CUSTOM_SVG_ICON.BcgLogo]: BcgLogoSVG,
  [CUSTOM_SVG_ICON.PlusCircle]: PlusCircleSVG,
  [CUSTOM_SVG_ICON.ArrowDown]: ArrowDownSVG,
  [CUSTOM_SVG_ICON.Delete]: DeleteSVG,
  [CUSTOM_SVG_ICON.Edit]: EditSVG,
  [CUSTOM_SVG_ICON.TriangleDown]: TriangleDownSVG,
  [CUSTOM_SVG_ICON.TriangleUp]: TriangleUpSVG,
  [CUSTOM_SVG_ICON.CaretUp]: CaretUpSVG,
  [CUSTOM_SVG_ICON.CaretDown]: CaretDownSVG,
  [CUSTOM_SVG_ICON.CaretRight]: CaretRightSVG,
  [CUSTOM_SVG_ICON.CaretLeft]: CaretLeftSVG,
  [CUSTOM_SVG_ICON.Cross]: CrossSVG,
  [CUSTOM_SVG_ICON.Hamburger]: HamburgerSVG,
  [CUSTOM_SVG_ICON.Grid]: GridSVG,
  [CUSTOM_SVG_ICON.Reload]: ReloadSVG,
  [CUSTOM_SVG_ICON.Map]: MapSVG,
  [CUSTOM_SVG_ICON.Email]: EmailSVG,
  [CUSTOM_SVG_ICON.Calender]: CalenderSVG,
  [CUSTOM_SVG_ICON.Sort]: SortSVG,
  [CUSTOM_SVG_ICON.SortUp]: SortUpSVG,
  [CUSTOM_SVG_ICON.SortDown]: SortDownSVG,
  [CUSTOM_SVG_ICON.Excel]: ExcelSVG,
  [CUSTOM_SVG_ICON.NewTab]: NewTabSVG,
  [CUSTOM_SVG_ICON.Vector] : VectorSVG,
  [CUSTOM_SVG_ICON.FileOutline] : FileOutlineSVG,
  [CUSTOM_SVG_ICON.HandPointer] : HandPointerSVG
};