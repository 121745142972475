import Loading from 'components/Loading'
import { Navigate } from 'react-router-dom'
import URLRoutes from 'urlRoutes'
export const ProtectedRoute = ({ children, isAllowed, isFetching }: any) => {
  if(isFetching) {
    return <Loading isGlobal/>
  } else if (!isAllowed) {
    return <Navigate to={URLRoutes.client.NotAllowed} replace />
  } else {
    return children
  }
}