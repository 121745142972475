import { linkInteraction } from "Analytics";
import Download from "components/Download";
import { HEADRES, SUB_HEADERS } from "components/Header"
import { IColumn, IReactTableComponent} from "components/ReactTable"
import SvgIcon, { CUSTOM_SVG_ICON, SVGType } from "components/SvgIcon"
import { memo } from "react"
import Moment from "react-moment"
import URLRoutes from "urlRoutes"
import { DATE_FORMAT } from "utils"

export function getGridColumns(): IColumn[] {
    return [
        {
            id: "fileNameLabel",
            label: "Name of Report",
            width: 70,
            CustomRowComponent: memo(({ data, header }: IReactTableComponent) => {
                return (
                  <div className="flex flex-row flex-align-center">
                    <SvgIcon svgType={SVGType.CUSTOM} name={CUSTOM_SVG_ICON.Excel} size="huge" baseclassname="margin-r-2"/>
                    {data[header.id]}
                  </div>
                )
              })
        },
        {
            id: "uploadedOn",
            label: "Duration",
            width: 20,
            CustomRowComponent: memo(({ data, header }: IReactTableComponent) => {
                return (
                 <Moment date={data[header.id]} format={DATE_FORMAT}/>
                )
              })
        },
        {
            id: "action",
            label: "Action",
            width: 10,
            CustomRowComponent: memo(({ data, header }: IReactTableComponent) => {
                return (
                    <div className="flex flex-justify-center">
                        <Download handleClick={() => {
                            linkInteraction("Download", "", HEADRES.Reports, SUB_HEADERS.Archived_Reports)
                        }} filename={data.fullFileName} body={undefined!} method="get" urlParams={{filename: data.fullFileName }} queryParams={undefined!} url={URLRoutes.server.archiveReportsDownload}
                        />
                    </div>
                )
            })
        }
    ]
}