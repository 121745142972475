import "./MultiSelectDropdown.scss";

export function getBGColor(isMulti: boolean, isSelected: boolean, isFocused: boolean) {
  if(isMulti || !(isSelected || isFocused)) {
    return "transparent"
  }
  return "#197A56";
}

export function getTextColor(isMulti: boolean, isSelected: boolean, isFocused: boolean, styles: any) {
  if(isMulti || !(isSelected || isFocused)) {
    return styles.color
  }
  return "#FFFFFF";
}

export function getActiveBgColor(isMulti: boolean, isSelected: boolean, data: any) {
  if(isMulti) {
    return "transparent"
  } else {
    if(isSelected) {
      return data.color;
    } else {
      return "#197A56";
    }
  }
}

export const customDropDownStyles = {
  container: (styles: any) => ({
    ...styles,
    height: 35,
    maxHeight: 35,
    minHeight: 35
  }),
  menu: (styles: any) => ({
    ...styles,
    zIndex: 10
  }),
  control: (styles: any, { data, isDisabled, isFocused, isSelected, menuIsOpen, outlineSquare, selectProps }: any) => {
    return {
      ...styles,
      backgroundColor: menuIsOpen ? "#197A56" : 'white',
      borderColor: menuIsOpen || isSelected || isFocused ? "#197A56" : "#7F7F7F",
      boxShadow: menuIsOpen || isSelected || isFocused ? "#197A56" : styles.boxShadow,
      borderRadius: selectProps.outlineSquare ? 0 : 20,
      height: 38,
      maxHeight: 38,
      minHeight: 38,
      ':hover': {
        borderColor: "#32c77f",
        cursor: 'pointer',
        boxShadow: "none"
      }
    }
  },
  option: (styles: any, { data, isDisabled, isFocused, isSelected, isMulti }: any) => {
    return {
      ...styles,
      padding: 5,
      cursor: "pointer",
      backgroundColor: getBGColor(isMulti, isSelected, isFocused),
      color: getTextColor(isMulti, isSelected, isFocused, styles),
      fontSize: "0.75rem",
      minHeight: 25,
      ':active': {
        ...styles[':active'],
        backgroundColor: getActiveBgColor(isMulti, isSelected, data),
        color: "#FFFFFF"
      },
    };
  },
  input: (styles: any) => ({ ...styles, width: "100%", color: "#FFFFFF" }),
  placeholder: (styles: any, { selectProps: { menuIsOpen } }: any) => {
    return { ...styles, fontSize: "0.75rem", color: menuIsOpen ? "#FFFFFF" : '#7F7F7F', fontFamily: "Henderson BCG Sans", fontWeight: 'normal' }
  },
  singleValue: (styles: any, { selectProps:{menuIsOpen},  data }: any) => ({ ...styles, fontSize: "0.75rem", color: menuIsOpen ? "#FFFFFF" : "#111111"  }),
  indicatorSeparator: (styles: any) => ({ display: "none" }),
  loadingIndicator: (styles: any) => ({ ...styles, padding: 0 }),
  dropdownIndicator: (styles: any, { selectProps: { menuIsOpen } }: any) => ({
    ...styles,
    color: menuIsOpen ? "#FFFFFF" : "#177b57",
    // ':hover': {
    //   backgroundColor:"#177b57",
    //   color: "white"
    // },
    alignItems: "center",
    ':hover': {
      ...styles[':hover'],
      color: menuIsOpen ? "#FFFFFF" : "#177b57"
    },
  }),
  menuPortal: (base: any) => ({ ...base, zIndex: 1100 }),
  valueContainer: (styles: any) => ({ ...styles, padding: "1px 8px" }),
  clearIndicator: (styles: any) => ({...styles, paddingRight: 0, color: "#000000",
  ':hover': {
    ...styles[':hover'],
    color: "#000000"
  }})
}