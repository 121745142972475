import { defaultListAction } from './defaultState';
import { IListAction, IListInfo, ActionIdentity, IListItems, IListParams } from './interface';

export const infoReducer =
  (identity: ActionIdentity, defaultListInfo: IListInfo) =>
    (info: IListInfo = defaultListInfo, action: IListAction = defaultListAction): IListInfo => {
      const { type, payload } = action;
      const { message } = payload || {};
      switch (type) {
        case identity.LIST_FETCH.REQUEST:
          return {
            ...info,
            list: {
              error: false,
              fetching: true,
              message: undefined!
            }
          };
        case identity.DELETE_ITEM.REQUEST:
        case identity.MULTIPLE_ADD_ITEMS.REQUEST:
        case identity.ADD_ITEM.REQUEST:
        case identity.EDIT_ITEM.REQUEST:
        case identity.MULTI_EDIT_ITEMS.REQUEST:
          return {
            ...info,
            operation: {
              error: false,
              fetching: true,
              message: undefined!
            }
          };
        case identity.LIST_FETCH.SUCCESS:
          return {
            ...info,
            list: {
              error: false,
              fetching: false,
              message: message!
            }
          };
        case identity.DELETE_ITEM.SUCCESS:
        case identity.MULTIPLE_ADD_ITEMS.SUCCESS:
        case identity.ADD_ITEM.SUCCESS:
        case identity.EDIT_ITEM.SUCCESS:
        case identity.MULTI_EDIT_ITEMS.SUCCESS:

          return {
            ...info,
            operation: {
              error: false,
              fetching: false,
              message: message!
            }
          };
        case identity.LIST_FETCH.FAILURE:
          return {
            ...info,
            list: {
              error: true,
              fetching: false,
              message: message!
            }
          };
        case identity.DELETE_ITEM.FAILURE:
        case identity.MULTIPLE_ADD_ITEMS.FAILURE:
        case identity.ADD_ITEM.FAILURE:
        case identity.EDIT_ITEM.FAILURE:
        case identity.MULTI_EDIT_ITEMS.FAILURE:
          return {
            ...info,
            operation: {
              error: true,
              fetching: false,
              message: message!
            }
          };
        default:
          return info;
      }
    };

function getListSuccess(items: Array<any>, primaryKey: string, transformationOfItem: any) {
  let ids: Array<string> = [];
  let raw: any = {};
  items.forEach((item: any) => {
    const newItem = transformationOfItem ? transformationOfItem(item) : item
    ids.push(newItem[primaryKey])
    raw[newItem[primaryKey]] = newItem;
  });
  return {
    raw,
    ids
  }
}

function getDeleteSuccess(items: IListItems, primaryKey: string, params: IListParams ) {
  const itemIndex = items.ids.findIndex((item) => {
    const deleteItem = params?.urlParams && params?.urlParams[primaryKey];
    return item === deleteItem;
  });

  if(itemIndex >=0) {
    items.ids.splice(itemIndex, 1);
  }
  return {
    ...items
  }
}


function getAddSuccess(reducerItems: IListItems, items: Array<any>, primaryKey: string, transformationOfItem: any) {
  let raw = { ...reducerItems.raw }
  let ids = [...reducerItems.ids];
  const newItem = transformationOfItem ? transformationOfItem(items) : items;
  ids.push(newItem[primaryKey])
  raw[newItem[primaryKey]] = newItem;
  return {
    raw,
    ids
  }
}


function getEditSuccess(reducerItems: IListItems, item: Array<any>, primaryKey: any, transformationOfItem: any) {
  let raw = { ...reducerItems.raw }
  const newItem = transformationOfItem ? transformationOfItem(item) : item;
  if (item[primaryKey]) {
    raw[item[primaryKey]] = newItem
  }
  return {
    ...reducerItems,
    raw
  }
}

function getMultiAddSuccess(reducerItems: IListItems, items: Array<any>, primaryKey: any, transformationOfItem: any) {
  let raw = { ...reducerItems.raw };
  let ids = [...reducerItems.ids];
  (items instanceof Array) && items.forEach((item: any) => {
    const newItem = transformationOfItem ? transformationOfItem(item) : item;
    ids.push(newItem[primaryKey])
    raw[newItem[primaryKey]] = newItem;
  });
  return {
    raw,
    ids
  }
}


function getMultiEditSuccess(reducerItems: IListItems, items: Array<any>, primaryKey: any, transformationOfItem: any) {
  let raw = { ...reducerItems.raw };
  (items instanceof Array) && items.forEach((item: any) => {
    const newItem = transformationOfItem ? transformationOfItem(item) : item;
    raw[newItem[primaryKey]] = newItem;
  });
  return {
    ...reducerItems,
    raw
  }
}



export const itemsReducer =
  (identity: ActionIdentity, defaultListItems: IListItems) =>
    (reducerItems: IListItems = defaultListItems, action: IListAction = defaultListAction): IListItems => {
      const { type, payload, meta } = action;
      const { keys } = meta || {};
      const { items, params } = payload || {};
      const { primaryKey, transformationOfItem } = keys || {};
      switch (type) {
        case identity.LIST_FETCH.REQUEST:
          return defaultListItems;
        case identity.LIST_FETCH.SUCCESS:
          return getListSuccess(items, primaryKey, transformationOfItem);
        case identity.DELETE_ITEM.SUCCESS:
          return getDeleteSuccess(reducerItems, primaryKey, params!)
        case identity.ADD_ITEM.SUCCESS:
          return getAddSuccess(reducerItems, items, primaryKey, transformationOfItem);
        case identity.EDIT_ITEM.SUCCESS:
          return getEditSuccess(reducerItems, items, primaryKey, transformationOfItem);
        case identity.MULTIPLE_ADD_ITEMS.SUCCESS:
          return getMultiAddSuccess(reducerItems, items, primaryKey, transformationOfItem);
        case identity.MULTI_EDIT_ITEMS.SUCCESS:
          return getMultiEditSuccess(reducerItems, items, primaryKey, transformationOfItem)
        default:
          return reducerItems;
      }
    };