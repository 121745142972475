import CustomButton from "components/CustomButton";
import { ICON_POSITION } from "components/CustomButton/CustomButton";
import { CUSTOM_SVG_ICON, SVGType } from "components/SvgIcon";
import { Fragment, useMemo } from "react";
import Moment from "react-moment";
import { useSelector } from "react-redux";
import { getFormValues } from "redux-form";
import { DATE_FORMAT, FILTER_TYPES, IFilterTypes } from "utils";
interface Props {
  filterTypes: IFilterTypes;
  removeValueFromState: (keyname: string) => void;
  allOptions?: any;
}

function FilteTile({ children, removeValueFromState }: any) {
  return (
    <div className="flex flex-row flex-justify-between filter-applied-container text-secondary-color border-1 border-grey-shade-2 border-radius-1 padding-1 margin-r-2 margin-b-2 text-sm" >
      {children}
      <CustomButton
        handleClick={removeValueFromState}
        transparent
        noOutline
        secondaryButton
        iconPosition={ICON_POSITION.LEFT}
        iconProps={{
          name: CUSTOM_SVG_ICON.Cross,
          svgType: SVGType.CUSTOM,
          size: "small"
        }}
        baseclassname="width-20p"
      />
    </div>
  )
}

const getValueForMulti = (label: string, value: any, keyname: string, allOptions: any) => {
  const optionsLabel = allOptions[keyname] || {};
  if (value.length) {
    return <Fragment><span className="text-bold text-inline">{label}</span>: ({value.map((valueId: string) => optionsLabel[valueId] || valueId).join(" | ")})</Fragment>
  }
  return undefined!
}

const getValues: any = {
  [FILTER_TYPES.MULTI_SELECT]: getValueForMulti,
  [FILTER_TYPES.TREE_SELECT]: getValueForMulti,
  [FILTER_TYPES.BOOLEAN]: (label: string, value: any) => {
    return <Fragment><span className="text-bold text-inline">{label}</span>: {value ? "Yes" : "No"}</Fragment>
  },
  [FILTER_TYPES.DATE_RANGE]: (label: string, value: any) => {
    if (value.length >= 2) {
      const startDate = <Moment date={value[0]} format={DATE_FORMAT} />
      const endDate = <Moment date={value[1]} format={DATE_FORMAT} />
      return (<Fragment>
        <div>
            <span className="text-bold text-inline">{label}</span>: {startDate} -
          {endDate}
        </div>
      </Fragment>)
    }
    return undefined;
  },
  [FILTER_TYPES.DATE]: (label: string, value: any) => {
    return <Fragment><span className="text-bold text-inline">{label}</span>: {<Moment date={value} format={DATE_FORMAT} />}</Fragment>
  },
  [FILTER_TYPES.SINGLE_SELECT]: (label: string, value: any, keyname: string, allOptions: any) => {
    return <Fragment><span className="text-bold text-inline">{label}</span>: {allOptions[keyname] ? allOptions[keyname][value] : value}</Fragment>
  },
  [FILTER_TYPES.DEFAULT]: (label: string, value: any, allOptions: any) => {
    return <Fragment><span className="text-bold text-inline">{label}</span>: {value}</Fragment>
  }
}


function NewFilterApplied({removeValueFromState, filterTypes, allOptions}: Props) {

  const filterState: any = useSelector((state: any) => getFormValues("FilterContainer")(state))

  const removeValueForKeyname = (keyname: string) => () => {
    removeValueFromState(keyname);
  }

  const filterTabs: JSX.Element[] = useMemo(() => {
    const elments: Array<JSX.Element> = [];
    Object.keys(filterState || {}).forEach((keyname: string, index: number) => {
      const value: any = filterState[keyname];
      let element: JSX.Element = undefined!;
      const commonProps = {
        key: `filter${index}`,
        removeValueFromState: removeValueForKeyname(keyname)
      }
      if (filterTypes[keyname] && value) {
        const { type, label } = filterTypes[keyname];
        let allValues = value;
        if(keyname == 'HOST_OFFICE' || keyname == 'HOME_OFFICE') {
          allValues = allValues.map((item: any) => item.split('_')[0]);
        }
        const elementValue = getValues[type](label, allValues, keyname, allOptions);
        if (elementValue) {
          elments.push(<FilteTile {...commonProps}>{elementValue}</FilteTile>);
        }
      }
      elments.push(element);
    })
    return elments.filter(elem => elem !== undefined);
  }, [filterState, allOptions])


  return filterTabs.length ? (
    <div className="padding-4 margin-t-4 flex flex-row background-grey-shade-7 text-secondary-color">
        <div className="margin-r-2 padding-t-1 text-inline">Filters applied</div>
        <div className="flex flex-row flex-wrap">
          {filterTabs}
        </div>
    </div>
  ) : null
  

}

export default NewFilterApplied;