import { Popup } from 'semantic-ui-react'
import SvgIcon, { CUSTOM_SVG_ICON, SVGType } from 'components/SvgIcon';
import CustomButton from 'components/CustomButton';
import { ICON_POSITION } from 'components/CustomButton/CustomButton';
import './PresentFIlterDropdown.scss'
import { useState } from 'react';
import classNames from 'classnames';
import { useSelector } from 'react-redux';
import { getPresetFilterState } from 'store/selectors';
import { IPreset } from './IPreset';

export interface Props {
  label?: string;
  handlePreset: (action: "DELETE" | "EDIT" | "VIEW", value: IPreset) => void;
}
const CustomPopUp = ({ label, handlePreset }: Props) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const { data, error, message, fetching } = useSelector(getPresetFilterState);

  const handleOnOpen = () => {
    setIsOpen(true);
  }

  const handleOnClose = () => {
    setIsOpen(false);
  }


  const handleAction = (eventType: string, value: IPreset) => (event: any) => {
    event.stopPropagation();
    handleOnClose();
    handlePreset(eventType as any, value);
  }

  const renderPresentFilter = (value: IPreset, index: number) => {
    const { id, presetName } = value;
    return (
    <div key={`PresentFIlter${id}`} className='flex flex-row present-filter-item cursor-pointer' onClick={ handleAction("VIEW",value)}>
      <div className='padding-r-1 width-75'>{presetName}</div>
      <div className='width-25 flex flex-row flex-align-center'>
          <CustomButton
            transparent
            iconPosition={ICON_POSITION.LEFT} primaryButton
            iconProps={{
              svgType: SVGType.CUSTOM,
              name: CUSTOM_SVG_ICON.Delete,
              size: "small"
            }}
            noOutline
            handleClick={handleAction("DELETE",value)}/>
          <CustomButton
            transparent
            iconPosition={ICON_POSITION.LEFT} primaryButton
            iconProps={{
              svgType: SVGType.CUSTOM,
              name: CUSTOM_SVG_ICON.Edit,
              size: "small"
            }}
            noOutline
            handleClick={handleAction("EDIT",value)}
          />
      </div>
    </div>
    )
  };

  return (
    <Popup
      open={isOpen}
      onOpen={handleOnOpen}
      onClose={handleOnClose}
      trigger={
        <div className={classNames(['present-filter-content padding-1', {"active": isOpen}])}>
          <span className='title'>{label}</span>
          <SvgIcon baseclassname="margin-l-2" svgType={SVGType.CUSTOM} name={CUSTOM_SVG_ICON.TriangleDown} size="small" />
        </div>
      }
      on='click'
      closeOnEscape={false}
      position='bottom center'
      basic
      className={classNames(['present-filter-dropdown'])}>
      <Popup.Content>
        {
          data.length === 0 && !error && !fetching &&  <div className='no-preset-container padding-4 text-primary-color'>
            Please apply and save your filters
          </div>
        }
        {
          !fetching && error && <div>{message}</div>
        }
        {!error && !fetching && data.map(renderPresentFilter)}
      </Popup.Content>
    </Popup>
  )
}
export default CustomPopUp