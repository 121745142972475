import SvgIcon, { CUSTOM_SVG_ICON, SVGType } from "components/SvgIcon";
import { NavLink, useNavigate } from "react-router-dom";
import URLRoutes from "urlRoutes";
import ItemWithPopup, { IItemMenuOptions } from "./ItemWithPopup";
import "./Header.scss";
import { useOktaAuth } from '@okta/okta-react';
import { getUserDetail, getUserName } from "utils";
import UsePeople from "HOC/UsePeople";
import ProfilePicture from "components/ProfilePicture";
import { forwardRef, Fragment, useEffect, useMemo } from "react";
import { Feature } from "utils/Features/Features";
import { useSelector } from "react-redux";
import { getFeaturesState } from "store/selectors";
import { setAnalyticsStaffEmailID, setHeaderAndSubheaderClick } from "Analytics";

export enum HEADRES {
  My_Affiliations = "My Affiliations",
  Search_PAAF = "Search PAAF",
  Learn_More_About_PAAF = "Learn More About PAAF",
  Reports = "Reports",
  Admin_Options = "Admin Options",
}

export enum SUB_HEADERS {
  Change_Report="Change Report",
  Archived_Reports="Archived Reports",
  Practice_Area_and_Topic_Mapping="Practice Area and Topic Mapping",
  PAAF_Users="PAAF Users",
  Access_Hierarchy="Access Hierarchy"
}


function Header(props: any, ref: any) {
  const navigate = useNavigate();
  const { authState } = useOktaAuth();
  const { hrid } = getUserDetail(authState!);
  const { data }: { data: Feature; } = (useSelector(getFeaturesState))!;

  const { user } = UsePeople(hrid);

  const navigateToHome = () => {
    navigate(URLRoutes.client.Home);
  }


  const Report: Array<IItemMenuOptions> = useMemo(() => {
    const reportOptions: Array<IItemMenuOptions> = [];
    if (data?.getChangesReportFeatures().isAllowed) {
      reportOptions.push({
        text: SUB_HEADERS.Change_Report,
        outsideApp: false,
        to: URLRoutes.client.ChangeReport
      })
    }

    if (data?.getArchiveReportFeatures().isAllowed) {
      reportOptions.push({
        text: SUB_HEADERS.Archived_Reports,
        outsideApp: false,
        to: URLRoutes.client.ArchiveReport
      })
    }

    if (data?.getPracticeAreaMappingFeatures().isAllowed) {
      reportOptions.push({
        text: SUB_HEADERS.Practice_Area_and_Topic_Mapping,
        outsideApp: false,
        to: URLRoutes.client.PracticeAreaTopic
      })
    }

    return reportOptions;
  }, [data])

  const AdminOption: Array<IItemMenuOptions> = useMemo(() => {
    const reportOptions: Array<IItemMenuOptions> = [];
    if (data?.getPaafUserFeatures().isAllowed) {
      reportOptions.push({
        text: SUB_HEADERS.PAAF_Users,
        outsideApp: false,
        to: URLRoutes.client.PaafUser
      })
    }

    if (data?.getAccessHirerarchyFeatures().isAllowed) {
      reportOptions.push({
        text: SUB_HEADERS.Access_Hierarchy,
        outsideApp: true,
        to: "https://bcgcloud.sharepoint.com/:x:/s/RC_DRS/EcJ53nLQg_lJo_Q1pVIckxEBUK-hJq5F-GoWipcpzD6e6A?e=SIvUKw"
      })
    }
    return reportOptions
  }, [data]);

  useEffect(() => {
    if (user?.email) {
      const { email } = user;
      setAnalyticsStaffEmailID(email);
    }
  }, [user])

  return (
    <Fragment>
      {/* <div className="flex flex-justify-center width-100 padding-2 background-primary-color text-white-color">To access the old Practice Area Affiliation (PAAF) landing page, click <a className="margin-l-1 text-white-color no-hover text-underline" href="https://legacy-paaffiliation.bcg.com/" target="_blank">here</a></div> */}
      <header ref={ref} className="flex flex-row flex-justify-between padding-0 padding-r-10 padding-l-10">
        <div className="flex flex-align-center">
          <div onClick={navigateToHome} className="logo"><SvgIcon svgType={SVGType.CUSTOM} name={CUSTOM_SVG_ICON.Paff} /></div>
          {data?.getMyAffiliationFeatures().isAllowed && <NavLink onClickCapture={() => {
            setHeaderAndSubheaderClick(HEADRES.My_Affiliations)
          }} id="my-affiliation-link" end className="menu-link-container height-100 padding-0 padding-r-6 padding-l-6 flex flex-row flex-align-center" to={URLRoutes.client.Home}><div className="menu-link">{HEADRES.My_Affiliations}</div></NavLink>}
          {data?.getSearchPaafFeatures().isAllowed && <NavLink onClickCapture={() => {
            setHeaderAndSubheaderClick(HEADRES.Search_PAAF)
          }} id="search-paaf-link" end className="menu-link-container height-100 padding-0 padding-r-6 padding-l-6 flex flex-row flex-align-center" to={URLRoutes.client.SearchPaff}><div className="menu-link">{HEADRES.Search_PAAF}</div></NavLink>}
          {data?.getReportFeatures().isAllowed && <ItemWithPopup id="report-link" options={Report} label={HEADRES.Reports} />}
          {data?.getAdminOptionsFeatures().isAllowed && <ItemWithPopup id="admin-option" options={AdminOption} label={HEADRES.Admin_Options} />}
          {data?.getLearnMoreFeatures().isAllowed && <a onClickCapture={() => {
            setHeaderAndSubheaderClick(HEADRES.Learn_More_About_PAAF)
          }} id="learn-more-link" className="menu-link-container height-100 padding-0 padding-r-6 padding-l-6 flex flex-row flex-align-center" href="https://internal.bcg.com/Microsites/Functions/PAAF" target="_blank">
            <div className="menu-link flex flex-row">
              {HEADRES.Learn_More_About_PAAF}
              <SvgIcon svgType={SVGType.CUSTOM} name={CUSTOM_SVG_ICON.Vector} size="huge" baseclassname="margin-l-1" />
            </div>
          </a>}
        </div>

        <div className=" flex flex-align-center flex-justify-end">
          {
            user?.profilePicture && <ProfilePicture baseClassName="padding-l-5" profileUrl={user?.profilePicture} />
          }
          <div className="padding-l-2">{getUserName(user)}</div>
        </div>
      </header>
    </Fragment>
  );
}

export default forwardRef(Header);