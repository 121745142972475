import CustomButton from "components/CustomButton";
import FilterApplied from "components/FilterApplied/NewFIlterApplied";
import PresentFilterDropdown, { IPreset } from "components/PresentFilterDropdown";
import { ModalsType } from "containers/ModalManager/ModalManager";
import { MutableRefObject, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Form, getFormValues, reduxForm } from "redux-form";
import { modalOperation } from "store/actions";
import { addPresetRequest, deletePresetRequest, editPresetRequest } from "store/list";
import { IFilterTypes, isFilterTypeDropdown, transformFormValueToPresetValue, transformPresetValueToFormValue } from "utils";


export interface Props {
  allOptions: any;
  handleFilterApplied: any;
  children: any;
  handleResetClick: any;
  itemsRef: MutableRefObject<any>;
  DEFAULT_FILTER_VALUE: any;
  FilterTypes: IFilterTypes;
  FILTER_IDS: {[key: string]: string};
  title?: string;
  showPresetFilters?: boolean;
  titleClass?: string;
  handlePresetTracking?: (type: string) => void;
  captions?: any;
}

const handleEditOrSavePreset = (dispatch: any, values: IPreset, formValues:any, FilterTypes: IFilterTypes) => {
  const searchAttributes = values ? [] : transformFormValueToPresetValue(formValues, FilterTypes);
  dispatch(modalOperation.showModal(ModalsType.SaveFilterModal, {
    isEditable: !!values,
    initialValues: {
      presetDescription: values?.presetDescription || "",
      presetName: values?.presetName || "",
      searchAttributes
    },
    onSave: (data: any) => {
      if (!values) {
        dispatch(addPresetRequest(data));
      } else {
        dispatch(editPresetRequest(data, values.id));
      }
      dispatch(modalOperation.hideModal());
    },
    onClose: () => {
      dispatch(modalOperation.hideModal());
    }
  }));
}


function FilterComponentForm(props: any) {

  const { allOptions, handleSubmit, handleFilterApplied, children, handleResetClick, itemsRef,
    DEFAULT_FILTER_VALUE, FilterTypes, FILTER_IDS, hideResetButton,
    submitButtonText, title, showPresetFilters, titleClass, handlePresetTracking, captions  } = props;
  
  const formValues = useSelector((state: any) => getFormValues('FilterContainer')(state))

  const dispatch = useDispatch();

  const clearValueForKey = (keyname: string, defaultValues: any, value?: any) => {
    const { type } = FilterTypes[keyname];
    const newVal = value || defaultValues[keyname];
    props.change(keyname, newVal);
    if (isFilterTypeDropdown(type)) {
      if (value) {
        itemsRef?.current[keyname]?.forceResetToOptions(newVal);
      } else {
        itemsRef?.current[keyname]?.clearAllValues();
      }
    }
  }

  const clearValueForKeyName = (keyname: string) => {
    clearValueForKey(keyname, DEFAULT_FILTER_VALUE);
  }

  const handleSubmitButton = (value: any) => {
    handleFilterApplied(value);
  }

  

  const handleDeletePreset = (id: string) => {
    dispatch(modalOperation.showModal(ModalsType.ConfimationDeleteModal, {
      onSave: () => {
        dispatch(deletePresetRequest(id))
        dispatch(modalOperation.hideModal());
      },
      onClose: () => {
        dispatch(modalOperation.hideModal());
      },
      title: "Confirmation",
      message: "Are you sure that you want to delete the preset?",
      primaryBtnTitle: "Delete"
    }));
  }

  const handleTracking = (type: string) => {
    if(typeof handlePresetTracking === "function") {
      handlePresetTracking(type);
    }
  }

  const handlePreset = (action: string, value: IPreset) => {
    if (action === "DELETE") {
      handleTracking("Delete Preset");
      handleDeletePreset(value.id);
    } else if (action === "EDIT") {
      handleTracking("Edit Preset");
      handleEditOrSavePreset(dispatch, value, {}, FilterTypes)
    } else {
      handleTracking("Apply Preset");
      const presetFormValues = transformPresetValueToFormValue(value.searchAttributes, FilterTypes);
      Object.keys(DEFAULT_FILTER_VALUE).forEach((keyname: string) => {
        clearValueForKey(keyname, DEFAULT_FILTER_VALUE, presetFormValues[keyname]);
      });
      handleFilterApplied(presetFormValues);
    }
  }

  const handlePresetButton = () => {
    handleEditOrSavePreset(dispatch, undefined!, formValues, FilterTypes)
  }

  useEffect(() => {
    function applyFilter({ key }: any) {
      if (key === 'Enter') {
        props.submit();
      }
    }
    window.addEventListener("keypress", applyFilter);
    return () => window.removeEventListener("keypress", applyFilter);
  }, []);

  const resetFilter = () => {
    Object.values(FILTER_IDS).forEach((keyname: any) => {
      clearValueForKey(keyname, DEFAULT_FILTER_VALUE);
    });
    handleResetClick();
  }

  return (
    <div className="margin-t-2 padding-10 background-white-color">
      <Form onSubmit={handleSubmit(handleSubmitButton)}>
        <div className='flex flex-row flex-justify-between'>
          <div className="flex flex-row flex-align-center">
            <div className={titleClass || ""}>{title}</div>
            {showPresetFilters && <span className='text-primary-color padding-l-3 cursor-pointer'>
              <PresentFilterDropdown label='APPLY EXISTING PRESET' handlePreset={handlePreset} />
            </span>
            }
          </div>
          {captions}
        </div>
        {children}

        <FilterApplied filterTypes={FilterTypes} removeValueFromState={clearValueForKeyName} allOptions={allOptions} />

        <div className="margin-t-6 maring-b-6 flex flex-row flex-justify-between">
          <div>
            <CustomButton
              round
              primaryButton
              type="button"
              handleClick={props.submit}
              buttonText={submitButtonText || "Submit"}
              buttonTextClass=" text-md text-uppercase"
            />

            {showPresetFilters && <CustomButton
              round
              greyButton
              type="button"
              handleClick={handlePresetButton}
              buttonText="SAVE AS PRESET"
              buttonTextClass="text-md text-uppercase"
              baseclassname="margin-l-4"
            />
            }
          </div>

          {!hideResetButton && <CustomButton
            greyButton
            round
            type="button"
            handleClick={resetFilter}
            buttonText="reset"
            buttonTextClass="text-md text-uppercase "
          />
          }
        </div>
      </Form>
    </div>
  )
}

const FilterContainer: any = reduxForm({
  form: "FilterContainer"
})(FilterComponentForm);

export default FilterContainer;