
import FilterContainer from "components/FilterContainer";
import { FormSelect } from "components/FormInputs";
import NoFilterBanner from "components/NoFilterBanner";
import moment from "moment";
import { Fragment, useEffect, useMemo, useRef, useState } from "react";
import { Field, SubmissionError } from "redux-form";
import { DEFAULT_FILTER_VALUE, FilterTypes, FILTER_IDS, IFilter } from "./IFilter";

export interface Props {
  handleFilterApplied: any; // On submit this will be called
  isFilterApplied: boolean; // is filter applied or not
  showNoFilterBanner: boolean; //show no filter banner
  filterState: IFilter; // current filter state
  handleResetClick: any; // on reset this will called
  hideResetButton?: boolean;
  submitButtonText?: string;
  noFilterMessage?: string;
}

const ArchiveReportOptions: any = {
  PA_MATRIX_REPORT: "PA Matrix Report",
  ALL_AFFILIATIONS_GRID_REPORT: "All Affiliations Grid Report",
  ALL_AFFILIATIONS_TABLE_REPORT: "All Affiliations Table Report",
  MATCHING_AFFILIATIONS_REPORT: "Matching Affiliations Report"
}

const Months: any = {
  "1": "January",
  "2": "Feburary",
  "3": "March",
  "4": "April",
  "5": "May",
  "6": "June",
  "7": "July",
  "8": "August",
  "9": "September",
  "10": "October",
  "11": "November",
  "12": "December"
}

const years = (year: number) => {
  const options: any = {};
  [...new Array(moment().year() - (year - 1))].forEach((item: any, index: number) => {
    const val = year + index;
    options[val] = val;
  })
  return options;
}

function FilterReportsForm(props: Props) {
  const { handleFilterApplied, filterState, isFilterApplied, showNoFilterBanner, handleResetClick, hideResetButton, submitButtonText, noFilterMessage } = props;
  const itemsRef: any = useRef({});
  const [initialValues, setInitialValues] = useState<any>(undefined!);


  const allOptions: any = useMemo(() => {
    return {
      [FILTER_IDS.MATRIX_REPORT]: ArchiveReportOptions,
      [FILTER_IDS.ALL_MONTHS]: Months,
      [FILTER_IDS.LAST_FOUR_YEARS]: years(2019)
    }
  }, [])

  useEffect(() => {
    setInitialValues(filterState);
  }, [])

  const handleSubmitButton = (value: any) => {
    if (!value[FILTER_IDS.MATRIX_REPORT]) {
      handleFilterApplied(DEFAULT_FILTER_VALUE);
      throw new SubmissionError({ [FILTER_IDS.MATRIX_REPORT]: 'Required' })
    }
    handleFilterApplied(value);
  }

  return (initialValues ? (
    <Fragment>
      <FilterContainer
        title={"Archived Reports"}
        titleClass="text-lg text-bold"
        allOptions={allOptions}
        handleFilterApplied={handleSubmitButton}
        itemsRef={itemsRef}
        DEFAULT_FILTER_VALUE={DEFAULT_FILTER_VALUE}
        FilterTypes={FilterTypes}
        FILTER_IDS={FILTER_IDS}
        initialValues={filterState}
        handleResetClick={handleResetClick}
        hideResetButton={hideResetButton}
        submitButtonText={submitButtonText}
      >
        <div className="margin-t-2 background-white-color flex flex-row ">
          <Field
            name={FILTER_IDS.MATRIX_REPORT}
            component={FormSelect}
            options={allOptions[FILTER_IDS.MATRIX_REPORT]}
            placeholder={FilterTypes[FILTER_IDS.MATRIX_REPORT].label}
            popupPosition={"bottom center"}
            label=""
            inputClassName="width-100"
            controlShouldRenderValue
            baseClassName="width-25"
            defaultValues={initialValues[FILTER_IDS.MATRIX_REPORT]}
            dropdownRef={(ref: any) => itemsRef.current[FILTER_IDS.MATRIX_REPORT] = ref}
            isClearable={true}
          />
          <Field
            name={FILTER_IDS.ALL_MONTHS}
            component={FormSelect}
            options={allOptions[FILTER_IDS.ALL_MONTHS]}
            placeholder={FilterTypes[FILTER_IDS.ALL_MONTHS].label}
            label=""
            inputClassName="width-100"
            baseClassName="width-25 padding-l-4"
            controlShouldRenderValue
            defaultValues={initialValues[FILTER_IDS.ALL_MONTHS]}
            dropdownRef={(ref: any) => itemsRef.current[FILTER_IDS.ALL_MONTHS] = ref}
            isClearable={true}
          />
          <Field
            name={FILTER_IDS.LAST_FOUR_YEARS}
            component={FormSelect}
            options={allOptions[FILTER_IDS.LAST_FOUR_YEARS]}
            placeholder={FilterTypes[FILTER_IDS.LAST_FOUR_YEARS].label}
            label=""
            inputClassName="width-100"
            baseClassName="width-25 padding-l-4"
            controlShouldRenderValue
            defaultValues={initialValues[FILTER_IDS.LAST_FOUR_YEARS]}
            dropdownRef={(ref: any) => itemsRef.current[FILTER_IDS.LAST_FOUR_YEARS] = ref}
            isClearable={true}
          />
        </div>
      </FilterContainer>
      {
        showNoFilterBanner && !isFilterApplied && <NoFilterBanner message={noFilterMessage || "Use the above filters to find PAAF Users"} />
      }
    </Fragment>
  ) : null
  )
}
export default FilterReportsForm;
