import { defaultPaginationAction } from './defaultState';
import { IPaginationAction, IPaginationInfo, ActionIdentity, IPaginationItems } from './interface';

export const infoReducer =
  (identity: ActionIdentity, defaultPaginationInfo: IPaginationInfo) =>
    (info: IPaginationInfo = defaultPaginationInfo, action: IPaginationAction = defaultPaginationAction) => {
      const { type, payload } = action;
      const { params, message, totalNumberOfPages, totalElements } = payload || {};
      const { pageNumber, resetPagination } = params || {};

      switch (type) {
        case identity.CHANGE_PAGE.REQUEST:
          return {
            ...info,
            currentPageNumber: pageNumber
          }
        case identity.PAGINATION_FETCH.REQUEST:
          if (resetPagination) {
            return {
              ...defaultPaginationInfo,
              fetching: {
                [pageNumber!]: true
              },
              currentPageNumber: pageNumber
            }
          }
          return {
            ...info,
            fetching: {
              ...info.fetching,
              [pageNumber!]: true
            },
            hasError: {
              ...info.hasError,
              [pageNumber!]: false
            },
            message: {
              ...info.message,
              [pageNumber!]: undefined!
            },
            currentPageNumber: pageNumber
          };
        case identity.PAGINATION_FETCH.SUCCESS:
          return {
            ...info,
            fetching: {
              ...info.fetching,
              [pageNumber!]: false
            },
            hasError: {
              ...info.hasError,
              [pageNumber!]: false
            },
            hasFetched: {
              ...info.hasFetched,
              [pageNumber!]: true
            },
            message: {
              ...info.message,
              [pageNumber!]: message!
            },
            totalNumberOfPages: totalNumberOfPages!,
            totalElements: totalElements!,
            currentPageNumber: pageNumber
          };
        case identity.PAGINATION_FETCH.FAILURE:
          return {
            ...info,
            fetching: {
              ...info.fetching,
              [pageNumber!]: false
            },
            hasError: {
              ...info.hasError,
              [pageNumber!]: true
            },
            message: {
              ...info.message,
              [pageNumber!]: message!
            },
            currentPageNumber: pageNumber,
            totalElements: 0
          };

        case identity.ADD_PAGE_ITEM.REQUEST:
        case identity.DELETE_PAGE_ITEM.REQUEST:
        case identity.UPDATE_PAGE_ITEM.REQUEST:
          return {
            ...info,
            operation: {
              error: false,
              fetching: true,
              message: undefined!
            }
          }
        case identity.ADD_PAGE_ITEM.SUCCESS:
        case identity.DELETE_PAGE_ITEM.SUCCESS:
        case identity.UPDATE_PAGE_ITEM.SUCCESS:
          return {
            ...info,
            operation: {
              error: false,
              fetching: false,
              message: message!
            }
          }
        case identity.ADD_PAGE_ITEM.FAILURE:
        case identity.DELETE_PAGE_ITEM.FAILURE:
        case identity.UPDATE_PAGE_ITEM.FAILURE:
          return {
            ...info,
            operation: {
              error: true,
              fetching: false,
              message: message!
            }
          }
        default:
          return info;
      }
    };
export const itemsReducer =
  (identity: ActionIdentity, defaultPaginationItems: IPaginationItems) =>
    (reducerItems: IPaginationItems = defaultPaginationItems, action: IPaginationAction = defaultPaginationAction) => {
      const { type, payload, meta } = action;
      const { keys } = meta || {};
      const { items, params } = payload || {};
      const { pageNumber, resetPagination } = params || {};
      const { primaryKey, transformationOfItem } = keys || {};
      switch (type) {
        case identity.PAGINATION_FETCH.REQUEST:
          if (resetPagination) {
            return defaultPaginationItems;
          }
          return reducerItems;
        case identity.PAGINATION_FETCH.SUCCESS:
          const newPageIds: Array<string> = [];
          const rawData: any = {};
          items.forEach((item: any) => {
            const transformItem = transformationOfItem ? transformationOfItem(item) : item;
            newPageIds.push(transformItem[primaryKey])
            rawData[transformItem[primaryKey]] = transformItem;
          });
          return {
            ...reducerItems,
            raw: {
              ...reducerItems.raw,
              ...rawData
            },
            ids: {
              ...reducerItems.ids,
              [pageNumber!]: newPageIds
            }
          };
        case identity.PAGINATION_FETCH.FAILURE:
          return {
            ...reducerItems,
            totalElements: 0,
            raw: {
            },
            ids: {
            }
          }
        default:
          return reducerItems;
      }
    };