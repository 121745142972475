import { IPresetItem } from "interface";
import { getDateRangeString } from "utils/common";
import { FILTER_TYPES, IFilterTypes } from "./utils";

function convertArrayOptions (filterValue: any, filterKey: string) {
  if (filterValue?.length >= 1) {
    return {
      key: filterKey,
      values: filterValue
    }
  }
  return undefined!;
}

const getValues = {
  [FILTER_TYPES.MULTI_SELECT]: convertArrayOptions,
  [FILTER_TYPES.TREE_SELECT]: convertArrayOptions,
  [FILTER_TYPES.SINGLE_SELECT]: (filterValue: any, filterKey: string) => {
    if (filterValue) {
      return {
        key: filterKey,
        values: [filterValue]
      }
    }
    return undefined;
  },
  [FILTER_TYPES.DATE_RANGE]: (filterValue: any, filterKey: string) => {
    const range = getDateRangeString(filterValue)
    if (range.length >= 2) {
      return {
        key: filterKey,
        values: [`${range[0]}`, `${range[1]}`]
      }
    }
    return undefined;
  },
  [FILTER_TYPES.DATE]: (filterValue: any, filterKey: string) => {
    if (filterValue) {
      return {
        key: filterKey,
        values: [`${filterValue.getTime()}`]
      };
    }
    return undefined;
  },
  [FILTER_TYPES.BOOLEAN]: (filterValue: any, filterKey: string) => {
    if (filterValue) {
      return {
        key: filterKey,
        values: ["true"]
      }
    }
    return undefined;
  },
  [FILTER_TYPES.DEFAULT]: (filterValue: any, filterKey: string) => {
    if (filterValue.length) {
      return {
        key: filterKey,
        values: [filterValue]
      };
    }
    return undefined;
  }
}


export function transformFormValueToPresetValue(formValue: any, filterTypes: IFilterTypes): IPresetItem[] {
  const presetItems: IPresetItem[] = [];
  const filterKeys = Object.keys(formValue || {});

  filterKeys.forEach((filterKey: string) => {
    const filterValue = formValue[filterKey];
    const { type } = filterTypes[filterKey] || {};
    if (type) {
      const filterKeyValue = getValues[type](filterValue, filterKey);
      if (filterKeyValue) {
        presetItems.push(filterKeyValue);
      }
    }
  });
  return presetItems;
}