export function setApplicationTitle(title: string): void {
  document.title = `${title}`;
}

export function getDateRangeString(value: Array<Date>): Array<string> {
  return value.map(date => date ? `${date.getTime()}` : "");
}

export function getDateRangeValue(value: string, URL_OPTTION_SUBFIX: string ): Array<Date> {
  const dates = value.split(URL_OPTTION_SUBFIX);
  if(dates.length >=2) {
    return dates.map(date => date ? new Date(parseInt(date,10)): new Date())
  }
  return [];
}

export function transformStringToSmartOptions(convertToNumber: boolean, queryString: string, URL_OPTTION_SUBFIX: string, toArray?: boolean): string | number | Array<string | number> {
  if(queryString === undefined || queryString === null  || queryString === "" || queryString.length === 0) {
    return toArray ? [] : undefined!
  }

  if(toArray) {
    const options = queryString.split(URL_OPTTION_SUBFIX);
    return convertToNumber ? options.map(item => parseInt(item , 10)) : options;
  } else {
    return convertToNumber ? parseInt(queryString , 10) : queryString;
  }
}

export function sortOptions(items: Array<any>, key: string) {
  return items.sort((currentView: any, nextView: any) => {
    if (currentView[key] > nextView[key]) {
      return 1
    } else {
      return -1
    }
  })
}

export function sortOptionsString(items: Array<any>) {
  return items.sort((currentView: any, nextView: any) => {
    if (currentView > nextView) {
      return 1
    } else {
      return -1
    }
  })
}


export function convertArrayToSmartOptions(items: Array<string>) {
  const sortedItems = sortOptionsString(items);
  const options: any = {};
  sortedItems.forEach((option) => {
    if(option.trim().length) {
      options[option] = option;
    }
  })
  return options;
}