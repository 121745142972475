import { FILTER_TYPES, IFilterTypes } from 'utils';

export interface IFilter {
  archiveReportsType: string;
  month: string;
  year: string;
}

export const FILTER_IDS = {
  MATRIX_REPORT: "archiveReportsType",
  ALL_MONTHS: "month",
  LAST_FOUR_YEARS: "year"
}

export const FilterTypes: IFilterTypes= {
  [FILTER_IDS.MATRIX_REPORT]: {
    type: FILTER_TYPES.SINGLE_SELECT,
    label: "Select Report Type",
  },
  [FILTER_IDS.ALL_MONTHS]: {
    type: FILTER_TYPES.SINGLE_SELECT,
    label: "Month",
  },
  [FILTER_IDS.LAST_FOUR_YEARS]: {
    type: FILTER_TYPES.SINGLE_SELECT,
    label: "Year",
  }
}

export const DEFAULT_FILTER_VALUE: any = {
  [FILTER_IDS.MATRIX_REPORT]: null!,
  [FILTER_IDS.ALL_MONTHS]: null!,
  [FILTER_IDS.LAST_FOUR_YEARS]: null!
}
