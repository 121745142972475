import { SagaIterator } from 'redux-saga';
import { put, call, select, takeLatest } from 'redux-saga/effects';
import { listActionFunctions } from './actions';
import { fetchRequest, getFullUrl } from 'Api';
import { IListAction, ActionIdentity } from './interface';
import { toast } from 'react-toastify';

function getPeopleIdsFormItems(items: Array<any>, hrIdKey: Array<string>): Array<string> {
  const peopleIds: Array<string> = [];
  if (items instanceof Array) {
    items?.forEach((item: any) => {
      hrIdKey.forEach(peopleIDKey => {
        if (item[peopleIDKey]) {
          peopleIds.push(item[peopleIDKey])
        }
      })
    });
  }
  return peopleIds;
}

function getItemsFromResponse(response: any, itemsKeyName: string) {
  if(itemsKeyName) {
    return ((response.data && response.data[itemsKeyName]) || response[itemsKeyName] || [])
  } else {
    return response.data?.records || response.data || response;
  }
}

function* performRequestOperation(action: IListAction): IterableIterator<{}> {
  const { meta: { keys: { getBaseUrl, getToken, getXAPIToken, callPeopleApi, hrIdKey, itemsKeyName}}, payload: { params, showNotifyMesage } } = action;
  const { url, method } = params || {};
  try {
    const token: string = (yield select(getToken))!;
    const apiKey: string =(yield select(getXAPIToken))!;
    const requesturl: string = getFullUrl((yield select(getBaseUrl))!, url!, params);
    const response: any = yield call(
      fetchRequest,
      requesturl,
      token,
      method!,
      apiKey,
      params?.body,
    );
    if(showNotifyMesage) {
      toast.info(showNotifyMesage);
    }
    const items = getItemsFromResponse(response, itemsKeyName!);
    yield put(
      listActionFunctions.success(
        action.meta,
        params!,
        items,
        response.message || 'Execution Done Successfully',
      ),
    );
    if (callPeopleApi && hrIdKey?.length) {
      yield put(
        callPeopleApi(getPeopleIdsFormItems(items, hrIdKey))
      )
    }
  } catch (error: any) {
    const message = error.message || error.errorText || error ;
    toast.error(message);
    yield put(listActionFunctions.failure(action.meta, params!, message));
  }
}

export default function (actionidentity: ActionIdentity) {
  return function* watchPaginationEvent(): SagaIterator {
    yield takeLatest(actionidentity.LIST_FETCH.REQUEST, performRequestOperation);
    yield takeLatest(actionidentity.MULTIPLE_ADD_ITEMS.REQUEST, performRequestOperation);
    yield takeLatest(actionidentity.DELETE_ITEM.REQUEST, performRequestOperation);
    yield takeLatest(actionidentity.ADD_ITEM.REQUEST, performRequestOperation);
    yield takeLatest(actionidentity.EDIT_ITEM.REQUEST, performRequestOperation);
    yield takeLatest(actionidentity.MULTI_EDIT_ITEMS.REQUEST, performRequestOperation);

  };
}