import TreeDropdown from "../TreeDropdown";

export function FormTreeDropdown({ input, meta, dropdownRef, baseClassName, ...props }: any) {
  return (
    <div className={`position-relative ${ baseClassName || ""}`}>
    <TreeDropdown
      {...props}
      ref={dropdownRef || null}
      value={input.value}
      handleValueChange={(data: any) => {
        input.onChange(data)
      }}
    />
    {meta.error && meta.touched && <div className="text-danger-color text-sm padding-l-4">{meta.error}</div>}
    </div>
  );
}