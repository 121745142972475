import { Fragment, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useOutletContext } from 'react-router-dom';


import { modalOperation } from "store/actions";
import { getActiveAffiliationsRequest, getLeadAffiliationsRequest, getPracticeAreaRequest } from 'store/rext';
import { getIntrestedAffiliationsRequest, deleteIntrestedAffiliationsRequest } from 'store/list';
import { getActiveAffiliations, getFeaturesState, getIntrestedAffiliations, getLeadAffiliations, getPracticeAreaState } from 'store/selectors';

import { ModalsType } from 'containers/ModalManager/ModalManager';
import { getAddAffiliationHeaders, getHeaders } from "./Columns";
import AffiliationTab from './AffiliationTab';
import BannerComponent from "./Banner";
import { IColumn } from 'components/ReactTable';
import Loading from 'components/Loading';
import Tutorial, { getTutorialsViews } from "./Tutorial";
import { ITutorial } from './Tutorial/Tutorial';
import { Feature } from 'utils/Features/Features';
import { IRextState } from 'baseProvider/rext';
import deepEqual from 'deep-equal';
import { linkInteraction, setGlobalPageLoad } from 'Analytics';
import { HEADRES } from 'components/Header';

const Affiliation_Link = "https://internal.bcg.com/Microsites/Functions/PAAF#internaltabs-tab-item-";
const TutorialLocalKey = "tutorial"

function HomeContainer() {
  const dispatch = useDispatch();
  const intrestedAffiliationState = useSelector(getIntrestedAffiliations);
  const { data: userFeatures }: {data: Feature;} = (useSelector(getFeaturesState))!;
  const { headerRef }: any = useOutletContext();
  const [startTutorial, setStartTutorial] = useState<boolean>(localStorage.getItem(TutorialLocalKey) !== "true");
  const intrestedAffiliationRef = useRef(null);
  const practiceAreaState: IRextState = useSelector(getPracticeAreaState, deepEqual);

  const handleAddAffilation = () => {
    linkInteraction("Add Affiliation", "", HEADRES.My_Affiliations, undefined!);
    dispatch(modalOperation.showModal(ModalsType.AffilationModal, {
      onSave: () => {
        dispatch(modalOperation.hideModal());
      },
      onClose: () => {
        dispatch(modalOperation.hideModal());
      }
    }));
  }

  const handleDeleteAffilation = (affiliationId: string) => {
    dispatch(modalOperation.showModal(ModalsType.ConfimationDeleteModal, {
      onSave: () => {
        dispatch(deleteIntrestedAffiliationsRequest(affiliationId));
        dispatch(modalOperation.hideModal());
      },
      onClose: () => {
        dispatch(modalOperation.hideModal());
      },
      title: "Confirmation",
      message: "Are you sure you want to delete this Affiliation ?",
      primaryBtnTitle: "Delete"
    }));
  }

  useEffect(() => {
    setGlobalPageLoad('Affiliations Home Page');
    dispatch(getActiveAffiliationsRequest());
    dispatch(getIntrestedAffiliationsRequest());
    dispatch(getLeadAffiliationsRequest());
    if(practiceAreaState.data?.dropdown) {
      dispatch(getPracticeAreaRequest());
    }
  }, [])

  const AddAffiliationHeaders: IColumn[] = useMemo(() => {
    return getAddAffiliationHeaders(handleDeleteAffilation, practiceAreaState.data?.mappingIds)
  }, [practiceAreaState]);

  const Headers: IColumn[] = useMemo(() => {
    return getHeaders(practiceAreaState.data?.mappingIds)
  }, [practiceAreaState]);

  const tutorialElement: Array<ITutorial> = useMemo(() => {
    return getTutorialsViews(userFeatures, intrestedAffiliationRef, headerRef);
  }, [headerRef, headerRef.current, intrestedAffiliationRef, intrestedAffiliationRef.current, userFeatures])

  const toggleTutorial = () => {
    localStorage.setItem(TutorialLocalKey, "true");
    setStartTutorial(tutorial => !tutorial);
  };

  return (
    <Fragment>
      <BannerComponent handleStartTutorial={toggleTutorial}/>
      { startTutorial && <Tutorial tutorialRef={tutorialElement} handleStopTutorial={toggleTutorial}/> }
      <AffiliationTab
        ref={intrestedAffiliationRef}
        headers={AddAffiliationHeaders}
        title="Interested Affiliations"
        actionButton={handleAddAffilation}
        actionButtonTitle="Add Affiliation"
        emptyDataMessage='_ No Interested Affiliations Added _'
        affiliationSelector={getIntrestedAffiliations}
        link={`${Affiliation_Link}3`}
      />
      <AffiliationTab
        headers={Headers}
        title="Active Affiliations"
        emptyDataMessage='_ No Active Affiliations Added _'
        affiliationSelector={getActiveAffiliations}
        link={`${Affiliation_Link}2`}
      />
      <AffiliationTab
        headers={Headers}
        title="Lead Affiliations"
        emptyDataMessage='_ No Lead Affiliations Added _'
        affiliationSelector={getLeadAffiliations}
        link={`${Affiliation_Link}1`}
      />
      {intrestedAffiliationState.operationProgress && <Loading isGlobal/>}
    </Fragment>
  );
}

export default HomeContainer;