import { createPagination, IPagination } from "baseProvider/pagination";
import { multiPeopleInfoActions } from "store/actions/people";
import { getBaseUrl, getBaseUrlAPIKEY, getToken } from "store/selectors";
import { makeid } from "utils";

export const paafUserPagination: IPagination = createPagination({
  identity: "paafUserPagination",
  getBaseUrl: getBaseUrl(false),
  getXAPIToken: getBaseUrlAPIKEY(false),
  getToken: getToken,
  primaryKey: "hrId",
  addItemMessage: "User has been successfully added",
  deleteItemMessage: "User has been successfully deleted",
  updateItemMessage: "User roles has been updated successfully",
  callPeopleApi: multiPeopleInfoActions.request,
  hrIdKey: ["hrId"],
  transformationOfItem: (item) => {
    const roles: Array<string> = [];
    let topics: Array<string> = [];
    let offices: Array<string> = [];
    let papOffices: Array<string> = [];
    item.roles.forEach(({role, canEdit}: {role:string, canEdit: Array<string>}) => {
      roles.push(role);
      if(role === "PAC") {
        topics = [
          ...topics,
          ...canEdit
        ]
      } else if(role === "CDS") {
        offices = [
          ...offices,
          ...canEdit
        ]
      } else if(role === "PAP") {
        papOffices = [
          ...papOffices,
          ...canEdit
        ]
      }
    })
    return {
      hrId: item.hrId,
      roles,
      topics,
      offices,
      papOffices
    };
  }
})

export const changeReportPagination: IPagination = createPagination({
  identity: "changeReportPagination",
  getBaseUrl: getBaseUrl(false),
  getXAPIToken: getBaseUrlAPIKEY(false),
  getToken: getToken,
  primaryKey: "paafId",
  callPeopleApi: multiPeopleInfoActions.request,
  hrIdKey: ["hrId", "newUpdatedBy"]
})

export const allAffiliationPagination: IPagination = createPagination({
  identity: "allAffiliationPagination",
  getBaseUrl: getBaseUrl(false),
  getXAPIToken: getBaseUrlAPIKEY(false),
  getToken: getToken,
  primaryKey: "genericId",
  callPeopleApi: multiPeopleInfoActions.request,
  hrIdKey: ["hrId"],
  transformationOfItem: (item) => {
    return {...item, genericId: makeid(10)};
  }
})

export const snowflakeAllAffiliationPagination: IPagination = createPagination({
  identity: "snowflakeAllAffiliationPagination",
  getBaseUrl: getBaseUrl(false),
  getXAPIToken: getBaseUrlAPIKEY(false),
  getToken: getToken,
  primaryKey: "genericId",
  hrIdKey: ["hrId"],
  transformationOfItem: (item) => {
    return {...item, genericId: makeid(10)};
  }
})

export const snowflakeAllAffiliationPaginationTableView: IPagination = createPagination({
  identity: "snowflakeAllAffiliationPaginationTableView",
  getBaseUrl: getBaseUrl(false),
  getXAPIToken: getBaseUrlAPIKEY(false),
  getToken: getToken,
  primaryKey: "genericId",
  hrIdKey: ["hrId"],
  transformationOfItem: (item) => {
    return {...item, genericId: makeid(10)};
  }
})

export const snowflakeAllAffiliationPaginationMatrixView: IPagination = createPagination({
  identity: "snowflakeAllAffiliationPaginationMatrixView",
  getBaseUrl: getBaseUrl(false),
  getXAPIToken: getBaseUrlAPIKEY(false),
  getToken: getToken,
  primaryKey: "genericId",
  hrIdKey: ["hrId"],
  transformationOfItem: (item) => {
    return {...item, genericId: makeid(10)};
  }
})