import classNames from "classnames";
import { Loader } from "semantic-ui-react";
import "./Loading.scss";

interface Props {
  isGlobal?: boolean;
  inverted?: boolean;
}

function Loading({ isGlobal, inverted }: Props) {
  return (
    <div className={classNames(["loading-container width-100 height-100", { "global": isGlobal, "inverted": inverted }])}>
      <Loader active inline="centered" />
    </div>
  )

}
export default Loading;