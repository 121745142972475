import { Popup } from 'semantic-ui-react';
import classNames from "classnames";
import "./styles.scss";
import SvgIcon, { CUSTOM_SVG_ICON, SVGType } from "components/SvgIcon";
import { OptionsView, SearchInput } from "./Common";

export default function PopupComponent({
    searchPlaceholder, searchTxt, setSearchTxt, handleSearchClear, isSearchActive, isLoading, dropdownOptions, checkedOptions, handleOptionChange, showHideNodes, inputProps,
    hirerarchy, setHirerarchy, isMulti, isMenuOpen, dropdownRef, placeholder, toggleMenuState, isSearchable, dropdownWidth
  }: any) {
  
    return (
      <div className='width-100'>
        <Popup
          on='click'
          pinned
          position='bottom left'
          open={isMenuOpen}
          trigger={<div ref={dropdownRef} className="flex flex-column position-relative">
            <div className={classNames(["taxanomy-dropdown-container flex flex-row flex-justify-between flex-align-center ", { "taxanomy-open": isMenuOpen }])}>
              {(isMulti) && <div className="placeholder">{placeholder}</div>}
              <SvgIcon svgType={SVGType.CUSTOM} name={CUSTOM_SVG_ICON.CaretDown} size="small" />
            </div>
          </div>
          }
          basic
          className='taxanomy-dropdown'
          onOpen={() => {
            toggleMenuState(true);
          }}
          onClose={() => {
            toggleMenuState(false);
          }}
        >
          {isSearchable && <Popup.Header style={{ width: "100%" }}>
            <SearchInput searchPlaceholder={searchPlaceholder || "Filter"} searchTxt={searchTxt} setSearchTxt={setSearchTxt} handleSearchClear={handleSearchClear} isSearchActive={isSearchActive} />
          </Popup.Header >
          }
          <Popup.Content className='flex flex-row' style={{ minWidth: dropdownWidth }}>
            <OptionsView
              isLoading={isLoading}
              dropdownOptions={dropdownOptions}
              checkedOptions={checkedOptions}
              handleOptionChange={handleOptionChange}
              searchTxt={searchTxt}
              setSearchTxt={setSearchTxt}
              isSearchActive={isSearchActive}
              showHideNodes={showHideNodes}
              inputProps={inputProps}
              hirerarchy={hirerarchy}
              setHirerarchy={setHirerarchy}
              isMulti={isMulti || false}
            />
          </Popup.Content>
        </Popup>
      </div>
    )
  }