import { ISmartOption } from "interface";
import { FILTER_TYPES, IFilterTypes } from "./utils";

export function getNumberOfFIlterApplied(formValue: any, filterTypes: IFilterTypes, priorityKey: "value" | "label" = "value"): number {
    let value: number = 0;
    const filterKeys = Object.keys(formValue || {});
    filterKeys.forEach((filterKey: string) => {
      const filterValue = formValue[filterKey];
      const { type } = filterTypes[filterKey] || {};
      if ((type === FILTER_TYPES.MULTI_SELECT || type === FILTER_TYPES.TREE_SELECT) && filterValue.length >= 1) {
        value+=filterValue.length;
      } else if (type === FILTER_TYPES.SINGLE_SELECT && filterValue as ISmartOption !== undefined && filterValue && filterValue[priorityKey]) {
        value++;
      } else if (type === FILTER_TYPES.BOOLEAN && filterValue) {
        value++;
      } else if (type === FILTER_TYPES.DATE_RANGE && filterValue as ISmartOption !== undefined && filterValue && filterValue[priorityKey]) {
        value++;
      } else if (type === FILTER_TYPES.DATE && filterValue) {
        value++;
      } else if (type && filterValue && filterValue.length) {
          value++;
      }
    });
    return value
  }