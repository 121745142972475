import React, { Fragment, useEffect } from 'react';
import { Route, Routes, useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from "react-redux";
import { Security, LoginCallback, } from '@okta/okta-react';
import { toRelativeUrl } from '@okta/okta-auth-js';
import Home from "./containers/HomeContainer"
import SearchPaaf from 'containers/SearchPaaf';
import PaafUser from 'containers/AdminOption/PaafUsers/PaafUsers';
import PracticeAreaTopic from 'containers/AdminOption/PracticeAreaTopic/PracticeAreaTopic';
import ArchiveReport from 'containers/Reports/ArchiveReport/ArchiveReport';
import ChangeReport from 'containers/Reports/ChangeReport/ChangeReport';
import EditAffiliations from 'containers/EditAffiliations';

import { RequiredAuth } from './HOC/SecureRoute';
import { getFeaturesState, getOktaAuthState } from 'store/selectors';
import { requestApplicationContext } from 'store/actions';
import { getOkatAuth } from 'utils';
import URLRoutes from 'urlRoutes';
import Loading from 'components/Loading';
import { Feature } from 'utils/Features/Features';
import { ProtectedRoute } from 'HOC/ProtectedRoute';
import NotAllowed from 'components/NotAllowed';

function App() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const oktaAuth = useSelector(getOktaAuthState);
  const { data, fetching }: {data: Feature; fetching?: boolean} = (useSelector(getFeaturesState))!;
  const restoreOriginalUri = async (_oktaAuth: any, originalUri: string) => {
    navigate(toRelativeUrl(originalUri || "/", window.location.origin));
  };

  useEffect(() => {
    const script = document.createElement('script');
    script.src = process.env.REACT_APP_ADOBE_URL!;
    script.async = true;
    document.body.appendChild(script);
    dispatch(requestApplicationContext(getOkatAuth()))
    return () => {
      document.body.removeChild(script);
    }
  }, [])

  return (
    <Fragment>
      {
        oktaAuth && <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
          <Routes>
            <Route path={URLRoutes.client.Home} element={<RequiredAuth />} >
              <Route index element={<Home />} />
              <Route path={URLRoutes.client.PaafUser} element={<ProtectedRoute  isFetching={!data || fetching} isAllowed={data?.getPaafUserFeatures().isAllowed}><PaafUser /></ProtectedRoute>} />
              {/* <Route path={URLRoutes.client.AccessHierachy} element={<ProtectedRoute  isFetching={!data || fetching} isAllowed={data?.getAccessHirerarchyFeatures().isAllowed}><AccessHierachy /></ProtectedRoute>} /> */}
              <Route path={URLRoutes.client.PracticeAreaTopic} element={<ProtectedRoute  isFetching={!data || fetching} isAllowed={data?.getPracticeAreaMappingFeatures().isAllowed}><PracticeAreaTopic /></ProtectedRoute>} />
              <Route path={URLRoutes.client.ChangeReport} element={<ProtectedRoute  isFetching={!data || fetching} isAllowed={data?.getChangesReportFeatures().isAllowed}><ChangeReport/></ProtectedRoute>} />
              <Route path={URLRoutes.client.ArchiveReport} element={<ProtectedRoute  isFetching={!data || fetching} isAllowed={data?.getArchiveReportFeatures().isAllowed}><ArchiveReport/></ProtectedRoute>} />
              <Route path={URLRoutes.client.SearchPaff} element={<ProtectedRoute  isFetching={!data || fetching} isAllowed={data?.getSearchPaafFeatures().isAllowed}><SearchPaaf/></ProtectedRoute>} />
              <Route path={`${URLRoutes.client.EditAffiliations}/:hrid`} element={<ProtectedRoute  isFetching={!data || fetching} isAllowed={data?.getSearchPaafFeatures().editUser}><EditAffiliations/></ProtectedRoute>} />
              <Route path={URLRoutes.client.NotAllowed} element={<NotAllowed/>} />
            </Route>
            <Route path='/login/callback' element={<LoginCallback loadingElement={<Loading isGlobal/>} />} />
          </Routes>
        </Security>
      }
    </Fragment>

  );
}

export default App;
