import CustomButton, { ICON_POSITION } from "components/CustomButton/CustomButton";
import { CUSTOM_SVG_ICON, SVGType } from "components/SvgIcon";
import moment from "moment";

const getYears = (year: number) => {
  const options: number[] = [];
  [...new Array(moment().year() - (year - 1))].forEach((item: any, index: number) => {
    const val = year + index;
    options.push(val);
  })
  return options;
}

const years = getYears(1990);
const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

const DateRangeHeader = ({
  date,
  changeYear,
  changeMonth,
  decreaseMonth,
  increaseMonth,
  prevMonthButtonDisabled,
  nextMonthButtonDisabled,
}: any) => {

  const renderOptions = (option: any) => (
    <option key={option} value={option}>
      {option}
    </option>
  )

  return (
    <div className="flex flex-row flex-justify-between flex-align-center">

      <CustomButton
        handleClick={decreaseMonth}
        disabled={prevMonthButtonDisabled}
        iconPosition={ICON_POSITION.LEFT}
        iconProps={{
          name: CUSTOM_SVG_ICON.CaretLeft,
          svgType: SVGType.CUSTOM,
          size: "small",
          baseclassname: "text-primary-color"
        }}
        transparent
        primaryButton
        noOutline
      />


      <div>
        <select
          value={moment(date).format("yyyy")}
          onChange={({ target: { value } }: any) => changeYear(parseInt(value, 10))}
          className="margin-r-4"
        >
          {years.map(renderOptions)}
        </select>

        <select
          value={moment(date).format("MMMM")}
          onChange={({ target: { value } }: any) => {
            changeMonth(months.indexOf(value))
          }}
        >
          {months.map(renderOptions)}
        </select>
      </div>

      <CustomButton
        handleClick={increaseMonth} 
        disabled={nextMonthButtonDisabled}
        iconPosition={ICON_POSITION.RIGHT}
        iconProps={{
          name: CUSTOM_SVG_ICON.CaretRight,
          svgType: SVGType.CUSTOM,
          size: "small"
        }}
        transparent
        primaryButton
        noOutline
        />

    </div>
  )
}

export default DateRangeHeader;