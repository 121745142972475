import { OptionsView, SearchInput } from "./Common";

export default function DirectComponent({ searchPlaceholder, searchTxt, setSearchTxt, handleSearchClear, isSearchActive, isLoading, dropdownOptions, checkedOptions, handleOptionChange, showHideNodes, inputProps,
    hirerarchy, setHirerarchy, isMulti, isDirectComponent }: any) {

    return (
        <div className="flex width-100 flex-column taxanomy-dropdown no-dropdown">
            <SearchInput searchPlaceholder={searchPlaceholder || "Filter"} searchTxt={searchTxt} setSearchTxt={setSearchTxt} handleSearchClear={handleSearchClear} isSearchActive={isSearchActive} />
            <div className="flex flex-row margin-t-2 taxanomy-option-wrapper">
                <OptionsView
                    isLoading={isLoading}
                    dropdownOptions={dropdownOptions}
                    checkedOptions={checkedOptions}
                    handleOptionChange={handleOptionChange}
                    searchTxt={searchTxt}
                    setSearchTxt={setSearchTxt}
                    isSearchActive={isSearchActive}
                    showHideNodes={showHideNodes}
                    inputProps={inputProps}
                    hirerarchy={hirerarchy}
                    setHirerarchy={setHirerarchy}
                    isMulti={isMulti || false}
                    isDirectComponent={isDirectComponent}
                />
            </div>
        </div>
    )
}