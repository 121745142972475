import deepEqual from "deep-equal";
import { Fragment, useEffect, useMemo, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { Field } from "redux-form";

import { IRextState } from "baseProvider/rext";
import FilterContainer from "components/FilterContainer";
import { FormSelect, FormTreeDropdown } from "components/FormInputs";
import { getAllRolesState, getLocations, getPracticeAreaState } from "store/selectors";
import { ROLES_LABEL, transformArrayAndLabelInKeyValue } from "utils";
import { DEFAULT_FILTER_VALUE, FilterTypes, FILTER_IDS, IFilter } from "./IFilter";
import NoFilterBanner from "components/NoFilterBanner";
import { IOption } from "components/TreeDropdown";


export interface Props {
  handleFilterApplied: any; // On submit this will be called
  handleResetClick: any; // on reset this will called
  isFilterApplied: boolean; // is filter applied or not
  showNoFilterBanner: boolean; //show no filter banner
  filterState: IFilter; // current filter state
  captions?: any;
}

function FilterComponentForm(props: Props) {
  const { handleFilterApplied, filterState, handleResetClick, isFilterApplied, showNoFilterBanner, captions } = props
  const itemsRef: any = useRef({});
  const practiceAreaState: IRextState = useSelector(getPracticeAreaState, deepEqual);
  const allRolesState: IRextState = useSelector(getAllRolesState, deepEqual);
  const locationState: IRextState = useSelector(getLocations, deepEqual);
  const [initialValues, setInitialValues] = useState<any>(undefined!);

  const locationOptions: IRextState = useMemo(() => {
    const newLocationState = locationState.data?.dropdown || [];
    const checkChildrens = (options: IOption[]) => {
      options?.forEach((option: IOption) => {
        if (option.childrens) {
          option.hideCheckbox = true;
          checkChildrens(option.childrens)
        }
      });
    }
    checkChildrens(newLocationState);

    return {
      ...locationState,
      data: {
        ...locationState.data,
        dropdown: newLocationState
      }
    }
  }, [locationState]);

  const allOptions = useMemo(() => {
    return {
      [FILTER_IDS.ROLES]: transformArrayAndLabelInKeyValue(allRolesState.data, ROLES_LABEL),
      [FILTER_IDS.PA_TOPICS]: practiceAreaState?.data?.mappingIds,
      [FILTER_IDS.OFFICES]: locationOptions?.data?.mappingIds
    }
  }, [allRolesState, practiceAreaState, locationOptions]);


  useEffect(() => {
    setInitialValues(filterState);
  }, [])

  return ((initialValues && allOptions) ? (
    <Fragment>
      <FilterContainer
        allOptions={allOptions}
        handleFilterApplied={handleFilterApplied}
        handleResetClick={handleResetClick}
        itemsRef={itemsRef}
        DEFAULT_FILTER_VALUE={DEFAULT_FILTER_VALUE}
        FilterTypes={FilterTypes}
        FILTER_IDS={FILTER_IDS}
        initialValues={filterState}
        submitButtonText="Search"
        title={"PAAF Users"}
        titleClass="text-lg text-bold"
        captions={captions}
      >
        <div className="margin-t-2 background-white-color flex flex-row ">
          <Field
            name={FILTER_IDS.PA_TOPICS}
            component={FormTreeDropdown}
            dropdownRef={(ref: any) => { itemsRef.current[FILTER_IDS.PA_TOPICS] = ref }}
            searchPlaceholder="Search Practice Area / Topic"
            showAppliedFilterIcon
            dropdownOptions={practiceAreaState?.data.dropdown}
            isMulti
            placeholder={FilterTypes[FILTER_IDS.PA_TOPICS].label}
            isSearchable={true}
            initializeValue={initialValues[FILTER_IDS.PA_TOPICS]}
            isLoading={practiceAreaState.fetching}
            inputClassName="width-100"
            baseClassName="width-25 "
          />

          <Field
            name={FILTER_IDS.OFFICES}
            component={FormTreeDropdown}
            dropdownRef={(ref: any) => { itemsRef.current[FILTER_IDS.OFFICES] = ref }}
            searchPlaceholder="Search Offices"
            showAppliedFilterIcon
            dropdownOptions={locationOptions?.data.dropdown}
            isMulti
            placeholder={FilterTypes[FILTER_IDS.OFFICES].label}
            isSearchable={true}
            initializeValue={initialValues[FILTER_IDS.OFFICES]}
            isLoading={locationOptions?.fetching}
            inputClassName="width-100"
            baseClassName="width-25 padding-l-4"
          />

          <Field
            name={FILTER_IDS.ROLES}
            dropdownRef={(ref: any) => { itemsRef.current[FILTER_IDS.ROLES] = ref }}
            component={FormSelect}
            showAppliedFilterIcon
            popupPosition={"bottom center"}
            options={allOptions?.ROLES}
            isMulti
            placeholder={FilterTypes[FILTER_IDS.ROLES].label}
            isSearchable={true}
            allowSelectAll={true}
            isLoading={allRolesState.fetching}
            closeMenuOnSelect={false}
            hideSelectedOptions={false}
            defaultValues={initialValues[FILTER_IDS.ROLES]}
            baseClassName="width-25 padding-l-4"
          />
        </div>
      </FilterContainer>
      {
        showNoFilterBanner && !isFilterApplied && <NoFilterBanner message="Use the above filters to find PAAF Users" />
      }
    </Fragment>
  ) : null
  )
}

export default FilterComponentForm;