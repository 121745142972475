import { Fragment, useEffect, useMemo, useState } from "react";
import FilterReport, { DEFAULT_FILTER_VALUE, FilterTypes, IFilter } from "./FilterReport";

import { transformFormValueToValues, transformValueForAPI, transformValuesToFormValues } from "utils";
import { useSearchParams } from "react-router-dom";
import ReactTable, { IColumn } from "components/ReactTable";
import { getGridColumns } from "./utils";
import { useDispatch, useSelector } from "react-redux";
import { getAllArchiveReports } from "store/rext";
import { getArchiveReports } from "store/selectors";
import "./ArchiveReport.scss";
import { internalSearchInteraction, linkInteraction, setGlobalPageLoad } from "Analytics";
import { transformFormValueToAdobe } from "utils/filterFormTransform/transformValueToAdobe";
import { HEADRES, SUB_HEADERS } from "components/Header";
import Loading from "components/Loading";


function ArchiveReport() {
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const { data: archiveData, fetching: archiveFetching, error, message } = useSelector(getArchiveReports);
  const [showNoSearchBanner] = useState<boolean>(true);

  //Filter State
  const [isFilterApplied, setIsFilterApplied] = useState<boolean>(false);
  const [currentFilterState, setCurrentFilterState] = useState<IFilter>(undefined!);

  const handleFilterApplied = (filterValue: IFilter) => {
    internalSearchInteraction(transformFormValueToAdobe(filterValue, FilterTypes), HEADRES.Reports, SUB_HEADERS.Archived_Reports);
    setCurrentFilterState(filterValue);
    setSearchParams({
      ...transformFormValueToValues(filterValue, FilterTypes, false)
    })
  }
  const fetchArchiveReports = (filterState: any) => {
    dispatch(getAllArchiveReports(transformValueForAPI(filterState, FilterTypes)));
  }

  const headers: IColumn[] = useMemo(() => {
    return getGridColumns()
  }, []);

  const handleResetClick = () => {
    linkInteraction("Reset", undefined!, HEADRES.Admin_Options, SUB_HEADERS.PAAF_Users);
    setIsFilterApplied(false);
    setSearchParams({});
  }


  useEffect(() => {
    const searchParamsString = searchParams.toString();
    let filter: any = transformValuesToFormValues(searchParamsString, FilterTypes);

    if (currentFilterState === undefined) {
      setCurrentFilterState(filter || DEFAULT_FILTER_VALUE);
    }

    if (!showNoSearchBanner || (showNoSearchBanner && Object.keys(filter).length)) {
      setIsFilterApplied(true);
      fetchArchiveReports(filter)
    } else {
      setIsFilterApplied(false);
    }

  }, [searchParams])

  useEffect(() => {
    setGlobalPageLoad("Archived Reports");
  }, [])


  return (
    <Fragment>
      {
        currentFilterState && <FilterReport
          filterState={currentFilterState}
          isFilterApplied={isFilterApplied}
          handleFilterApplied={handleFilterApplied}
          showNoFilterBanner={showNoSearchBanner}
          handleResetClick={handleResetClick}
          hideResetButton={true}
          submitButtonText="GET REPORT"
          noFilterMessage="Use the above filters to find  archive report options."
        />
      }

      {isFilterApplied &&
        <div className="padding-l-10 padding-r-10">
          {/* <div className=" text-inline"> Search Results: {totalfilterApplied} Filters applied</div> */}
          <ReactTable headers={headers} data={archiveData} baseClassName="margin-t-4 archive-table-container" isLoading={archiveFetching} noDataMessage={error ? message : "No Archive Reports Found"} />
        </div>
      }

      {
        archiveFetching && <Loading isGlobal />
      }
    </Fragment >
  )
}

export default ArchiveReport;