export default () => {
    return (
        <svg width="70" height="28" viewBox="0 0 70 28" fill="none">
            <path d="M19.4001 10.84C19.4001 11.848 19.2161 12.696 18.8481 13.384C18.4801 14.056 18.0001 14.6 17.4081 15.016C16.8161 15.432 16.1361 15.728 15.3681 15.904C14.6001 16.08 13.8241 16.168 13.0401 16.168H10.4001V22H6.92009V5.68H13.1361C13.9681 5.68 14.7601 5.776 15.5121 5.968C16.2801 6.16 16.9521 6.464 17.5281 6.88C18.1041 7.296 18.5601 7.832 18.8961 8.488C19.2321 9.128 19.4001 9.912 19.4001 10.84ZM15.6561 10.864C15.6561 10.4 15.5761 10 15.4161 9.664C15.2561 9.328 15.0321 9.056 14.7441 8.848C14.4721 8.624 14.1441 8.464 13.7601 8.368C13.3761 8.272 12.9601 8.224 12.5121 8.224H10.4001V13.624H12.3201C12.7201 13.624 13.1201 13.592 13.5201 13.528C13.9201 13.448 14.2801 13.312 14.6001 13.12C14.9201 12.912 15.1761 12.632 15.3681 12.28C15.5601 11.912 15.6561 11.44 15.6561 10.864ZM31.1803 22L29.8363 18.352H23.5483L22.2763 22H18.7723L24.8203 5.464H28.8043L34.9963 22H31.1803ZM26.6923 9.304H26.6203L24.4363 15.664H28.9243L26.6923 9.304ZM47.6335 22L46.2895 18.352H40.0015L38.7295 22H35.2255L41.2735 5.464H45.2575L51.4495 22H47.6335ZM43.1455 9.304H43.0735L40.8895 15.664H45.3775L43.1455 9.304ZM53.9826 22V5.68H64.5666V8.44H57.4626V12.688H63.0546V15.448H57.4626V22H53.9826Z" fill="#323232" />
            <path d="M8 27H1L1 21" stroke="#197A56" />
            <path d="M8 27H1L1 21" stroke="#197A56" />
            <path d="M8 27H1L1 21" stroke="#197A56" />
            <path d="M62 1H69V7" stroke="#197A56" />
            <path d="M62 1H69V7" stroke="#197A56" />
            <path d="M62 1H69V7" stroke="#197A56" />
        </svg>
    )
}