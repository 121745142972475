import { ISmartOption } from "interface";

export const maxLength = (max: number) => (value: any) =>
  value && value.length > max ? `Must be ${max} characters or less` : undefined;

export const minLength = (min: number) => (value: any) =>
  value && value.length < min ? `Must be ${min} characters or more` : undefined;

export const patternMatch = (pattren: any, message: string) => (value: string) =>
  value && !pattren.test(value) ?
    message : undefined;

export const required = (value: string) => value === undefined || value.length === 0;

export const requiredWithMessage = (message?: string) => (value: string) =>
  !!!value || value.length === 0 ? message || "Required" : undefined;

export const smartSearchRequired = (message: string) => (value: ISmartOption) => !!!value || !value.value ? message : undefined;

export const numberOnly = (value: any) => value && isNaN(Number(value)) ? 'Must be a number' : undefined;

export const minValue = (min: number) => (value: any) =>
  value && Number(value) < min ? `Must be at least ${min}` : undefined;

export const maxValue = (max: number, message?: string) => (value: any) =>
  value && Number(value) > max ? message || `Must be less than ${max}` : undefined;

export const emailValidation = (value: any) =>
  value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)
    ? 'Invalid email address'
    : undefined;
    
export const normalizePhone = (value: any) => {
  if (!value) {
    return value
  }

  const onlyNums = value.replace(/[^\d]/g, '')
  if (onlyNums.length <= 3) {
    return onlyNums
  }
  if (onlyNums.length <= 7) {
    return `${onlyNums.slice(0, 3)}-${onlyNums.slice(3)}`
  }
  return `${onlyNums.slice(0, 3)}-${onlyNums.slice(3, 6)}-${onlyNums.slice(6, 10)}`
}