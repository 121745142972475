
import Loading from "components/Loading";
import UsePeople from "HOC/UsePeople";
import { useEffect } from "react";
import Moment from "react-moment";
import { useDispatch, useSelector } from "react-redux";
import { Modal } from "semantic-ui-react";
import { requestPreviousComments } from "store/list";
import { getPreviousCommentsState } from "store/selectors";
import { DATE_FORMAT, getUserName } from "utils";
import { ModalHeader } from "../Common"
import './PreviousCommentsModal.scss'

interface IComments {
  id: string;
  updatedByHrId: string;
  comment: string;
  updatedAt: string
}
interface Props {
  hideModal: () => void;
  onClose: () => void;
  onSave: () => void;
  title: string;
  hrid: string;
  topicId: string;
}

function Comment({comment: { comment, updatedAt, updatedByHrId}}: {comment: IComments}) {
  const { user } = UsePeople(updatedByHrId, true);
  return (
    <div className="flex flex-column background-grey-shade-7 padding-4 margin-b-4">
      <div className="padding-b-4">{comment}</div>
      <div className="flex flex-row flex-justify-between">
        <div>Updated By: <span className="text-primary-color">{getUserName(user)}</span></div>
        <div><Moment format={DATE_FORMAT}>{updatedAt}</Moment></div>

      </div>
    </div>
  )
}

function PreviousCommentsModal(props: Props) {
  const { title, hideModal, hrid, topicId } = props;
  const { data, fetching} = useSelector(getPreviousCommentsState);
  const dispatch = useDispatch();

  const renderComment = (comment: IComments, index: number) => {
    return <Comment key={`comment${comment.id}`} comment={comment}/>
  }

  useEffect(() => {
    dispatch(requestPreviousComments(hrid, topicId))
  }, [dispatch])

  return (
    <Modal
      size="small"
      dimmer
      open
      onClose={hideModal}
      closeOnEscape={false}
      closeOnDimmerClick={false}
      className="previous-comment-modal">
      <ModalHeader title={title} toggle={hideModal} />
      <Modal.Content>
        <div>
          { data.map(renderComment)}
          { fetching && <Loading isGlobal/>}
        </div>
      </Modal.Content>
    </Modal>
  )
}

export default PreviousCommentsModal;