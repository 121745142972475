import classNames from "classnames";
import * as React from "react";
import { ICheckedOptions, IInputProps, IOption, ReactTreeNode } from "../interface";
import OptionComponent from "./Option";


export interface Props {
  dropdownOptions: IOption[];
  checkedOptions: ICheckedOptions;
  handleOptionChange: (checked: boolean, option: IOption, options: ReactTreeNode) => void;
  searchTxt: string
  setSearchTxt: any;
  isSearchActive: boolean;
  showHideNodes: {[key: string]: boolean};
  inputProps: IInputProps;
  hirerarchy: ReactTreeNode[];
  setHirerarchy: any;
  isMulti: boolean;
}

function getChildrenExpandHirerarchy(hirerarchy: ReactTreeNode[], option: IOption, index: number, options: ReactTreeNode) {
  const isAlreadyActive: boolean = option.id === (hirerarchy[index+1] && hirerarchy[index+1].node.id);
  if(isAlreadyActive) {
    return hirerarchy.slice(0, index + 1);
  } else {
    const tmp =
      index === 0
        ? [hirerarchy[0]]
        : hirerarchy.slice(0, index + 1);
    return[
      ...tmp,
      {
        node: option,
        parent: options,
      },
    ];
  }
}

function HorizontalDropdown({ dropdownOptions, checkedOptions, handleOptionChange, isSearchActive, showHideNodes, hirerarchy, setHirerarchy, isMulti }: Props) {

  const renderNodes = (options: ReactTreeNode, index: number) => {
    const { node } = options;
    const renderNodeOptions = (option: IOption) => {
      const activeHirerachy = hirerarchy && hirerarchy[index+1] && hirerarchy[index+1].node.id === option.id; 
      return (
        <OptionComponent
        hideCheckbox = {option.hideCheckbox!}
        disableOption = {option.disableItem!}
        checked={!!(
          checkedOptions[option.id] &&
          checkedOptions[option.id].checked
        )}
        partialChecked={checkedOptions[option.id] && checkedOptions[option.id].partialChecked}
        handleOptionChange={({ target: { checked } }: any) => {
          handleOptionChange(checked, option, options);
        }}
        handleExpandClick={() => {
          if (option.childrens) {
            setHirerarchy(getChildrenExpandHirerarchy(hirerarchy, option, index, options));
          } else {
            if(!isMulti && !option.childrens) {
              handleOptionChange(true, option, options)
            }
          }
        }}
        option={option}
        key={option.id}
        isActive={activeHirerachy}
        isSearchActive={isSearchActive}
        showHideOption={!!showHideNodes[option.id]}
        isMulti={isMulti}
        />
      );
    };

    const isAnyChildVisible = node.childrens?.some(item => !!showHideNodes[item.id]) || Object.keys(showHideNodes).length === 0;
    return (
      <div className={classNames(["width-50 options-scroll-container childrens", {"show-background": isAnyChildVisible}, {"odd": index%2 !==0}])} key={node.id}>
        {(node.childrens|| []).map(renderNodeOptions)}
      </div>
    );
  };

  const renderParent = () => {
    const renderParentOptions = (option: IOption) => {
      const activeHirerachy = hirerarchy && hirerarchy[0] && hirerarchy[0].node.id === option.id;
      return (
        <OptionComponent
        checked={!!(
          checkedOptions[option.id] &&
          checkedOptions[option.id].checked
        )}
        hideCheckbox = {option.hideCheckbox!}
        disableOption = {option.disableItem!}
        partialChecked={checkedOptions[option.id] && checkedOptions[option.id].partialChecked}
        handleOptionChange={({ target: { checked } }: any) => {
          handleOptionChange(checked, option, undefined!);
        }}
        handleExpandClick={() => {
          if (option.childrens) {
            const isAlreadyActive: boolean = option.id === (hirerarchy[0] && hirerarchy[0].node.id);

            if(isAlreadyActive) {
              setHirerarchy([]);
            } else {
              setHirerarchy([
                {
                  node: option,
                  parent: undefined!,
                },
              ]);
            }
          }
        }}
        option={option}
        key={option.id}
        isActive={activeHirerachy}
        isSearchActive={isSearchActive}
        showHideOption={!!showHideNodes[option.id]}
        isMulti={isMulti}
        />
      );
    };

    return (dropdownOptions.map(renderParentOptions));
  };

  const hasChildrens = hirerarchy && hirerarchy.length >=1;

  return (
    <React.Fragment>
      <div className={classNames(["options-scroll-container", {"width-100": !hasChildrens, "width-50": hasChildrens}])}>
        {renderParent()}
      </div>
      {hirerarchy.map(renderNodes)}
    </React.Fragment>
  )
}

export default HorizontalDropdown;