import CustomButton from "components/CustomButton";
import SvgIcon, { CUSTOM_SVG_ICON, SVGType } from "components/SvgIcon";
import { Modal } from "semantic-ui-react";
import './TutorialModal.scss'
interface Props {
  hideModal: () => void;
  onClose: () => void;
  onSave: () => void;

}

function TutorialModal(props: Props) {
  const { hideModal, onSave, onClose } = props;

  return (
    <Modal
      size="mini"
      dimmer
      open
      onClose={hideModal}
      closeOnEscape={false}
      closeOnDimmerClick={false}
      className="tutorial-modal"
      >
      <Modal.Content>
        <div className="flex flex-column ">
          <SvgIcon svgType={SVGType.CUSTOM} name={CUSTOM_SVG_ICON.Paff} size="massive" baseclassname={"flex flex-justify-center"} />
          <div className="padding-4  text-lg text-bold text-align-center">Welcome to the new PAAF Database Tool!</div>
          <li>Your one-stop shop for Practice Area Affiliations</li>
          <li>Re-Designed and Easy to Navigate</li>
          <li>A more modern and user-friendly interface</li>
          <li>Check out your individual affiliation profile</li>
          <li>Self-affiliate as Interested to the PA, Sector, or Topic of your choice</li>
          <li>And a lot more...</li>
          <div className="flex flex-row flex-justify-around padding-4 ">
            <CustomButton primaryButton buttonText="Take the Tour" round handleClick={onSave} />
            <CustomButton greyButton buttonText="Close/Take it Later" round handleClick={onClose} />
          </div>
        </div>
      </Modal.Content>
    </Modal>
  )
}

export default TutorialModal;