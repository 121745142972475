import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { peopleInfoActions } from "store/actions/people";
import { IPeople } from "store/reducers";
import { getPeopleState } from "store/selectors";

export default function UsePeople(id: string, noApiCall?: boolean): IPeople {
  const peopleUserState = useSelector(getPeopleState(id));
  const dispatch = useDispatch();

  useEffect(() => {
    const { fetching, user, error } = peopleUserState || {};
    if (id && !noApiCall) {
      if (!fetching && !user && !error) {
        dispatch(peopleInfoActions.request(id));
      }
    }
  }, [id])


  return peopleUserState || {}

}