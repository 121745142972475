import classNames from "classnames";
import { useMemo } from "react";
import Moment from "react-moment";
import { DATE_FORMAT, sortOptions } from "utils";
import { tableheader } from "./mock";

function History({ isExpanded, data }: any) {

  const practiceArea = useMemo(() => {
    const groupingData:any = {};
    if(data?.levelDetails !== undefined) {
      data.levels = data?.levelDetails;
    }
    data?.levels?.forEach((level: any) => {
      const { paParentTopicId, paafTopicId, practiceArea : levelPracticeArea, bstLabel } = level;
      const groupId: string = paParentTopicId || paafTopicId;
      const parentPracticeArea: string = levelPracticeArea || bstLabel;
  
      if(!groupingData[groupId]) {
        groupingData[groupId] = {
          id: groupId,
          parentPracticeArea,
          value: []
        }
      }

      groupingData[groupId].value.push(level)
    })

    Object.keys(groupingData).forEach((groupingKey: string) => {
      groupingData[groupingKey].value = sortOptions(groupingData[groupingKey].value, "topicLevel")
    })

    return sortOptions(Object.values(groupingData), "parentPracticeArea");
  }, [data])

  const renderRow = (parentPracticeArea: string, isDivider: boolean) => ({bstLabel, topicLevel, paAffiliationLevel
    , updatedAt, staffingFlag}: any, index: number) => {

    return (
      <tr key={`${parentPracticeArea}${index}`} className={classNames([{"dark": isDivider && index === 0}])}>
        <td>{index === 0 ? parentPracticeArea : ""}</td>
        <td>{bstLabel}</td>
        <td>{topicLevel}</td>
        <td>{paAffiliationLevel}</td>
        <td><Moment format={DATE_FORMAT}>{updatedAt}</Moment></td>
        <td>{staffingFlag ? bstLabel : ""}</td>
      </tr>
    )
  }

  const renderGroups = ({parentPracticeArea, value}: any, index: number) => {
    return value.map(renderRow(parentPracticeArea, true))
  }

  return (
    <div className={classNames(["affiliation-card__table", {"overflow": !isExpanded}])}>
      <table >
        <thead>
          <tr>
          {
            tableheader.map(({ name, id, width }) => (
              <th style={{width: width}} key={id} >{name}</th>
            ))
          }
          </tr>
        </thead>
        <tbody>
          {
            practiceArea.map(renderGroups)
          }
        </tbody>
      </table>
    </div>
  )
}

export default History;