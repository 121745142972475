

import { FILTER_TYPES, IFilterTypes } from 'utils';

export const FILTER_IDS = {
  NAME: "NAME",
  HOME_OFFICE: "HOME_OFFICE",
  HOST_OFFICE: "HOST_OFFICE",
  PA_TOPICS: "PA_TOPICS",
  CAREER_STEP: "CAREER_STEP",
  GLOBAL_TITLE: "GLOBAL_TITLE",
  CAREER_TRACK: "CAREER_TRACK",
  AFFILIATION: "AFFILIATION",
  ACTIVITY_DATE_RANGE: "ACTIVITY_DATE_RANGE",
  TERMINATED_EMP: "TERMINATED_EMP",
  HRID: "HRID",
  POINT_IN_TIME_DATE: "POINT_IN_TIME_DATE",
  PROMOTION_DATE_RANGE: "PROMOTION_DATE_RANGE",
  SEARCH_FOR_SELECTED: "SEARCH_FOR_SELECTED",
  SEARCH_FOR_AFFILIATIONS: "SEARCH_FOR_AFFILIATIONS",
  ORGANIZATION: "ORGANIZATION",
  COHORT: "COHORT",
} 

export interface IFilter {
  NAME: string;
  GLOBAL_TITLE: string[];
  HOME_OFFICE: string[];
  HOST_OFFICE: string[];
  PA_TOPICS: string[];
  CAREER_STEP: string[];
  CAREER_TRACK : string[];
  AFFILIATION : string[];
  ORGANIZATION : string[];
  COHORT: string[];

  ACTIVITY_DATE_RANGE: Array<Date>;
  TERMINATED_EMP: string;
  HRID: string;
  POINT_IN_TIME_DATE: Date;
  PROMOTION_DATE_RANGE: Array<Date>;
  SEARCH_FOR_SELECTED: boolean;
  SEARCH_FOR_AFFILIATIONS: boolean;
}

export const DEFAULT_FILTER_VALUE: any = {
  [FILTER_IDS.NAME]: "",
  [FILTER_IDS.HOME_OFFICE]: [],
  [FILTER_IDS.HOST_OFFICE]: [],
  [FILTER_IDS.PA_TOPICS]: [],
  [FILTER_IDS.CAREER_STEP]: [],
  [FILTER_IDS.GLOBAL_TITLE]: [],
  [FILTER_IDS.CAREER_TRACK]: [],
  [FILTER_IDS.AFFILIATION]: [],
  [FILTER_IDS.ORGANIZATION]: [],

  [FILTER_IDS.ACTIVITY_DATE_RANGE]: [],
  [FILTER_IDS.TERMINATED_EMP]: "",
  [FILTER_IDS.HRID]: "",
  [FILTER_IDS.POINT_IN_TIME_DATE]: undefined!,
  [FILTER_IDS.PROMOTION_DATE_RANGE]: [],
  [FILTER_IDS.SEARCH_FOR_SELECTED]: false,
  [FILTER_IDS.SEARCH_FOR_AFFILIATIONS]: false,
  [FILTER_IDS.COHORT]: []
}



export const FilterTypes: IFilterTypes = {
  [FILTER_IDS.NAME]: {
    type: FILTER_TYPES.DEFAULT,
    label: "Search by first, last or full name",
  },
  [FILTER_IDS.HOME_OFFICE]: {
    type: FILTER_TYPES.TREE_SELECT,
    label: "Home Office",
  },
  [FILTER_IDS.HOST_OFFICE]: {
    type: FILTER_TYPES.TREE_SELECT,
    label: "Host Office",
  },
  [FILTER_IDS.PA_TOPICS]: {
    type: FILTER_TYPES.TREE_SELECT,
    label: "Practice Area",
  },
  [FILTER_IDS.CAREER_STEP]: {
    type: FILTER_TYPES.MULTI_SELECT,
    label: "Job Family Group",
  },
  [FILTER_IDS.GLOBAL_TITLE]: {
    type: FILTER_TYPES.MULTI_SELECT,
    label: "Job Title",
  },
  [FILTER_IDS.CAREER_TRACK]: {
    type: FILTER_TYPES.MULTI_SELECT,
    label: "Job Category",
  },
  [FILTER_IDS.AFFILIATION]: {
    type: FILTER_TYPES.MULTI_SELECT,
    label: "Affiliation",
    // isNumeric: true
  },
  [FILTER_IDS.ACTIVITY_DATE_RANGE]: {
    type: FILTER_TYPES.DATE_RANGE,
    label: "Activity Date Range",
  },
  [FILTER_IDS.TERMINATED_EMP]: {
    type: FILTER_TYPES.BOOLEAN,
    label: "Include Terminated Employee",
  },
  [FILTER_IDS.HRID]: {
    type: FILTER_TYPES.DEFAULT,
    label: "HR ID",
  },
  [FILTER_IDS.POINT_IN_TIME_DATE]: {
    type: FILTER_TYPES.DATE,
    label: "Point In Time Date",
  },
  [FILTER_IDS.PROMOTION_DATE_RANGE]: {
    type: FILTER_TYPES.DATE_RANGE,
    label: "Promotion Date Range",
  },
  [FILTER_IDS.SEARCH_FOR_SELECTED]: {
    type: FILTER_TYPES.BOOLEAN,
    label: "Search for Selected",
  },
  [FILTER_IDS.SEARCH_FOR_AFFILIATIONS]: {
    type: FILTER_TYPES.BOOLEAN,
    label: "Search for Affiliations and Selected",
  },
  [FILTER_IDS.ORGANIZATION]: {
    type: FILTER_TYPES.MULTI_SELECT,
    label: "BCG Organization",
  },
  [FILTER_IDS.COHORT]: {
    type: FILTER_TYPES.MULTI_SELECT,
    label: "Cohort",
  }
}

