import FilterContainer from "components/FilterContainer";
import { FormCheckbox, FormDateRange, FormInput, FormSelect, FormTreeDropdown } from "components/FormInputs";
import NoFilterBanner from "components/NoFilterBanner";
import { CUSTOM_SVG_ICON, SVGType } from "components/SvgIcon";
import { Fragment, useEffect, useMemo, useRef, useState } from "react";
import { Field } from "redux-form";
import deepEqual from "deep-equal";
import { DEFAULT_FILTER_VALUE, FilterTypes, FILTER_IDS, IFilter } from "./IFilter";
import { getAffiliationState, getCarrerStepState, getCarrerTrackState, getCohortState, getJobTitleState, getLocations, getOrganizationsState, getPracticeAreaState, getSnoeflakeRegionsState } from "store/selectors";
import { getAffiliationRequest, getCarrerStepRequest, getCarrerTrackRequest, getCohortRequest, getGlobalTitleRequest, getLocationsRequest, getOrganizationRequest, getPracticeAreaRequest, getSnowFlakeRegionRequest, getUserGlobalTitleRequest } from "store/rext";
import { IRextState } from "baseProvider/rext";
import { useDispatch, useSelector } from "react-redux";
import { getAllPresetRequest } from "store/list";
import CustomButton, { ICON_POSITION } from "components/CustomButton/CustomButton";
import { linkInteraction } from "Analytics";
import { HEADRES } from "components/Header";


export interface Props {
  handleFilterApplied: any; // On submit this will be called
  isFilterApplied: boolean; // is filter applied or not
  showNoFilterBanner: boolean; //show no filter banner
  filterState: IFilter; // current filter state
  handleResetClick: any; // on reset this will called
  hideResetButton?: boolean;
  submitButtonText?: string;
  noFilterMessage?: string;
}

function FilterReportsForm(props: Props) {
  const { handleFilterApplied, filterState, isFilterApplied, showNoFilterBanner, handleResetClick, hideResetButton, submitButtonText, noFilterMessage } = props;
  const itemsRef: any = useRef({});
  const [initialValues, setInitialValues] = useState<any>(undefined!);
  const dispatch = useDispatch();
  const [advanceSearch, setAdvanceSearch] = useState(true);
  const locationState: IRextState = useSelector(getLocations, deepEqual);
  // const globalTitleState: IRextState = useSelector(getGlobalTitleState, deepEqual);
  const carrerTrackState: IRextState = useSelector(getCarrerTrackState, deepEqual);
  const carrerStepState: IRextState = useSelector(getCarrerStepState, deepEqual);
  const affiliationLevelState: IRextState = useSelector(getAffiliationState, deepEqual);
  const practiceAreaState: IRextState = useSelector(getPracticeAreaState, deepEqual);
  const organizationsState: IRextState = useSelector(getOrganizationsState, deepEqual);
  const regionsState: IRextState = useSelector(getSnoeflakeRegionsState, deepEqual);
  const jobTitleState: IRextState = useSelector(getJobTitleState, deepEqual);
  const cohortState: IRextState = useSelector(getCohortState, deepEqual);

  const filterAffiliationLevels = useMemo(() => {
    if (affiliationLevelState?.data) {
      delete affiliationLevelState.data["4"]
    }
    return affiliationLevelState?.data
  }, [affiliationLevelState]);

  const filterOrganizationLevels = useMemo(() => {
    return organizationsState?.data
  }, [organizationsState]);

  const filterCohort = useMemo(() => {
    return cohortState?.data
  }, [cohortState]);

  const toggleAdvanceSearch = () => {
    setAdvanceSearch(!advanceSearch)
  }

  const getAdvanceIconName = (checkAdvanceSearch: boolean) => {
    return checkAdvanceSearch ? CUSTOM_SVG_ICON.CaretDown : CUSTOM_SVG_ICON.CaretUp
  }

  const allOptions = useMemo(() => {
    return {
      [FILTER_IDS.AFFILIATION]: filterAffiliationLevels,
      [FILTER_IDS.HOME_OFFICE]: regionsState.data.mappingIds,
      [FILTER_IDS.HOST_OFFICE]: regionsState.data.mappingIds,
      [FILTER_IDS.PA_TOPICS]: practiceAreaState.data.mappingIds,
      [FILTER_IDS.CAREER_STEP]: carrerStepState.data,
      [FILTER_IDS.CAREER_TRACK]: carrerTrackState.data,
      [FILTER_IDS.GLOBAL_TITLE]: jobTitleState.data,
      [FILTER_IDS.ORGANIZATION]: filterOrganizationLevels,
      [FILTER_IDS.COHORT]: filterCohort,
    }

  }, [locationState, jobTitleState, carrerTrackState, carrerStepState, filterAffiliationLevels, practiceAreaState, filterOrganizationLevels, regionsState, filterCohort])

  const checkAndCallDropdowns = (value: Array<any>, funcRef: any) => {
    if (!value?.length) {
      dispatch(funcRef())
    }
  }

  const handlePrestTracking = (type: string) => {
    linkInteraction(type, undefined!, HEADRES.Search_PAAF, undefined!);
  }

  useEffect(() => {
    setInitialValues(filterState);
    checkAndCallDropdowns(regionsState.data?.dropdown, getSnowFlakeRegionRequest)
    // checkAndCallDropdowns(locationState.data?.dropdown, getLocationsRequest)
    // checkAndCallDropdowns(globalTitleState.data, getGlobalTitleRequest)
    checkAndCallDropdowns(jobTitleState.data, getUserGlobalTitleRequest)
    checkAndCallDropdowns(carrerTrackState.data, getCarrerTrackRequest)
    checkAndCallDropdowns(carrerStepState.data, getCarrerStepRequest)
    checkAndCallDropdowns(practiceAreaState.data?.dropdown, getPracticeAreaRequest)
    checkAndCallDropdowns(affiliationLevelState.data, getAffiliationRequest);
    checkAndCallDropdowns(organizationsState.data, getOrganizationRequest);
    checkAndCallDropdowns(cohortState.data, getCohortRequest);
    dispatch(getAllPresetRequest());
  }, [])

  return (initialValues ? (
    <Fragment>
      <FilterContainer
        title={"Search the PAAF database by using any of the following search methods."}
        handleFilterApplied={handleFilterApplied}
        itemsRef={itemsRef}
        DEFAULT_FILTER_VALUE={DEFAULT_FILTER_VALUE}
        FilterTypes={FilterTypes}
        FILTER_IDS={FILTER_IDS}
        initialValues={filterState}
        handleResetClick={handleResetClick}
        hideResetButton={hideResetButton}
        submitButtonText={submitButtonText}
        showPresetFilters={true}
        allOptions={allOptions}
        handlePresetTracking={handlePrestTracking}
      >
        <div className="flex flex-wrap flex-justify-between padding-t-6">
          <Field
            name={FILTER_IDS.NAME}
            component={FormInput}
            showAppliedFilterIcon
            placeholder={FilterTypes[FILTER_IDS.NAME].label}
            label=""
            inputClassName="width-100"
            baseClassName="width-19"
            roundedCorner
            icon={CUSTOM_SVG_ICON.SearchIcon}
          />

          <Field
            dropdownRef={(ref: any) => { itemsRef.current[FILTER_IDS.HOME_OFFICE] = ref }}
            name={FILTER_IDS.HOME_OFFICE}
            component={FormTreeDropdown}
            searchPlaceholder="Search Home Offices"
            popupPosition={"bottom left"}
            showAppliedFilterIcon
            dropdownOptions={regionsState.data.dropdown}
            isMulti
            placeholder={FilterTypes[FILTER_IDS.HOME_OFFICE].label}
            isSearchable={true}
            initializeValue={initialValues[FILTER_IDS.HOME_OFFICE]}
            isLoading={regionsState.fetching}
            baseClassName="width-19"
          />

          <Field
            name={FILTER_IDS.CAREER_TRACK}
            dropdownRef={(ref: any) => { itemsRef.current[FILTER_IDS.CAREER_TRACK] = ref }}
            component={FormSelect}
            showAppliedFilterIcon
            popupPosition={"bottom center"}
            options={carrerTrackState.data}
            isMulti
            isClearable={false}
            placeholder={FilterTypes[FILTER_IDS.CAREER_TRACK].label}
            isSearchable={true}
            allowSelectAll={true}
            isLoading={carrerTrackState.fetching}
            closeMenuOnSelect={false}
            hideSelectedOptions={false}
            baseClassName="width-19"
            defaultValues={initialValues[FILTER_IDS.CAREER_TRACK]}

          />

          <Field
            name={FILTER_IDS.CAREER_STEP}
            dropdownRef={(ref: any) => { itemsRef.current[FILTER_IDS.CAREER_STEP] = ref }}
            component={FormSelect}
            showAppliedFilterIcon
            popupPosition={"bottom center"}
            options={carrerStepState.data}
            isMulti
            isClearable={false}
            placeholder={FilterTypes[FILTER_IDS.CAREER_STEP].label}
            isSearchable={true}
            allowSelectAll={true}
            isLoading={carrerStepState.fetching}
            closeMenuOnSelect={false}
            hideSelectedOptions={false}
            baseClassName="width-19"
            defaultValues={initialValues[FILTER_IDS.CAREER_STEP]}

          />

          <Field
            name={FILTER_IDS.GLOBAL_TITLE}
            dropdownRef={(ref: any) => { itemsRef.current[FILTER_IDS.GLOBAL_TITLE] = ref }}
            component={FormSelect}
            showAppliedFilterIcon
            popupPosition={"bottom center"}
            options={jobTitleState.data}
            isMulti
            isClearable={false}
            placeholder={FilterTypes[FILTER_IDS.GLOBAL_TITLE].label}
            isSearchable={true}
            allowSelectAll={true}
            isLoading={jobTitleState.fetching}
            closeMenuOnSelect={false}
            hideSelectedOptions={false}
            baseClassName="width-19"
            defaultValues={initialValues[FILTER_IDS.GLOBAL_TITLE]}

          />

        </div>
        <div className='flex flex-wrap flex-justify-between padding-t-6'>

          <Field
            name={FILTER_IDS.HOST_OFFICE}
            dropdownRef={(ref: any) => { itemsRef.current[FILTER_IDS.HOST_OFFICE] = ref }}
            component={FormTreeDropdown}
            searchPlaceholder="Search Host Offices"
            popupPosition={"bottom left"}
            showAppliedFilterIcon
            dropdownOptions={regionsState.data.dropdown}
            isMulti
            placeholder={FilterTypes[FILTER_IDS.HOST_OFFICE].label}
            isSearchable={true}
            initializeValue={initialValues[FILTER_IDS.HOST_OFFICE]}
            isLoading={regionsState.fetching}
            baseClassName="width-19"

          />

          <Field
            name={FILTER_IDS.PA_TOPICS}
            component={FormTreeDropdown}
            dropdownRef={(ref: any) => { itemsRef.current[FILTER_IDS.PA_TOPICS] = ref }}
            popupPosition={"bottom left"}
            searchPlaceholder="Search Practice Area / Topic"
            showAppliedFilterIcon
            dropdownOptions={practiceAreaState.data.dropdown}
            isMulti
            placeholder={FilterTypes[FILTER_IDS.PA_TOPICS].label}
            isSearchable={true}
            initializeValue={initialValues[FILTER_IDS.PA_TOPICS]}
            isLoading={practiceAreaState.fetching}
            baseClassName="width-19"
          />

          <Field
            name={FILTER_IDS.AFFILIATION}
            dropdownRef={(ref: any) => { itemsRef.current[FILTER_IDS.AFFILIATION] = ref }}
            component={FormSelect}
            showAppliedFilterIcon
            popupPosition={"bottom center"}
            options={filterAffiliationLevels}
            isMulti
            isClearable={false}
            placeholder={FilterTypes[FILTER_IDS.AFFILIATION].label}
            isSearchable={true}
            allowSelectAll={true}
            isLoading={affiliationLevelState.fetching}
            closeMenuOnSelect={false}
            hideSelectedOptions={false}
            defaultValues={initialValues[FILTER_IDS.AFFILIATION]}
            baseClassName="width-19"

          />

          <Field
            name={FILTER_IDS.ACTIVITY_DATE_RANGE}
            component={FormDateRange}
            showAppliedFilterIcon
            placeholder={FilterTypes[FILTER_IDS.ACTIVITY_DATE_RANGE].label}
            label=""
            inputClassName="width-100"
            baseClassName="width-19"

          />
          <Field
            name={FILTER_IDS.ORGANIZATION}
            dropdownRef={(ref: any) => { itemsRef.current[FILTER_IDS.ORGANIZATION] = ref }}
            component={FormSelect}
            showAppliedFilterIcon
            popupPosition={"bottom center"}
            options={filterOrganizationLevels}
            isMulti
            isClearable={false}
            placeholder={FilterTypes[FILTER_IDS.ORGANIZATION].label}
            isSearchable={true}
            allowSelectAll={true}
            isLoading={organizationsState?.fetching}
            closeMenuOnSelect={false}
            hideSelectedOptions={false}
            defaultValues={initialValues[FILTER_IDS.ORGANIZATION]}
            baseClassName="width-19"

          />
        </div>

        <div className='flex flex-wrap flex-justify-between padding-t-6'>
          <Field
            name={FILTER_IDS.COHORT}
            dropdownRef={(ref: any) => { itemsRef.current[FILTER_IDS.COHORT] = ref }}
            component={FormSelect}
            showAppliedFilterIcon
            popupPosition={"bottom center"}
            options={filterCohort}
            isMulti
            isClearable={false}
            placeholder={FilterTypes[FILTER_IDS.COHORT].label}
            isSearchable={true}
            allowSelectAll={true}
            isLoading={cohortState?.fetching}
            closeMenuOnSelect={false}
            hideSelectedOptions={false}
            defaultValues={initialValues[FILTER_IDS.COHORT]}
            baseClassName="width-19"
          />
        </div>

        <div className='flex flex-justify-between padding-t-6'>
          <CustomButton
            primaryButton
            transparent
            noOutline
            noPadding
            type="button"
            buttonText="Advanced Search"
            buttonTextClass=" text-md text-uppercase"
            handleClick={toggleAdvanceSearch}
            iconProps={{
              svgType: SVGType.CUSTOM,
              name: getAdvanceIconName(advanceSearch),
              baseclassname: "text-primary-color margin-l-2",
              size: "small"
            }}
            iconPosition={ICON_POSITION.RIGHT}
          />
        </div>
        {!advanceSearch &&
          <div className='flex flex-row flex-wrap flex-justify-between padding-t-4'>
            <Field
              name={FILTER_IDS.HRID}
              component={FormInput}
              placeholder={FilterTypes[FILTER_IDS.HRID].label}
              label=""
              inputClassName="width-100"
              baseClassName="width-19"
              roundedCorner
            />
            {/* <Field
              name={FILTER_IDS.POINT_IN_TIME_DATE}
              component={FormDate}
              showAppliedFilterIcon
              placeholder={FilterTypes[FILTER_IDS.POINT_IN_TIME_DATE].label}
              label=""
              inputClassName="width-100"
              baseClassName="width-19"
            /> */}
            <Field
              name={FILTER_IDS.PROMOTION_DATE_RANGE}
              component={FormDateRange}
              showAppliedFilterIcon
              placeholder={FilterTypes[FILTER_IDS.PROMOTION_DATE_RANGE].label}
              label=""
              inputClassName="width-100"
              baseClassName="width-19"
            />
            <Field
              name={FILTER_IDS.TERMINATED_EMP}
              component={FormCheckbox}
              baseClassName="left-align"
              labelTxt={FilterTypes[FILTER_IDS.TERMINATED_EMP].label}
            />
            <Field
              name={FILTER_IDS.SEARCH_FOR_SELECTED}
              component={FormCheckbox}
              baseClassName="left-align "
              labelTxt={FilterTypes[FILTER_IDS.SEARCH_FOR_SELECTED].label}
            />
            <Field
              name={FILTER_IDS.SEARCH_FOR_AFFILIATIONS}
              component={FormCheckbox}
              baseClassName="left-align "
              labelTxt={FilterTypes[FILTER_IDS.SEARCH_FOR_AFFILIATIONS].label}
            />
          </div>
        }
      </FilterContainer>
      {
        showNoFilterBanner && !isFilterApplied && <NoFilterBanner message={noFilterMessage || "Use the above filters to find PAAF Users"} />
      }
    </Fragment>
  ) : null
  )
}
export default FilterReportsForm;

