import { IMyAffiliationsFeature, IReportFeature, ISearchPaafFeature,
    IChangeReportFeature, IArchiveReportFeature, IPracticeAreaMappingFeature,
    IAdminOptionFeature, IPAAFUserFeature, IAccessHirerarchyFeature,
    ILearnMoreFeature } from "./interface";
import { FEATURES } from "./constant";

const findFeatures = (srcFeatures: Array<FEATURES>, destinationFeatures: Array<FEATURES>): any => {
  const result: { [key: string]: boolean } = {};
  let isAllowed: boolean = false;
  destinationFeatures.forEach(feature => {
    const isFound = srcFeatures.findIndex(item => item === feature) >= 0;
    result[feature] = isFound;
    if (isFound && !isAllowed) {
      isAllowed = true
    }
  })
  return {
    ...result,
    isAllowed
  }
}


class Feature {
  private _myAffiliationsFeature: IMyAffiliationsFeature;
  private _searchPaafFeature: ISearchPaafFeature;
  private _reportFeatures: IReportFeature;
  private _adminOptionsFeatures: IAdminOptionFeature;
  private _learnMoreFeatures: ILearnMoreFeature;
  private _changeReportFeatures: IChangeReportFeature;
  private _archiveReportFeatures: IArchiveReportFeature;
  private _practiceAreaMappingFeatures: IPracticeAreaMappingFeature;
  private _paafUserFeatures: IPAAFUserFeature;
  private _accessHirerarchyFeatures: IAccessHirerarchyFeature;

  constructor(features: Array<FEATURES>) {
    this._myAffiliationsFeature = this.setMyAffiliationFeatures(features);
    this._searchPaafFeature = this.setSearchPaffFeatures(features);
    this._learnMoreFeatures = this.setLearnMoreFeatures(features);
    this._changeReportFeatures = this.setChangeReportFeatures(features);
    this._archiveReportFeatures = this.setArchiveReportFeatures(features);
    this._practiceAreaMappingFeatures = this.setPracticeAreaMappingFeatures(features);
    this._paafUserFeatures = this.setPAAFUserFeatures(features);
    this._accessHirerarchyFeatures = this.setAccessHirerarchyFeatures(features);
    this._reportFeatures = this.setReportFeatures(features);
    this._adminOptionsFeatures = this.setAdminOptionsFeatures(features);
  }

  private setMyAffiliationFeatures = (features: Array<FEATURES>): IMyAffiliationsFeature => {
    const result = findFeatures(features, [FEATURES.MY_AFFILIATION]);
    return {
      isAllowed: result.isAllowed
    }
  }

  private setSearchPaffFeatures = (features: Array<FEATURES>): ISearchPaafFeature => {
    const result = findFeatures(features, [FEATURES.SEARCH_PAAF, FEATURES.SP_EDIT_USER, FEATURES.SP_EXPORT_EXCEL]);
 return {
    exportToExcel: result[FEATURES.SP_EXPORT_EXCEL],
    editUser: result[FEATURES.SP_EDIT_USER],
    isAllowed: result.isAllowed
    }
  }

  private setReportFeatures = (features: Array<FEATURES>): IReportFeature => {
    return {
      isAllowed: this._changeReportFeatures.isAllowed || this._archiveReportFeatures.isAllowed || this._practiceAreaMappingFeatures.isAllowed
    }
  }

  private setAdminOptionsFeatures = (features: Array<FEATURES>): IAdminOptionFeature => {
    return {
      isAllowed: this._paafUserFeatures.isAllowed || this._accessHirerarchyFeatures.isAllowed
    }
  }

  private setLearnMoreFeatures = (features: Array<FEATURES>): ILearnMoreFeature => {
    const result = findFeatures(features, [FEATURES.LEARN_MORE]);
    return {
      isAllowed: result.isAllowed
    }
  }


  private setChangeReportFeatures = (features: Array<FEATURES>): IChangeReportFeature => {
    const result = findFeatures(features, [FEATURES.CHANGE_REPORT]);
    return {
      isAllowed: result.isAllowed
    }
  }

  private setArchiveReportFeatures = (features: Array<FEATURES>): IArchiveReportFeature => {
    const result = findFeatures(features, [FEATURES.ARCHIVED_REPORT]);
    return {
      isAllowed: result.isAllowed
    }
  }

  private setPracticeAreaMappingFeatures = (features: Array<FEATURES>): IPracticeAreaMappingFeature => {
    const result = findFeatures(features, [FEATURES.PRACTICE_AREA_TOPIC_MAPPING]);
    return {
      isAllowed: result.isAllowed
    }
  }

  private setPAAFUserFeatures = (features: Array<FEATURES>): IPAAFUserFeature => {
    const result = findFeatures(features, [FEATURES.PAAF_ADD_USER, FEATURES.PAAF_DELETE_USER, FEATURES.PAAF_EDIT_USER, FEATURES.PAAF_USER, FEATURES.PAAF_EXPORT_USER]);
    return {
      isAllowed: result[FEATURES.PAAF_USER],
      add: result[FEATURES.PAAF_ADD_USER],
      edit: result[FEATURES.PAAF_EDIT_USER],
      delete: result[FEATURES.PAAF_DELETE_USER],
      export: result[FEATURES.PAAF_EXPORT_USER]
    }
  }

  private setAccessHirerarchyFeatures = (features: Array<FEATURES>): IAccessHirerarchyFeature => {
    const result = findFeatures(features, [FEATURES.ACCESS_HIERARCHY]);
    return {
      isAllowed: result.isAllowed
    }
  }

  getMyAffiliationFeatures = (): IMyAffiliationsFeature => {
    return this._myAffiliationsFeature;
  }

  getSearchPaafFeatures = (): ISearchPaafFeature => {
    return this._searchPaafFeature;
  }

  getReportFeatures = (): IReportFeature => {
    return this._reportFeatures;
  }

  getAdminOptionsFeatures = (): IAdminOptionFeature => {
    return this._adminOptionsFeatures;
  }

  getLearnMoreFeatures = (): ILearnMoreFeature => {
    return this._learnMoreFeatures;
  }

  getChangesReportFeatures = (): IChangeReportFeature => {
    return this._changeReportFeatures;
  }

  getArchiveReportFeatures = (): IArchiveReportFeature => {
    return this._archiveReportFeatures;
  }

  getPracticeAreaMappingFeatures = (): IPracticeAreaMappingFeature => {
    return this._practiceAreaMappingFeatures;
  }

  getPaafUserFeatures = (): IPAAFUserFeature => {
    return this._paafUserFeatures;
  }

  getAccessHirerarchyFeatures = (): IAccessHirerarchyFeature => {
    return this._accessHirerarchyFeatures
  }
}

export { Feature };