import React, { useState, useEffect, forwardRef, useImperativeHandle } from "react";
import { default as ReactSelect, components, createFilter } from "react-select";
import makeAnimated from "react-select/animated";
import { customDropDownStyles } from "./styles";
import classNames from "classnames";
import SvgIcon, { CUSTOM_SVG_ICON, SVGType } from "components/SvgIcon";
import { ISmartOption } from "interface";

const Option = (props: any) => {
  const { options, selectProps, value, isSelected, label } = props;
  const allCheckClassName: string = selectProps?.value?.length === options.length - 1 ? "check" : ""
  return (
    <div>
      <components.Option {...props}>
        <label className="customCheckbox">
          {label}
          <span className={`multiSelectDropdonCheckmark ${value === "*" ? allCheckClassName : isSelected && "check"}`} />
        </label>
      </components.Option>
    </div>
  );
};

const SingleOption= (props: any) => {
  const { label } = props;
  return (
      <components.Option {...props}>
        {label}
      </components.Option>
  );
}

const MultiValue = (props: any) => (
  <components.MultiValue {...props}>
    <span>{props.data.label}</span>
  </components.MultiValue>
);

const DropdownIndicator = (props: any) => {
  return (
    <components.DropdownIndicator {...props}>
      <SvgIcon svgType={SVGType.CUSTOM} name={CUSTOM_SVG_ICON.CaretDown} size="small"  />
    </components.DropdownIndicator>
  );
};

const animatedComponents = makeAnimated();

function getDefaultValues(isMulti: boolean, defaultValues: any, options: any) {
  if(isMulti && defaultValues?.length) {
    return defaultValues.map((optionID: string) => ({ label: options[optionID], value: optionID}))
  } else if(!isMulti && defaultValues) {
    return { label: options[defaultValues], value: defaultValues}
  }
}

function getForceValues(isMulti: boolean, forceOptions: any, options: any) {
  if(isMulti && forceOptions?.length) {
    return (forceOptions as string[]).map((optionID: string) => ({ label: options[optionID], value: optionID}));
  } else if(!isMulti) {
    if(forceOptions) {
      return { label: options[forceOptions as string], value: forceOptions};
    } else {
      return null!;
    }
  }
}

function checkOption(selected: any, allOption: any) {
  return selected !== null &&
  selected.length > 0 &&
  selected[selected.length - 1].value === allOption.value
}

function getOptionComponent(isMulti: boolean) {
  return isMulti ? Option : SingleOption;
}

function getSingleSelectValue(selected: ISmartOption) {
  return selected?.value || null!;
}

function MySelect(props: any, ref: any) {
  const { defaultValues, onChange, allowSelectAll, options, baseClassName, isClearable, children, isMulti, outlineSquare, controlShouldRenderValue, disabled } = props;
  const [selectedValue, setSelectedValue] = useState<any>(undefined!);
  const [dropdownOptions, setDropdownOptions] = useState<ISmartOption[]>([]);
  const [searchValue, setSearchValue] = useState<string>("");

  const allOption = {
    label: "Select all",
    value: "*"
  }

  const handleMultiSelectOption = (selected: ISmartOption[]) => {
    if (checkOption(selected, allOption)) {
      if (selected.length - 1 === dropdownOptions.length) {
        setSelectedValue([]);
        return onChange([]);
      }
      setSelectedValue(dropdownOptions);
      return onChange(dropdownOptions.map(item => item.value));
    }
    setSelectedValue(selected);
    return onChange(selected.map(item => item.value));
  }

  const handleSingleSelection = (selected: ISmartOption) => {
    setSelectedValue(selected);
    onChange(getSingleSelectValue(selected)); // If we clear something for single select
  }


  useEffect(() => {
    setDropdownOptions(Object.keys(options).map((optionID: string) => ({ label: options[optionID], value: optionID})));
    setSelectedValue(getDefaultValues(isMulti, defaultValues, options));
  }, [defaultValues, options]);

  useImperativeHandle(ref, () => ({
    clearAllValues() {
      isMulti ? handleMultiSelectOption([]) : handleSingleSelection(null!)
    },
    forceResetToOptions(forceOptions: string | Array<string>) {
      setSelectedValue(getForceValues(isMulti, forceOptions, options));
    }
  }));

  return (
    <div className={classNames([baseClassName, "position-relative"])}>
      <ReactSelect
        {...props}
        inputValue={searchValue}
        onInputChange={(value: any, meta: any) => {
          if(meta?.action !== "set-value") {
            setSearchValue(value);
          }
        }}
        filterOption={createFilter({ ignoreAccents: false})}
        value={selectedValue}
        styles={customDropDownStyles}
        components={{ Option: getOptionComponent(isMulti), MultiValue, animatedComponents, DropdownIndicator  }}
        options={allowSelectAll ? [allOption, ...dropdownOptions] : dropdownOptions}
        controlShouldRenderValue={ controlShouldRenderValue || false }
        isClearable={isClearable}
        className={classNames(["basic-single"])}
        onChange={(selected: any) => {
          isMulti ? handleMultiSelectOption(selected) : handleSingleSelection(selected)
        }}
        outlineSquare={outlineSquare}
        isDisabled={disabled ? disabled : false}
      />
      {children}
    </div>
  )
}

export default forwardRef(MySelect);
