import { FILTER_TYPES, IFilterTypes } from 'utils';

export interface IFilter {
  ROLES: string[];
  PA_TOPICS: string[];
  OFFICES:string[];
}

export const FILTER_IDS = {
  ROLES: "ROLES",
  PA_TOPICS: "PA_TOPICS",
  OFFICES:"OFFICES"
} 

export const DEFAULT_FILTER_VALUE: any = {
  [FILTER_IDS.ROLES]: [],
  [FILTER_IDS.PA_TOPICS]: [],
  [FILTER_IDS.OFFICES]:[]
}

export const FilterTypes: IFilterTypes = {
  [FILTER_IDS.ROLES]: {
    type: FILTER_TYPES.MULTI_SELECT,
    label: "Select Roles",
  },
  [FILTER_IDS.PA_TOPICS]: {
    type: FILTER_TYPES.TREE_SELECT,
    label: "Practice Area",
  },
  [FILTER_IDS.OFFICES]:{
    type:FILTER_TYPES.TREE_SELECT,
    label:"Offices"
  }
}

