import { useRef, forwardRef, MutableRefObject, useImperativeHandle } from "react";
import classNames from "classnames";
import { IGrouping } from "./IReactTable";

interface Props {
  grouping: IGrouping;
  direction: "left" | "right" | "center";
  headerRef: any;
  rowsRef: any;
}

function Scroll(props: Props, ref: any) {
  const scrollRef: MutableRefObject<HTMLDivElement> = useRef(undefined!);
  const { direction, grouping: { headers, totalWidthStr, groupingWidth }, headerRef, rowsRef } = props;

  const handleScroll = (event: any) => {
    headerRef?.current?.leftScroll(event.target.scrollLeft);
    rowsRef?.current?.leftScroll(event.target.scrollLeft);
  }

  useImperativeHandle(ref, () => {
    return {
      scroll: (value: number) => {
        scrollRef.current.scrollBy({
          left: value * 3
        })
      },
      scrollTo: (value: number) => {
        scrollRef.current.scrollTo({
          left: value * 3
        })
      }
    }
  }, []);

  return (
    <div className={classNames(["flex flex-column"])} style={{width: totalWidthStr, overflow: "auto", height: 10}} ref={scrollRef} id={`scroll-${direction}-container`} onScroll={handleScroll}>
      {
        !!headers.length && <div className="flex flex-column" style={{width: groupingWidth["originalWidth"], height: 10}}>
          <div className="flex flex-row" id={`scroll-${direction}`} style={{ height: 10}}>
            
          </div>
        </div>
      }
    </div>
  )
}

export default forwardRef(Scroll);