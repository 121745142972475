import { ISortState, SortOrder } from "components/ReactTable";
import { ReactTableDefaultState } from "components/ReactTable/ReactTable";
import queryString from "query-string";

export * from "./getNumberOfFIlterApplied";
export * from "./transformFormValueToValues";
export * from "./transformValueForAPI";
export * from "./transformValuesToFormValues";
export * from "./transformFormValueToPresetValue";
export * from "./transformPresetValueToFormValue";
export * from "./utils";

export function transformOptions(item: Array<any>, valueKey: string, labelKey: string): any {
  const options: any = {};
  item.forEach((raw: any) => {
    options[raw[valueKey]] = raw[labelKey];
  })
  return options;
}


export function transformStringToTableState(query: string, defaultSortState?: ISortState, defaultPageNumber ?: number): ReactTableDefaultState {
  const values: any = queryString.parse(query);
  let sortState: any = {};

  const getFilterState = (filterState: string) => {
    const filter: any = {};
    const splitFIlters = filterState.split(";");
    splitFIlters.forEach((subFilter: string) => {
      const subFilterSplit = subFilter.split("|");
      if(subFilterSplit.length === 2) {
        filter[subFilterSplit[0]] = subFilterSplit[1]
      }
    })
    return filter;
  }

  if (values.t_sortKey || defaultSortState) {
    sortState = {
      sort: {
        key: values.t_sortKey || defaultSortState?.key,
        sortOrder: values.t_sortOrder || defaultSortState?.sortOrder || SortOrder.ASSCENDING
      }
    }
  }

  return  {
    pageNumber: parseInt(values.t_pageNumber  || "0", 10),
    pageSize: parseInt(values.t_pageSize  || defaultPageNumber?.toString() || "100", 10),
    filter: getFilterState(values.t_filter || ""),
    ...sortState
  }
}


export function transformTableStateToString(filterState: ReactTableDefaultState, asString: boolean): any {
  let value: any = {
    t_pageNumber: filterState.pageNumber,
    t_pageSize: filterState.pageSize
  };
  const filterString = Object.keys(filterState.filter || {}).map(filterKey => `${filterKey}|${filterState.filter[filterKey]}`).join(";")
  if(filterState.sort?.key) {
    value.t_sortKey = filterState.sort?.key;
    value.t_sortOrder = filterState.sort?.sortOrder;
  }
  if(filterString.length) {
    value.t_filter=filterString;
  }
  return asString ? queryString.stringify(value) : value;
}