import { combineReducers, Reducer } from 'redux';
import { infoReducer, itemsReducer } from './reducer';
import { paginationActionFunctions, createIdentityAction } from './actions';
import {
  IPaginationKeys,
  IPaginationActionCreators,
  IPaginationParams,
  IPaginationAction,
  IPaginationInfo,
  IPagination,
  ActionIdentity,
  IPaginationItems,
} from './interface';
import { defaultPaginationInfo, defaultPaginationItems } from './defaultState';
import createSagaEvent from './saga';

export const getPaginationActionCreators = (keys: IPaginationKeys, actionidentity: ActionIdentity): IPaginationActionCreators => {
  return {
    request: (pageNumber: number, pageSize: number, params: IPaginationParams) =>
      paginationActionFunctions.request(
        { actions: actionidentity.PAGINATION_FETCH, keys }, { pageNumber, pageSize, ...params }
      ),
    goToPage: (pageNumber: number) => paginationActionFunctions.request({ actions: actionidentity.CHANGE_PAGE, keys }, { pageNumber } as any),
    deleteRequest: (pageNumber: number, params: IPaginationParams) =>
      paginationActionFunctions.request(
        { actions: actionidentity.DELETE_PAGE_ITEM, keys }, { pageNumber, ...params}, keys.deleteItemMessage
      ),
    addRequest: (params: IPaginationParams) =>
      paginationActionFunctions.request(
        { actions: actionidentity.ADD_PAGE_ITEM, keys }, params, keys.addItemMessage
      ),
    editRequest: (pageNumber: number, params: IPaginationParams) =>
      paginationActionFunctions.request(
        { actions: actionidentity.UPDATE_PAGE_ITEM, keys }, { pageNumber, ...params }, keys.updateItemMessage
      ),
      resetUpdate: (pageNumber: number, params: IPaginationParams) =>
        paginationActionFunctions.reset({actions: actionidentity.RESET_UPDATE_STATE, keys}, { pageNumber, ...params })    
  };
};
export const rext = (
  items: Reducer<IPaginationItems, IPaginationAction>,
  info: Reducer<IPaginationInfo, IPaginationAction>,
  requestPaginationActionCreators: IPaginationActionCreators,
  saga: any,
): IPagination => ({
  reducers: combineReducers({
    info,
    items
  }),
  ...requestPaginationActionCreators,
  saga,
});
export const createPagination = (keys: IPaginationKeys): IPagination => {
  const { identity } = keys;
  const actionidentity = createIdentityAction(identity);
  const info = infoReducer(actionidentity, defaultPaginationInfo);
  const items = itemsReducer(actionidentity, defaultPaginationItems);
  const paginationActionCreators = getPaginationActionCreators(keys, actionidentity);
  const sagaEvent = createSagaEvent(actionidentity);
  return rext(items, info, paginationActionCreators, sagaEvent);
};