import CustomButton from "components/CustomButton"
import { IReactTableComponent } from "components/ReactTable";
import { memo } from "react";
import UsePeople from "HOC/UsePeople";
import TooltipText from "components/TooltipText";
import ProfilePicture from "components/ProfilePicture";
import { IExcelCell, Months } from "interface";
import { IPeopleUser } from "store/reducers";
import { DATE_FORMAT, checkNumberAndConvertToMonths, getMonthOfEmpAndExp, getRowValue, getSystemFromHierarchies, getUserName } from "utils";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import URLRoutes from "urlRoutes";
import Moment from "react-moment";
import classNames from "classnames";
import { iconInteraction } from "Analytics";
import { HEADRES } from "components/Header";

export enum AFFILATIONS_VIEWS {
  GRID_VIEW = "GRID_VIEW",
  ALL_AFFILIATIONS_VIEW = "ALL_AFFILIATIONS_VIEW",
  ALL_AFFILIATIONS_TABLE_VIEW = "ALL_AFFILIATIONS_TABLE_VIEW",
  ALL_AFFILIATIONS_TABLE_VIEW_LEAD_ALLOCATION = "LEAD_ALLOCATION_TABLE_VIEW",
  PA_MATRIX_VIEW = "PA_MATRIX_VIEW",
  PA_MATRIX_VIEW_LEAD_ALLOCATION = "LEAD_ALLOCATION_MATRIX_VIEW",
}


// get left options (fixed)
export enum CellIDS {
  First_Name = "firstName",
  Last_Name = "lastName",
  HR_ID = "hrId",
  T_B_ID = "tandbId",
  Email_ID = "email",
  Home_Region = "homeOfficeRegion",
  Home_System = "homeSystem",
  Home_Sub_System = "homesubsystem",
  Home_Office = "homeOffice",
  Host_Region = "hostOfficeRegion",
  Host_System = "hostSystem",
  Host_Sub_System = "hostsubsystem",
  Host_Office = "hostOffice",
  Allocation = "allocation",
  Lateral_Hire = "lateralHire",
  Career_Track = "careerTrack",
  Gender = "gender",
  Cohort = "cohort",
  CohortStep = "cohortStep",
  Months_Of_Experience = "monthsOfExperience",
  Months_Of_Employment = "monthsOfEmployment",
  Assistant_Email_ID = "assistantEmail",
  Career_Step_Job_Function = "careerStepJobFunction",
  Global_Title = "globalTitle",
  Business_Title = "businessTitle",
  MDP_Archetype = "mdpArchetype",
  Staffing_Coordinator = "staffingCoordinator",
  Birth_Month = "birthMonth",
  Birth_Date = "Birth_Date",
  Months_In_Role = "monthsInRole",
  Last_Promotion_Date = "lastPromotionDate",
  Termination_Date = "terminationDate",
  Level = "paAffiliationLevel",
  Levels = "levels",
  Practice_Area_Topic_ID = "bstGuid",
  PA_Topic_Sector = "bstLabel",
  Lead_Allocation_Percentage = "leadAllocation",
  Type = "topicLevel",
  Staffing_PA = "staffingPa",
  PA_Sponsor = "paSponsor",
  Sponsor_PA = "sponsorPa",
  Primary_Topic = "primaryTopic",
  Action = "action",
  CP = "CP",
  EN = "EN",
  FIP = "FIP",
  HC = "HC",
  IG = "IG",
  INS = "INS",
  PI = "PI",
  PS = "PS",
  TMT = "TMT",
  TRF = "TRF",
  CS = "CS",
  CFS = "CFS",
  MSP = 'MSP',
  TCI = 'TCI',
  OPS = "OPS",
  POP = "POP",
  TDA = 'TDA',
  RSK = "RSK",
  GA = "GA",
  SI = "SI",
  CFSAL = "CFS%",
  CSAL = "CS%",
  FPAagn = "FPAagn%",
  GAAL = "GA%",
  IPAagn = "IPAagn%",
  MSPAL = "MSP%",
  SIAL = "SI%",
  CPAL = "CP%",
  ENAL = "EN%",
  FIPAL = "FIP%",
  HCAL = "HC%",
  IGAL = "IG%",
  INSAL = "INS%",
  PIAL = "PI%",
  PSAL = "PS%",
  TMTAL = "TMT%",
  TRFAL = "TRF%",
  TCIAL = 'TCI%',
  OPSAL = "OPS%",
  POPAL = "POP%",
  TDAAL = 'TDA%',
  RSKAL = "RSK%",
}

export enum SnowFlakeCellIDS {
  First_Name = "firstName",
  Last_Name = "preferredLastName",
  HR_ID = "hrId",
  T_B_ID = "tandbId",
  Email_ID = "email",
  Home_Region = "homeOfficeRegion",
  Home_System = "homeSystem",
  Home_Sub_System = "homesubsystem",
  Home_Office = "homeOffice",
  Host_Region = "hostOfficeRegion",
  Host_System = "hostSystem",
  Host_Sub_System = "hostsubsystem",
  Host_Office = "hostOffice",
  Allocation = "allocation",
  Lateral_Hire = "lateralHire",
  Career_Track = "careerTrack",
  Gender = "gender",
  Cohort = "cohort",
  CohortStep = "cohortStep",
  Months_Of_Experience = "monthsOfExperience",
  Months_Of_Employment = "monthsOfEmployment",
  Assistant_Email_ID = "assistantEmail",
  Career_Step_Job_Function = "careerStepJobFunction",
  Global_Title = "globalTitle",
  Business_Title = "businessTitle",
  MDP_Archetype = "mdpArchetype",
  Staffing_Coordinator = "staffingCoordinator",
  Birth_Month = "birthMonth",
  Birth_Date = "Birth_Date",
  Months_In_Role = "monthsInRole",
  Last_Promotion_Date = "lastPromotionDate",
  Termination_Date = "terminationDate",
  Level = "paAffiliationLevel",
  Levels = "levels",
  Practice_Area_Topic_ID = "bstGuid",
  PA_Topic_Name = "bstLabel",
  Lead_Allocation_Percentage = "leadAllocation",
  Type = "topicLevel",
  Staffing_PA = "staffingPa",
  PA_Sponsor = "paSponsor",
  Sponsor_PA = "sponsorPa",
  Primary_Topic = "primaryTopic",
  Action = "action",
  CP = "CP",
  EN = "EN",
  FIP = "FIP",
  HC = "HC",
  IG = "IG",
  INS = "INS",
  PI = "PI",
  PS = "PS",
  TMT = "TMT",
  TRF = "TRF",
  CS = "CS",
  CFS = "CFS",
  MSP = 'MSP',
  TCI = 'TCI',
  OPS = "OPS",
  POP = "POP",
  TDA = 'TDA',
  RSK = "RSK",
  GA = "GA",
  SI = "SI",
  CFSAL = "CFS%",
  CSAL = "CS%",
  FPAagn = "FPAagn%",
  GAAL = "GA%",
  IPAagn = "IPAagn%",
  MSPAL = "MSP%",
  SIAL = "SI%",
  CPAL = "CP%",
  ENAL = "EN%",
  FIPAL = "FIP%",
  HCAL = "HC%",
  IGAL = "IG%",
  INSAL = "INS%",
  PIAL = "PI%",
  PSAL = "PS%",
  TMTAL = "TMT%",
  TRFAL = "TRF%",
  TCIAL = 'TCI%',
  OPSAL = "OPS%",
  POPAL = "POP%",
  TDAAL = 'TDA%',
  RSKAL = "RSK%",
}

export const AFFILIATIONS_LABELS: any = {
  [CellIDS.First_Name]: "First Name",
  [CellIDS.Last_Name]: "Last Name",
  [CellIDS.HR_ID]: "HR ID",
  [CellIDS.T_B_ID]: "T&B ID",
  [CellIDS.Email_ID]: "Email ID",
  [CellIDS.Home_Region]: "Home Region",
  [CellIDS.Home_System]: "Home System",
  [CellIDS.Home_Sub_System]: "Home Sub-System",
  [CellIDS.Home_Office]: "Home Office",
  [CellIDS.Host_Region]: "Host Region",
  [CellIDS.Host_System]: "Host System",
  [CellIDS.Host_Sub_System]: "Host Sub-System",
  [CellIDS.Host_Office]: "Host Office",
  [CellIDS.Allocation]: "Allocation",
  [CellIDS.Lateral_Hire]: "Lateral Hire",
  [CellIDS.Career_Track]: "Job Category",
  [CellIDS.Gender]: "Gender",
  [CellIDS.Cohort]: "Cohort",
  [CellIDS.CohortStep]: "Cohort Step",
  [CellIDS.Months_Of_Experience]: "Months Of Experience",
  [CellIDS.Months_Of_Employment]: "Months Of Employment",
  [CellIDS.Assistant_Email_ID]: "Assistant Email ID",
  [CellIDS.Career_Step_Job_Function]: "Job Family Group",
  [CellIDS.Global_Title]: "Job Title",
  [CellIDS.Business_Title]: "Business Title",
  [CellIDS.MDP_Archetype]: "MDP Archetype",
  [CellIDS.Staffing_Coordinator]: "Staffing Coordinator",
  [CellIDS.Birth_Date]: "Birth Date",
  [CellIDS.Birth_Month]: "Birth Month",
  [CellIDS.Months_In_Role]: "Months In Role",
  [CellIDS.Last_Promotion_Date]: "Last Promotion Date",
  [CellIDS.Termination_Date]: "Termination Date",
  [CellIDS.Level]: "Level",
  [CellIDS.Levels]: "Levels",
  [CellIDS.Practice_Area_Topic_ID]: "Practice Area Topic ID",
  [SnowFlakeCellIDS.PA_Topic_Name]: "PA Affiliation",
  [CellIDS.Lead_Allocation_Percentage]: "%Lead Allocation",
  [CellIDS.Type]: "Type",
  [CellIDS.Staffing_PA]: "Primary PA",
  [CellIDS.PA_Sponsor]: "PA Sponsor",
  [CellIDS.Sponsor_PA]: "Sponsor PA",
  [CellIDS.Primary_Topic]: "Primary Topic",
  [CellIDS.Action]: "Action",
  [CellIDS.CP]: "CP",
  [CellIDS.EN]: "EN",
  [CellIDS.FIP]: "FIP",
  [CellIDS.HC]: "HC",
  [CellIDS.IG]: "IG",
  [CellIDS.INS]: "INS",
  [CellIDS.PI]: "PI",
  [CellIDS.PS]: "PS",
  [CellIDS.TMT]: "TMT",
  [CellIDS.TRF]: "TRF",
  [CellIDS.CS]: "CS",
  [CellIDS.CFS]: "CFS",
  [CellIDS.MSP]: "MSP",
  [CellIDS.TCI]: "TCI",
  [CellIDS.OPS]: "OPS",
  [CellIDS.POP]: "POP",
  [CellIDS.TDA]: "TDA",
  [CellIDS.RSK]: "RSK",
  [CellIDS.GA]: "GA",
  [CellIDS.SI]: "SI",
  [CellIDS.CFSAL]: "CFS%",
  [CellIDS.CSAL]: "CS%",
  [CellIDS.FPAagn]: "FPAagn%",
  [CellIDS.GAAL]: "GA%",
  [CellIDS.IPAagn]: "IPAagn%",
  [CellIDS.MSPAL]: "MSP%",
  [CellIDS.SIAL]: "SI%",
  [CellIDS.CPAL]: "CP%",
  [CellIDS.ENAL]: "EN%",
  [CellIDS.FIPAL]: "FIP%",
  [CellIDS.HCAL]: "HC%",
  [CellIDS.IGAL]: "IG%",
  [CellIDS.INSAL]: "INS%",
  [CellIDS.PIAL]: "PI%",
  [CellIDS.PSAL]: "PS%",
  [CellIDS.TMTAL]: "TMT%",
  [CellIDS.TRFAL]: "TRF%",
  [CellIDS.TCIAL]: "TCI%",
  [CellIDS.OPSAL]: "OPS%",
  [CellIDS.POPAL]: "POP%",
  [CellIDS.TDAAL]: "TDA%",
  [CellIDS.RSKAL]: "RSK%",
}

const getPaMatrixTopicValue = (data: any, id: string): string => {
  if (data?.levels) {
    return data?.levels[id] || "";
  }
  return "";
}


const PaMatrixTopicComponent = memo(({ data, header }: IReactTableComponent) => {
  return (<div>{getPaMatrixTopicValue(data, header.id)}</div>)
})

export const SnoFlake_Cell_IDS_VIEW = {
  [SnowFlakeCellIDS.First_Name]: ({ affiliationView }: any) => {
    return {
      id: SnowFlakeCellIDS.First_Name,
      label: AFFILIATIONS_LABELS[SnowFlakeCellIDS.First_Name],
      isFixed: true,
      fixedDirection: "left",
      width: 15,
      CustomRowComponent: memo(({ data, header }: IReactTableComponent) => {
        return (
          <div className={classNames(["flex flex-row", { "flex-align-center": affiliationView !== AFFILATIONS_VIEWS.ALL_AFFILIATIONS_VIEW }])} id={data[header.id]}>
            {data?.employeeRecords.hrId && <ProfilePicture profileUrl={`/picture?hrid=${data.employeeRecords.hrId}`} baseClassName="margin-r-4" imageClassName="paaf_grid--avatar" />}
            {data?.employeeRecords.preferredFirstName || ""}
          </div>)
      })
    }
  },

  [CellIDS.Last_Name]: () => {
    return {
      id: CellIDS.Last_Name,
      label: AFFILIATIONS_LABELS[CellIDS.Last_Name],
      isFixed: true,
      fixedDirection: "left",
      width: 15,
      CustomRowComponent: memo(({ data, header }: IReactTableComponent) => {
        return (<TooltipText value={getRowValue(data?.employeeRecords.preferredLastName)} />)
      })
    }
  },

  [CellIDS.HR_ID]: () => {
    return {
      id: CellIDS.HR_ID,
      label: AFFILIATIONS_LABELS[CellIDS.HR_ID],
      width: 20,
      CustomRowComponent: memo(({ data, header }: IReactTableComponent) => {
        return (<TooltipText value={getRowValue(data?.hrId)} />)
      })
    }
  },
  [SnowFlakeCellIDS.T_B_ID]: () => {
    return {
      id: SnowFlakeCellIDS.T_B_ID,
      label: AFFILIATIONS_LABELS[SnowFlakeCellIDS.T_B_ID],
      width: 15,
      CustomRowComponent: memo(({ data, header }: IReactTableComponent) => {
        return (<TooltipText value={getRowValue(data?.employeeRecords.tandbId)} />)
      })
    }
  },
  [SnowFlakeCellIDS.Email_ID]: () => {
    return {
      id: SnowFlakeCellIDS.Email_ID,
      label: AFFILIATIONS_LABELS[SnowFlakeCellIDS.Email_ID],
      width: 15,
      CustomRowComponent: memo(({ data, header }: IReactTableComponent) => {
        return (<TooltipText value={getRowValue(data?.employeeRecords.email)} />)
      })
    }
  },
  [CellIDS.Home_Region]: () => {
    return {
      id: CellIDS.Home_Region,
      label: AFFILIATIONS_LABELS[CellIDS.Home_Region],
      width: 15,
      CustomRowComponent: memo(({ data, header }: IReactTableComponent) => {
        return (<TooltipText value={getRowValue(data?.employeeRecords.homeOfficeRegion)} />)
      })
    }
  },
  [CellIDS.Home_System]: () => {
    return {
      id: CellIDS.Home_System,
      label: AFFILIATIONS_LABELS[CellIDS.Home_System],
      width: 15,
      CustomRowComponent: memo(({ data, header }: IReactTableComponent) => {
        return (<TooltipText value={getRowValue(data?.employeeRecords.homeSystem)} />)
      })
    }
  },
  [CellIDS.Home_Sub_System]: () => {
    return {
      id: CellIDS.Home_Sub_System,
      label: AFFILIATIONS_LABELS[CellIDS.Home_Sub_System],
      width: 15,
      CustomRowComponent: memo(({ data, header }: IReactTableComponent) => {
        return (<TooltipText value={getRowValue(data?.employeeRecords.homesubsystem)} />)
      })
    }
  },
  [CellIDS.Home_Office]: () => {
    return {
      id: CellIDS.Home_Office,
      label: AFFILIATIONS_LABELS[CellIDS.Home_Office],
      width: 15,
      CustomRowComponent: memo(({ data, header }: IReactTableComponent) => {
        return (<TooltipText value={getRowValue(data?.employeeRecords.homeOffice)} />)
      })
    }
  },
  [CellIDS.Host_Region]: () => {
    return {
      id: CellIDS.Host_Region,
      label: AFFILIATIONS_LABELS[CellIDS.Host_Region],
      width: 15,
      CustomRowComponent: memo(({ data, header }: IReactTableComponent) => {
        // const { user } = UsePeople(data["hrId"], true);
        return (<TooltipText value={getRowValue(data?.employeeRecords.hostOfficeRegion)} />)
      })
    }
  },
  [CellIDS.Host_System]: () => {
    return {
      id: CellIDS.Host_System,
      label: AFFILIATIONS_LABELS[CellIDS.Host_System],
      width: 15,
      CustomRowComponent: memo(({ data, header }: IReactTableComponent) => {
        return (<TooltipText value={getRowValue(data?.employeeRecords.hostSystem)} />)
      })
    }
  },
  [CellIDS.Host_Sub_System]: () => {
    return {
      id: CellIDS.Host_Sub_System,
      label: AFFILIATIONS_LABELS[CellIDS.Host_Sub_System],
      width: 15,
      CustomRowComponent: memo(({ data, header }: IReactTableComponent) => {
        return (<TooltipText value={getRowValue(data?.employeeRecords.hostsubsystem)} />)
      })
    }
  },
  [CellIDS.Host_Office]: () => {
    return {
      id: CellIDS.Host_Office,
      label: AFFILIATIONS_LABELS[CellIDS.Host_Office],
      width: 15,
      CustomRowComponent: memo(({ data, header }: IReactTableComponent) => {
        return (<TooltipText value={getRowValue(data?.employeeRecords.hostOffice)} />)
      })
    }
  },
  [SnowFlakeCellIDS.Allocation]: () => {
    return {
      id: SnowFlakeCellIDS.Allocation,
      label: AFFILIATIONS_LABELS[SnowFlakeCellIDS.Allocation],
      width: 15,
      CustomRowComponent: memo(({ data, header }: IReactTableComponent) => {
        return (<TooltipText value={getRowValue(data?.employeeRecords?.defaultAllocation)} />)
      })
    }
  },
  [CellIDS.Lateral_Hire]: () => {
    return {
      id: CellIDS.Lateral_Hire,
      label: AFFILIATIONS_LABELS[CellIDS.Lateral_Hire],
      width: 15,
      CustomRowComponent: memo(({ data, header }: IReactTableComponent) => {
        return (<TooltipText value={data?.employeeRecords.lateralHire ? "Yes" : "No"} />)
      })
    }
  },
  [CellIDS.Career_Track]: () => {
    return {
      id: CellIDS.Career_Track,
      label: AFFILIATIONS_LABELS[CellIDS.Career_Track],
      width: 15,
      CustomRowComponent: memo(({ data, header }: IReactTableComponent) => {
        return (<TooltipText value={getRowValue(data?.employeeRecords.jobCategoryId)} />)
      })
    }
  },
  [CellIDS.Gender]: () => {
    return {
      id: CellIDS.Gender,
      label: AFFILIATIONS_LABELS[CellIDS.Gender],
      width: 15,
      CustomRowComponent: memo(({ data, header }: IReactTableComponent) => {
        return (<TooltipText value={getRowValue(data?.employeeRecords.gender)} />)
      })
    }
  },
  [CellIDS.Cohort]: () => {
    return {
      id: CellIDS.Cohort,
      label: AFFILIATIONS_LABELS[CellIDS.Cohort],
      width: 15,
      CustomRowComponent: memo(({ data, header }: IReactTableComponent) => {
        return (<TooltipText value={getRowValue(data?.employeeRecords.cohort || data?.employeeRecords.cohort)} />)
      })
    }
  },
  [CellIDS.CohortStep]: () => {
    return {
      id: CellIDS.CohortStep,
      label: AFFILIATIONS_LABELS[CellIDS.CohortStep],
      width: 15,
      CustomRowComponent: memo(({ data, header }: IReactTableComponent) => {
        return (<TooltipText value={getRowValue(data?.employeeRecords.cohortStep)} />)
      })
    }
  },
  [CellIDS.Months_Of_Experience]: () => {
    return {
      id: SnowFlakeCellIDS.Months_Of_Experience,
      label: AFFILIATIONS_LABELS[CellIDS.Months_Of_Experience],
      width: 20,
      CustomRowComponent: memo(({ data, header }: IReactTableComponent) => {
        return (<TooltipText value={getRowValue(data?.employeeRecords.monthsOfExperience)} />)
      })
    }
  },
  [CellIDS.Months_Of_Employment]: () => {
    return {
      id: CellIDS.Months_Of_Employment,
      label: AFFILIATIONS_LABELS[CellIDS.Months_Of_Employment],
      width: 20,
      CustomRowComponent: memo(({ data, header }: IReactTableComponent) => {
        return (<TooltipText value={getRowValue(data?.employeeRecords?.monthsOfEmployment)} />)
      })
    }
  },
  [CellIDS.Assistant_Email_ID]: () => {
    return {
      id: CellIDS.Assistant_Email_ID,
      label: AFFILIATIONS_LABELS[CellIDS.Assistant_Email_ID],
      width: 15,
      CustomRowComponent: memo(({ data, header }: IReactTableComponent) => {
        return (<TooltipText value={getRowValue(data.assistantEmail)} />)
      })
    }
  },
  [CellIDS.Career_Step_Job_Function]: () => {
    return {
      id: CellIDS.Career_Step_Job_Function,
      label: AFFILIATIONS_LABELS[CellIDS.Career_Step_Job_Function],
      width: 20,
      CustomRowComponent: memo(({ data, header }: IReactTableComponent) => {
        return (<TooltipText value={getRowValue(data?.employeeRecords.jobFamilyGroupName)} />)
      })
    }
  },
  [CellIDS.Global_Title]: () => {
    return {
      id: CellIDS.Global_Title,
      label: AFFILIATIONS_LABELS[CellIDS.Global_Title],
      width: 15,
      CustomRowComponent: memo(({ data, header }: IReactTableComponent) => {
        return (<TooltipText value={getRowValue(data?.employeeRecords.globalTitle)} />)
      })
    }
  },
  [CellIDS.Business_Title]: () => {
    return {
      id: CellIDS.Business_Title,
      label: AFFILIATIONS_LABELS[CellIDS.Business_Title],
      width: 15,
      CustomRowComponent: memo(({ data, header }: IReactTableComponent) => {
        return (<TooltipText value={getRowValue(data?.employeeRecords.businessTitle)} />)
      })
    }
  },
  [CellIDS.MDP_Archetype]: () => {
    return {
      id: CellIDS.MDP_Archetype,
      label: AFFILIATIONS_LABELS[CellIDS.MDP_Archetype],
      width: 15,
      CustomRowComponent: memo(({ data, header }: IReactTableComponent) => {
        return (<TooltipText value={getRowValue(data?.mdpArchetype)} />)
      })
    }
  },
  [CellIDS.Staffing_Coordinator]: () => {
    return {
      id: CellIDS.Staffing_Coordinator,
      label: AFFILIATIONS_LABELS[CellIDS.Staffing_Coordinator],
      width: 15,
      CustomRowComponent: memo(({ data, header }: IReactTableComponent) => {
        return (<TooltipText value={""} />)
      })
    }
  },
  [CellIDS.Birth_Month]: () => {
    return {
      id: CellIDS.Birth_Month,
      label: AFFILIATIONS_LABELS[CellIDS.Birth_Month],
      width: 15,
      CustomRowComponent: memo(({ data, header }: IReactTableComponent) => {
        const dob: any = data?.employeeRecords?.dateOfBirth?.split('-') || '';
        return (<TooltipText value={data?.employeeRecords?.dateOfBirth ? months[`${dob[1]}`] : ""} />)
      })
    }
  },
  [CellIDS.Birth_Date]: () => {
    return {
      id: CellIDS.Birth_Date,
      label: AFFILIATIONS_LABELS[CellIDS.Birth_Date],
      width: 15,
      CustomRowComponent: memo(({ data, header }: IReactTableComponent) => {
        const dob = data?.employeeRecords?.dateOfBirth?.split('-') || '';
        return (<TooltipText value={data?.employeeRecords?.dateOfBirth ? dob[2] : ""} />)
      })
    }
  },
  [CellIDS.Months_In_Role]: () => {
    return {
      id: CellIDS.Months_In_Role,
      label: AFFILIATIONS_LABELS[CellIDS.Months_In_Role],
      width: 15,
      CustomRowComponent: memo(({ data, header }: IReactTableComponent) => {
        return (<TooltipText value={getRowValue(data.employeeRecords?.monthsInRole)} />);
      })
    }
  },
  [CellIDS.Last_Promotion_Date]: () => {
    return {
      id: CellIDS.Last_Promotion_Date,
      label: AFFILIATIONS_LABELS[CellIDS.Last_Promotion_Date],
      width: 15,
      CustomRowComponent: memo(({ data, header }: IReactTableComponent) => {
        return (<div> {data.employeeRecords?.lastPromotionDate ? <Moment format={DATE_FORMAT}>{data.employeeRecords?.lastPromotionDate}</Moment> : ""}</div>)
      })
    }
  },
  [CellIDS.Termination_Date]: () => {
    return {
      id: CellIDS.Termination_Date,
      label: AFFILIATIONS_LABELS[CellIDS.Termination_Date],
      width: 15,
      CustomRowComponent: memo(({ data, header }: IReactTableComponent) => {
        const terminationDateSplitted = data?.employeeRecords?.terminationDate?.split('-') || '';
        return (<div> {data.employeeRecords?.terminationDate ? `${terminationDateSplitted[1]}/${terminationDateSplitted[2]}/${terminationDateSplitted[0]}` : ""}</div>)
      })
    }
  },
  [CellIDS.Level]: () => {
    return {
      id: CellIDS.Level,
      label: AFFILIATIONS_LABELS[CellIDS.Level],
      width: 15,
      CustomRowComponent: memo(({ data, header }: IReactTableComponent) => {
        return (<TooltipText value={getRowValue(data.paAffiliationLevel)} />);
      })
    }
  },
  [CellIDS.Levels]: () => {
    return {
      id: CellIDS.Levels,
      label: AFFILIATIONS_LABELS[CellIDS.Levels],
      width: 30,
      CustomRowComponent: memo(({ data, header }: IReactTableComponent) => {
        return data?.levels?.length ? <table className="search-table-level">
          <thead>
            <tr>
              <th>Practice Area/Topic ID</th>
              <th>Practice Area/Topic/Sector</th>
              <th>Affiliation Level</th>
            </tr>
          </thead>
          <tbody>
            {data?.levels?.map(({ bstLabel, bstGuid, paAffiliationLevel }: any, index: number) => (
              <tr key={`level-${index}`}>
                <td>{bstGuid}</td>
                <td>{bstLabel}</td>
                <td>{paAffiliationLevel}</td>
              </tr>
            ))}
          </tbody>
        </table>
          : <div></div>
      }
      )
    }
  },
  [CellIDS.Practice_Area_Topic_ID]: () => {
    return {
      id: CellIDS.Practice_Area_Topic_ID,
      label: AFFILIATIONS_LABELS[CellIDS.Practice_Area_Topic_ID],
      width: 15,
      CustomRowComponent: memo(({ data, header }: IReactTableComponent) => {
        return (<TooltipText value={getRowValue(data?.bstGuid)} />)
      })
    }
  },
  // [CellIDS.PA_Topic_Sector]: () => {
  //   return {
  //     id: CellIDS.PA_Topic_Sector,
  //     label: AFFILIATIONS_LABELS[CellIDS.PA_Topic_Sector],
  //     width: 15,
  //     CustomRowComponent: memo(({ data, header }: IReactTableComponent) => {
  //       return (<TooltipText value={getRowValue(data?.bstLabel)} />)
  //     })
  //   }
  // },
  [SnowFlakeCellIDS.PA_Topic_Name]: () => {
    return {
      id: SnowFlakeCellIDS.PA_Topic_Name,
      label: AFFILIATIONS_LABELS[SnowFlakeCellIDS.PA_Topic_Name],
      width: 15,
      CustomRowComponent: memo(({ data, header }: IReactTableComponent) => {
        return (<TooltipText value={getRowValue(data?.bstLabel)} />)
      })
    }
  },
  [CellIDS.Lead_Allocation_Percentage]: () => {
    return {
      id: CellIDS.Lead_Allocation_Percentage,
      label: AFFILIATIONS_LABELS[CellIDS.Lead_Allocation_Percentage],
      width: 15,
      CustomRowComponent: memo(({ data, header }: IReactTableComponent) => {
        return (<TooltipText value={`${getRowValue(data?.topicLevel)}%`} />)
      })
    }
  },
  [CellIDS.Type]: () => {
    return {
      id: CellIDS.Type,
      label: AFFILIATIONS_LABELS[CellIDS.Type],
      width: 15,
      CustomRowComponent: memo(({ data, header }: IReactTableComponent) => {
        return (<TooltipText value={getRowValue(data?.topicLevel)} />)
      })
    }
  },
  [CellIDS.Staffing_PA]: ({ affiliationView }: any) => {
    return {
      id: CellIDS.Staffing_PA,
      label: AFFILIATIONS_LABELS[CellIDS.Staffing_PA],
      width: 15,
      CustomRowComponent: memo(({ data, header }: IReactTableComponent) => {
        if (affiliationView === AFFILATIONS_VIEWS.ALL_AFFILIATIONS_TABLE_VIEW) {
          return (<TooltipText value={data.staffingFlag ? data.bstLabel : ""} />)
        } else if (affiliationView === AFFILATIONS_VIEWS.ALL_AFFILIATIONS_VIEW) {
          const staffingString: string = data.levels?.filter((level: any) => level.staffingFlag).map((item: any) => item.bstLabel).join(", ") || "";
          return (<TooltipText value={staffingString} />)
        } else if (affiliationView === AFFILATIONS_VIEWS.PA_MATRIX_VIEW) {
          const staffingString: string = data.levelDetails?.filter((level: any) => level.staffingFlag).map((item: any) => item.bstLabel).join(", ") || "";
          return (<TooltipText value={staffingString} />)
        } else {
          return <div></div>
        }
      })
    }
  },
  [CellIDS.PA_Sponsor]: () => {
    return {
      id: CellIDS.PA_Sponsor,
      label: AFFILIATIONS_LABELS[CellIDS.PA_Sponsor],
      width: 15,
      CustomRowComponent: memo(({ data, header }: IReactTableComponent) => {
        const { user } = UsePeople(data["hrId"], true);
        return (<TooltipText value={user?.sponsors?.map(sponsor => sponsor.name).join(", ") || ""} />)
      })
    }
  },
  [CellIDS.Sponsor_PA]: () => {
    return {
      id: CellIDS.Sponsor_PA,
      label: AFFILIATIONS_LABELS[CellIDS.Sponsor_PA],
      width: 15,
      CustomRowComponent: memo(({ data, header }: IReactTableComponent) => {
        const { user } = UsePeople(data["hrId"], true);
        return (<TooltipText value={user?.sponsors?.map(sponsor => sponsor.practiceArea).join(", ") || ""} />)
      })
    }
  },
  [CellIDS.Primary_Topic]: () => {
    return {
      id: CellIDS.Primary_Topic,
      label: AFFILIATIONS_LABELS[CellIDS.Primary_Topic],
      width: 15,
      CustomRowComponent: memo(({ data, header }: IReactTableComponent) => {
        const { user } = UsePeople(data["hrId"], true);
        return (<TooltipText value={user?.sponsors?.map(sponsor => sponsor.primaryTopic).join(", ") || ""} />)
      })
    }
  },
  [CellIDS.Action]: () => {
    return {
      id: CellIDS.Action,
      label: AFFILIATIONS_LABELS[CellIDS.Action],
      isFixed: true,
      fixedDirection: "right",
      width: 10,
      CustomRowComponent: ({ data, header }: IReactTableComponent) => {
        const navigate = useNavigate();
        const { hrId } = data;
        const { user } = UsePeople(hrId, true)

        return (
          <div className="">
            <CustomButton
              transparent
              primaryButton
              noOutline
              noPadding
              buttonText="Edit"
              handleClick={() => {
                iconInteraction(HEADRES.Search_PAAF, undefined!, "Edit", getUserName(user));
                navigate(`${URLRoutes.client.EditAffiliations}/${hrId}`)
              }} />
          </div>
        )
      }
    }
  },
  [CellIDS.CP]: () => {
    return {
      id: CellIDS.CP,
      label: AFFILIATIONS_LABELS[CellIDS.CP],
      width: 5,
      CustomRowComponent: PaMatrixTopicComponent
    }
  },
  [CellIDS.EN]: () => {
    return {
      id: CellIDS.EN,
      label: AFFILIATIONS_LABELS[CellIDS.EN],
      width: 5,
      CustomRowComponent: PaMatrixTopicComponent

    }
  },
  [CellIDS.FIP]: () => {
    return {
      id: CellIDS.FIP,
      label: AFFILIATIONS_LABELS[CellIDS.FIP],
      width: 5,
      CustomRowComponent: PaMatrixTopicComponent

    }
  },
  [CellIDS.HC]: () => {
    return {
      id: CellIDS.HC,
      label: AFFILIATIONS_LABELS[CellIDS.HC],
      width: 5,
      CustomRowComponent: PaMatrixTopicComponent

    }
  },
  [CellIDS.IG]: () => {
    return {
      id: CellIDS.IG,
      label: AFFILIATIONS_LABELS[CellIDS.IG],
      width: 5,
      CustomRowComponent: PaMatrixTopicComponent

    }
  },
  [CellIDS.INS]: () => {
    return {
      id: CellIDS.INS,
      label: AFFILIATIONS_LABELS[CellIDS.INS],
      width: 5,
      CustomRowComponent: PaMatrixTopicComponent

    }
  },
  [CellIDS.PI]: () => {
    return {
      id: CellIDS.PI,
      label: AFFILIATIONS_LABELS[CellIDS.PI],
      width: 5,
      CustomRowComponent: PaMatrixTopicComponent

    }
  },
  [CellIDS.PS]: () => {
    return {
      id: CellIDS.PS,
      label: AFFILIATIONS_LABELS[CellIDS.PS],
      width: 5,
      CustomRowComponent: PaMatrixTopicComponent

    }
  },
  [CellIDS.TMT]: () => {
    return {
      id: CellIDS.TMT,
      label: AFFILIATIONS_LABELS[CellIDS.TMT],
      width: 5,
      CustomRowComponent: PaMatrixTopicComponent

    }
  },
  [CellIDS.TRF]: () => {
    return {
      id: CellIDS.TRF,
      label: AFFILIATIONS_LABELS[CellIDS.TRF],
      width: 5,
      CustomRowComponent: PaMatrixTopicComponent

    }
  },
  [CellIDS.CS]: () => {
    return {
      id: CellIDS.CS,
      label: AFFILIATIONS_LABELS[CellIDS.CS],
      width: 5,
      CustomRowComponent: PaMatrixTopicComponent

    }
  },
  [CellIDS.CFS]: () => {
    return {
      id: CellIDS.CFS,
      label: AFFILIATIONS_LABELS[CellIDS.CFS],
      width: 5,
      CustomRowComponent: PaMatrixTopicComponent

    }
  },
  [CellIDS.MSP]: () => {
    return {
      id: CellIDS.MSP,
      label: AFFILIATIONS_LABELS[CellIDS.MSP],
      width: 5,
      CustomRowComponent: PaMatrixTopicComponent

    }
  },
  [CellIDS.TCI]: () => {
    return {
      id: CellIDS.TCI,
      label: AFFILIATIONS_LABELS[CellIDS.TCI],
      width: 5,
      CustomRowComponent: PaMatrixTopicComponent
    }
  },
  [CellIDS.OPS]: () => {
    return {
      id: CellIDS.OPS,
      label: AFFILIATIONS_LABELS[CellIDS.OPS],
      width: 5,
      CustomRowComponent: PaMatrixTopicComponent

    }
  },
  [CellIDS.POP]: () => {
    return {
      id: CellIDS.POP,
      label: AFFILIATIONS_LABELS[CellIDS.POP],
      width: 5,
      CustomRowComponent: PaMatrixTopicComponent

    }
  },
  [CellIDS.TDA]: () => {
    return {
      id: CellIDS.TDA,
      label: AFFILIATIONS_LABELS[CellIDS.TDA],
      width: 5,
      CustomRowComponent: PaMatrixTopicComponent

    }
  },
  [CellIDS.RSK]: () => {
    return {
      id: CellIDS.RSK,
      label: AFFILIATIONS_LABELS[CellIDS.RSK],
      width: 5,
      CustomRowComponent: PaMatrixTopicComponent

    }
  },
  [CellIDS.GA]: () => {
    return {
      id: CellIDS.GA,
      label: AFFILIATIONS_LABELS[CellIDS.GA],
      width: 5,
      CustomRowComponent: PaMatrixTopicComponent

    }
  },
  [CellIDS.SI]: () => {
    return {
      id: CellIDS.SI,
      label: AFFILIATIONS_LABELS[CellIDS.SI],
      width: 5,
      CustomRowComponent: PaMatrixTopicComponent

    }
  }
}

export const Cell_IDS_VIEW = {
  [CellIDS.First_Name]: ({ affiliationView }: any) => {
    return {
      id: CellIDS.First_Name,
      label: AFFILIATIONS_LABELS[CellIDS.First_Name],
      isFixed: true,
      fixedDirection: "left",
      width: 15,
      CustomRowComponent: memo(({ data, header }: IReactTableComponent) => {
        const { user } = UsePeople(data["hrId"], true);
        return (
          <div className={classNames(["flex flex-row", { "flex-align-center": affiliationView !== AFFILATIONS_VIEWS.ALL_AFFILIATIONS_VIEW }])} id={data[header.id]}>
            {user?.profilePicture && <ProfilePicture profileUrl={user.profilePicture} baseClassName="margin-r-4" imageClassName="paaf_grid--avatar" />}
            {user?.preferredFirstName || ""}
          </div>)
      })
    }
  },

  [CellIDS.Last_Name]: () => {
    return {
      id: CellIDS.Last_Name,
      label: AFFILIATIONS_LABELS[CellIDS.Last_Name],
      isFixed: true,
      fixedDirection: "left",
      width: 15,
      CustomRowComponent: memo(({ data, header }: IReactTableComponent) => {
        const { user } = UsePeople(data["hrId"], true);
        return (<TooltipText value={getRowValue(user?.preferredLastName)} />)
      })
    }
  },

  [CellIDS.HR_ID]: () => {
    return {
      id: CellIDS.HR_ID,
      label: AFFILIATIONS_LABELS[CellIDS.HR_ID],
      width: 20,
      CustomRowComponent: memo(({ data, header }: IReactTableComponent) => {
        const { user } = UsePeople(data["hrId"], true);
        return (<TooltipText value={getRowValue(user?.id)} />)
      })
    }
  },
  [CellIDS.T_B_ID]: () => {
    return {
      id: CellIDS.T_B_ID,
      label: AFFILIATIONS_LABELS[CellIDS.T_B_ID],
      width: 15,
      CustomRowComponent: memo(({ data, header }: IReactTableComponent) => {
        const { user } = UsePeople(data["hrId"], true);
        return (<TooltipText value={getRowValue(user?.tandbId)} />)
      })
    }
  },
  [CellIDS.Email_ID]: () => {
    return {
      id: CellIDS.Email_ID,
      label: AFFILIATIONS_LABELS[CellIDS.Email_ID],
      width: 15,
      CustomRowComponent: memo(({ data, header }: IReactTableComponent) => {
        const { user } = UsePeople(data["hrId"], true);
        return (<TooltipText value={getRowValue(user?.email)} />)
      })
    }
  },
  [CellIDS.Home_Region]: () => {
    return {
      id: CellIDS.Home_Region,
      label: AFFILIATIONS_LABELS[CellIDS.Home_Region],
      width: 15,
      CustomRowComponent: memo(({ data, header }: IReactTableComponent) => {
        const { user } = UsePeople(data["hrId"], true);
        return (<TooltipText value={getRowValue(user?.homeOfficeRegion)} />)
      })
    }
  },
  [CellIDS.Home_System]: () => {
    return {
      id: CellIDS.Home_System,
      label: AFFILIATIONS_LABELS[CellIDS.Home_System],
      width: 15,
      CustomRowComponent: memo(({ data, header }: IReactTableComponent) => {
        const { user } = UsePeople(data["hrId"], true);
        // getSystemFromHierarchies(user?.homeOfficeLocation?.hierarchies)
        return (<TooltipText value={''} />)
      })
    }
  },
  [CellIDS.Home_Office]: () => {
    return {
      id: CellIDS.Home_Office,
      label: AFFILIATIONS_LABELS[CellIDS.Home_Office],
      width: 15,
      CustomRowComponent: memo(({ data, header }: IReactTableComponent) => {
        const { user } = UsePeople(data["hrId"], true);
        return (<TooltipText value={getRowValue(user?.homeOffice)} />)
      })
    }
  },
  [CellIDS.Host_Region]: () => {
    return {
      id: CellIDS.Host_Region,
      label: AFFILIATIONS_LABELS[CellIDS.Host_Region],
      width: 15,
      CustomRowComponent: memo(({ data, header }: IReactTableComponent) => {
        const { user } = UsePeople(data["hrId"], true);
        return (<TooltipText value={getRowValue(user?.hostOfficeRegion)} />)
      })
    }
  },
  [CellIDS.Host_System]: () => {
    return {
      id: CellIDS.Host_System,
      label: AFFILIATIONS_LABELS[CellIDS.Host_System],
      width: 15,
      CustomRowComponent: memo(({ data, header }: IReactTableComponent) => {
        const { user } = UsePeople(data["hrId"], true);
        return (<TooltipText value={getSystemFromHierarchies(user?.hostOfficeLocation?.hierarchies)} />)
      })
    }
  },
  [CellIDS.Host_Office]: () => {
    return {
      id: CellIDS.Host_Office,
      label: AFFILIATIONS_LABELS[CellIDS.Host_Office],
      width: 15,
      CustomRowComponent: memo(({ data, header }: IReactTableComponent) => {
        const { user } = UsePeople(data["hrId"], true);
        return (<TooltipText value={getRowValue(user?.hostOfficeLocation?.name)} />)
      })
    }
  },
  [CellIDS.Allocation]: () => {
    return {
      id: CellIDS.Allocation,
      label: AFFILIATIONS_LABELS[CellIDS.Allocation],
      width: 15,
      CustomRowComponent: memo(({ data, header }: IReactTableComponent) => {
        const { user } = UsePeople(data["hrId"], true);
        return (<TooltipText value={getRowValue(user?.costAllocations?.map(item => item.allocationName).join(","))} />)
      })
    }
  },
  [CellIDS.Lateral_Hire]: () => {
    return {
      id: CellIDS.Lateral_Hire,
      label: AFFILIATIONS_LABELS[CellIDS.Lateral_Hire],
      width: 15,
      CustomRowComponent: memo(({ data, header }: IReactTableComponent) => {
        const { user } = UsePeople(data["hrId"], true);
        return (<TooltipText value={user?.lateralHire ? "Yes" : "No"} />)
      })
    }
  },
  [CellIDS.Career_Track]: () => {
    return {
      id: CellIDS.Career_Track,
      label: AFFILIATIONS_LABELS[CellIDS.Career_Track],
      width: 15,
      CustomRowComponent: memo(({ data, header }: IReactTableComponent) => {
        const { user } = UsePeople(data["hrId"], true);
        return (<TooltipText value={getRowValue(user?.jobCategoryId)} />)
      })
    }
  },
  [CellIDS.Gender]: () => {
    return {
      id: CellIDS.Gender,
      label: AFFILIATIONS_LABELS[CellIDS.Gender],
      width: 15,
      CustomRowComponent: memo(({ data, header }: IReactTableComponent) => {
        const { user } = UsePeople(data["hrId"], true);
        return (<TooltipText value={getRowValue(user?.gender)} />)
      })
    }
  },
  [CellIDS.Cohort]: () => {
    return {
      id: CellIDS.Cohort,
      label: AFFILIATIONS_LABELS[CellIDS.Cohort],
      width: 15,
      CustomRowComponent: memo(({ data, header }: IReactTableComponent) => {
        const { user } = UsePeople(data["hrId"], true);
        return (<TooltipText value={getRowValue(user?.cohortStep || user?.cohort)} />)
      })
    }
  },
  [CellIDS.CohortStep]: () => {
    return {
      id: CellIDS.CohortStep,
      label: AFFILIATIONS_LABELS[CellIDS.CohortStep],
      width: 15,
      CustomRowComponent: memo(({ data, header }: IReactTableComponent) => {
        return (<TooltipText value={getRowValue(data?.employeeRecords.cohortStep)} />)
      })
    }
  },
  [CellIDS.Months_Of_Experience]: () => {
    return {
      id: CellIDS.Months_Of_Experience,
      label: AFFILIATIONS_LABELS[CellIDS.Months_Of_Experience],
      width: 20,
      CustomRowComponent: memo(({ data, header }: IReactTableComponent) => {
        const { user } = UsePeople(data["hrId"], true);
        const { moExp } = getMonthOfEmpAndExp(user);
        return (<TooltipText value={getRowValue(moExp)} />)
      })
    }
  },
  [CellIDS.Months_Of_Employment]: () => {
    return {
      id: CellIDS.Months_Of_Employment,
      label: AFFILIATIONS_LABELS[CellIDS.Months_Of_Employment],
      width: 20,
      CustomRowComponent: memo(({ data, header }: IReactTableComponent) => {
        const { user } = UsePeople(data["hrId"], true);
        const { moEmp } = getMonthOfEmpAndExp(user);
        return (<TooltipText value={getRowValue(moEmp)} />)
      })
    }
  },
  [CellIDS.Assistant_Email_ID]: () => {
    return {
      id: CellIDS.Assistant_Email_ID,
      label: AFFILIATIONS_LABELS[CellIDS.Assistant_Email_ID],
      width: 15,
      CustomRowComponent: memo(({ data, header }: IReactTableComponent) => {
        return (<TooltipText value={getRowValue(data.assistantEmail)} />)
      })
    }
  },
  [CellIDS.Career_Step_Job_Function]: () => {
    return {
      id: CellIDS.Career_Step_Job_Function,
      label: AFFILIATIONS_LABELS[CellIDS.Career_Step_Job_Function],
      width: 20,
      CustomRowComponent: memo(({ data, header }: IReactTableComponent) => {
        const { user } = UsePeople(data["hrId"], true);
        return (<TooltipText value={getRowValue(user?.jobFamilyGroupName)} />)
      })
    }
  },
  [CellIDS.Global_Title]: () => {
    return {
      id: CellIDS.Global_Title,
      label: AFFILIATIONS_LABELS[CellIDS.Global_Title],
      width: 15,
      CustomRowComponent: memo(({ data, header }: IReactTableComponent) => {
        const { user } = UsePeople(data["hrId"], true);
        return (<TooltipText value={getRowValue(user?.globalTitle)} />)
      })
    }
  },
  [CellIDS.Business_Title]: () => {
    return {
      id: CellIDS.Business_Title,
      label: AFFILIATIONS_LABELS[CellIDS.Business_Title],
      width: 15,
      CustomRowComponent: memo(({ data, header }: IReactTableComponent) => {
        const { user } = UsePeople(data["hrId"], true);
        return (<TooltipText value={getRowValue(user?.businessTitle)} />)
      })
    }
  },
  [CellIDS.MDP_Archetype]: () => {
    return {
      id: CellIDS.MDP_Archetype,
      label: AFFILIATIONS_LABELS[CellIDS.MDP_Archetype],
      width: 15
    }
  },
  [CellIDS.Staffing_Coordinator]: () => {
    return {
      id: CellIDS.Staffing_Coordinator,
      label: AFFILIATIONS_LABELS[CellIDS.Staffing_Coordinator],
      width: 15,
      CustomRowComponent: memo(({ data, header }: IReactTableComponent) => {
        return (<TooltipText value={""} />)
      })
    }
  },
  [CellIDS.Birth_Month]: () => {
    return {
      id: CellIDS.Birth_Month,
      label: AFFILIATIONS_LABELS[CellIDS.Birth_Month],
      width: 15,
      CustomRowComponent: memo(({ data, header }: IReactTableComponent) => {
        const { user } = UsePeople(data["hrId"], true);
        return (<TooltipText value={user?.birthDate ? moment(user.birthDate).format("MMMM") : ""} />)
      })
    }
  },
  [CellIDS.Birth_Date]: () => {
    return {
      id: CellIDS.Birth_Date,
      label: AFFILIATIONS_LABELS[CellIDS.Birth_Date],
      width: 15,
      CustomRowComponent: memo(({ data, header }: IReactTableComponent) => {
        const { user } = UsePeople(data["hrId"], true);
        return (<TooltipText value={user?.birthDate ? moment(user.birthDate).format("DD") : ""} />)
      })
    }
  },
  [CellIDS.Months_In_Role]: () => {
    return {
      id: CellIDS.Months_In_Role,
      label: AFFILIATIONS_LABELS[CellIDS.Months_In_Role],
      width: 15,
      CustomRowComponent: memo(({ data, header }: IReactTableComponent) => {
        const { user } = UsePeople(data["hrId"], true);
        const { moInRole } = getMonthOfEmpAndExp(user);
        return (<TooltipText value={getRowValue(moInRole)} />);
      })
    }
  },
  [CellIDS.Last_Promotion_Date]: () => {
    return {
      id: CellIDS.Last_Promotion_Date,
      label: AFFILIATIONS_LABELS[CellIDS.Last_Promotion_Date],
      width: 15,
      CustomRowComponent: memo(({ data, header }: IReactTableComponent) => {
        const { user } = UsePeople(data["hrId"], true);
        return (<div> {user?.lastPromotionDate ? <Moment format={DATE_FORMAT}>{user?.lastPromotionDate}</Moment> : ""}</div>)
      })
    }
  },
  [CellIDS.Termination_Date]: () => {
    return {
      id: CellIDS.Termination_Date,
      label: AFFILIATIONS_LABELS[CellIDS.Termination_Date],
      width: 15,
      CustomRowComponent: memo(({ data, header }: IReactTableComponent) => {
        const { user } = UsePeople(data["hrId"], true);
        return (<div> {user?.pastTerminationDate ? <Moment format={DATE_FORMAT}>{parseInt(user?.pastTerminationDate, 10)}</Moment> : ""}</div>)
      })
    }
  },
  [CellIDS.Level]: () => {
    return {
      id: CellIDS.Level,
      label: AFFILIATIONS_LABELS[CellIDS.Level],
      width: 15
    }
  },
  [CellIDS.Levels]: () => {
    return {
      id: CellIDS.Levels,
      label: AFFILIATIONS_LABELS[CellIDS.Levels],
      width: 30,
      CustomRowComponent: memo(({ data, header }: IReactTableComponent) => {
        return data?.levels?.length ? <table className="search-table-level">
          <thead>
            <tr>
              <th>Practice Area/Topic ID</th>
              <th>Practice Area/Topic/Sector</th>
              <th>Affiliation Level</th>
            </tr>
          </thead>
          <tbody>
            {data?.levels?.map(({ bstLabel, bstGuid, paAffiliationLevel }: any, index: number) => (
              <tr key={`level-${index}`}>
                <td>{bstGuid}</td>
                <td>{bstLabel}</td>
                <td>{paAffiliationLevel}</td>
              </tr>
            ))}
          </tbody>
        </table>
          : <div></div>
      }
      )
    }
  },
  [CellIDS.Practice_Area_Topic_ID]: () => {
    return {
      id: CellIDS.Practice_Area_Topic_ID,
      label: AFFILIATIONS_LABELS[CellIDS.Practice_Area_Topic_ID],
      width: 15
    }
  },
  [CellIDS.PA_Topic_Sector]: () => {
    return {
      id: CellIDS.PA_Topic_Sector,
      label: AFFILIATIONS_LABELS[CellIDS.PA_Topic_Sector],
      width: 15
    }
  },
  [CellIDS.Lead_Allocation_Percentage]: () => {
    return {
      id: CellIDS.Lead_Allocation_Percentage,
      label: AFFILIATIONS_LABELS[CellIDS.Lead_Allocation_Percentage],
      width: 15
    }
  },
  [CellIDS.Type]: () => {
    return {
      id: CellIDS.Type,
      label: AFFILIATIONS_LABELS[CellIDS.Type],
      width: 15
    }
  },
  [CellIDS.Staffing_PA]: ({ affiliationView }: any) => {
    return {
      id: CellIDS.Staffing_PA,
      label: AFFILIATIONS_LABELS[CellIDS.Staffing_PA],
      width: 15,
      CustomRowComponent: memo(({ data, header }: IReactTableComponent) => {
        if (affiliationView === AFFILATIONS_VIEWS.ALL_AFFILIATIONS_TABLE_VIEW) {
          return (<TooltipText value={data.staffingFlag ? data.bstLabel : ""} />)
        } else if (affiliationView === AFFILATIONS_VIEWS.ALL_AFFILIATIONS_VIEW) {
          const staffingString: string = data.levels?.filter((level: any) => level.staffingFlag).map((item: any) => item.bstLabel).join(", ") || "";
          return (<TooltipText value={staffingString} />)
        } else if (affiliationView === AFFILATIONS_VIEWS.PA_MATRIX_VIEW) {
          const staffingString: string = data.levelDetails?.filter((level: any) => level.staffingFlag).map((item: any) => item.bstLabel).join(", ") || "";
          return (<TooltipText value={staffingString} />)
        } else {
          return <div></div>
        }
      })
    }
  },
  [CellIDS.PA_Sponsor]: () => {
    return {
      id: CellIDS.PA_Sponsor,
      label: AFFILIATIONS_LABELS[CellIDS.PA_Sponsor],
      width: 15,
      CustomRowComponent: memo(({ data, header }: IReactTableComponent) => {
        const { user } = UsePeople(data["hrId"], true);
        return (<TooltipText value={user?.sponsors?.map(sponsor => sponsor.name).join(", ") || ""} />)
      })
    }
  },
  [CellIDS.Sponsor_PA]: () => {
    return {
      id: CellIDS.Sponsor_PA,
      label: AFFILIATIONS_LABELS[CellIDS.Sponsor_PA],
      width: 15,
      CustomRowComponent: memo(({ data, header }: IReactTableComponent) => {
        const { user } = UsePeople(data["hrId"], true);
        return (<TooltipText value={user?.sponsors?.map(sponsor => sponsor.practiceArea).join(", ") || ""} />)
      })
    }
  },
  [CellIDS.Primary_Topic]: () => {
    return {
      id: CellIDS.Primary_Topic,
      label: AFFILIATIONS_LABELS[CellIDS.Primary_Topic],
      width: 15,
      CustomRowComponent: memo(({ data, header }: IReactTableComponent) => {
        const { user } = UsePeople(data["hrId"], true);
        return (<TooltipText value={user?.sponsors?.map(sponsor => sponsor.primaryTopic).join(", ") || ""} />)
      })
    }
  },
  [CellIDS.Action]: () => {
    return {
      id: CellIDS.Action,
      label: AFFILIATIONS_LABELS[CellIDS.Action],
      isFixed: true,
      fixedDirection: "right",
      width: 10,
      CustomRowComponent: ({ data, header }: IReactTableComponent) => {
        const navigate = useNavigate();
        const { hrId } = data;
        const { user } = UsePeople(hrId, true)

        return (
          <div className="">
            <CustomButton
              transparent
              primaryButton
              noOutline
              noPadding
              buttonText="Edit"
              handleClick={() => {
                iconInteraction(HEADRES.Search_PAAF, undefined!, "Edit", getUserName(user));
                navigate(`${URLRoutes.client.EditAffiliations}/${hrId}`)
              }} />
          </div>
        )
      }
    }
  },
  [CellIDS.CP]: () => {
    return {
      id: CellIDS.CP,
      label: AFFILIATIONS_LABELS[CellIDS.CP],
      width: 5,
      CustomRowComponent: PaMatrixTopicComponent
    }
  },
  [CellIDS.EN]: () => {
    return {
      id: CellIDS.EN,
      label: AFFILIATIONS_LABELS[CellIDS.EN],
      width: 5,
      CustomRowComponent: PaMatrixTopicComponent

    }
  },
  [CellIDS.FIP]: () => {
    return {
      id: CellIDS.FIP,
      label: AFFILIATIONS_LABELS[CellIDS.FIP],
      width: 5,
      CustomRowComponent: PaMatrixTopicComponent

    }
  },
  [CellIDS.HC]: () => {
    return {
      id: CellIDS.HC,
      label: AFFILIATIONS_LABELS[CellIDS.HC],
      width: 5,
      CustomRowComponent: PaMatrixTopicComponent

    }
  },
  [CellIDS.IG]: () => {
    return {
      id: CellIDS.IG,
      label: AFFILIATIONS_LABELS[CellIDS.IG],
      width: 5,
      CustomRowComponent: PaMatrixTopicComponent

    }
  },
  [CellIDS.INS]: () => {
    return {
      id: CellIDS.INS,
      label: AFFILIATIONS_LABELS[CellIDS.INS],
      width: 5,
      CustomRowComponent: PaMatrixTopicComponent

    }
  },
  [CellIDS.PI]: () => {
    return {
      id: CellIDS.PI,
      label: AFFILIATIONS_LABELS[CellIDS.PI],
      width: 5,
      CustomRowComponent: PaMatrixTopicComponent

    }
  },
  [CellIDS.PS]: () => {
    return {
      id: CellIDS.PS,
      label: AFFILIATIONS_LABELS[CellIDS.PS],
      width: 5,
      CustomRowComponent: PaMatrixTopicComponent

    }
  },
  [CellIDS.TMT]: () => {
    return {
      id: CellIDS.TMT,
      label: AFFILIATIONS_LABELS[CellIDS.TMT],
      width: 5,
      CustomRowComponent: PaMatrixTopicComponent

    }
  },
  [CellIDS.TRF]: () => {
    return {
      id: CellIDS.TRF,
      label: AFFILIATIONS_LABELS[CellIDS.TRF],
      width: 5,
      CustomRowComponent: PaMatrixTopicComponent

    }
  },
  [CellIDS.CS]: () => {
    return {
      id: CellIDS.CS,
      label: AFFILIATIONS_LABELS[CellIDS.CS],
      width: 5,
      CustomRowComponent: PaMatrixTopicComponent

    }
  },
  [CellIDS.CFS]: () => {
    return {
      id: CellIDS.CFS,
      label: AFFILIATIONS_LABELS[CellIDS.CFS],
      width: 5,
      CustomRowComponent: PaMatrixTopicComponent

    }
  },
  [CellIDS.MSP]: () => {
    return {
      id: CellIDS.MSP,
      label: AFFILIATIONS_LABELS[CellIDS.MSP],
      width: 5,
      CustomRowComponent: PaMatrixTopicComponent

    }
  },
  [CellIDS.TCI]: () => {
    return {
      id: CellIDS.TCI,
      label: AFFILIATIONS_LABELS[CellIDS.TCI],
      width: 5,
      CustomRowComponent: PaMatrixTopicComponent
    }
  },
  [CellIDS.OPS]: () => {
    return {
      id: CellIDS.OPS,
      label: AFFILIATIONS_LABELS[CellIDS.OPS],
      width: 5,
      CustomRowComponent: PaMatrixTopicComponent

    }
  },
  [CellIDS.POP]: () => {
    return {
      id: CellIDS.POP,
      label: AFFILIATIONS_LABELS[CellIDS.POP],
      width: 5,
      CustomRowComponent: PaMatrixTopicComponent

    }
  },
  [CellIDS.TDA]: () => {
    return {
      id: CellIDS.TDA,
      label: AFFILIATIONS_LABELS[CellIDS.TDA],
      width: 5,
      CustomRowComponent: PaMatrixTopicComponent

    }
  },
  [CellIDS.RSK]: () => {
    return {
      id: CellIDS.RSK,
      label: AFFILIATIONS_LABELS[CellIDS.RSK],
      width: 5,
      CustomRowComponent: PaMatrixTopicComponent

    }
  },
  [CellIDS.GA]: () => {
    return {
      id: CellIDS.GA,
      label: AFFILIATIONS_LABELS[CellIDS.GA],
      width: 5,
      CustomRowComponent: PaMatrixTopicComponent

    }
  },
  [CellIDS.SI]: () => {
    return {
      id: CellIDS.SI,
      label: AFFILIATIONS_LABELS[CellIDS.SI],
      width: 5,
      CustomRowComponent: PaMatrixTopicComponent

    }
  }
}

const COMMON_CELLS_IDS = [CellIDS.First_Name, CellIDS.Last_Name, CellIDS.HR_ID, CellIDS.T_B_ID, CellIDS.Email_ID, CellIDS.Home_Region,
CellIDS.Home_System, CellIDS.Home_Sub_System, CellIDS.Home_Office, CellIDS.Host_Region, CellIDS.Host_System, CellIDS.Host_Sub_System, CellIDS.Host_Office, CellIDS.Allocation, CellIDS.Lateral_Hire, CellIDS.Gender, CellIDS.Cohort, CellIDS.CohortStep,
CellIDS.Months_Of_Experience, CellIDS.Months_Of_Employment, CellIDS.Assistant_Email_ID, CellIDS.Career_Track, CellIDS.Career_Step_Job_Function, CellIDS.Global_Title, CellIDS.Business_Title, CellIDS.MDP_Archetype,
CellIDS.Staffing_Coordinator, CellIDS.Birth_Month, CellIDS.Birth_Date, CellIDS.Months_In_Role, CellIDS.Last_Promotion_Date, CellIDS.Termination_Date
];

export const ALL_AFFILIATIONS_TABLE: Array<CellIDS> = [...COMMON_CELLS_IDS, CellIDS.Practice_Area_Topic_ID, CellIDS.Level, CellIDS.PA_Topic_Sector, CellIDS.Type,
CellIDS.Staffing_PA, CellIDS.PA_Sponsor, CellIDS.Sponsor_PA, CellIDS.Primary_Topic];

export const ALL_AFFILIATIONS_TABLE_LEAD_ALLOCATION: Array<any> = [...COMMON_CELLS_IDS, CellIDS.Practice_Area_Topic_ID, CellIDS.Level, SnowFlakeCellIDS.PA_Topic_Name, CellIDS.Lead_Allocation_Percentage, CellIDS.Type,
CellIDS.Staffing_PA, CellIDS.PA_Sponsor, CellIDS.Sponsor_PA, CellIDS.Primary_Topic];

export const ALL_AFFILIATIONS: Array<CellIDS> = [...COMMON_CELLS_IDS, CellIDS.Levels, CellIDS.Staffing_PA, CellIDS.PA_Sponsor,
CellIDS.Sponsor_PA, CellIDS.Primary_Topic];

export const PA_MATRIX: Array<CellIDS> = [...COMMON_CELLS_IDS, CellIDS.Staffing_PA, CellIDS.PA_Sponsor, CellIDS.Sponsor_PA, CellIDS.Primary_Topic,
CellIDS.CP, CellIDS.EN, CellIDS.FIP, CellIDS.HC, CellIDS.IG, CellIDS.INS, CellIDS.PI, CellIDS.PS, CellIDS.TCI, CellIDS.TMT, CellIDS.TRF,
CellIDS.CS, CellIDS.CFS, CellIDS.GA, CellIDS.MSP, CellIDS.OPS, CellIDS.POP, CellIDS.SI, CellIDS.RSK, CellIDS.TDA];

export const PA_MATRIX_GRID_LEAD_ALLOCATION: Array<CellIDS> = [...PA_MATRIX, CellIDS.CFSAL, CellIDS.CSAL, CellIDS.FPAagn, CellIDS.GAAL, CellIDS.IPAagn, CellIDS.MSPAL, CellIDS.SIAL, CellIDS.CPAL, CellIDS.ENAL, CellIDS.FIPAL, CellIDS.HCAL, CellIDS.IGAL, CellIDS.INSAL, CellIDS.PIAL, CellIDS.PSAL, CellIDS.TMTAL, CellIDS.TRFAL, CellIDS.TCIAL, CellIDS.OPSAL, CellIDS.POPAL, CellIDS.TDAAL, CellIDS.RSKAL];


export const getPaMatrixTopicExport = (id: CellIDS) => () => ({
  id: id,
  label: AFFILIATIONS_LABELS[id],
  getValue: (peopleData: IPeopleUser, rowData: any) => {
    return getPaMatrixTopicValue(rowData, id)
  }
})

export const getPaMatrixLeadAllocationExport = (id: CellIDS, key: string) => () => ({
  id: key,
  label: AFFILIATIONS_LABELS[id],
  getValue: (peopleData: IPeopleUser, rowData: any) => {
    const data = rowData?.leadAllocationLevels;
    return data && data.hasOwnProperty(key) ? data[key] : '';
  }
})

export const Format_MAPING: { [key: string]: (affiliationView?: string) => IExcelCell } = {
  [CellIDS.First_Name]: () => ({
    id: "preferredFirstName",
    label: AFFILIATIONS_LABELS[CellIDS.First_Name],
    isPeople: true,
    hridKey: "hrId"
  }),
  [CellIDS.Last_Name]: () => ({
    id: "preferredLastName",
    label: AFFILIATIONS_LABELS[CellIDS.Last_Name],
    isPeople: true,
    hridKey: "hrId"
  }),
  [CellIDS.HR_ID]: () => ({
    id: "hrId",
    label: AFFILIATIONS_LABELS[CellIDS.HR_ID],
    isPeople: true,
    hridKey: "hrId"
  }),
  [CellIDS.Assistant_Email_ID]: () => ({
    id: "assistantEmail",
    label: AFFILIATIONS_LABELS[CellIDS.Assistant_Email_ID],
    isPeople: true,
    hridKey: "hrId"
  }),
  [CellIDS.T_B_ID]: () => ({
    id: "tandbId",
    label: AFFILIATIONS_LABELS[CellIDS.T_B_ID],
    isPeople: true,
    hridKey: "hrId"
  }),
  [CellIDS.Email_ID]: () => ({
    id: "email",
    label: AFFILIATIONS_LABELS[CellIDS.Email_ID],
    isPeople: true,
    hridKey: "hrId"
  }),
  [CellIDS.Home_Region]: () => ({
    id: "homeOfficeRegion",
    label: AFFILIATIONS_LABELS[CellIDS.Home_Region],
    isPeople: true,
    hridKey: "hrId"
  }),
  [CellIDS.Home_System]: () => ({
    id: "homeSystem",
    label: AFFILIATIONS_LABELS[CellIDS.Home_System],
    isPeople: true,
    hridKey: "hrId",
  }),
  [CellIDS.Home_Sub_System]: () => ({
    id: "homesubsystem",
    label: AFFILIATIONS_LABELS[CellIDS.Home_Sub_System],
    isPeople: true,
    hridKey: "hrId",
    getValue: (peopleData, rowData) => {
      return peopleData?.homesubsystem || '';
    },
  }),
  [CellIDS.Allocation]: () => {
    return {
      id: "allocation",
      label: AFFILIATIONS_LABELS[CellIDS.Allocation],
      isPeople: true,
      hridKey: "hrId",
      getValue: (peopleData, rowData) => {
        return peopleData?.defaultAllocation
      }
    }
  },
  [CellIDS.Home_Office]: () => ({
    id: "homeOffice",
    label: AFFILIATIONS_LABELS[CellIDS.Home_Office],
    isPeople: true,
    hridKey: "hrId"
  }),
  [CellIDS.Host_Region]: () => ({
    id: "hostOfficeRegion",
    label: AFFILIATIONS_LABELS[CellIDS.Host_Region],
    isPeople: true,
    hridKey: "hrId",
    getValue: (peopleData, rowData) => {
      return peopleData?.hostOfficeRegion
    },
  }),
  [CellIDS.Host_System]: () => ({
    id: "hostSystem",
    label: AFFILIATIONS_LABELS[CellIDS.Host_System],
    isPeople: true,
    hridKey: "hrId",
    getValue: (peopleData, rowData) => {
      return peopleData?.hostSystem
    },
  }),
  [CellIDS.Host_Sub_System]: () => ({
    id: "hostsubsystem",
    label: AFFILIATIONS_LABELS[CellIDS.Host_Sub_System],
    isPeople: true,
    hridKey: "hrId",
    getValue: (peopleData, rowData) => {
      return peopleData?.hostsubsystem || '';
    },
  }),
  [CellIDS.Host_Office]: () => ({
    id: "hostOfficeId",
    label: AFFILIATIONS_LABELS[CellIDS.Host_Office],
    isPeople: true,
    hridKey: "hrId",
    getValue: (peopleData, rowData) => {
      return peopleData?.hostOffice || ""
    },
  }),
  [CellIDS.Lateral_Hire]: () => ({
    id: "lateralHire",
    label: AFFILIATIONS_LABELS[CellIDS.Lateral_Hire],
    isPeople: true,
    hridKey: "hrId",
    getValue: (peopleData, rowData) => {
      return peopleData?.lateralHire ? "Yes" : "No"
    },
  }),
  [CellIDS.Career_Track]: () => ({
    id: "jobCategoryId",
    label: AFFILIATIONS_LABELS[CellIDS.Career_Track],
    isPeople: true,
    hridKey: "hrId"
  }),
  [CellIDS.Gender]: () => ({
    id: "gender",
    label: AFFILIATIONS_LABELS[CellIDS.Gender],
    isPeople: true,
    hridKey: "hrId"
  }),
  [CellIDS.Cohort]: () => ({
    id: "cohort",
    label: AFFILIATIONS_LABELS[CellIDS.Cohort],
    isPeople: true,
    hridKey: "hrId",
    getValue: (peopleData: IPeopleUser, rowData: any) => {
      return peopleData?.cohort || ""
    }
  }),
  [CellIDS.CohortStep]: () => ({
    id: "cohortStep",
    label: AFFILIATIONS_LABELS[CellIDS.CohortStep],
    isPeople: true,
    hridKey: "hrId",
    getValue: (peopleData: IPeopleUser, rowData: any) => {
      let cohortStep = peopleData?.cohortStep;
      return cohortStep
    }
  }),
  [CellIDS.Months_Of_Experience]: () => ({
    id: "Months_Of_Experience",
    label: AFFILIATIONS_LABELS[CellIDS.Months_Of_Experience],
    isPeople: true,
    hridKey: "hrId",
    getValue: (peopleData: any, rowData: any) => {
      return peopleData?.monthsOfExperience;
    }
  }),
  [CellIDS.Months_Of_Employment]: () => ({
    id: "Months_Of_Employment",
    label: AFFILIATIONS_LABELS[CellIDS.Months_Of_Employment],
    isPeople: true,
    hridKey: "hrId",
    getValue: (peopleData: any, rowData: any) => {
      return peopleData?.monthsOfEmployment;
    }
  }),
  [CellIDS.Career_Step_Job_Function]: () => ({
    id: "jobFamilyGroupName",
    label: AFFILIATIONS_LABELS[CellIDS.Career_Step_Job_Function],
    isPeople: true,
    hridKey: "hrId"
  }),
  [CellIDS.Global_Title]: () => ({
    id: CellIDS.Global_Title,
    label: AFFILIATIONS_LABELS[CellIDS.Global_Title],
    isPeople: true,
    hridKey: "hrId"
  }),
  [CellIDS.Business_Title]: () => ({
    id: CellIDS.Business_Title,
    label: AFFILIATIONS_LABELS[CellIDS.Business_Title],
    isPeople: true,
    hridKey: "hrId"
  }),
  [CellIDS.Birth_Month]: () => ({
    id: "birthMonth",
    label: AFFILIATIONS_LABELS[CellIDS.Birth_Month],
    isPeople: true,
    hridKey: "hrId",
    getValue: (peopleData: IPeopleUser, rowData) => {
      const dob: any = peopleData?.dateOfBirth?.split('-') || '';
      return peopleData?.dateOfBirth ? months[`${dob[1]}`] : ""
    },
  }),
  [CellIDS.Birth_Date]: () => ({
    id: "birthDate",
    label: AFFILIATIONS_LABELS[CellIDS.Birth_Date],
    isPeople: true,
    hridKey: "hrId",
    getValue: (peopleData: IPeopleUser, rowData) => {
      const dob: any = peopleData?.dateOfBirth?.split('-') || '';
      return peopleData?.dateOfBirth ? dob[2] : ""
    },
  }),
  [CellIDS.Months_In_Role]: () => ({
    id: CellIDS.Months_In_Role,
    label: AFFILIATIONS_LABELS[CellIDS.Months_In_Role],
    isPeople: true,
    hridKey: "hrId",
    getValue: (peopleData: IPeopleUser, rowData: any) => {
      return peopleData?.monthsInRole;
    }
  }),
  [CellIDS.Last_Promotion_Date]: () => ({
    id: "lastPromotionDate ",
    label: AFFILIATIONS_LABELS[CellIDS.Last_Promotion_Date],
    isPeople: true,
    hridKey: "hrId",
    getValue: (peopleData: IPeopleUser, rowData) => {
      return peopleData?.lastPromotionDate;
      // return peopleData?.lastPromotionDate ? moment(peopleData.lastPromotionDate).format(DATE_FORMAT) : ""
    },
  }),
  [CellIDS.Termination_Date]: () => ({
    id: "terminationDate",
    label: AFFILIATIONS_LABELS[CellIDS.Termination_Date],
    isPeople: true,
    hridKey: "hrId",
    getValue: (peopleData: IPeopleUser, rowData) => {
      const terminationDateSplit = peopleData?.terminationDate?.split('-') || '';
      return peopleData?.terminationDate ? `${terminationDateSplit[1]}/${terminationDateSplit[2]}/${terminationDateSplit[0]}` : "";
    },
  }),
  [CellIDS.Levels]: () => ({
    id: CellIDS.Levels,
    label: AFFILIATIONS_LABELS[CellIDS.Levels],
    isPeople: true,
    hridKey: "hrId",
    getValue: (peopleData: IPeopleUser, rowData: any) => {
      if (rowData.levels) {
        return `Practice Area/Topic ID : Practice Area/Topic/Sector : Affiliation Level \n
        ${rowData?.levels?.map(({ bstLabel, bstGuid, paAffiliationLevel }: any) => {
          return bstGuid + " : " + bstLabel + " : " + paAffiliationLevel + "\n";
        }).join("")}`;
      }
      return "";
    }
  }),
  [CellIDS.Staffing_PA]: (affiliationView: any) => ({
    id: CellIDS.Staffing_PA,
    label: AFFILIATIONS_LABELS[CellIDS.Staffing_PA],
    isPeople: true,
    hridKey: "hrId",
    getValue: (peopleData: IPeopleUser, rowData: any) => {
      if (affiliationView === AFFILATIONS_VIEWS.ALL_AFFILIATIONS_TABLE_VIEW) {
        return rowData.staffingFlag ? rowData.bstLabel : ""
      } else if (affiliationView === AFFILATIONS_VIEWS.ALL_AFFILIATIONS_VIEW) {
        return rowData.levels?.filter((level: any) => level.staffingFlag).map((item: any) => item.bstLabel).join(", ") || "";
      } else if (affiliationView === AFFILATIONS_VIEWS.PA_MATRIX_VIEW) {
        return rowData?.levelDetails?.filter((level: any) => level.staffingFlag).map((item: any) => item.bstLabel).join(", ") || "";
      } else {
        return ""
      }
    }
  }),
  [CellIDS.PA_Sponsor]: () => ({
    id: CellIDS.PA_Sponsor,
    label: AFFILIATIONS_LABELS[CellIDS.PA_Sponsor],
    isPeople: true,
    hridKey: "hrId",
    getValue: (peopleData: IPeopleUser, rowData: any) => {
      return peopleData?.sponsors?.map(sponsor => sponsor.name).join(", ") || ""
    }
  }),
  [CellIDS.Sponsor_PA]: () => ({
    id: CellIDS.Sponsor_PA,
    label: AFFILIATIONS_LABELS[CellIDS.Sponsor_PA],
    isPeople: true,
    hridKey: "hrId",
    getValue: (peopleData: IPeopleUser, rowData: any) => {
      return peopleData?.sponsors?.map(sponsor => sponsor.practiceArea).join(", ") || ""
    }
  }),
  [CellIDS.Primary_Topic]: () => ({
    id: CellIDS.Primary_Topic,
    label: AFFILIATIONS_LABELS[CellIDS.Primary_Topic],
    isPeople: true,
    hridKey: "hrId",
    getValue: (peopleData: IPeopleUser, rowData: any) => {
      return peopleData?.sponsors?.map(sponsor => sponsor.primaryTopic).join(", ") || ""
    }
  }),
  [CellIDS.CP]: getPaMatrixTopicExport(CellIDS.CP),
  [CellIDS.EN]: getPaMatrixTopicExport(CellIDS.EN),
  [CellIDS.FIP]: getPaMatrixTopicExport(CellIDS.FIP),
  [CellIDS.HC]: getPaMatrixTopicExport(CellIDS.HC),
  [CellIDS.IG]: getPaMatrixTopicExport(CellIDS.IG),
  [CellIDS.INS]: getPaMatrixTopicExport(CellIDS.INS),
  [CellIDS.PI]: getPaMatrixTopicExport(CellIDS.PI),
  [CellIDS.PS]: getPaMatrixTopicExport(CellIDS.PS),
  [CellIDS.TMT]: getPaMatrixTopicExport(CellIDS.TMT),
  [CellIDS.TRF]: getPaMatrixTopicExport(CellIDS.TRF),
  [CellIDS.CS]: getPaMatrixTopicExport(CellIDS.CS),
  [CellIDS.CFS]: getPaMatrixTopicExport(CellIDS.CFS),
  [CellIDS.MSP]: getPaMatrixTopicExport(CellIDS.MSP),
  [CellIDS.TCI]: getPaMatrixTopicExport(CellIDS.TCI),
  [CellIDS.OPS]: getPaMatrixTopicExport(CellIDS.OPS),
  [CellIDS.POP]: getPaMatrixTopicExport(CellIDS.POP),
  [CellIDS.TDA]: getPaMatrixTopicExport(CellIDS.TDA),
  [CellIDS.RSK]: getPaMatrixTopicExport(CellIDS.RSK),
  [CellIDS.GA]: getPaMatrixTopicExport(CellIDS.GA),
  [CellIDS.SI]: getPaMatrixTopicExport(CellIDS.SI),
  [CellIDS.CFSAL]: getPaMatrixLeadAllocationExport(CellIDS.CFSAL, 'CFS'),
  [CellIDS.CSAL]: getPaMatrixLeadAllocationExport(CellIDS.CSAL, 'CS'),
  [CellIDS.FPAagn]: getPaMatrixLeadAllocationExport(CellIDS.FPAagn, 'FPAagn'),
  [CellIDS.GAAL]: getPaMatrixLeadAllocationExport(CellIDS.GAAL, 'GA'),
  [CellIDS.IPAagn]: getPaMatrixLeadAllocationExport(CellIDS.IPAagn, 'IPAagn'),
  [CellIDS.MSPAL]: getPaMatrixLeadAllocationExport(CellIDS.MSPAL, 'MSP'),
  [CellIDS.SIAL]: getPaMatrixLeadAllocationExport(CellIDS.SIAL, 'SI'),
  [CellIDS.CPAL]: getPaMatrixLeadAllocationExport(CellIDS.CPAL, 'CP'),
  [CellIDS.ENAL]: getPaMatrixLeadAllocationExport(CellIDS.ENAL, 'EN'),
  [CellIDS.FIPAL]: getPaMatrixLeadAllocationExport(CellIDS.FIPAL, 'FIP'),
  [CellIDS.HCAL]: getPaMatrixLeadAllocationExport(CellIDS.HCAL, 'HC'),
  [CellIDS.IGAL]: getPaMatrixLeadAllocationExport(CellIDS.IGAL, 'IG'),
  [CellIDS.INSAL]: getPaMatrixLeadAllocationExport(CellIDS.INSAL, 'INS'),
  [CellIDS.PIAL]: getPaMatrixLeadAllocationExport(CellIDS.PIAL, 'PI'),
  [CellIDS.PSAL]: getPaMatrixLeadAllocationExport(CellIDS.PSAL, 'PS'),
  [CellIDS.TMTAL]: getPaMatrixLeadAllocationExport(CellIDS.TMTAL, 'TMT'),
  [CellIDS.TRFAL]: getPaMatrixLeadAllocationExport(CellIDS.TRFAL, 'TRF'),
  [CellIDS.TCIAL]: getPaMatrixLeadAllocationExport(CellIDS.TCIAL, 'TCI'),
  [CellIDS.OPSAL]: getPaMatrixLeadAllocationExport(CellIDS.OPSAL, 'OPS'),
  [CellIDS.POPAL]: getPaMatrixLeadAllocationExport(CellIDS.POPAL, 'POP'),
  [CellIDS.TDAAL]: getPaMatrixLeadAllocationExport(CellIDS.TDAAL, 'TDA'),
  [CellIDS.RSKAL]: getPaMatrixLeadAllocationExport(CellIDS.RSKAL, 'RSK'),
}



export function getAffiliationExportItems(cellIds: Array<CellIDS>, affiliationView: string): IExcelCell[] {
  const columns: IExcelCell[] = [];
  cellIds.forEach((cellId: string) => {
    if (cellId !== CellIDS.Action) {
      if (Format_MAPING[cellId]) {
        columns.push(Format_MAPING[cellId](affiliationView));
      } else {
        columns.push({
          id: cellId,
          label: AFFILIATIONS_LABELS[cellId]
        })
      }
    }
  })
  return columns;
}


export const getDefaultCardViews = (currentAffiliationView: AFFILATIONS_VIEWS, isEditAllowed?: boolean): Array<CellIDS> => {
  let defaultSequence: Array<CellIDS> = [];
  if (currentAffiliationView === AFFILATIONS_VIEWS.ALL_AFFILIATIONS_TABLE_VIEW) {
    defaultSequence = [...ALL_AFFILIATIONS_TABLE];
  } else if (currentAffiliationView === AFFILATIONS_VIEWS.ALL_AFFILIATIONS_VIEW) {
    defaultSequence = [...ALL_AFFILIATIONS];
  } else if (currentAffiliationView === AFFILATIONS_VIEWS.PA_MATRIX_VIEW) {
    defaultSequence = [...PA_MATRIX];
  } else if (currentAffiliationView === AFFILATIONS_VIEWS.ALL_AFFILIATIONS_TABLE_VIEW_LEAD_ALLOCATION) {
    defaultSequence = [...ALL_AFFILIATIONS_TABLE_LEAD_ALLOCATION];
  } else if (currentAffiliationView === AFFILATIONS_VIEWS.PA_MATRIX_VIEW_LEAD_ALLOCATION) {
    defaultSequence = [...PA_MATRIX_GRID_LEAD_ALLOCATION];
  }
  if (isEditAllowed) {
    defaultSequence.push(CellIDS.Action);
  }
  return defaultSequence;
}

export const haveFilterChanges = (filter: any, filterChange: any) => {
  const filterKeys = Object.keys(filter);
  const filterChangeKeys = Object.keys(filterChange);

  if (filterKeys.length !== filterChangeKeys.length) {
    return true; // Different number of properties
  }

  for (const key in filter) {
    if (filter.hasOwnProperty(key)) {
      if (!filterChange.hasOwnProperty(key) || JSON.stringify(filter[key]) !== JSON.stringify(filterChange[key])) {
        return true; // Changes exist
      }
    }
  }

  return false; // No changes
}

// Transform the end date time like: 11:59:59.999 PM
export const transformEndDateTime = (endDateString: any) => {
  const dateToNumber = Number(endDateString);
  const endDate = new Date(Number(dateToNumber));

  // Set end time to 11:59:59.999 PM
  endDate.setHours(23, 59, 59, 999);

  const endTimestamp = endDate.getTime();
  return endTimestamp;
}

export const checkItemWithUnderscores = (array: any) => {
  return array?.filter((item: any) => item.includes('_')) || [];
}

const months: Months = {
  "01": "January",
  "02": "February",
  "03": "March",
  "04": "April",
  "05": "May",
  "06": "June",
  "07": "July",
  "08": "August",
  "09": "September",
  "10": "October",
  "11": "November",
  "12": "December"
};

export const checkIfPapAdminRolesExists = (userObject: any) => {
  if (userObject && userObject.roles && Array.isArray(userObject.roles)) {
    // return userObject.roles.includes('PAP') || userObject.roles.includes('ADMIN');
    return userObject.roles.includes('ADMIN');
  }
  return false;
}

export const hasPAPOnlyAccess = (data: { roles: string[] }): boolean => {
  const { roles } = data;
  let hasPAPRole = false;
  let hasAdminRole = false;

  for (const role of roles) {
    if (role === "PAP") {
      hasPAPRole = true;
    } else if (role === "ADMIN") {
      hasAdminRole = true;
    }
  }

  return hasPAPRole && !hasAdminRole;
};

export const hasPACRoleOnly = (data: { roles: string[] }): boolean => {
  const { roles } = data;
  let hasPACRole = false;
  let hasAdminRole = false;
  let hasPAPRole = false;

  for (const role of roles) {
    if (role === "PAC") {
      hasPACRole = true;
    } else if (role === "ADMIN") {
      hasAdminRole = true;
    } else if (role === "PAP") {
      hasPAPRole = true;
    }
  }
  let hasPACRoleOnly = (hasPACRole && !hasAdminRole && !hasPAPRole) || (hasPACRole && hasPAPRole);

  return hasPACRoleOnly;
};