import moment from "moment";
import { API_DATE_FORMAT } from "utils";
import { FILTER_TYPES, IFilterTypes, URL_OPTTION_SUBFIX } from "./utils";
import { checkItemWithUnderscores, transformEndDateTime } from "containers/SearchPaaf/utils";

function convertArrayOptions(filterValue: any, filterKey: string) {
  if (filterValue?.length >= 1) {
    return { [filterKey]: filterValue.join(URL_OPTTION_SUBFIX) };
  }
  return undefined!;
}


const getValues = {
  [FILTER_TYPES.MULTI_SELECT]: convertArrayOptions,
  [FILTER_TYPES.TREE_SELECT]: convertArrayOptions,
  [FILTER_TYPES.SINGLE_SELECT]: (filterValue: any, filterKey: string) => {
    if (filterValue) {
      return { [filterKey]: filterValue };
    }
    return undefined;
  },
  [FILTER_TYPES.DATE_RANGE]: (filterValue: any, filterKey: string) => {
    if (filterValue?.length >= 1) {
      return {
        [`${filterKey}StartDate`]: moment(filterValue[0]).format(API_DATE_FORMAT),
        [`${filterKey}EndDate`]: moment(filterValue[1]).format(API_DATE_FORMAT)
      }
    }
    return undefined;
  },
  [FILTER_TYPES.DATE]: (filterValue: any, filterKey: string) => {
    if (filterValue) {
      return { [filterKey]: filterValue.getTime() };
    }
    return undefined;
  },
  [FILTER_TYPES.BOOLEAN]: (filterValue: any, filterKey: string) => {
    if (filterValue) {
      return { [filterKey]: true };
    }
    return false;
  },
  [FILTER_TYPES.DEFAULT]: (filterValue: any, filterKey: string) => {
    if (filterValue.length) {
      return { [filterKey]: filterValue };
    }
    return undefined;
  }
}

export function transformValueForAPI(formValue: any, filterTypes: IFilterTypes): any {
  let value: any = {};
  const filterKeys = Object.keys(formValue);
  filterKeys.forEach((filterKey: string) => {
    const filterValue = formValue[filterKey];
    const { type } = filterTypes[filterKey] || {};
    if (type) {
      const filterKeyValue = getValues[type](filterValue, filterKey);
      if (value) {
        value = {
          ...value,
          ...filterKeyValue
        }
      }
    }
  });
  return value
}

export function transformValueForSnowflakeAPI(searchParameters: any): any {
  const payload: any = {
    "name": "",
    "homeOffice": null,
    "jobCategory": null,
    "jobtTitle": null,
    "jobFamilyGroup": null,
    "hostOffice": null,
    "practiceArea": null,
    "affiliation": null,
    "activityDateRange": null,
    "promotionDateRange": null,
    "hrId": null,
    "terminated": false,
    "selected": false,
    "affiliatedSelected": false,
    "organization": null,
    "cohort": null
  }
  const mapping: any = {
    ACTIVITY_DATE_RANGE: 'activityDateRange',
    AFFILIATION: 'affiliation',
    CAREER_STEP: 'jobFamilyGroup',
    CAREER_TRACK: 'jobCategory',
    GLOBAL_TITLE: 'jobTitle',
    HOME_OFFICE: 'homeOffice',
    HOST_OFFICE: 'hostOffice',
    NAME: 'name',
    ORGANIZATION: 'organization',
    PA_TOPICS: 'practiceArea',
    HRID: 'hrId',
    PROMOTION_DATE_RANGE: 'promotionDateRange',
    SEARCH_FOR_AFFILIATIONS: 'affiliatedSelected',
    SEARCH_FOR_SELECTED: 'selected',
    TERMINATED_EMP: 'terminated',
    COHORT: 'cohort'
  };
  searchParameters.forEach((filterItem: any) => {
    const payloadKey = mapping[filterItem.key];
    if (payloadKey) {
      payload[payloadKey] = filterItem.values;
    }
  })

  payload.selected = payload?.selected ? Boolean(payload?.selected[0]) : false;
  payload.affiliatedSelected = payload?.affiliatedSelected ? Boolean(payload?.affiliatedSelected[0]) : false;
  payload.terminated = payload?.terminated ? Boolean(payload?.terminated[0]) : false;
  payload.name = payload?.name ? String(payload?.name[0]) : '';

  if(payload.homeOffice && payload.homeOffice.length) {
    payload.homeOffice = checkItemWithUnderscores(payload.homeOffice)
  }
  if(payload.hostOffice && payload.hostOffice.length) {
    payload.hostOffice = checkItemWithUnderscores(payload.hostOffice)
  }
  if (payload.activityDateRange !== null) {
    payload.activityDateRange = [
      payload.activityDateRange[0],
      String(transformEndDateTime(payload.activityDateRange[1]))
    ]
  }
  return payload;
}