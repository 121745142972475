import { FILTER_TYPES, IFilterTypes } from 'utils';

export interface IFilter {
  incudeChange: boolean;
  activityDateRange: Array<Date>;
}

export const FILTER_IDS = {
  INCLUDE_CHANGES: "includeMyChanges",
  ACTIVITY_DATE_RANGE: "activity",
}

export const FilterTypes: IFilterTypes = {
  [FILTER_IDS.INCLUDE_CHANGES]: {
    type: FILTER_TYPES.BOOLEAN,
    label: "Include only My Changes",
  },
  [FILTER_IDS.ACTIVITY_DATE_RANGE]: {
    type: FILTER_TYPES.DATE_RANGE,
    label: "Activity Date Range"
  }
}


export const DEFAULT_FILTER_VALUE: any = {
  [FILTER_IDS.INCLUDE_CHANGES]: false,
  [FILTER_IDS.ACTIVITY_DATE_RANGE]: []
}
