import { SagaIterator } from 'redux-saga';
import { put, call, select, takeLatest } from 'redux-saga/effects';
import { paginationActionFunctions } from './actions';
import { fetchRequest, getFullUrl } from 'Api';
import { IPaginationAction, ActionIdentity } from './interface';
import { toast } from 'react-toastify';

function* performRequestPaginationOperation(action: IPaginationAction): IterableIterator<{}> {
  const { meta: { keys: { getBaseUrl, getToken, getXAPIToken, callPeopleApi, hrIdKey}}, payload: { params } } = action;
  try {
    const token: string = (yield select(getToken))!;
    const apiKey: string = (yield select(getXAPIToken))!;

    const { url, method, pageNumber, pageSize, urlParams, queryParams} = params!;
    const requesturl: string = getFullUrl((yield select(getBaseUrl))!, url, {
      urlParams,
      queryParams: {
        ...queryParams,
        pageNo: pageNumber,
        pageSize
      },
      
    });
    const response: any = yield call(
      fetchRequest,
      requesturl,
      token,
      method,
      apiKey,
      params?.body
    );
    const items = response.data?.info;
    yield put(
      paginationActionFunctions.success(
        action.meta,
        params!,
        items,
        response.data?.pagingInfo?.noOfPages,
        response.data?.pagingInfo?.totalElements,
        response.message || 'Execution Done Successfully',
      ),
    );
    if(callPeopleApi && hrIdKey?.length) {
      const peopleIds: Array<string> = [];
      if(items instanceof Array) {
        items?.forEach((item: any) => {
          hrIdKey.forEach(peopleIDKey => {
            if(item[peopleIDKey]) {
              peopleIds.push(item[peopleIDKey])
            }
          })
        });
      }
      yield put(
        callPeopleApi(peopleIds)
      )
    }
  } catch (error: any) {
    const message = error.message || error.errorText || error ;
    yield put(paginationActionFunctions.failure(action.meta, params!, message));
  }
}


function* performPaginationOperation(action: IPaginationAction): IterableIterator<{}> {
  const { meta: { keys: { getBaseUrl, getToken, getXAPIToken}}, payload: { params, showNotifyMessage } } = action;
  try {
    const token: string = (yield select(getToken))!;
    const apiKey: string = (yield select(getXAPIToken))!;

    const { url, method, urlParams, queryParams} = params!;
    const requesturl: string = getFullUrl((yield select(getBaseUrl))!, url, {
      urlParams,
      queryParams
    });
    const response: any = yield call(
      fetchRequest,
      requesturl,
      token,
      method,
      apiKey,
      params?.body
    );
    if(showNotifyMessage) {
      toast.info(showNotifyMessage);
    }
    yield put(
      paginationActionFunctions.success(
        action.meta,
        params!,
        response.data,
        0,
        0,
        response.message || 'Execution Done Successfully',
      ),
    );
  } catch (error: any) {
    const message = error.message || error.errorText || error ;
    toast.error(message);
    yield put(paginationActionFunctions.failure(action.meta, params!, message));
  }
}

export default function (actionidentity: ActionIdentity) {
  return function* watchPaginationEvent(): SagaIterator {
    yield takeLatest(actionidentity.PAGINATION_FETCH.REQUEST, performRequestPaginationOperation);
    yield takeLatest(actionidentity.ADD_PAGE_ITEM.REQUEST, performPaginationOperation);
    yield takeLatest(actionidentity.DELETE_PAGE_ITEM.REQUEST, performPaginationOperation);
    yield takeLatest(actionidentity.UPDATE_PAGE_ITEM.REQUEST, performPaginationOperation);
  };
}