
import { IRextState } from "baseProvider/rext";
import CustomButton from "components/CustomButton";
import { ICON_POSITION } from "components/CustomButton/CustomButton";
import { CUSTOM_SVG_ICON, SVGType } from "components/SvgIcon";
import deepEqual from "deep-equal";
import { ITopic } from "interface";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Modal } from "semantic-ui-react";
import { getAffiliationState, getPracticeAreaState } from "store/selectors";
import { ModalHeader } from "../Common"
import './SubmitAffiliation.scss';

interface Props {
  hideModal: () => void;
  onClose: () => void;
  onSave: (data: {[key: string]: ITopic}) => void;
  title: string;
  content: any;
  topicChanges: {[key: string]: ITopic}
}

function SubmitAffiliationModal(props: Props) {
  const { title, hideModal, topicChanges, onSave } = props;
  const practiceAreaState : IRextState = useSelector(getPracticeAreaState, deepEqual);
  const affiliationLevelState: IRextState = useSelector(getAffiliationState, deepEqual);
  const [ updatedTopic, setUpdatedTopics ] = useState({...topicChanges});
  const renderTopic = (topicId: string) => {
    const { affiliationLevelId, comment, selectedFlag} : ITopic = updatedTopic[topicId];
    return (
      <tr key={topicId}>
        <td>{practiceAreaState.data.mappingIds[topicId]}</td>
        <td>{affiliationLevelState.data[affiliationLevelId]}</td>
        <td>{selectedFlag ? "Yes" : "No"}</td>
        <td>{comment}</td>
        <td>
        <CustomButton
            transparent
            primaryButton
            noOutline
            noPadding
            iconPosition={ICON_POSITION.LEFT}
            iconProps={{
              name: CUSTOM_SVG_ICON.Delete,
              svgType: SVGType.CUSTOM,
              size: "huge"
            }}
            handleClick={() => { 
              setUpdatedTopics((updateTopics) => {
                delete updateTopics[topicId];
                return {...updateTopics};
              })
            }} />
        </td>
      </tr>
    )
  }

  const handleUpdate = () => {
    onSave(updatedTopic);
  }


  useEffect(() => {
    if(Object.keys(updatedTopic).length ===0) {
      props.onClose();
    }
  }, [updatedTopic])

  return (
    <Modal
      size="small"
      dimmer
      open
      onClose={hideModal}
      closeOnEscape={false}
      closeOnDimmerClick={false}
      className="delete-modal">
      <ModalHeader title={title} toggle={hideModal} />
      <Modal.Content scrolling>
        <div className="custom-table padding-b-4 width-100">
          <table>
            <thead>
              <tr>
                <th>Topics</th>
                <th>Affiliation</th>
                <th>S</th>
                <th>Comments</th>
                <th>Delete</th>
              </tr>
            </thead>
            <tbody>
              {Object.keys(updatedTopic).map(renderTopic)}
            </tbody>
          </table>
        </div>
      </Modal.Content>
      <Modal.Actions>
        <CustomButton primaryButton buttonText="Update" handleClick={handleUpdate} round />
      </Modal.Actions>
    </Modal>
  )
}

export default SubmitAffiliationModal;