import * as React from "react";
import Checkbox from "../../CheckboxLabel";
import { IOption } from "../interface";
import classNames from "classnames";
import SvgIcon, { CUSTOM_SVG_ICON, SVGType } from "components/SvgIcon";


interface Props {
  option: IOption;
  handleOptionChange: any
  checked: boolean;
  handleExpandClick: any;
  isActive: boolean;
  isSearchActive: boolean;
  showHideOption: boolean;
  partialChecked: boolean;
  isMulti: boolean;
  hideCheckbox: boolean;
  disableOption: boolean;
}

function Option({ option, partialChecked, handleOptionChange, checked, handleExpandClick, isActive, isSearchActive, showHideOption, isMulti, hideCheckbox, disableOption }: Props) {
  const { value, childrens} = option;
  const hideOption = isSearchActive && !showHideOption;
  const iconName: CUSTOM_SVG_ICON = React.useMemo(() => {
    if(isActive) {
      return CUSTOM_SVG_ICON.CaretLeft
    }
    return CUSTOM_SVG_ICON.CaretRight
  }, [isActive])
  const isChecked: boolean = disableOption ? true : checked;
  return (
    hideOption ? null : <div key={option.id} className={classNames(["flex flex-row flex-justify-between width-100 taxonamy-option", {"active": isActive}])} onClick={handleExpandClick}>
      {isMulti && !hideCheckbox && <Checkbox labelTxt={value} handleChange={handleOptionChange}
        checked={isChecked} partialChecked={partialChecked}  disabled={disableOption}/>}
      {
        !isMulti && <div className="single-value-label">{value}</div>
      }
      {
        hideCheckbox && <div className="single-value-label">{value}</div>
      }
      {childrens && 
       <SvgIcon svgType={SVGType.CUSTOM} name={iconName} baseclassname="taxanomy-arrow-icon margin-r-4" size="small" />
      }
    </div>
  );
}

export default Option