import { useMemo, useState, useEffect, MutableRefObject, useRef, Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import {  useSearchParams } from "react-router-dom";
import equal from "deep-equal";


import CustomButton from "components/CustomButton";
import ReactTable, { CHANGE_EVENTS, RefProps } from "components/ReactTable";
import { ReactTableDefaultState } from "components/ReactTable/ReactTable";
import { ModalsType } from 'containers/ModalManager/ModalManager';

import { excelItems, getGridView, paafUserExcelTransformation } from "./utils";
import { Feature } from "utils/Features/Features";
import { IPAAFUserFeature } from "utils/Features/interface";
import {  getNumberOfFIlterApplied, transformFormValueToPresetValue, transformFormValueToValues, transformStringToTableState, transformTableStateToString, transformValuesToFormValues } from "utils";
import { addPaafUser, deletePaafUser, editPaafUser, getPaafUser } from "store/pagination";
import { IPaginationState } from "baseProvider/pagination";
import { getAllRolesRequest, getLocationsRequest, getPracticeAreaRequest } from "store/rext";
import { modalOperation } from "store/actions";
import { getAllRolesState, getFeaturesState, getLocations, getPaafUserState, getPracticeAreaState } from "store/selectors";

import './PaafUser.scss';
import { IRextState } from "baseProvider/rext";
import { INewUser } from "interface";
import Loading from "components/Loading";
import usePrevious from "HOC/UsePrevious";
import { internalSearchInteraction, linkInteraction, setGlobalPageLoad } from "Analytics";
import { HEADRES, SUB_HEADERS } from "components/Header";
import URLRoutes from "urlRoutes";
import FilterComponent, { DEFAULT_FILTER_VALUE, FilterTypes, IFilter } from "./FilterComponent";
import { transformFormValueToAdobe } from "utils/filterFormTransform/transformValueToAdobe";

function PaafUsers() {
  const dispatch = useDispatch();
  const [ searchParams, setSearchParams] = useSearchParams();
  const { data: features }: { data: Feature; } = (useSelector(getFeaturesState, equal))!;
  const paafUserFeatures: IPAAFUserFeature = useMemo(() => features?.getPaafUserFeatures(), [features]);
  const [ showNoSearchBanner ] = useState<boolean>(false);

  // Filter State
  const [ isFilterApplied, setIsFilterApplied] = useState<boolean>(false);
  const [ currentFilterState, setCurrentFilterState ] = useState<IFilter>(undefined!);

  // Table State
  const tableRef: MutableRefObject<RefProps> = useRef(null!);
  const [ currentTableState, setCurrentTableState] = useState<ReactTableDefaultState>(undefined!);
  const [ defaultTableState, setDefaultTableState] = useState<ReactTableDefaultState>(undefined!);


  // Add User State
  const currentPageState: IPaginationState = useSelector(getPaafUserState, equal);
  const isOperationInProgress = usePrevious(currentPageState.opertionInProgress);
  const [ totalfilterApplied, setTotalFilterApplied] = useState<number>(0);

  // Dropdowns Options
  const allRolesState: IRextState = useSelector(getAllRolesState);
  const locationState: IRextState = useSelector(getLocations, equal);
  const practiceAreaState: IRextState = useSelector(getPracticeAreaState, equal);

  
  const handleSelectUserModal = (): Promise<any> => {
    return new Promise((resolve: any, reject: any) => {
      dispatch(modalOperation.showModal(ModalsType.SelectUserModal, {
        onSave: (user: any) => {
          dispatch(modalOperation.hideModal());
          resolve(user);
        },
        onClose: () => {
          dispatch(modalOperation.hideModal());
        }
      }));
    })
  };


  const handleSelectRoleModal = (user: INewUser, isEditable: boolean): Promise<any> => {
    return new Promise((resolve: any, reject: any) => {
      dispatch(modalOperation.showModal(ModalsType.SelectRoleModal, {
        user,
        isEditable,
        onSave: (data: any, isUpdate: boolean) => {
          resolve({user: data, isUpdate });
          dispatch(modalOperation.hideModal());
        },
        onClose: () => {
          dispatch(modalOperation.hideModal());
        }
      }));
    })
  };

  const handleDeleteModal = (user: any) => {
    dispatch(modalOperation.showModal(ModalsType.ConfimationDeleteModal, {
      onSave: () => {
        dispatch(modalOperation.hideModal());
        dispatch(deletePaafUser(user.hrId));
      },
      onClose: () => {
        dispatch(modalOperation.hideModal());
      },
      title: "Confirmation",
      message: "Are you sure you want to delete this user ?",
      primaryBtnTitle: "Delete"
    }));
  };

  /**
   * 
   * @param tableState 
   * @param filter 
   */
  const fetchUsers = (tableState: ReactTableDefaultState, filter: IFilter) => {
    const { pageNumber, pageSize } = tableState;
    const searchParameters = transformFormValueToPresetValue(filter, FilterTypes);
    setTotalFilterApplied(getNumberOfFIlterApplied(filter, FilterTypes))
    dispatch(getPaafUser(pageNumber, pageSize,  searchParameters, true))
  };

 

  useEffect(() => {
    setGlobalPageLoad("PAAF USERS");
    
    if (!allRolesState.data.length) {
      dispatch(getAllRolesRequest())
    }

    if (!locationState.data?.dropdown.length) {
      dispatch(getLocationsRequest());
    }

    if (!practiceAreaState.data?.dropdown.length) {
      dispatch(getPracticeAreaRequest());
    }
  }, [dispatch])

  useEffect(() => {
    if(isOperationInProgress && !currentPageState.opertionInProgress && !currentPageState.operationError) {
      handleChange(CHANGE_EVENTS.PAGE_NUMBER, {...currentTableState, pageNumber: 0});
    }
  }, [isOperationInProgress, currentPageState.opertionInProgress])

  const showUsers = useMemo(() => {
    return locationState?.data?.mappingIds && practiceAreaState?.data?.mappingIds;
  }, [locationState,  practiceAreaState])

  const handleAddModal = async () => {
    try {
      const hrId = await handleSelectUserModal();
      const {user, isUpdate} = await handleSelectRoleModal({
        hrId,
        offices: [],
        officesPap: [],
        roles: [],
        topics: []
      }, false);
      if(isUpdate) {
        dispatch(editPaafUser(user));
      } else {
        dispatch(addPaafUser(user));
      }
    } catch (error) {
    }
  };

  const handleExport = () => {
    linkInteraction("Export to Excel", undefined!, HEADRES.Admin_Options, SUB_HEADERS.PAAF_Users);
    const searchParameters = transformFormValueToPresetValue(currentFilterState, FilterTypes);
    dispatch(modalOperation.showModal(ModalsType.ExportToExcel,{
      url: `${URLRoutes.server.listAllUsers}`,
      urlParams: {},
      body: searchParameters,
      excelCells: excelItems,
      transformation: paafUserExcelTransformation(practiceAreaState.data.mappingIds, locationState.data.mappingIds),
      peopleApiKeys: ["hrId"],
      fileName: "Paaf_Users",
      responseKey: "info",
      chunksToDownload: 500,
      totalElements: currentPageState.totalElements,
      onSave: ()=> {
        dispatch(modalOperation.hideModal());
      },
      onClose: ()=>{
        dispatch(modalOperation.hideModal());
      },
      title: `Export to Excel`
    }))
  }

  const handleEditModal = async (data: INewUser) => {
    const { user } = await handleSelectRoleModal(data, true);
    dispatch(editPaafUser(user));
  };

  const gridView = useMemo(() => {
    if (locationState.data.mappingIds && practiceAreaState.data.mappingIds) {
      return getGridView(handleDeleteModal, handleEditModal, paafUserFeatures, practiceAreaState.data.mappingIds, locationState.data.mappingIds)
    } else {
      return []
    }
  }, [locationState.data?.mappingIds, practiceAreaState.data?.mappingIds])


  // changes done in table
  const handleChange = (changeEvent: CHANGE_EVENTS, tableState: ReactTableDefaultState) => {
    if(changeEvent !== CHANGE_EVENTS.PAGE_NUMBER) {
      tableState.pageNumber = 0;
    }
    setCurrentTableState({...tableState});
    setSearchParams({
      ...transformFormValueToValues(currentFilterState, FilterTypes, false),
      ...transformTableStateToString(tableState, false)
    })
  };

  // changes done in filter
  const handleFilterApplied = (filterValue: IFilter) => {
    internalSearchInteraction(transformFormValueToAdobe(filterValue, FilterTypes), HEADRES.Reports, SUB_HEADERS.Change_Report);
    setCurrentFilterState(filterValue);
    setCurrentTableState({...currentTableState, pageNumber: 0});
    tableRef?.current?.changePageNumber(0);
    setSearchParams({
      ...transformTableStateToString({...currentTableState, pageNumber: 0} ,false),
      ...transformFormValueToValues(filterValue, FilterTypes, false)
    })
  }

  const handleResetClick = () => {
    linkInteraction("Reset", undefined!, HEADRES.Admin_Options, SUB_HEADERS.PAAF_Users);
    setIsFilterApplied(false);
    setCurrentFilterState(DEFAULT_FILTER_VALUE);
    setSearchParams({});
  }

  useEffect(() => {
    const searchParamsString = searchParams.toString();
    let tableState = transformStringToTableState(searchParamsString);
    let filter: any = transformValuesToFormValues(searchParamsString, FilterTypes);

    if(currentFilterState === undefined) {
      setCurrentFilterState(filter || DEFAULT_FILTER_VALUE);
    }

    if(currentTableState === undefined) {
      setCurrentTableState(tableState);
      setDefaultTableState(tableState);
    }

    if(!showNoSearchBanner || (showNoSearchBanner && Object.keys(filter).length)) {
      setIsFilterApplied(true);
      fetchUsers(tableState, filter);
    } else {
      setIsFilterApplied(false);
    }
  }, [searchParams]);

  return (
    <Fragment>
      {
        currentFilterState && <FilterComponent
          isFilterApplied={isFilterApplied}
          handleFilterApplied={handleFilterApplied}
          filterState={currentFilterState}
          showNoFilterBanner={showNoSearchBanner}
          handleResetClick={handleResetClick}
          captions={
            <div>
              {showUsers && <Fragment>
                {paafUserFeatures?.export && <CustomButton
                  primaryButton
                  buttonText="Export To Excel"
                  handleClick={handleExport}
                  round
                />
                }

                {paafUserFeatures?.add && <CustomButton
                  primaryButton
                  buttonText="Add New User"
                  handleClick={handleAddModal}
                  round
                  baseclassname={"margin-l-4"}
                />
                }
              </Fragment>
              }
            </div>
          }
        />
      }

      <div className="padding-l-10 padding-r-10">
        {isFilterApplied && <Fragment>
          <div className="flex flex-row flex-justify-between  padding-t-4 padding-b-4">
            <div className="margin-r-2 padding-t-1 text-inline">{currentPageState.totalElements} Search Results: {totalfilterApplied} Filters applied</div>
          </div>
          <ReactTable ref={tableRef} primarykey="hrId" baseClassName="paaf-user-table-container" defaultState={defaultTableState} isLoading={currentPageState.fetching}
            headers={gridView} data={currentPageState.data} uncontrolledHeight isPaginationAllowed handleChange={handleChange} totalElements={currentPageState.totalElements}
            totalNumberOfPages={currentPageState.totalNumberOfPages} />
        </Fragment>
        }
      </div>
      {
        currentPageState.opertionInProgress && <Loading isGlobal />
      }
    </Fragment >
  )
}
export default PaafUsers;