import CustomButton from "components/CustomButton";
import { ICON_POSITION } from "components/CustomButton/CustomButton";
import SvgIcon, { CUSTOM_SVG_ICON, SVGType } from "components/SvgIcon";
import { useMemo } from "react";
import { Dropdown } from "semantic-ui-react";
import "./Pagination.scss"
interface Props {
  pageNumber: number;
  pageSize: number
  totalNoOfPage: number;
  totalNoOfElements: number;
  handlePageChange: (pageNo: number) => void;
  handlePageSizeChange: (pageSize: number) => void;
  isLoading: boolean;
  paginationSize?: number[];
}

function Pagination({ pageNumber, pageSize, totalNoOfPage, totalNoOfElements, handlePageChange, handlePageSizeChange, paginationSize}: Props) {

  const indexes: {currentStartIndex: number, currentEndIndex: number} = useMemo(() => {
    const startIndex: number = pageNumber=== 0 ? 1 : pageSize * (pageNumber) + 1;
    const endIndex= startIndex + pageSize - 1;
    return {
      currentEndIndex: endIndex < totalNoOfElements ? endIndex : totalNoOfElements,
      currentStartIndex: startIndex < totalNoOfElements ? startIndex : totalNoOfElements
    }
  }, [pageNumber, pageSize, totalNoOfPage]);


  const paginationOptions: any = useMemo(() => {
    return (paginationSize || [100, 200, 500]).map(size => ({key: size, value: size, text: size}))
  }, [paginationSize])


  const handleBackPageChange = () => {
    if(pageNumber >=1) {
      handlePageChange(--pageNumber);
    }

  }

  const handleNextPageChange = () => {
    if(pageNumber < totalNoOfPage - 1) {
      handlePageChange(++pageNumber);
    }
  }

  return (
    <div className="flex flex-row flex-align-center  padding-t-4 padding-b-4 flex-justify-center text-sm ">
      <span className="padding-r-4">Rows per page:</span>
      <Dropdown
        icon={<SvgIcon svgType={SVGType.CUSTOM} name={CUSTOM_SVG_ICON.CaretDown} size="huge" baseclassname={"padding-l-2"} />}
        value={pageSize}
        className="pagination-dropdown"
        inline
        onChange={(event, {value}: any) => {
          handlePageSizeChange(value);
        }}
        options={paginationOptions}
      />
      <div className="flex flex-row padding-l-8 ">
        {indexes.currentStartIndex}-{indexes.currentEndIndex} of {totalNoOfElements}
        <CustomButton
          transparent
          iconPosition={ICON_POSITION.LEFT} primaryButton
          iconProps={{
            svgType: SVGType.CUSTOM,
            name: CUSTOM_SVG_ICON.CaretLeft,
            size: "small"
          }}
          noOutline
          handleClick={handleBackPageChange}
        />
        <CustomButton
          transparent
          iconPosition={ICON_POSITION.LEFT} primaryButton
          iconProps={{
            svgType: SVGType.CUSTOM,
            name: CUSTOM_SVG_ICON.CaretRight,
            size: "small"
          }}
          noOutline
          handleClick={handleNextPageChange}
        />
      </div>
    </div>
  )
}

export default Pagination;