import classNames from "classnames";
import SvgIcon, { CUSTOM_SVG_ICON, SVGType } from "components/SvgIcon";
import { useState, useEffect, useMemo } from "react";

export interface IHirerarchy {
  title: string;
  childrens?: IHirerarchy[];
}

interface Props {
  items: IHirerarchy[];
  baseclassname?: any;
  forceExpand?:  boolean;
}


interface HirProps {
  item: IHirerarchy;
  id: string;
  forceExpand: boolean;
  isRoot: boolean;
}


function Hirerarchy({item, id, forceExpand, isRoot}: HirProps) {
  const [isExpanded, setIsExpanded] = useState<boolean>(forceExpand);

  const hasChildrens = useMemo(() => {
    return !!(item.childrens && item.childrens.length)
  }, [item])

  const hasNestedChildrens = useMemo(() => {
    return (item.childrens || []).some(itr => !!(itr.childrens?.length))
  }, [item])

  const toggleExpand = () => {
    if(hasChildrens) {
      setIsExpanded(!isExpanded);
    }
  }

  const renderHirerarchyChild = (hir: IHirerarchy, index: number) => {
    const key: string = `${id}-${index}`;
    return (
      <Hirerarchy item={hir} key={key} id={key} forceExpand={forceExpand} isRoot={!item}/>
    )
  }

  useEffect(()=> {
    setIsExpanded(forceExpand);
  }, [forceExpand]);


  return (
    <div id={id} className={classNames([{"margin-b-4": isRoot}])}>
      <div className={classNames(["flex flex-row",
      {"background-primary-color text-white-color padding-6": isRoot},
      {"padding-l-6 padding-t-3 padding-b-3" :!isRoot && hasChildrens},
      {"padding-l-6 padding-t-1 padding-b-1" :!isRoot && !hasChildrens},
      {"cursor-pointer": hasChildrens}])} onClick={toggleExpand}>
        {
          hasChildrens && <SvgIcon svgType={SVGType.CUSTOM} name={isExpanded? CUSTOM_SVG_ICON.CaretDown : CUSTOM_SVG_ICON.CaretRight} size="small"/>
        }
        {
          !hasChildrens && <div>&#x2022;</div>
        }
        <div className="margin-l-3">{item.title}</div>
      </div>
      {
        hasChildrens && isExpanded && <div className={classNames([{"border-1 border-grey-shade-11": isRoot,"padding-3 padding-l-6": hasNestedChildrens}, {"padding-l-6": !hasNestedChildrens}])}>
          {
            item.childrens?.map(renderHirerarchyChild)
          }
        </div>
      }
    </div>
  )
}


function HirerarchyLevel({ items, baseclassname, forceExpand }: Props) {

  const renderHirerarchy = (item: IHirerarchy, index: number) => {
    const id: string = `parent-${index}`;
    return (
      <Hirerarchy item={item} key={id} id={id} forceExpand={forceExpand!} isRoot={true}/>
    )
  }


  return (
    <div className={classNames([baseclassname || ""])}>
      {items.map(renderHirerarchy)}
    </div>
  )
}

export default HirerarchyLevel;