import { Fragment, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { modalOperation } from "store/actions";
import { getModalState } from "store/selectors";

import AlertModalView from "./AlertModal";
import AffilationModalView from "./AffilationModal";
import SaveFilterModalView from "./SaveFilterModal";
import ConfimationDeleteModalView from "./ConfimationDeleteModal";
import ToggleColumnModalView from "./ToggleColumnModal";
import SelectUserModalView from "./SelectUserModal";
import SelectRoleModalView from "./SelectRoleModal";
import ExportToExcelView from "./ExportToExcel";
import PreviousCommentsModalView from "./PreviousCommentsModal";
import SubmitAffiliationModalView from "./SubmitAffiliationModal";
import TutorialModalView from "./TutorialModal";

import "./modal.scss";

export enum ModalsType {
  AlertModal = "AlertModalView",
  AffilationModal = "AffilationModalView",
  ConfimationDeleteModal = "ConfimationDeleteModalView",
  SaveFilterModal = "SaveFilterModalView",
  ToggleColumnModal = "ToggleColumnModalView",
  SelectUserModal = "SelectUserModalView",
  SelectRoleModal = "SelectRoleModalView",
  AddUser = "AddUserView",
  ExportToExcel = "ExportToExcelView",
  PreviousCommentsModal="PreviousCommentsModalView",
  SubmitAffiliationModal="SubmitAffiliationModalView",
  TutorialModal="TutorialModalView"
}
const modalComponentLookupTable: any = {
  AlertModalView,
  AffilationModalView,
  ConfimationDeleteModalView,
  ToggleColumnModalView,
  SelectUserModalView,
  SelectRoleModalView,
  ExportToExcelView,
  PreviousCommentsModalView,
  SubmitAffiliationModalView,
  TutorialModalView,
  SaveFilterModalView
};


function ModalManager(props: any) {
  const dispatch = useDispatch();
  const modalState = useSelector(getModalState);
  const hideModal = () => {
    dispatch(modalOperation.hideModal());
  }

  const modal = useMemo(() => {
    const { name, modalProps = {} } = modalState || {};
    if (name) {
      const ModalComponent = modalComponentLookupTable[name];
      return (
        <ModalComponent {...modalProps} hideModal={hideModal} />
      );
    } else {
      return <Fragment />
    }

  }, [modalState]);

  return <Fragment>{modal}</Fragment>;
}

export default ModalManager;
