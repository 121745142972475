import { getDateRangeString } from "utils/common";
import { FILTER_TYPES, IFilterTypes } from "./utils";

function convertArrayOptions(filterValue: any, label : string){
  if (filterValue?.length >= 1) {
    return `${label} (${filterValue.join("|")})`;
  }
  return undefined!;
}

const getValues = {
  [FILTER_TYPES.MULTI_SELECT]: convertArrayOptions,
  [FILTER_TYPES.TREE_SELECT]: convertArrayOptions,
  [FILTER_TYPES.SINGLE_SELECT]: (filterValue: any, label: string) => {
    if (filterValue) {
      return `${label} (${filterValue})`;
    }
    return undefined;
  },
  [FILTER_TYPES.DATE_RANGE]: (filterValue: any, label: string) => {
    if (filterValue?.length >= 1) {
      return `${label} (${getDateRangeString(filterValue).join("|")})`;
    }
    return undefined;
  },
  [FILTER_TYPES.DATE]: (filterValue: any, label: string) => {
    if (filterValue) {
      return `${label} (${filterValue.getTime()})`;
    }
    return undefined;
  },
  [FILTER_TYPES.BOOLEAN]: (filterValue: any, label: string) => {
    if (filterValue) {
      return `${label} (${true})`;
    }
    return false;
  },
  [FILTER_TYPES.DEFAULT]: (filterValue: any, label: string) => {
    if (filterValue.length) {
      return `${label} (${filterValue})`;
    }
    return undefined;
  }
}


export function transformFormValueToAdobe(formValue: any, filterTypes: IFilterTypes) {
  const value: string[] = [];
  const filterKeys = Object.keys(formValue);
  filterKeys.forEach((filterKey: string) => {
    const filterValue = formValue[filterKey];
    const { type, label } = filterTypes[filterKey] || {};
    if (type) {
      const filterKeyValue = getValues[type](filterValue, label);
      if (filterKeyValue) {
        value.push(filterKeyValue);
      }
    }
  });
  return value.join(",");
}