import { Grid, Modal } from "semantic-ui-react";
import CustomButton from "components/CustomButton";
import { ModalHeader } from "../Common";

import "./SaveFilterModal.scss";
import { FormInput, FormTextArea } from 'components/FormInputs';
import { Form, reduxForm, Field, InjectedFormProps } from "redux-form";
import { maxLength, requiredWithMessage } from "utils";
import { linkInteraction } from "Analytics";
import { HEADRES } from "components/Header";

interface Props {
  isEditable: boolean;
  hideModal: () => void;
  onClose: () => void;
  onSave: (value: any) => void;
}


const NameValidation = [requiredWithMessage(), maxLength(50)];
const DescripitonValidation = [requiredWithMessage(), maxLength(250)];


const SaveFilterForm: React.FC<Props & InjectedFormProps<{}, Props>> = (props: any) => {
  const { hideModal, isEditable } = props;

  const handleFormSubmit = (value: any) => {
    linkInteraction("SAVE AS PRESET", isEditable ? "Save Changes" : "Save Preset", HEADRES.Search_PAAF, undefined!);
    props.onSave(value);
  }

  return (
    <Form onSubmit={props.handleSubmit(handleFormSubmit)}>

      <Modal
        size="mini"
        dimmer
        open
        onClose={hideModal}
        closeOnEscape={false}
        closeOnDimmerClick={false}
        className="save-filter-modal"
      >
        <ModalHeader title="Save Preset" toggle={hideModal} />
        <Modal.Content>
          <Grid>
            <Grid.Row>
              <Grid.Column>
                <Field
                  label="Preset Name"
                  component={FormInput}
                  name='presetName'
                  placeholder="Enter Preset Name"
                  type={"text"}
                  required
                  inputClassName="width-100"
                  validate={NameValidation}
                />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column>
                <Field
                  component={FormTextArea}
                  name='presetDescription'
                  label="Description"
                  placeholder="Enter Description"
                  inputClassName="width-100"
                  required
                  validate={DescripitonValidation}
                />
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Modal.Content>
        <Modal.Actions>
          <CustomButton type="button" round primaryButton buttonText={isEditable ? "Save Changes" : "Save Preset"} handleClick={props.submit} />
        </Modal.Actions>
      </Modal>
    </Form>
  );
}

const SaveFilterModal = reduxForm<{}, Props>({
  form: "SaveFilterContainer"
})(SaveFilterForm);

export default SaveFilterModal;
