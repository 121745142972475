export enum DIRECTION {
    LEFT = "left",
    CENTER = "center",
    RIGHT = "right"
}

export interface IColumn {
    id: string;
    label: string;
    isSortable?: boolean;
    isSearchEnable?: boolean;
    isFixed?: boolean;
    fixedDirection?: "left" | "right";
    width: number;
    CustomRowComponent?: any;
}

export interface IGrouping {
    headers: IColumn[];
    totalWidth: number;
    originalWidth: number;
    totalWidthStr: string;
    groupingWidth: IKeyOfString;
}

export interface IGroupingColumns {
    leftColumns: IGrouping;
    centerColumns: IGrouping;
    rightColumns: IGrouping;
}


export interface IKeyOfString {
    [key: string]: string
}

export interface IReactTableRef {
    leftScroll: (value: number) => void;
}

export interface IReactTableComponent {
    data: any;
    header: IColumn;
}

export enum SortOrder {
    ASSCENDING = "ass",
    DESCENDING = "desc"
}
export interface ISortState {
    key: string;
    sortOrder: SortOrder;
}

export enum CHANGE_EVENTS {
    PAGE_SIZE = "PAGE_SIZE",
    PAGE_NUMBER = "PAGE_NUMBER",
    SORTING = "SORTING",
    FILTER = "FILTER"
}

export interface IChangeEventData {
    filter: any;
    pageNumber: number;
    pageSize: number;
    sort: ISortState;
}

export interface RefProps {
    changePageNumber: (pageNumber: number) => void,
    changePageSize: (pageSize: number) => void,
    changeSortState: (sortState: ISortState) => void,
    intializeFilterState: (filter: any) => void
    setDefaultState: (filter: any) => void;
}