import CustomButton from "components/CustomButton";
import { ModalsType } from "containers/ModalManager/ModalManager";
import { MutableRefObject, useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { Popup } from "semantic-ui-react";
import { modalOperation } from "store/actions";
import "./Tutorial.scss";


export interface ITutorial {
  id: string;
  message: any;
  node: MutableRefObject<any>;
  threshold: number;
}


function TutorilaPopover({domNode, message, tutorialIndex, tutorialRef, setTutorialIndex, handleStopTutorial, threshold  }: any) {
  const { left, width, height, top } = domNode.getBoundingClientRect();

  return (
    <div className="tutorial-overlay">
      <Popup
        hideOnScroll={true}
        content={<div className="tutorial-content flex flex-column flex-justify-center">
          {message}
          <div className="margin-t-4 flex flex-row flex-justify-center">
            <CustomButton type="button" baseclassname="tutorial-start-button"
              primaryButton buttonText={tutorialIndex === tutorialRef.length - 1 ? "Thank you!"
                : "Next"} handleClick={() => {
                  if (tutorialIndex <= tutorialRef.length - 2) {
                    setTutorialIndex((index: number) => {
                      return index +1;
                    });
                  } else {
                    handleStopTutorial();
                  }
                }} />
          </div>
        </div>}
        open={true}
        closeOnEscape={false}
        closeOnPortalMouseLeave={false}
        closeOnDocumentClick={true}
        onClose={(event: any) => {
          if (event.type === "click" || event.type === "scroll") {
            handleStopTutorial()
          }
        }}
        position="bottom left"
        basic
        className="tutorial-popover"
        trigger={<div style={{
          width: width + threshold, height: height + threshold, left: left - (threshold / 2), top: top - (threshold / 2), position: "fixed", zIndex: 1100
        }} className="tutorial-pointing"></div>}
      />
    </div>
  )
}

function Tutorial({ tutorialRef, handleStopTutorial }: { tutorialRef: Array<ITutorial>, handleStopTutorial: () => void }) {
  const [tutorialIndex, setTutorialIndex] = useState<number>(0);
  const [startPointing, setStartPointing] = useState<boolean>(false)
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(modalOperation.showModal(ModalsType.TutorialModal, {
      onSave: () => {
        dispatch(modalOperation.hideModal());
        setTimeout(() => {
          setStartPointing(true)
        }, 0)
      },
      onClose: () => {
        dispatch(modalOperation.hideModal());
        handleStopTutorial();
      },
    }))
  }, [dispatch])

  const findDomNode = useCallback((nodes: Array<Node> | NodeListOf<ChildNode>, id: string, index: number): any => {
    const node: Node = nodes[index];
    let findNode: any = undefined!;
    if (node) {
      if ((node as any).id === id) {
        findNode = node;
      } else if (node.childNodes.length) {
        findNode = findDomNode(node.childNodes, id, 0);
      }

      if (!findNode) {
        ++index;
        if (nodes.length > index) {
          findNode = findDomNode(nodes, id, index);
        }
      }
    }
    return findNode;

  }, [tutorialRef, tutorialIndex]);

  const domeNode = useMemo(() => {
    if (!startPointing) {
      return null;
    }

    const { id, message, node, threshold } = (tutorialRef && tutorialRef[tutorialIndex]) || {};
    if (id && node && node.current) {

      let domNode: any = undefined!;
      if (node.current.id === id) {
        domNode = node;
      }

      if (!domNode && node.current.childNodes.length) {
        domNode = findDomNode(node.current.childNodes, id, 0);
      }

      if (domNode) {
        return <TutorilaPopover domNode={domNode} handleStopTutorial={handleStopTutorial} tutorialRef={tutorialRef}
          setTutorialIndex={setTutorialIndex} tutorialIndex={tutorialIndex} message={message} threshold={threshold}/>
      }

      return null;
    }
    return null;
  }, [tutorialRef, tutorialIndex, startPointing]);

  return domeNode || null
}

export default Tutorial;