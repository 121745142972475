import {
  ICheckedOptions,
  IOption,
  ReactTreeNode,
  ReactTreeObjectNode
} from "../interface";


export const findNode = (
  node: IOption,
  parent: ReactTreeNode,
  value: Array<string>,
  valueNodes: ReactTreeObjectNode,
  disableBackwardSelection: boolean,
  disableForwardSelection: boolean
) => {
  const parentNode: ReactTreeNode = { node, parent };

  const valueIndex: number = value.findIndex((item) => item === node.id);
  if (valueIndex >= 0) {
    valueNodes[node.id] = parentNode;
    // break statement as forward all will be selected;
    if(!disableForwardSelection) {
      return valueNodes;
    }
  }
  if (node.childrens && !!node.childrens.length) {
    node.childrens.forEach((child) => {
      findNode(child, parentNode, value, valueNodes, disableBackwardSelection, disableForwardSelection);
    });
  }
  return valueNodes;
};

export const filterNodes = (dropdownOptions: IOption[], searchtxt: string) => {
  const showHide = {};
  dropdownOptions.forEach((dropdownOption) => {
    searchString(dropdownOption, searchtxt, showHide);
  });
  return showHide;
};

export const searchString = (
  node: IOption,
  searchtxt: string,
  showHide: ReactTreeObjectNode
) => {
  let found = node.value.toLowerCase().includes(searchtxt.toLowerCase());
  if (node.childrens && node.childrens.length >= 1) {
    node.childrens.forEach((childItem) => {
      const childFound = searchString(childItem, searchtxt, showHide);
      if (!found && childFound) {
        found = childFound;
      }
    });
  }
  showHide[node.id] = found;
  return found;
};

function isPartialChecked (node: IOption, 
  checkedOptions: ReactTreeObjectNode
  ) {
  return (node.childrens || []).some(
    (parentChildNode) =>
      (checkedOptions[parentChildNode.id] &&
        checkedOptions[parentChildNode.id].checked) ||
      (checkedOptions[parentChildNode.id] &&
        checkedOptions[parentChildNode.id].partialChecked)
  )
}

function isParentNodeChildrenChecked(node: IOption, 
  checkedOptions: ReactTreeObjectNode){
  return (node.childrens || []).every(
    (parentChildNode) =>
      checkedOptions[parentChildNode.id] &&
      checkedOptions[parentChildNode.id].checked
  )
}

export const backwardSelectionChange = (
  checked: boolean,
  checkedOptions: ReactTreeObjectNode,
  _currentNode: IOption,
  parentNodes: ReactTreeNode
) => {
  if (parentNodes) {
    const { node, parent } = parentNodes;

    const partialChecked = isPartialChecked(node, checkedOptions)
    if (!checked) {
      checkedOptions[node.id] = { checked, partialChecked };
      if (parent) {
        backwardSelectionChange(checked, checkedOptions, node, parent);
      }
    } else {
      const allParentNodeChildrensChecked = isParentNodeChildrenChecked(node, checkedOptions);

      checkedOptions[node.id] = {
        checked: node.hideCheckbox ? false : allParentNodeChildrensChecked,
        partialChecked,
        childrens:
          node?.childrens?.map(obj => obj.id),
        node: {
          node,
          parent,
        },
      };
      if (allParentNodeChildrensChecked || partialChecked) {
        backwardSelectionChange(
          allParentNodeChildrensChecked,
          checkedOptions,
          node,
          parent
        );
      }
    }
  }
  return checkedOptions;
};

export const forwardSelectionChange = (
  checked: boolean,
  checkedOptions: ReactTreeObjectNode,
  option: IOption,
  options: ReactTreeNode,
  disableForwardSelection: boolean
) => {
  const { id, childrens, hideCheckbox } = option;
  checkedOptions[id] = {
    checked : hideCheckbox ? false : checked,
    partialChecked: checked,
    childrens:
      childrens && childrens.length >= 1 ? childrens.map(obj => obj.id) : undefined,
    node: {
      node: option,
      parent: options,
    },
  };
  if (childrens && !disableForwardSelection) {
    childrens.forEach((child) => {
      forwardSelectionChange(checked, checkedOptions, child, {
        node: option,
        parent: options,
      }, disableForwardSelection);
    });
  }
  return checkedOptions;
};

export const getSelectedOptions = (
  selectedIds: string[],
  checkedOptionState: ICheckedOptions,
  isDependent: boolean,
  hideAllNodes?: boolean
) => {
  selectedIds.forEach((optionId) => {
    if (checkedOptionState[optionId]) {
      const { checked, childrens } = checkedOptionState[optionId];
      if (checked) {
        if (childrens && childrens.length >= 1) {
          getSelectedOptions(childrens, checkedOptionState, hideAllNodes || false, hideAllNodes);
        }
        if (isDependent) {
          delete checkedOptionState[optionId];
        }
      } else {
        delete checkedOptionState[optionId];
      }
    }
  });
  return checkedOptionState;
};


export function debounce<Params extends any[]>(
  func: (...args: Params) => any,
  timeout: number,
): (...args: Params) => void {
  let timer: any;
  return (...args: Params) => {
    clearTimeout(timer)
    timer = setTimeout(() => {
      func(...args)
    }, timeout)
  }
}


export function getInitializeCheckOptions(empty: boolean, checkedOptions: ICheckedOptions, node: ReactTreeNode[],
  disableForwardSelection: boolean, disableBackwardSelection: boolean) {
  let cloneCheckOptions = Object.assign({}, empty ? {} : checkedOptions);
  node.forEach((currentNode: ReactTreeNode) => {
    cloneCheckOptions = forwardSelectionChange(
      true,
      cloneCheckOptions,
      currentNode.node,
      currentNode.parent,
      disableForwardSelection
    )
    if (!disableBackwardSelection) {
      cloneCheckOptions = backwardSelectionChange(
        true,
        cloneCheckOptions,
        currentNode.node,
        currentNode.parent
      )
    }
  });
  return cloneCheckOptions;
}


export function getCheckedOptionState(isMulti: boolean, checked: boolean, checkedOptions: ICheckedOptions,
  option: IOption, options: ReactTreeNode, disableForwardSelection: boolean, disableBackwardSelection: boolean) {
  if (isMulti) {
    let tmpCheckOptionsState = forwardSelectionChange(
      checked,
      Object.assign({}, checkedOptions),
      option,
      options,
      disableForwardSelection
    );
    if (!disableBackwardSelection) {
      tmpCheckOptionsState = backwardSelectionChange(
        checked,
        tmpCheckOptionsState,
        option,
        options
      );
    }
    return tmpCheckOptionsState;
  } else {
    return {
      [option.id]: {
        checked: true,
        childrens: [],
        node: {
          node: option,
          parent: options
        },
        partialChecked: false
      }
    }
  }
}

export function getShowHideNodes(searchTxt: string, dropdownOptions: IOption[]) {
  if (searchTxt && searchTxt.length >= 1) {
    return filterNodes(dropdownOptions, searchTxt);
  } else {
    return {};
  }
}