import CustomButton from "components/CustomButton"
import { ICON_POSITION } from "components/CustomButton/CustomButton";
import ProfilePicture from "components/ProfilePicture";
import { IColumn, IReactTableComponent } from "components/ReactTable";
import { CUSTOM_SVG_ICON, SVGType } from "components/SvgIcon";
import UsePeople from "HOC/UsePeople";
import { IExcelCell } from "interface";
import moment from "moment";
import { memo, useMemo, useState } from "react";
import { IPeopleUser } from "store/reducers";
import { getUserName, ROLES_LABEL } from "utils";
import { IPAAFUserFeature } from "utils/Features/interface";


const ExpandCollapse = ({ mappindData, data, header, mappingKey }: any) => {
  const items: Array<string> = data[header.id];
  const [expand, setExpand] = useState<boolean>(false);
  const displayItems: Array<string> = useMemo(() => {
    const sortItems = items.map(item => mappindData[item] || item).sort();
    return expand ? sortItems : sortItems.slice(0, 3)
  }, [expand]);
  return <div>
    {displayItems.map((item: string, index: number) => <div className="margin-b-1" key={`${mappingKey}${index}`}>{item}</div>)}
    {
      !expand && items.length >= 4 && <div onClick={() => {
        setExpand(prevsValue => !prevsValue)
      }} className="more-dot background-primary-color text-white-color cursor-pointer">+{items.length - 3}</div>
    }
    {
      expand && items.length >= 4 && <CustomButton
        noOutline
        transparent
        primaryButton
        iconPosition={ICON_POSITION.RIGHT}
        iconProps={{
          svgType: SVGType.CUSTOM,
          name: CUSTOM_SVG_ICON.TriangleUp,
          size: "small"
        }}
        buttonText={"Collapse"}
        noPadding
        handleClick={() => { setExpand(prevsValue => !prevsValue)}}
      />
    }
  </div>
};


export const getGridView = (
  handleDeleteModal: (data: any) => void,
  handleEditModal: (data: any) => void,
  paafUserFeatures: IPAAFUserFeature,
  topicMapping: any = {},
  officesMapping: any = {}
): IColumn[] => {
  const showActions = paafUserFeatures?.delete || paafUserFeatures?.edit;
  const columns: IColumn[] = [
    {
      id: "hrId",
      label: "User Name",
      width: !showActions ? 40 : 25,
      CustomRowComponent: memo(({ data, header }: IReactTableComponent) => {
        const { user } = UsePeople(data[header.id], true);
        return (
          <div className="flex flex-row flex-align-center" id={data[header.id]}>
            {user?.profilePicture && <ProfilePicture profileUrl={user.profilePicture} baseClassName="margin-r-4" imageClassName="paaf_grid--avatar" />}
            {getUserName(user)}
          </div>
        )
      })
    },
    {
      id: "roles",
      label: "Roles",
      width: 20,
      CustomRowComponent: memo(({ data, header }: IReactTableComponent) => {
        const items = data[header.id];
        return (
          <div className="flex flex-column">
            {
              items.map((item: string, index: number) => (<div key={`role${index}`}>{(ROLES_LABEL as any)[item]}</div>))
            }
          </div>
        )
      })
    },
    {
      id: "topics",
      label: "PA / Topics",
      width: 20,
      CustomRowComponent: memo(({ data, header }: IReactTableComponent) => {
        return (
          <ExpandCollapse data={data} header={header} mappindData={topicMapping} mappingKey="topic"/>
        )
      })
    },
    {
      id: "offices",
      label: "Office",
      width: 20,
      CustomRowComponent: memo(({ data, header }: IReactTableComponent) => {
        return (
          <ExpandCollapse data={data} header={header} mappindData={officesMapping} mappingKey="offices"/>
        )
      })
    }
  ]

  if (showActions) {
    columns.push({
      id: 'action',
      label: 'Action',
      width: 15,
      CustomRowComponent: ({ data, header }: IReactTableComponent) => {
        return (
          <div className="flex flex-row">
            {paafUserFeatures.edit && <CustomButton
              transparent
              primaryButton
              noOutline
              noPadding
              buttonText="Edit"
              baseclassname="margin-r-4"
              handleClick={() => handleEditModal(data)} />
            }

            {paafUserFeatures.delete && <CustomButton
              transparent
              secondaryButton
              noOutline
              noPadding
              buttonText="Delete"
              handleClick={() => handleDeleteModal(data)}
              buttonTextClass="text-red-shade-2"
            />
            }
          </div>
        )
      }
    })
  }

  return columns;
};


export const excelItems: IExcelCell[] = [
  {
    id: "hrId",
    label: "HrId",
  },
  {
    id: "preferredName",
    label: "User Name"
  },
  {
    id: "preferredFirstName",
    label: "First Name"
  },
  {
    id: "preferredLastName",
    label: "Last Name"
  },
  {
    id: "latestHireDate",
    label: "Member Since"
  },
  {
    id: "role",
    label: "Role Name"
  },
  {
    id: "topic",
    label: "Practice Area/Topic"
  },
  {
    id: "office",
    label: "Office",
  }
];

interface IPaafUserExcel {
  preferredFirstName: string;
  preferredLastName: string;
  preferredName:string;
  hrId: string;
  role: string;
  topic: string;
  office: string;
  latestHireDate:string;
}

interface IRole {
  role: string;
  canEdit?: string[];
}

interface IEmployee{
  hrId: string;
  roles: IRole[];
}

function addValueToRecord(value: string, key: string, record: any) {
  if(value?.length) {
    record[key] = value;
  }
}

function createRecord(user: IPeopleUser, role: string, topic: string, office: string): IPaafUserExcel {
  const { id, preferredFirstName, preferredName, preferredLastName,latestHireDate} = user || {};
  let record: any = { };

  addValueToRecord(id, "hrId", record);
  addValueToRecord(preferredName, "preferredName", record);
  addValueToRecord(preferredFirstName, "preferredFirstName", record);
  addValueToRecord(preferredLastName, "preferredLastName", record);
  addValueToRecord(latestHireDate ? moment(parseInt(latestHireDate, 10)).format("MM/DD/YYYY HH:MM") : null!, "latestHireDate", record);
  addValueToRecord(role, "role", record);
  addValueToRecord(office, "office", record);
  addValueToRecord(topic, "topic", record);

  return record;
}


export function paafUserExcelTransformation( practiceAreaMapping: any, officeMapping: any) {
  return (reportData: Array<any>, rawPeople: any): Array<IPaafUserExcel> => {
    const result:Array<IPaafUserExcel>= [];
    
    reportData.forEach((employee: IEmployee) => {
      employee.roles.forEach(({role, canEdit}: IRole) => {
        if(!canEdit || canEdit.length === 0){
          result.push(createRecord(rawPeople[employee.hrId], role, undefined!, undefined!));
        } else {
          canEdit.forEach((permission: string) => {
            if(role === "PAC") {
              result.push(createRecord(rawPeople[employee.hrId], ROLES_LABEL.PAC, practiceAreaMapping[permission], undefined!));
            } else if(role === "CDS"){
              result.push(createRecord(rawPeople[employee.hrId], ROLES_LABEL.CDS, undefined!, officeMapping[permission]))
            }
          }
      )}
      })
    });

    return result;
  }
}