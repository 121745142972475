import URLRoutes from "urlRoutes";
import { allAffiliationPagination, changeReportPagination, paafUserPagination, snowflakeAllAffiliationPagination, snowflakeAllAffiliationPaginationMatrixView, snowflakeAllAffiliationPaginationTableView } from "./pagination";

export function getPaafUser(pageNumber: number, pageSize: number, body: any, resetPagination: boolean = false) {
  return paafUserPagination.request(
    pageNumber,
    pageSize,
    {
      url: URLRoutes.server.listAllUsers,
      method: "post",
      body,
      resetPagination
    }
  );
}

export function editPaafUser(body: any) {
  return paafUserPagination.editRequest(
    1,
    {
      url: URLRoutes.server.userRolesOperation,
      method: "put",
      body
    }
  );
}

export function deletePaafUser( hrId: string) {
  return paafUserPagination.deleteRequest(
    1,
    {
      url: URLRoutes.server.allUsersOperation,
      method: "delete",
      urlParams: {
        hrId
      }
    }
  );
}

export function addPaafUser(body: any) {
  return paafUserPagination.addRequest(
    {
      url: URLRoutes.server.userRolesOperation,
      method: "post",
      body
    }
  );
}

export function goToPaafPage(pageNumber: number) {
  return paafUserPagination.goToPage(pageNumber);
}

export function getChangeReport(pageNumber: number, pageSize: number, queryParams: any, resetPagination: boolean = false) {
  return changeReportPagination.request(
    pageNumber,
    pageSize,
    {
      url: URLRoutes.server.allChangeReports,
      method: "get",
      queryParams,
      resetPagination
    }
  );
}

export function goToChangeReport(pageNumber: number) {
  return changeReportPagination.goToPage(pageNumber);
}

export function getAllAffiliations(pageNumber: number, pageSize: number, body: any, urlParams: any, resetPagination: boolean = false) {
  return allAffiliationPagination.request(
    pageNumber,
    pageSize,
    {
      url: URLRoutes.server.allAffiliations,
      method: "post",
      body,
      urlParams,
      resetPagination
    }
  );
}

export function getSnoflakeAllAffiliations(pageNumber: number, pageSize: number, body: any, urlParams: any, resetPagination: boolean = false) {
  return snowflakeAllAffiliationPagination.request(
    pageNumber,
    pageSize,
    {
      url: URLRoutes.server.snowflakeEmployeeSearch,
      method: "post",
      body,
      urlParams,
      resetPagination
    }
  );
}

export function resetSourceDetailsState() {
  return snowflakeAllAffiliationPagination.resetUpdate(0, {});
}

export function getSnoflakeAllAffiliationsTableView(pageNumber: number, pageSize: number, body: any, urlParams: any, resetPagination: boolean = false) {
  return snowflakeAllAffiliationPaginationTableView.request(
    pageNumber,
    pageSize,
    {
      url: URLRoutes.server.snowflakeEmployeeSearchTableView,
      method: "post",
      body,
      urlParams,
      resetPagination
    }
  );
}

export function getSnoflakeAllAffiliationsMatrixView(pageNumber: number, pageSize: number, body: any, urlParams: any, resetPagination: boolean = false) {
  return snowflakeAllAffiliationPaginationMatrixView.request(
    pageNumber,
    pageSize,
    {
      url: URLRoutes.server.snowflakeEmployeeSearchMatrixView,
      method: "post",
      body,
      urlParams,
      resetPagination
    }
  );
}

export function goToAllAffiliations(pageNumber: number) {
  return allAffiliationPagination.goToPage(pageNumber);
}