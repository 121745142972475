import { DigitalData, PageName } from "types";
import SHA256 from "crypto-js/sha256";
import { HEADRES, SUB_HEADERS } from "components/Header";

export const DEFAULT_ANALYTICS_DATA: DigitalData = {
  page: {
    pageInfo: {
      pageName: undefined!,
      staffSystemID: undefined!,
      previousPageName: undefined!
    },
    category: {
      primaryCategory: undefined!,
    }
  },
  source: {
    application: {
      applicationName: undefined!
    }
  },
  internal: {
    search: {
      term: undefined!
    }
  },
  headerName: undefined!,
  subHeaderName: undefined!,
  displayView: undefined!,
  iconName: undefined!,
  level1Selection: undefined!,
  level2Selection: undefined!,
  filterCategory: undefined!,
  linkName: undefined!,
  subLinkName: undefined!,
  PAAFUserName: undefined!,
  reportName: undefined!,
  checkBoxName: undefined!
}

/**
 * 
 * Button_Link_Clicks, Internal_Search, Icon_Action_Clicks
 */

export enum INTERACTION_CLICKS {
  Button_Link_Clicks = "Button_Link_Clicks",
  Internal_Search = "Internal_Search",
  Icon_Action_Clicks = "Icon_Action_Clicks"
}


export function setAnalyticsStaffEmailID(email: string) {
  const staffSystemID = SHA256(email && email.toLowerCase()).toString();
  const { digitalData: { page: { category, pageInfo }, source: { application } } } = window;
  pageInfo.staffSystemID = staffSystemID;
  application.applicationName = "PAAF";
  category.primaryCategory = "PAAF";
}


export function setGlobalPageLoad(pageName: PageName) {
  const { digitalData: { page: { pageInfo } } } = window;
  pageInfo.previousPageName = pageInfo.pageName;
  pageInfo.pageName = pageName;
  window!._satellite?.track("Global_Page_Load");
}

export function setHeaderAndSubheaderClick(headerName: HEADRES, subHeaderName?: SUB_HEADERS) {
  const { digitalData  } = window;
  digitalData.headerName = headerName;
  digitalData.subHeaderName = subHeaderName!;
  if(subHeaderName) {
    window!._satellite?.track("Sub_Header_Clicks");
  } else {
    window!._satellite?.track("Header_Clicks");
  }
}

export function linkInteraction(linkName: string, subLinkName: string, headerName: HEADRES, subHeaderName: SUB_HEADERS, filterCategory?: string) {
  const { digitalData  } = window;
  digitalData.filterCategory = filterCategory || "";
  digitalData.linkName = linkName;
  digitalData.subLinkName = subLinkName;
  digitalData.headerName = headerName;
  digitalData.subHeaderName = subHeaderName;
  digitalData.displayView = "";
  window!._satellite?.track("Button_Link_Clicks");
}

export function internalSearchInteraction(filterCategory: string, headerName: HEADRES, subHeaderName: SUB_HEADERS) {
  const { digitalData  } = window;
  digitalData.filterCategory = filterCategory;
  digitalData.linkName = "";
  digitalData.subLinkName = "";
  digitalData.headerName = headerName;
  digitalData.subHeaderName = subHeaderName;
  window!._satellite?.track("Internal_Search");
}

export function iconInteraction(headerName: HEADRES, subHeaderName: SUB_HEADERS, iconName: string, paafUserName: string) {
  const { digitalData  } = window;
  digitalData.filterCategory = "";
  digitalData.linkName = "";
  digitalData.subLinkName = "";
  digitalData.headerName = headerName;
  digitalData.subHeaderName = subHeaderName;
  digitalData.iconName = iconName;
  digitalData.PAAFUserName = paafUserName;
  window!._satellite?.track("Icon_Action_Clicks");
}

export function viewInteraction(headerName: HEADRES, subHeaderName: SUB_HEADERS, displayView: string) {
  const { digitalData  } = window;
  digitalData.filterCategory = "";
  digitalData.linkName = "";
  digitalData.subLinkName = "";
  digitalData.headerName = headerName;
  digitalData.subHeaderName = subHeaderName;
  digitalData.displayView = displayView;
  digitalData.iconName = "";
  digitalData.PAAFUserName = "";
  window!._satellite?.track("Icon_Action_Clicks");
}

export function expandCollapseInteraction(headerName: string, subHeaderName: string, checkBoxName: string, paafUserName: string) {
  const { digitalData  } = window;
  digitalData.iconName = "";
  digitalData.PAAFUserName = paafUserName;
  digitalData.filterCategory = "";
  digitalData.headerName = headerName;
  digitalData.subHeaderName = subHeaderName;
  digitalData.displayView = "";
  digitalData.iconName = "";
  digitalData.checkBoxName = checkBoxName;
  window!._satellite?.track("Check_Box_Clicks");
}