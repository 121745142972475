import { MutableRefObject, useEffect, useRef, useState } from "react";
import { Modal } from "semantic-ui-react";

import CustomButton from "components/CustomButton";
import TreeDropdown from "components/TreeDropdown";
import { ModalHeader } from "../Common";

import "./AffilationModal.scss";
import { useDispatch, useSelector } from "react-redux";
import { getIntrestedAffiliations, getPracticeAreaForUserState } from "store/selectors";
import { getPracticeAreaForUserRequest } from "store/rext";
import { addIntrestedAffiliationsRequest } from "store/list";
import usePrevious from "HOC/UsePrevious";
import { linkInteraction } from "Analytics";
import { HEADRES } from "components/Header";

interface Props {
  hideModal: () => void;
  onClose: () => void;
  onSave: () => void;
}


function AffilationModal(props: Props) {
  const {
    hideModal
  } = props;
  const dispatch = useDispatch();
  const homeOfficeRef: MutableRefObject<any> = useRef(null);
  const [seletedOptions, setSelectedOptions] = useState<Array<string>>([]);
  const taxanomyState = useSelector(getPracticeAreaForUserState);
  const { operationError, operationProgress } = useSelector(getIntrestedAffiliations)
  const prevsAddInProgressState = usePrevious(operationProgress);

  useEffect(() => {
    dispatch(getPracticeAreaForUserRequest());
  }, [])

  useEffect(() => {
    if(!operationProgress && prevsAddInProgressState) {
      if(!operationError) {
        props.onSave();
      }
    }

  }, [prevsAddInProgressState, operationProgress])

  const handleUpdateButton = () => {
    linkInteraction("Add Affiliation", "Add Affiliation", HEADRES.My_Affiliations, undefined!, `Affiliation (${seletedOptions.map(item => taxanomyState.data.mappingIds[item]).join(" | ")})`);
    dispatch(addIntrestedAffiliationsRequest(seletedOptions));
  }

  return (
    <Modal
      size="mini"
      dimmer
      open
      onClose={hideModal}
      closeOnEscape={false}
      closeOnDimmerClick={false}
      className="affilation-modal"
    >
      <ModalHeader title="Add Interested Affiliation" toggle={hideModal} />
      <Modal.Content>
        <TreeDropdown
          popupPosition={"bottom left"}
          searchPlaceholder="Search Practice Area / Topic"
          showAppliedFilterIcon
          dropdownOptions={taxanomyState.data.dropdown}
          isMulti
          placeholder={"Home Office"}
          isSearchable={true}
          initializeValue={[]}
          isLoading={taxanomyState.fetching}
          ref={homeOfficeRef || null}
          handleValueChange={(data: any) => {
            setSelectedOptions(data)
          }}
          disableBackwardSelection
          disableForwardSelection
          isDirectComponent
        />
      </Modal.Content>
      <Modal.Actions>
        <CustomButton primaryButton buttonText="Add Affiliation" handleClick={handleUpdateButton} round />
      </Modal.Actions>
    </Modal>
  );
}
export default AffilationModal;
