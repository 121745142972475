import { getDateRangeString } from "utils/common";
import { FILTER_TYPES, IFilterTypes, URL_OPTTION_SUBFIX } from "./utils";
import queryString from "query-string";

function convertArrayOptions(filterValue: any){
  if (filterValue?.length >= 1) {
    return filterValue.join(URL_OPTTION_SUBFIX);
  }
  return undefined!;
}

const getValues = {
  [FILTER_TYPES.MULTI_SELECT]: convertArrayOptions,
  [FILTER_TYPES.TREE_SELECT]: convertArrayOptions,
  [FILTER_TYPES.SINGLE_SELECT]: (filterValue: any) => {
    if (filterValue) {
      return filterValue;
    }
    return undefined;
  },
  [FILTER_TYPES.DATE_RANGE]: (filterValue: any) => {
    if (filterValue?.length >= 1) {
      return getDateRangeString(filterValue).join(URL_OPTTION_SUBFIX);
    }
    return undefined;
  },
  [FILTER_TYPES.DATE]: (filterValue: any) => {
    if (filterValue) {
      return filterValue.getTime();
    }
    return undefined;
  },
  [FILTER_TYPES.BOOLEAN]: (filterValue: any) => {
    if (filterValue) {
      return true;
    }
    return false;
  },
  [FILTER_TYPES.DEFAULT]: (filterValue: any) => {
    if (filterValue.length) {
      return filterValue;
    }
    return undefined;
  }
}


export function transformFormValueToValues(formValue: any, filterTypes: IFilterTypes, asString: boolean) {
  const value: any = {};
  const filterKeys = Object.keys(formValue);
  filterKeys.forEach((filterKey: string) => {
    const filterValue = formValue[filterKey];
    const { type } = filterTypes[filterKey] || {};
    if (type) {
      const filterKeyValue = getValues[type](filterValue);
      if (filterKeyValue) {
        value[filterKey] = filterKeyValue;
      }
    }
  });
  return asString ? queryString.stringify(value) : value;
}