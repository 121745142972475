import { SagaIterator } from 'redux-saga';
import { put, call, select, takeEvery } from 'redux-saga/effects';
import { fetchPeopleRequest, getFullUrl } from 'Api';
import { multiPeopleInfoActions, MULTI_PEOPLE_API, peopleInfoActions, PEOPLE_API } from 'store/actions/people';
import { StandardAction } from 'store/actions';
import { getAllPeopleState, getBaseUrl, getBaseUrlAPIKEY, getToken } from 'store/selectors';
import URLRoutes from 'urlRoutes';
import { IPeople } from 'store/reducers';

export const peopleSchemaName = [
  "id", "preferredName", "preferredFirstName", "preferredLastName",
  "tbId", "profilePicture",
  "jobFamilyGroupId", "email", "workEmail",
  "latestHireDate",
  "homeOfficeId",
  "homeOfficeRegion",
  "homeOffice",
  "hostOfficeId",
  "hostOfficeRegion",
  "lateralHire",
  "jobCategoryId",
  "jobTitle",
  "jobFamilyGroupName",
  "gender",
  "businessTitle",
  "birthDate",
  "lastPromotionDate",
  "alumni",
  "globalTitle",
  "homeOfficeLocation { name system hierarchies {name type}}",
  "hostOfficeLocation { name system hierarchies {name type}}",
  "sponsors { name practiceArea primaryTopic}",
  "pastTerminationDate",
  "defaultAllocation",
  "costAllocations { allocationName }",
  "cohort",
  // "assistantIds",
  // "assistants{ id  preferredFirstName  preferredLastName email}",
  "workExperience{ jobTitle startDate endDate company companyLocation }",
  "internalJobHistory{ effectiveDate ineffectiveDate}",
  "cohortStep",
  "cohort"

]

function* performRequestOperation(action: StandardAction): IterableIterator<{}> {

  const { payload: { peopleId } } = action;
  try {
    const token: string = (yield select(getToken))!;
    const apiKey: string = (yield select(getBaseUrlAPIKEY(true)))!;
    const requesturl: string = getFullUrl((yield select(getBaseUrl(true)))!, URLRoutes.server.people, {});
    const response: any = yield call(
      fetchPeopleRequest,
      requesturl,
      token,
      "post",
      apiKey,
      {
        query: `query { employee(id: \"${peopleId}\") {
          ${peopleSchemaName.join(" ")} }}`,
        variables: {}
      }
    );
    yield put(
      peopleInfoActions.success(
        peopleId,
        response?.data?.employee
      ),
    );
  } catch (error: any) {
    const message = error.message || error.errorText || error;
    yield put(peopleInfoActions.failure(peopleId, message));
  }
}

function* performMultiRequestOperation(action: StandardAction): IterableIterator<{}> {

  const { payload: { peopleIds } } = action;
  try {
    const token: string = (yield select(getToken))!;
    const apiKey: string = (yield select(getBaseUrlAPIKEY(true)))!;
    const allPeopleState = yield select(getAllPeopleState)
    const employeesToFetch: Array<string> = peopleIds.filter((peopleId: string) => {
      const people: IPeople = (allPeopleState || {})[peopleId]!;
      return !people || people && !people.user
    });

    if(employeesToFetch.length) {
      const requesturl: string = getFullUrl((yield select(getBaseUrl(true)))!, URLRoutes.server.people, {});
      const response: any = yield call(
        fetchPeopleRequest,
        requesturl,
        token,
        "post",
        apiKey,
        {
          query: `query { employees(ids: [\"${employeesToFetch.join('\",\"')}\"]) {
            ${peopleSchemaName.join("\n ")} }}`
        }
      );
      yield put(
        multiPeopleInfoActions.success(
          peopleIds,
          response?.data?.employees
        ),
      );
    } else {
      multiPeopleInfoActions.success(
        [],
        []
      )
    }

    
  } catch (error: any) {
    const message = error.message || error.errorText || error;
    yield put(multiPeopleInfoActions.failure(peopleIds, message));
  }
}

export function* watchPeopleEvent(): SagaIterator {
  yield takeEvery(PEOPLE_API.REQUEST, performRequestOperation);
  yield takeEvery(MULTI_PEOPLE_API.REQUEST, performMultiRequestOperation);
}
