import classNames from 'classnames';
import SvgIcon, { SVGType } from 'components/SvgIcon';

import "./FormInput.scss";
import FormLabel from './FormLabel';

export function FormInput({ input, ...props }: any) {
  const { showAppliedFilterIcon, label, baseClassName, inputClassName, icon = null, meta, roundedCorner, required, ...restProps } = props;
  const { value } = input;

  return (
    <div className={classNames([baseClassName, "position-relative"])}>
      <FormLabel label={label} required={required}/>
      <div className='position-relative'>
          <input className={classNames(["customInput", inputClassName, {"rounded-corner": roundedCorner, "no-icon": !icon}])} {...input} {...restProps} value={value} />
          {icon && <SvgIcon svgType={SVGType.CUSTOM} name={icon} baseclassname="text-lg text-primary-color input-icon" size='small' />}
          {meta?.error && meta?.touched && <div className="text-danger-color text-sm padding-l-4">{meta?.error}</div>}
      </div>
    </div>
  )
}