export const client = {
    Home: "/",
    SearchPaff:"/searchPaaf",
    ChangeReport:"/changeReport",
    ArchiveReport:"/archiveReport",
    PaafUser:"/paafUser",
    // AccessHierachy:"/accessHierachy",
    PracticeAreaTopic:"/practiceAreaTopic",
    EditAffiliations: "/editAffiliations",
    NotAllowed: "/not-allowed"
}
