import { Modal } from "semantic-ui-react";
import CustomButton from "components/CustomButton";
import DNDSorting from "components/DNDSorting";

import { ModalHeader } from "../Common";
import { useEffect, useState } from "react";
import { IDNDCard } from "components/DNDSorting/ICard";

import "./ToggleColumnModal.scss";
import { IColumSequence } from "interface";

interface Props {
  hideModal: () => void;
  onClose: () => void;
  onSave: (cards: IColumSequence) => void;
  headers: Array<{
    id: string,
    label: string;
  }>;
  selectedHeaders: {[key: string]: boolean};
  disableHeaders: Array<string>;
  title: string;
}

function ToggleColumnModal(props: Props) {
  const {
    hideModal,
    headers,
    disableHeaders,
    selectedHeaders,
    title
  } = props;

  const [cards, setCards] = useState<IDNDCard[]>([]);


  useEffect(() => {
    const transformCards: IDNDCard[] = [];
    headers.forEach(({id, label}) => {
      const isDisable: boolean = disableHeaders?.findIndex(item => item === id) >= 0;
      transformCards.push({
        id,
        label,
        isSelected: isDisable ? true : !!(selectedHeaders && selectedHeaders[id]),
        isDisable
      })
    });
    setCards(transformCards);
  }, [headers]);


  const handleSave = () => {
    let formatedCards: IColumSequence  = {sequence: [], showHide: {}};
    cards.forEach(({id, isSelected }: IDNDCard) => {
      formatedCards.sequence.push(id);
      formatedCards.showHide[id] = isSelected
    })
    props.onSave(formatedCards);
  }


  return (
    <Modal
      size="tiny"
      dimmer
      open
      onClose={hideModal}
      closeOnEscape={false}
      closeOnDimmerClick={false}
      className="toggle-column-modal"
    >
      <ModalHeader title={title} toggle={hideModal} />
      <Modal.Content>
        <div className="margin-b-8">
          <DNDSorting cards={cards} setCards={setCards}/>
        </div>
      </Modal.Content>
      <Modal.Actions>
        <CustomButton primaryButton buttonText="Apply & Save" handleClick={handleSave} />
      </Modal.Actions>
    </Modal>
  )
}

export default ToggleColumnModal;