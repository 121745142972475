import React from 'react'
import { Dropdown } from 'semantic-ui-react'
import className from "classnames";
import { useNavigate } from 'react-router-dom';
import SvgIcon, { CUSTOM_SVG_ICON, SVGType } from 'components/SvgIcon';
import { setHeaderAndSubheaderClick } from 'Analytics';
import { HEADRES, SUB_HEADERS } from './Header';

export interface IItemMenuOptions {
  text: SUB_HEADERS;
  outsideApp: boolean;
  to: string;
}


export interface Props {
  label: HEADRES;
  options: Array<IItemMenuOptions>;
  id: string;
}

const ItemWithPopup = ({ label, options, id }: Props) => {
  const navigate = useNavigate();

  const handleNavigation = ({ outsideApp, to, text }: IItemMenuOptions) => {
    setHeaderAndSubheaderClick(label, text);
    if (!outsideApp) {
      navigate(to);
    } else {
      window.open(to, "_blank");
    }
  }

  const renderMenuItem = (item: IItemMenuOptions, index: number) => (
    <Dropdown.Item key={`Link${index}`} onClick={() => handleNavigation(item)} text={item.text} />
  )

  return (
    <Dropdown
      id={id}
      icon={<SvgIcon svgType={SVGType.CUSTOM} name={CUSTOM_SVG_ICON.CaretDown} size="small" />}
      className={className(["itemWithPopup padding-t-7 padding-r-6 padding-l-6 height-100"])}
      text={label}
      onOpen={() => {
        setHeaderAndSubheaderClick(label);
      }}
    >
      <Dropdown.Menu>
        {options.map(renderMenuItem)}
      </Dropdown.Menu>
    </Dropdown>
  )
}

export default ItemWithPopup