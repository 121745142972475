import { forwardRef, MutableRefObject, useImperativeHandle, useRef } from "react";
import { IColumn, IGrouping, ISortState } from "./IReactTable";
import HeaderCell from "./HeaderCell";
interface Props {
  grouping: IGrouping;
  direction: "left" | "right" | "center";
  showFilters: boolean;
  toggleFilters: () => void;
  handleSortChange: (key: string) => void;
  handleFilterChange: (key: string, value: string) => void;
  sortState: ISortState;
  defaultFilterState: any;
}

function Header(props: Props, ref: any) {
  const { direction, grouping: { headers, totalWidthStr, groupingWidth }, showFilters, toggleFilters, handleSortChange, handleFilterChange, sortState, defaultFilterState } = props;
  const headerRef: MutableRefObject<HTMLDivElement> = useRef(undefined!);
  useImperativeHandle(ref, () => {
    return {
      leftScroll: (leftScroll: number) => {
        if(headerRef?.current) {
          headerRef.current.style.transform = `translateX(-${leftScroll}px)`
        }
      }
    }
  }, []);

  const renderHeader = (header:IColumn, index: number) => {
    const key = `header-cell-${direction}-${index}`;
    return <HeaderCell key={key} direction={direction} header={header} groupingWidth={groupingWidth}  handleSortChange={handleSortChange}
      showFilters={showFilters} sortState={sortState}  index={index} toggleFilters={toggleFilters} handleFilterChange={handleFilterChange}
      defaultFilterState={defaultFilterState}
    />
  }

  return (
    <div style={{width: totalWidthStr, overflow: "hidden"}} id={`header-${direction}-container`}>
      {
        !!headers.length && <div className="flex flex-row height-100" id={`header-${direction}`} style={{width: groupingWidth["originalWidth"]}} ref={headerRef}>
            {
              headers.map(renderHeader)
            }
        </div>
      }
    </div>
  )
}

export default forwardRef(Header);