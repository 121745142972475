import * as React from "react";
import "./styles.scss";
export default ({labelTxt, checked, handleChange, partialChecked, baseClassName="", disabled}: any) => {
    return (
        <label className={`reactCustomCheckbox ${baseClassName} ${disabled ? "disbaled" : ""}`}>
            <input type="checkbox" checked={checked} disabled={disabled} onChange={(event) => {
                if(!disabled) {
                    handleChange(event);
                    event.stopPropagation();
                }
            }}/>
            <span className={`checkmark ${!checked && partialChecked ? "partialChecked" : ""}`}>
                {
                  partialChecked && <div className="partialCheckContainer"/>  
                }
            </span>
            {labelTxt}
        </label>
    )
}