import SvgIcon, { CUSTOM_SVG_ICON, SVGType } from "components/SvgIcon";
import moment from "moment";
import { useMemo } from "react";

function Footer() {

  const currentYear = useMemo(() => {
    return moment().format("YYYY");
  }, [])

  return (
    <footer className="padding-l-10 padding-r-10">
      <div className="flex flex-column padding-t-8 padding-b-8 border-top border-grey-shade-11">
        <div className="flex flex-row flex-justify-between flex-align-center">
          <div><SvgIcon svgType={SVGType.CUSTOM} name={CUSTOM_SVG_ICON.BcgLogo} /></div>
          <div className=" flex flex-row  ">
            {/* <NavLink className="menu-link text-secondary-color text-md padding-r-6" to="">Privacy</NavLink>
            <NavLink className="menu-link  text-secondary-color text-md  padding-r-6" to="">Terms of Service</NavLink> */}
            <div className="text-secondary-color text-md ">© {currentYear} Boston Consulting Group</div>
          </div>
        </div>
        <div className="padding-t-7 text-sm text-grey-shade-2">
          Boston Consulting Group is an Equal Opportunity Employer. All qualified applicants will receive consideration
          for employment without regard to race, color, age, religion, sex, sexual orientation, gender identity / expression,
          national origin, protected veteran status, or any other characteristic protected under federal, state or local law,
          where applicable, and those with criminal histories will be considered in a manner consistent with applicable state
          and local laws.
        </div>
      </div>
    </footer>
  );
}

export default Footer;