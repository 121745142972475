import { fetchRequest, getFullUrl } from "Api";
import CustomButton from "components/CustomButton";
import { Fragment, MutableRefObject, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { getBaseUrl, getBaseUrlAPIKEY, getToken } from "store/selectors";

interface Props {
  url: string;
  urlParams: any;
  method: any;
  queryParams: any;
  body: any;
  filename: string;
  handleClick: any;
}

function Download({
  url, method, queryParams, body, urlParams, filename, handleClick
}: Props) {
  const downloadRef: MutableRefObject<HTMLAnchorElement> = useRef(null!);
  const token = useSelector(getToken);
  const baseUrl = useSelector(getBaseUrl(false));
  const apiKey = useSelector(getBaseUrlAPIKEY(false));
  const [fetching, setFetching] = useState<boolean>(false);

  const handleDownload = async () => {
    if (!fetching) {
      if( typeof handleClick === "function") {
        handleClick();
      }
      try {
        setFetching(true);
        const response = await fetchRequest(getFullUrl(baseUrl, url, { urlParams, queryParams }), token, method, apiKey, body);
        const blob: Blob = await response.blob();
        if(downloadRef?.current) {
          downloadRef.current.href =  window.encodeURI(URL.createObjectURL(blob));
          downloadRef.current.download = filename;
          downloadRef.current.click();
        }
        setFetching(false);
      } catch (error: any) {
        setFetching(false);
        toast.error(error.message)
      }
    }
  }

  return (
    <Fragment>
      <CustomButton
        primaryButton
        round
        buttonText="Download"
        loading={fetching}
        handleClick={handleDownload} />
      <a ref={downloadRef}></a>
    </Fragment>
  )
}

export default Download;