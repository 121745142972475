import { IOption } from "components/TreeDropdown";
import { City, IColumSequence, Region } from "interface";
import URLRoutes from "urlRoutes";
import { convertArrayToSmartOptions, sortOptions, transformOptions } from "utils";
import { Feature } from "utils/Features/Features";
import { archiveReports, toggleColumnRext, getActiveAffiliations, getAffiliation, getAllRoles, getCarrerStep, getCarrerTrack,
  getFeatures, getGlobalTitle, getLeadAffiliations, getLocations, getPracticeArea, getPracticeAreaForUser, searchBCGUsers,
  userRoleRext, 
  practiceTopicsPermissionsRext,
  getSnoFlakeRegions,
  getOrganization,
  getChort,
  getJobTitles} from "./rext";

export function getActiveAffiliationsRequest() {
  return getActiveAffiliations.request({ method: "get", url: URLRoutes.server.getAffiliations, urlParams: {
    AFFILIATIONS_TYPE: "ACTIVE"
  } });
}

export function getLeadAffiliationsRequest() {
  return getLeadAffiliations.request({ method: "get", url: URLRoutes.server.getAffiliations, urlParams: {
    AFFILIATIONS_TYPE: "LEAD"
  } });
}

const recursiveAdd = (mappingIds: any, recursiveItem: Array<any>, recursiveItemIndex: number, recursiveItemRaw: any, rootId: string) => {
  const indexItem = recursiveItem[recursiveItemIndex];
  if(indexItem) {
    if(!recursiveItemRaw[indexItem.id]) {
      const hasNextItem = recursiveItem[recursiveItemIndex +1] ? true : false;
      const locationName = indexItem.name.replace("(ALL)", "");
      recursiveItemRaw[indexItem.id] = {
        id: hasNextItem? indexItem.id : rootId,
        value: locationName,
        childrens: {}
      }
      mappingIds[hasNextItem? indexItem.id : rootId] = locationName;
    }
    recursiveItemIndex++;
    recursiveAdd(mappingIds, recursiveItem, recursiveItemIndex, recursiveItemRaw[indexItem.id].childrens, rootId);
  } else {
    return recursiveItemRaw;
  }
}

const deleteOrFormatChildrens = (formatItemitem:any) => {
  if(formatItemitem?.childrens) {
    const childValues = Object.values(formatItemitem?.childrens);
    if(childValues.length) {
      formatItemitem["childrens"] = sortOptions(childValues, "value");
      childValues.forEach(childItem => {
        deleteOrFormatChildrens(childItem)
      })
    } else {
      delete formatItemitem["childrens"]
    }
  }
}

export function getLocationsRequest() {
  let header = new Headers();
  header.set('psid', '');
  return getLocations.request({ method: "post", url: URLRoutes.server.location, body: {"query":"{ locations { id name regionId street   hierarchies { id name type level  }}}","variables":{}}, headers: header }, {
    transformation: (item: {locations: Array<any>}) => {
      const {locations} = item || {};
      const mappingIds: any = {};
      const raw: any = {};
      locations.forEach((locationItem) => {
        const { hierarchies, id } = locationItem;
        const sortedHirerarchy = sortOptions(hierarchies, "level").reverse();
        recursiveAdd(mappingIds, sortedHirerarchy, 0, raw, id);
      })

    
      deleteOrFormatChildrens({
        id: "demo",
        label: "",
        childrens: raw
      })

      let values: Array<any> = [];

      Object.keys(raw).forEach((rawItem: any) => {
        if(raw[rawItem]?.childrens?.length) {
          values = [
            ...values,
            ...raw[rawItem].childrens.filter((childItem: any) => childItem.value.trim() !== "CEMA")
          ]
        }
      })
      return {dropdown: values, mappingIds}
    }
  });
}

export function getSnowFlakeRegionRequest() {
  return getSnoFlakeRegions.request({ method: "get", url: URLRoutes.server.snowflakeRegion }, {
    transformation: (item: any) => {
    const transformedData = transformEmptyData(item);
    const tranformed = transformRegionObject(transformedData.data);
      return tranformed;
    }
  });
}

function transformEmptyData(apiResponse: any) {
  const transformedData = { data: {} };

  function recursiveTransform(input: any, output: any) {
    for (const key in input) {
      if (key !== 'EMPTY') {
        if (typeof input[key] === 'object' && Object.keys(input[key]).length > 0) {
          output[key] = {};
          recursiveTransform(input[key], output[key]);
        } else {
          output[key] = input[key];
        }
      } else {
        // Flatten only the data inside the "EMPTY" key
        for (const emptyKey in input[key]) {
          output[input[key][emptyKey]] = { [emptyKey]: input[key][emptyKey], isEmpty: true };
        }
      }
    }
  }

  recursiveTransform(apiResponse, transformedData.data);

  return transformedData;
}


function transformRegionObject(dataObject: Record<string, Record<string, Record<string, any>>>): any {
  const result: Region[] = [];
  const mappingIds: Record<string, string> = {};

  for (const [regionKey, regionValue] of Object.entries(dataObject)) {
    const region: Region = {
      id: regionKey,
      value: regionKey,
      childrens: [],
    };

    for (const [subRegionKey, subRegionValue] of Object.entries(regionValue)) {
      const subRegion: City = {
        id: subRegionKey,
        value: subRegionKey,
        childrens: [],
      };

      for (const [locationKey, locationValue] of Object.entries(subRegionValue)) {
        let location: any  = {
            id: locationKey,
            value: locationKey,
            childrens: [],
          };
          if(locationValue.isEmpty !== undefined || locationValue?.isEmpty) {
            location  = {
              id: `${locationKey}_${Object.keys(locationValue)[0]}`,
              value: locationKey,
              childrens: [],
            };
          }

        for (const [cityKey, cityName] of Object.entries(locationValue)) {
          if(locationValue.isEmpty == undefined || !locationValue?.isEmpty) {
            const city: any = {
              id: `${cityName}_${cityKey}`,
              value: cityName,
            };
            location.childrens?.push(city);
            mappingIds[cityKey] = `${cityName}_${cityKey}`;
          } else {
            location.childrens = null;
          }
        }

        subRegion.childrens?.push(location);
        mappingIds[locationKey] = locationKey;
      }

      region.childrens?.push(subRegion);
      mappingIds[subRegionKey] = subRegionKey;
    }

    result.push(region);
    mappingIds[regionKey] = regionKey;
  }

  return { dropdown: result, mappingIds };
}

export function getOrganizationRequest() {
  return getOrganization.request({ method: "get", url: URLRoutes.server.snowflakeOrganization}, {
    transformation: (item: any) =>  {
      const options: any = {};
      item.forEach((raw: any) => {
        options[raw["id"]] = raw["label"];
      })
      return options;
    }
  });
}

export function getGlobalTitleRequest() {
  let header = new Headers();
    header.set('psid', '');
  return getGlobalTitle.request({
    method: "post", url: URLRoutes.server.people, body: {
      "query": "query { jobTitles(dataSet:BCG_ALL) }",
      "variables": {}
    },
    headers: header
  }, {
    transformation: (item: any) => convertArrayToSmartOptions(item.jobTitles)
  });
}

//Gobal Title New API
export function getUserGlobalTitleRequest() {
  return getJobTitles.request({ method: "get", url: URLRoutes.server.getGlobalTitles, urlParams: { }},{
    transformation: (item: any) => transformOptions(sortOptions(item, "label"), "id", "label")
  });
}

export function getCarrerStepRequest() {
  return getCarrerStep.request({ method: "get", url: URLRoutes.server.getOptions, urlParams: { optionId: "CAREERSTEP"}},{
    transformation: (item: any) => transformOptions(sortOptions(item, "label"), "id", "label")
  });
}

export function getCarrerTrackRequest() {
  return getCarrerTrack.request({ method: "get", url: URLRoutes.server.getOptions, urlParams: { optionId: "CAREERTRACK"}},{
    transformation: (item: any) => transformOptions(sortOptions(item, "label"), "id", "label")
  });
}

export function getPracticeAreaRequest() {
  return getPracticeArea.request({ method: "get", url: URLRoutes.server.getPracticeArea},
  {
    transformation: transformTaxanomy
  });
}

export function getPracticeAreaForUserRequest() {
  return getPracticeAreaForUser.request({ method: "get", url: URLRoutes.server.getPracticeAreaForUser},
  {
  transformation: transformTaxanomy
});
}

export function getAffiliationRequest() {
  const levelLabels: any = {
    "INTERESTED":"INTERESTED",
    "ACTIVE": "ACTIVE",
    "LEAD": "LEAD",
    "NOT_AFFILIATED": ""
  }
  return getAffiliation.request({ method: "get", url: URLRoutes.server.getAffiliationLevel}, {
    transformation: (item: any) =>  {
      const options: any = {};
      item.forEach((raw: any) => {
        options[raw["id"]] = levelLabels[raw["level"]];
      })
      return options;
    }
  });
}

export function getFeaturesRequest() {
  return getFeatures.request({ method: "get", url: URLRoutes.server.features}, {
    transformation: (item: any) =>  new Feature(item.features || [])
  })
}

export function getAllRolesRequest() {
  return getAllRoles.request({ method: "get", url: URLRoutes.server.allRoles}, {
    transformation: (item: any) =>  item.roles
  })
}

export function getAllBCGUsersByName(searchText: string) {
  let header = new Headers();
  header.set('psid', '');
  return searchBCGUsers.request({
    method: "post", url: URLRoutes.server.people, body: {
      query: `{
          searchFilter (aliasMatching:["title"] 
          compositeFilter: {logicOperator: AND 
          subFilters: [
          { logicOperator: OR  subFilters: [   
          { valueFilter: { field: "preferredName=*${searchText}*" } } 
          { valueFilter: { field: "preferredFirstName=*${searchText}*" } } 
          { valueFilter: { field: "preferredLastName=*${searchText}*" } } 
          ]	}
          ]} 
          resultFilters:[ "jobPosition", "hostOfficeId", "hostOfficeRegion", "alumni"] dataSet:BCG_ALL limit: 50 offset:0){ 
          employees{ 
            id preferredName businessTitle profilePicture
          }
        }}`,
      variables: {}
    },
    headers: header,
  }, {
    transformation: (item: any) => item?.searchFilter?.employees
  })
}

export function getAllArchiveReports(queryParams: any) {
  return archiveReports.request({ method: "get", url: URLRoutes.server.archiveReports, queryParams})
}

export function getToggleColumns(gridType: string){
  return toggleColumnRext.request({
    method:"get",
    url:URLRoutes.server.toggleChangeReports,
    urlParams: {
      gridType
    }
  })
}

export function updateToggleColumns(gridType: string, body: IColumSequence) {
  return toggleColumnRext.update({
    method: "put",
    url: URLRoutes.server.toggleChangeReports,
    urlParams: {
      gridType
    },
    body
  })
}

export function getUserRolesRequest(userId: string) {
  return userRoleRext.request({
    method: "get",
    url: URLRoutes.server.userRoles,
    urlParams: {
      userId
    }
  })
}


export function fetchPracticeTopicPermissionMapping() {
  return practiceTopicsPermissionsRext.request({
    method: "get",
    url: URLRoutes.server.topicPracticePermission
  })
}

function transformTaxanomy(items: Array<any>) {
  const mappingIds: any = {};
  const options: IOption[] = [
    {
      id: "Functional Practice Area",
      value: "Functional Practice Area",
      childrens: [],
      hideCheckbox: true
    },
    {
      id: "Industry Practice Area",
      value: "Industry Practice Area",
      childrens: [],
      hideCheckbox: true
    }
  ]

  items= sortOptions(items, "bstLabel");

  const formatItem = (item:any): IOption => {
    const option:IOption ={
      value: item.topicShortCode ? `${item.bstLabel} - ${item.topicShortCode}` : item.bstLabel,
      id: item.topicId || item.bstGuid,
      disableItem: item.affiliated,
      raw: item.bstGuid
    }
    mappingIds[item.topicId] = item.bstLabel;

    if(item.child && item.child.length) {
      item.child= sortOptions(item.child, "bstLabel");
      option.childrens = item.child.map(formatItem)
    }
    return option;
  }


  items.forEach((item: any) => {
    if(item.topicType === "Functional Practice Area") {
      options[0].childrens?.push(formatItem(item))
    } else {
      options[1].childrens?.push(formatItem(item))
    }
  })
  return {dropdown: options, mappingIds}
}

export function getCohortRequest() {
  return getChort.request({ method: "get", url: URLRoutes.server.cohortDropdownUrl}, {
    transformation: (item: any) =>  {
      const options: any = {};
      item.forEach((raw: any) => {
        options[raw["id"]] = raw["label"];
      })
      return options;
    }
  });
}