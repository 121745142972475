import { Progress, Modal } from 'semantic-ui-react'
// import xlsx from "json-as-xlsx";

import * as XLSX from 'xlsx';

import { callApiWithRetries, getFullUrl } from "Api";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { peopleSchemaName } from "store/saga/people";
import { getBaseUrl, getBaseUrlAPIKEY, getToken } from "store/selectors";
import URLRoutes from "urlRoutes";
import { IExcelCell } from 'interface';
import { convertArrayInChunks, getRowValue } from 'utils';

const MAX_AFFILIATION_CHUNK: number = 5000;
const MAX_PEOPLE_CHUNK: number = 5000;

interface Props {
  hideModal: () => void;
  onClose: () => void;
  onSave: () => void;
  title: string;
  url: string;
  queryParams: any;
  urlParams: any;
  excelCells: IExcelCell[];
  peopleApiKeys: Array<string>;
  fileName: string;
  responseKey: string;
  body?: any;
  totalElements: number;
  chunksToDownload?: number;
  reportPayload?: any;
  transformation?: (reportData: Array<any>, rawPeople: any) => Array<any>
}

function ExportToExcel(props: Props) {
  const {
    queryParams,
    urlParams,
    excelCells,
    hideModal,
    url,
    fileName,
    peopleApiKeys,
    responseKey,
    body,
    totalElements,
    transformation,
    chunksToDownload,
    reportPayload
  } = props;
  const token = useSelector(getToken);
  const baseUrl = useSelector(getBaseUrl(false));
  const baseXApi = useSelector(getBaseUrlAPIKEY(false));
  const peopleBaseUrl = useSelector(getBaseUrl(true));
  const peopleBaseXApi = useSelector(getBaseUrlAPIKEY(true));
  const [stepsComplete, setStepsComplete] = useState<number>(0);
  const [maxSteps, setMaxSteps] = useState<number>(1000);
  const [message, setMessage] = useState<string>("");
  const fetchData = (pageNo: number): Promise<any> => {
    return new Promise((resolve: any, reject: any) => {
      callApiWithRetries(3, getFullUrl(baseUrl, `${url}`, {
        queryParams: { ...queryParams, pageNo, pageSize: chunksToDownload || MAX_AFFILIATION_CHUNK },
        urlParams
      }), token, body ? "post" : "get", baseXApi, body)
        .then(data => {
          setStepsComplete(value => {
            return value + 1;
          });
          resolve(data.data);
        }).catch(error => reject(error))
    });
  }

  const fetchPeopleData = (peopleIds: string[]): Promise<any> => {
    const payload = { ...reportPayload, hrId: peopleIds };
    return new Promise((resolve: any, reject: any) => {
      callApiWithRetries(3, getFullUrl(baseUrl, `${URLRoutes.server.employeeData}`, {}), token, "post", baseXApi, payload)
        .then(data => {
          setStepsComplete(value => Math.round(value + 1));
          resolve(data);
        }).catch(error => reject(error))
    });
  }

  const getValueForId = (isPeople: boolean, id: string, peopleData: any, item: any, getValue: any) => {
    if (getValue) {
      return getValue(peopleData, item);
    }

    if (isPeople) {
      return getRowValue(peopleData && peopleData[id]);
    } else {
      return getRowValue(item[id]);
    }
  }


  const exportDataToExcel = (reportData: Array<any>, rawPeople: any) => {
    const data = transformation ? transformation(reportData, rawPeople) : reportData.map((item, index) => {
      const excelRow: any = {};

      excelCells.forEach(({ id, isPeople, hridKey, label, getValue }) => {
        const peopleData = isPeople ? rawPeople[item[hridKey || "hrId"]] : {};
        const excelCellValue = getValueForId(isPeople!, id, peopleData, item, getValue);
        // Enable the below for CSV format
        excelRow[label] = excelCellValue;
        // Enable the below for XLSX format
        // excelRow[id] = excelCellValue;
      })
      return excelRow
    })
    // Enable for CSV Format
    const finalData = data.filter((employee: any) => employee['First Name'] !== '' || employee['HR ID'] !== '' || employee['Email ID'] !== '');
    // Enable the below for XLSX format
    // const finalData = data.filter((employee: any) => employee['preferredFirstName'] !== '' || employee['hrId'] !== '' || employee['email'] !== '');
    if (process.env.REACT_APP_TEST_EXPORT) {
      console.log('affiliation Data, snoflake Data', reportData.length, Object.keys(rawPeople).length)
      console.log('original-data, filtered-data', data, finalData, excelCells)
      // console.log(index + 2, item, rawPeople[item["hrId"]]);
    }

    // Enable for CSV Format
    const workBook = XLSX.utils.book_new(); // create a new blank book
    const workSheet = XLSX.utils.json_to_sheet(finalData);

    XLSX.utils.book_append_sheet(workBook, workSheet, fileName); // add the worksheet to the book
    XLSX.writeFile(workBook, `${fileName}${new Date().getTime()}.csv`, { bookType: 'csv' });
    setMessage("Download Complete");
    setStepsComplete(value => {
      return value + 1;
    });
    setTimeout(() => {
      hideModal();
    }, 1000)

    //  xlsx([{
    //     sheet: fileName,
    //     columns: excelCells.map(({ id, label}) => ({
    //       label,
    //       value: id
    //     })),
    //     content: finalData
    //   }],
    //   {
    //     fileName: `${fileName}${new Date().getTime()}`, // Name of the resulting spreadsheet
    //     // extraLength: 3, // A bigger number means that columns will be wider
    //     writeMode: "writeFile", // The available parameters are 'WriteFile' and 'write'. This setting is optional. Useful in such cases https://docs.sheetjs.com/docs/solutions/output#example-remote-file
    //     writeOptions: {}, // Style options from https://docs.sheetjs.com/docs/api/write-options
    //     RTL: false, // Display the columns from right-to-left (the default value is false)
    //   }, () => {
    //     setMessage("Download Complete");
    //     setStepsComplete(value => {
    //       return value+1;
    //     });
    //     setTimeout(() => {
    //       hideModal();
    //     }, 1000)
    //   })
  }

  const fetchAllPeopleData = async (peopleIds: string[]): Promise<any> => {
    setMessage("Fetching Data For People");
    const mergePeoples: any = {};
    const chunks: Array<any> = convertArrayInChunks(peopleIds, MAX_PEOPLE_CHUNK);
    setMaxSteps(chunks.length);
    setStepsComplete(0);
    for (const peopleChunk of chunks) {
      try {
        const employees = await fetchPeopleData(peopleChunk);
        employees?.forEach((employee: any) => {
          mergePeoples[employee.hrId] = employee;
        })
      } catch (error) {
        setStepsComplete(value => {
          return value + 1;
        });
      }
    }
    return mergePeoples;
  }



  const fetchAffiliationsData = async (): Promise<any> => {
    setMessage("Fetching Data From Affiliations");
    const numberOfPages: number = Math.ceil(totalElements / (chunksToDownload || MAX_AFFILIATION_CHUNK)) - 1;
    setMaxSteps(numberOfPages); // 1000 is addedso that we can have unique calculation in percentage
    setStepsComplete(0);

    const pageNumbersArray = [...new Array(numberOfPages + 1)].map((item, index) => index);
    const allPeopleIds: any = {};
    let allResponseData: Array<any> = [];
    for (const pageNo of pageNumbersArray) {
      try {
        const response = await fetchData(pageNo);
        const responseData = responseKey ? response[responseKey] : response;
        allResponseData = [...allResponseData, ...responseData];

        responseData.forEach((item: any) => {
          peopleApiKeys.forEach(peopleKey => {
            allPeopleIds[item[peopleKey]] = true;
          })
        });

      } catch (error) {
        setStepsComplete(value => {
          return value + 1;
        });
      }
    }
    return { allResponseData, allPeopleIds };
  }

  const fetchAllData = async () => {
    try {
      const { allResponseData, allPeopleIds } = await fetchAffiliationsData();
      const peoplesData = await fetchAllPeopleData(Object.keys(allPeopleIds));
      setMessage("Creating wait for 2-3 minutes max");
      exportDataToExcel(allResponseData, peoplesData);
    } catch (error: any) {
      console.log(error);
      toast.error(error.message || error || "Please try again.");
      hideModal();
    }
  }

  useEffect(() => {
    fetchAllData();
  }, []);

  return (
    <Modal
      size="mini"
      dimmer
      open
      onClose={hideModal}
      closeOnEscape={false}
      closeOnDimmerClick={false}
      className="delete-modal"
    >
      {/* <ModalHeader title={title} toggle={hideModal} /> */}
      <Modal.Content>
        <div className='padding-b-4 width-100'>
          <Progress percent={Math.round((stepsComplete / maxSteps) * 100)} progress='percent'>{
            message
          }</Progress>
        </div>
      </Modal.Content>
    </Modal>
  )
}

export default ExportToExcel;
