import update from 'immutability-helper';
import { FC, useMemo, useCallback } from 'react';
import Checkbox from "components/CheckboxLabel";

import { Card } from './Card'
import { IDNDCard } from './ICard';

interface Props {
  cards: IDNDCard[];
  setCards: any;
}

export const Container: FC<Props> = ({ cards, setCards }) => {
    const moveCard = useCallback((dragIndex: number, hoverIndex: number) => {
      setCards((prevCards: IDNDCard[]) =>
        update(prevCards, {
          $splice: [
            [dragIndex, 1],
            [hoverIndex, 0, prevCards[dragIndex]],
          ],
        }),
      )
    }, [])

    const renderCard = useCallback(
      (card: IDNDCard, index: number) => {
        return (
          <Card
            key={card.id}
            index={index}
            id={card.id}
            text={card.label}
            isDisable={!!card.isDisable}
            handleCheckbox={(name: string, checked: boolean, checkBoxindex: number) => {
              if(!card.isDisable) {
                setCards(update(cards, { [checkBoxindex]: { isSelected: { $set: checked } } }));
              }
            }}
            isSelected={card.isSelected}
            moveCard={moveCard}
          />
        )
      },
      [cards],
    )

    const allCardSelected = useMemo(() => {
      return cards.every(card => card.isSelected)
    }, [cards]);

    const updateAllCards = () => {
      cards.forEach(card => {
        if(!card.isDisable) {
          card.isSelected = !allCardSelected;
        }
      })
      setCards([...cards]);
    }


    return (
      <div className='width-100 height-100'>
        <Checkbox labelTxt={"Select All"} checked={allCardSelected} handleChange={updateAllCards}/>
        {cards.map((card, i) => renderCard(card, i))}
      </div>
    )
  }
