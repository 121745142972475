import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { Container } from "./DNDSorting";
import { IDNDCard } from "./ICard";


import "./DNDSorting.scss";

interface Props {
  cards: IDNDCard[];
  setCards: any;
}


function DNDSorting({cards, setCards}: Props) {
  return (
    <DndProvider backend={HTML5Backend}>
		<Container cards={cards} setCards={setCards}/>
	</DndProvider>
  )
}

export default DNDSorting;