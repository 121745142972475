import { forwardRef, useEffect, useState, useImperativeHandle, useRef } from "react";
import { ICheckedOptions, IOption, ReactTreeDropdownProps, ReactTreeNode } from "./interface";
import { findNode, getCheckedOptionState, getInitializeCheckOptions, getSelectedOptions, getShowHideNodes } from "./utils";
import "./styles.scss";
import DirectComponent from "./DirectComponent";
import PopupComponent from "./PopupComponent";

const ReactTreeDropdown = forwardRef((props: ReactTreeDropdownProps, ref) => {
  const { initializeValue, isDirectComponent, dropdownOptions, hideAllNodes, handleValueChange, inputProps,
    isMulti, isSearchable = true, placeholder = "Practice Area", isLoading, disableBackwardSelection, disableForwardSelection, searchPlaceholder } = props;
  const [checkedOptions, setCheckedOptions] = useState<ICheckedOptions>({});
  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);
  const [searchTxt, setSearchTxt] = useState<string>("");
  const [showHideNodes, setShowHideNodes] = useState<{ [key: string]: boolean }>({});
  const dropdownRef = useRef<HTMLDivElement>(null);
  const [hirerarchy, setHirerarchy] = useState<ReactTreeNode[]>([]);
  const [dropdownWidth, setDropdownWidth] = useState<number>(0);

  useEffect(() => {
    setDropdownWidth(dropdownRef.current?.clientWidth || 0);
  }, [dropdownRef.current?.clientWidth, hirerarchy])


  useEffect(() => {
    if (dropdownOptions && dropdownOptions.length >= 1 && initializeValue && initializeValue.length >= 1) {
      const valueNodes: any = {};
      (dropdownOptions || []).forEach((node) => {
        findNode(node, undefined!, initializeValue, valueNodes, disableBackwardSelection!, disableForwardSelection!);
      });
      intializeCheckOptionState(Object.values(valueNodes))
    }
  }, [dropdownOptions, initializeValue]);


  useEffect(() => {
    setShowHideNodes(getShowHideNodes(searchTxt, dropdownOptions));
  }, [searchTxt]);


  useImperativeHandle(ref, () => ({
    removeSelectedOption(id: string) {
      handleCrossClicked(undefined, id)
    },
    clearAllValues() {
      clearAllItem()
    },
    forceResetToOptions(values: Array<any>) {
      const valueNodes: any = {};
      (dropdownOptions || []).forEach((node) => {
        findNode(node, undefined!, values, valueNodes, disableBackwardSelection!, disableForwardSelection!);
      });

      intializeCheckOptionState(Object.values(valueNodes), true);
    }
  }));

  const intializeCheckOptionState = (node: ReactTreeNode[], empty?: boolean) => {
    let cloneCheckOptions = getInitializeCheckOptions(empty!, checkedOptions, node, disableForwardSelection!, disableBackwardSelection!);
    handleValueChange(tranformSelectedOptions(cloneCheckOptions));
    setCheckedOptions(cloneCheckOptions);
  }

  const tranformSelectedOptions = (selectedOptions: ICheckedOptions): Array<string> => {
    const markChecked = getSelectedOptions(
      Object.keys(selectedOptions),
      Object.assign({}, selectedOptions),
      false,
      hideAllNodes
    )
    return Object.values(markChecked).map(({ node: { node } }: any) => node.id);
  }

  const handleOptionChange = (checked: boolean, option: IOption, options: ReactTreeNode) => {

    let tmpCheckOptionsState = getCheckedOptionState(isMulti!, checked, checkedOptions, option, options, disableForwardSelection!, disableBackwardSelection!);
    setCheckedOptions(tmpCheckOptionsState);
    handleValueChange(tranformSelectedOptions(tmpCheckOptionsState));

    if (!isMulti) {
      toggleMenuState(false);
      setHirerarchy([]);
    }
  };

  const toggleMenuState = (state: boolean) => {
    setIsMenuOpen(state)
    if (state) {
      setHirerarchy([]);
    }
    if (!state) {
      setSearchTxt("");
    }
  }

  const handleCrossClicked = (event: any, id: string) => {
    if (checkedOptions[id]) {
      const { node } = checkedOptions[id];
      handleOptionChange(false, node.node, node.parent);
      event?.stopPropagation();
    }
  }

  const clearAllItem = (event?: any) => {
    setCheckedOptions({});
    handleValueChange([]);
    event?.stopPropagation();
  }

  const handleSearchClear = (event: any) => {
    setSearchTxt("");
  }

  const isSearchActive: boolean = !!(searchTxt?.length);

  return (
    isDirectComponent ?
      <DirectComponent
        isSearchActive={isSearchActive}
        handleSearchClear={handleSearchClear}
        searchPlaceholder={searchPlaceholder}
        searchTxt={searchTxt}
        setSearchTxt={setSearchTxt}
        isLoading={isLoading}
        dropdownOptions={dropdownOptions}
        checkedOptions={checkedOptions}
        handleOptionChange={handleOptionChange}
        showHideNodes={showHideNodes}
        inputProps={inputProps}
        hirerarchy={hirerarchy}
        setHirerarchy={setHirerarchy}
        isMulti={isMulti}
        isDirectComponent={isDirectComponent}
      /> :
      <PopupComponent
        isSearchActive={isSearchActive}
        handleSearchClear={handleSearchClear}
        searchPlaceholder={searchPlaceholder}
        searchTxt={searchTxt}
        setSearchTxt={setSearchTxt}
        isLoading={isLoading}
        dropdownOptions={dropdownOptions}
        checkedOptions={checkedOptions}
        handleOptionChange={handleOptionChange}
        showHideNodes={showHideNodes}
        inputProps={inputProps}
        hirerarchy={hirerarchy}
        setHirerarchy={setHirerarchy}
        isMulti={isMulti}
        isMenuOpen={isMenuOpen}
        dropdownRef={dropdownRef}
        placeholder={placeholder}
        toggleMenuState={toggleMenuState}
        isSearchable={isSearchable}
        dropdownWidth={dropdownWidth} />
  );
});

export default ReactTreeDropdown;