import { IColumn } from "components/ReactTable";
import moment from "moment";
import { IPeopleUser } from "store/reducers";

export const DATE_FORMAT: string = "MM/DD/YYYY";
export const API_DATE_FORMAT: string = "YYYY-MM-DD";

export * from "./common"
export * from "./debounce";
export * from "./filterFormTransform";
export * from "./getOktaAuth";
export * from "./FormValidations";

export function makeid(length: number) {
  let result = '';
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';
  const charactersLength = characters.length;
  let counter = 0;
  while (counter < length) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
    counter += 1;
  }
  return result;
}

export function getUserName(user: IPeopleUser) {
  return `${user?.preferredFirstName || ""} ${user?.preferredLastName || ""}`;
}

export const ROLES_LABEL = {
  "ADMIN": "Admin",
  "SEARCH_AND_REPORTING": "Search & Reporting",
  "PAC": "Practice Area Coordinator",
  "CDS": "Career Development Specialist",
  "PAP": "PA Allocation Planning"
}

export function getGridColumns(views: any, cellIDS: string[], showHide: { [key: string]: boolean } = {}, options?: any): IColumn[] {
  const headers: IColumn[] = [];
  const defaultShowAll = Object.keys(showHide).length === 0;
  cellIDS.forEach((item) => {
    if (defaultShowAll || showHide[item]) {
      const cellView: any = views[item];
      if (cellView) {
        headers.push(cellView(options))
      }
    }
  })
  return headers;
}


export function getIdLabelForEachView(views: any, cellIDS: string[], defaultCellIDS: string[]): Array<{ id: string, label: string }> {
  const items: Array<{ id: string, label: string }> = [];
  let newCellIds: any = {}
  if (cellIDS) {
    defaultCellIDS.forEach(id => {
      newCellIds[id] = true;
    })

    cellIDS.forEach((id: string) => {
      delete newCellIds[id]
    });
  }



  ([...cellIDS || defaultCellIDS, ...Object.keys(newCellIds) ]).forEach(cell => {
    const cellView = views[cell];
    if (cellView) {
      items.push({
        id: cell,
        label: cellView({}).label
      })
    }
  })
  return items;
}

export function getAllHeadersAsTrue(cellIDS: string[]) {
  const allViews: any = {};
  cellIDS.forEach(id => {
    allViews[id] = true
  })
  return allViews;
}

export function convertArrayInChunks(inputArray: Array<any>, chunks: number) {
  return inputArray.reduce((resultArray, item, index) => {
    const chunkIndex = Math.floor(index / chunks)

    if (!resultArray[chunkIndex]) {
      resultArray[chunkIndex] = [] // start a new chunk
    }

    resultArray[chunkIndex].push(item)

    return resultArray
  }, [])
}

export const checkNumberAndConvertToMonths = (value: any) => {
  if (isNaN(value)) {
    return 0;
  }
  return Math.round((value / 365) * 12);
}

export function getMonthOfEmpAndExp(user: IPeopleUser): { moEmp: string, moExp: string, moInRole: string } {
  let { workExperience, alumni, lastPromotionDate, pastTerminationDate, internalJobHistory } = user || {};
  if(pastTerminationDate === null) {
    alumni = 'NO'
  }
  let internalExp: number = 0;
  let externalExp: number = 0;
  let expEndDate: Date = alumni === "NO" ? new Date() : new Date(parseInt(pastTerminationDate, 10));
  if (workExperience) {
    workExperience?.forEach(({ endDate, startDate }) => {
      externalExp += moment(endDate || new Date()).diff(startDate, "days")
    });
  }

  if (internalJobHistory) {
    internalJobHistory?.forEach(({ effectiveDate, ineffectiveDate }) => {
      internalExp += moment( parseInt(ineffectiveDate, 10) || new Date()).diff(parseInt(effectiveDate, 10), "days")
    });
  }

  return {
    moEmp: checkNumberAndConvertToMonths(internalExp).toString(),
    moExp: checkNumberAndConvertToMonths(internalExp + externalExp).toString(),
    moInRole: checkNumberAndConvertToMonths((moment(expEndDate).diff(lastPromotionDate, "days") || internalExp)).toString()
  }
}

export function arrayBufferToBase64(buffer: any) {
  let binary = '';
  let bytes = new Uint8Array(buffer);
  let len = bytes.byteLength;
  for (let i = 0; i < len; i++) {
    binary += String.fromCharCode(bytes[i]);
  }
  return window.btoa(binary);
}

export function getRowValue(value: any) {
  const returnDefaultValue: boolean = !value || value === "nan" || value === "null";
  return returnDefaultValue ? "" : value;
}

export function transformArrayAndLabelInKeyValue(items: string[], labels: any) {
  const options: any = {};
  items?.forEach(item => options[item] = labels[item]);
  return options;
}
export function getSystemFromHierarchies(hierarchies: [{ name: string, type: string }]): string {

  const newHierarchies: any = {};
  hierarchies?.forEach(({ type, name }) => {
    if(!newHierarchies[type]) {
      newHierarchies[type] = name;
    }
    return newHierarchies;
  })

  return newHierarchies.Subsystem || newHierarchies.System || ""
}
