import ProfilePicture from "components/ProfilePicture";
import { IReactTableComponent } from "components/ReactTable";
import TooltipText from "components/TooltipText";
import UsePeople from "HOC/UsePeople";
import { IExcelCell } from "interface";
import moment from "moment";
import { memo } from "react";
import Moment from "react-moment";
import { IPeopleUser } from "store/reducers";
import { DATE_FORMAT, getRowValue, getUserName } from "utils";


export enum CellIDS {
  Full_Name = "preferredName",
  HR_ID = "hrId",
  Home_Office = "homeOffice",
  Home_Region = "homeRegion",
  Host_Office = "hostOffice",
  Host_Region = "hostRegion",
  Title = "title",
  Old_Practice_Area = "oldPracticeArea",
  Old_Topic_Area = "oldTopicArea",
  Old_Affiliation_Level = "oldPaAffiliationLevel",
  New_Practice_Area = "newPracticeArea",
  New_Topic_Area = "newTopicArea",
  New_Affiliation_Level = "newPaAffiliationLevel",
  Comments = "newComment",
  Date = "newUpdatedAt",
  Created_By = "newUpdatedBy",
  Action = "action"
}


export const Cell_IDS_VIEW = {
  [CellIDS.Full_Name]: () => {
    return {
      id: CellIDS.Full_Name,
      label: "Name",
      width: 20,
      isFixed: true,
      fixedDirection: "left",
      CustomRowComponent: memo(({ data, header }: IReactTableComponent) => {
        const { user } = UsePeople(data["hrId"], true);
        return (
          <div className="flex flex-row flex-align-center" id={data[header.id]}>
            {user?.profilePicture && <ProfilePicture profileUrl={user.profilePicture} baseClassName="margin-r-4" imageClassName="paaf_grid--avatar" />}
            {getUserName(user)}
          </div>
        )
      })
    }
  },
  [CellIDS.HR_ID]: () => {
    return {
      id: CellIDS.HR_ID,
      label: "HR ID",
      width: 16,
      isSearchEnable: true
    }
  },
  [CellIDS.Home_Office]: () => {
    return {
      id: CellIDS.Home_Office,
      label: "Home Office",
      width: 16,
      // isSearchEnable: true,
      CustomRowComponent: memo(({ data, header }: IReactTableComponent) => {
        const { user } = UsePeople(data["hrId"], true);
        return (<TooltipText value={getRowValue(user?.homeOffice)} />)
      })
    }
  },
  [CellIDS.Home_Region]: () => {
    return {
      id: CellIDS.Home_Region,
      label: "Home Region",
      width: 16,
      CustomRowComponent: memo(({ data, header }: IReactTableComponent) => {
        const { user } = UsePeople(data["hrId"], true);
        return (<TooltipText value={getRowValue(user?.homeOfficeRegion)} />)
      })
    }
  },
  [CellIDS.Host_Office]: () => {
    return {
      id: CellIDS.Host_Office,
      label: "Host Office",
      width: 16,
      CustomRowComponent: memo(({ data, header }: IReactTableComponent) => {
        const { user } = UsePeople(data["hrId"], true);
        return (<TooltipText value={getRowValue(user?.hostOfficeLocation?.name)} />)
      })
    }
  },
  [CellIDS.Host_Region]: () => {
    return {
      id: CellIDS.Host_Region,
      label: "Host Region",
      width: 16,
      CustomRowComponent: memo(({ data, header }: IReactTableComponent) => {
        const { user } = UsePeople(data["hrId"], true);
        return (<TooltipText value={getRowValue(user?.hostOfficeRegion)} />)
      })
    }
  },
  [CellIDS.Title]: () => {
    return {
      id: CellIDS.Title,
      label: "Title",
      width: 25,
      CustomRowComponent: memo(({ data, header }: IReactTableComponent) => {
        const { user } = UsePeople(data["hrId"], true);
        return (<TooltipText value={getRowValue(user?.businessTitle)} />)
      })
    }
  },
  [CellIDS.Old_Practice_Area]: () => {
    return {
      id: CellIDS.Old_Practice_Area,
      label: "Old Practice Area",
      width: 16
    }
  },
  [CellIDS.Old_Topic_Area]: () => {
    return {
      id: CellIDS.Old_Topic_Area,
      label: "Old Topic Area",
      width: 16,
    }
  },
  [CellIDS.Old_Affiliation_Level]: () => {
    return {
      id: CellIDS.Old_Affiliation_Level,
      label: "Old Affiliation Level",
      width: 16,
    }
  },
  [CellIDS.New_Practice_Area]: () => {
    return {
      id: CellIDS.New_Practice_Area,
      label: "New Practice Area",
      width: 16,
    }
  },
  [CellIDS.New_Topic_Area]: () => {
    return {
      id: CellIDS.New_Topic_Area,
      label: "New Topic Area",
      width: 16,
    }
  },
  [CellIDS.New_Affiliation_Level]: () => {
    return {
      id: CellIDS.New_Affiliation_Level,
      label: "New Affiliation Level",
      width: 16,
    }
  },
  [CellIDS.Comments]: () => {
    return {
      id: CellIDS.Comments,
      label: "Comments",
      width: 16,
    }
  },
  [CellIDS.Date]: () => {
    return {
      id: CellIDS.Date,
      label: "Date",
      width: 16,
      CustomRowComponent: memo(({ data, header }: IReactTableComponent) => {
        return (
         <Moment date={data[header.id]} format={DATE_FORMAT}/>
        )
      })
    }
  },
  [CellIDS.Created_By]: () => {
    return {
      id: CellIDS.Created_By,
      label: "Created By",
      width: 16,
      CustomRowComponent: memo(({ data, header }: IReactTableComponent) => {
        const { user } = UsePeople(data[header.id], true);
        return (
          <div className="flex flex-row flex-align-center" id={data[header.id]}>
            {getUserName(user)}
          </div>
        )
      })
    }
  },
  [CellIDS.Action]: () => {
    return {
      id: CellIDS.Action,
      label: "Action",
      width: 16,
    }
  },
}

export const DEFAULT_CHANGE_REPORT_CELLIDS = [CellIDS.Full_Name, CellIDS.HR_ID, CellIDS.Home_Office, CellIDS.Host_Office,
CellIDS.Home_Region, CellIDS.Host_Region, CellIDS.Title,
CellIDS.Old_Practice_Area, CellIDS.Old_Topic_Area, CellIDS.Old_Affiliation_Level,
CellIDS.New_Practice_Area, CellIDS.New_Topic_Area, CellIDS.New_Affiliation_Level,
CellIDS.Comments, CellIDS.Date, CellIDS.Created_By, CellIDS.Action]

export const exportExcelItems: IExcelCell[] = [
  {
    id: "hrId",
    label: "HR ID",
    isPeople: true,
    hridKey: "hrId"
  },
  {
    id:"preferredName",
    label:"Name",
    isPeople: true,
    hridKey: "hrId"
  },
  {
    id:"homeOffice",
    label:"Home Office",
    isPeople: true,
    hridKey: "hrId"
  },
  {
    id:"hostOffice",
    label:"Host Office",
    isPeople: true,
    hridKey: "hrId"
  },
  {
    id:"homeOfficeRegion",
    label:"Home Region",
    isPeople: true,
    hridKey: "hrId"
  },
  {
    id:"hostOfficeRegion",
    label:"Host Region",
    isPeople: true,
    hridKey: "hrId"
  },
  {
    id:"businessTitle",
    label:"Title",
    isPeople: true,
    hridKey: "hrId"
  },
  {
    id:"oldPracticeArea",
    label:"Old Practice Area"
  },
  {
    id:"oldTopicArea",
    label:"Old Topic Area"
  },
  {
    id:"oldPaAffiliationLevel",
    label:"Old Affiliation level "
  },
  {
    id:"newPracticeArea",
    label:"New Practice Area"
  },
  {
    id:"newTopicArea",
    label:"New Topic Area"
  },
  {
    id:"newPaAffiliationLevel",
    label:"New Affiliation level"
  },
  {
    id:"newComment",
    label:"Comments"
  },
  {
    id:"newUpdatedAt",
    label:"Date",
    getValue: (peopleData: IPeopleUser, rowData: any) => {
      return rowData["newUpdatedAt"] ? moment(rowData["newUpdatedAt"]).format(DATE_FORMAT) : ""
    },
  },
  {
    id: "preferredFirstName",
    label: "Created by",
    isPeople: true,
    hridKey: "newUpdatedBy",
    getValue: (peopleData: IPeopleUser, rowData: any) => {
      return peopleData?.preferredName;
    },
  },
  {
    id: "action",
    label: "Action"
  }
]

