import CustomButton from "components/CustomButton";
import ReactTable, { CHANGE_EVENTS, IColumn, RefProps } from "components/ReactTable";
import { useEffect, useState, useMemo, Fragment, MutableRefObject, useRef } from "react";
import { useSearchParams } from "react-router-dom";
import { getAllHeadersAsTrue, getGridColumns, getIdLabelForEachView, getNumberOfFIlterApplied, transformFormValueToValues, transformStringToTableState, transformTableStateToString, transformValueForAPI, transformValuesToFormValues } from "utils";
import FilterReport, { DEFAULT_FILTER_VALUE, FilterTypes, IFilter } from "./FilterReport";

import { useDispatch, useSelector } from "react-redux";
import { changeReportFetchPageState, gettoggleColumnState } from "store/selectors";
import { CellIDS, Cell_IDS_VIEW, DEFAULT_CHANGE_REPORT_CELLIDS, exportExcelItems } from "./utils";
import { ReactTableDefaultState } from "components/ReactTable/ReactTable";
import { getChangeReport } from "store/pagination";
import { modalOperation } from "store/actions";
import { ModalsType } from "containers/ModalManager/ModalManager";
import { getToggleColumns, updateToggleColumns } from "store/rext";
import Loading from "components/Loading";
import { IColumSequence } from "interface";
import equal from "deep-equal";
import URLRoutes from "urlRoutes";
import { internalSearchInteraction, linkInteraction, setGlobalPageLoad } from "Analytics";
import { transformFormValueToAdobe } from "utils/filterFormTransform/transformValueToAdobe";
import { HEADRES, SUB_HEADERS } from "components/Header";
import UsePrevious from "HOC/UsePrevious";


function ChangeReport() {
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const { data: toggleData, fetching: toggleFetching, isUpdated } = useSelector(gettoggleColumnState, equal);
  const [showNoSearchBanner] = useState<boolean>(true);

  // Filter State
  const [isFilterApplied, setIsFilterApplied] = useState<boolean>(false);
  const [currentFilterState, setCurrentFilterState] = useState<IFilter>(undefined!);

  // Table State
  const tableRef: MutableRefObject<RefProps> = useRef(null!);
  const [defaultTableState, setDefaultTableState] = useState<ReactTableDefaultState>(undefined!);
  const [currentTableState, setCurrentTableState] = useState<ReactTableDefaultState>(undefined!);


  // User Report State
  const currentPageState = useSelector(changeReportFetchPageState, equal);
  const isOperationInProgress = UsePrevious(currentPageState.opertionInProgress);
  const [totalfilterApplied, setTotalFilterApplied] = useState<number>(0);


  const handleExport = () => {
    dispatch(modalOperation.showModal(ModalsType.ExportToExcel, {
      queryParams: transformValueForAPI(currentFilterState, FilterTypes),
      url: `${URLRoutes.server.allChangeReports}`,
      urlParams: {},
      excelCells: exportExcelItems,
      peopleApiKeys: ["hrId", "newUpdatedBy"],
      fileName: "change-report",
      responseKey: "info",
      totalElements: currentPageState.totalElements,
      onSave: () => {
        dispatch(modalOperation.hideModal());
      },
      onClose: () => {
        dispatch(modalOperation.hideModal());
      },
      title: `Export to Excel`
    }))
  }

  const gridView: IColumn[] = useMemo(() => {
    if (toggleFetching) {
      return [];
    }
    return getGridColumns(Cell_IDS_VIEW, toggleData.sequence || DEFAULT_CHANGE_REPORT_CELLIDS, toggleData.showHide)
  }, [toggleData, toggleFetching]);


  /**
   * 
   * @param tableState 
   * @param filter 
   */
  const fetchUsers = (tableState: ReactTableDefaultState, filter: IFilter) => {
    const { pageNumber, pageSize, filter: tableFIlter } = tableState;
    const apiFilter = transformValueForAPI(filter, FilterTypes);
    setTotalFilterApplied(getNumberOfFIlterApplied(filter, FilterTypes))
    dispatch(getChangeReport(pageNumber, pageSize, {
      ...apiFilter,
      ...tableFIlter,
      sortBy: "newUpdatedAt"
    }, true))
  }

  // changes done in table
  const handleChange = (changeEvent: CHANGE_EVENTS, tableState: ReactTableDefaultState) => {
    if (changeEvent !== CHANGE_EVENTS.PAGE_NUMBER) {
      tableState.pageNumber = 0;
    }
    setCurrentTableState({ ...tableState });
    setSearchParams({
      ...transformFormValueToValues(currentFilterState, FilterTypes, false),
      ...transformTableStateToString(tableState, false)
    })
  };

  // changes done in filter
  const handleFilterApplied = (filterValue: IFilter) => {
    internalSearchInteraction(transformFormValueToAdobe(filterValue, FilterTypes), HEADRES.Reports, SUB_HEADERS.Change_Report);
    setCurrentFilterState(filterValue);
    setCurrentTableState({ ...currentTableState, pageNumber: 0 });
    tableRef?.current?.changePageNumber(0);
    setSearchParams({
      ...transformTableStateToString({ ...currentTableState, pageNumber: 0 }, false),
      ...transformFormValueToValues(filterValue, FilterTypes, false)
    })
  }


  const handleToggleColumns = () => {
    linkInteraction("Toggle Columns", undefined!, HEADRES.Reports, SUB_HEADERS.Change_Report);
    dispatch(modalOperation.showModal(ModalsType.ToggleColumnModal, {
      onSave: (cards: IColumSequence) => {
        linkInteraction("Toggle Columns", "Apply & Save", HEADRES.Reports, SUB_HEADERS.Change_Report);
        dispatch(updateToggleColumns("CHANGE_REPORT", cards))
        dispatch(modalOperation.hideModal());
      },
      onClose: () => {
        dispatch(modalOperation.hideModal());
      },
      headers: getIdLabelForEachView(Cell_IDS_VIEW, toggleData.sequence, DEFAULT_CHANGE_REPORT_CELLIDS),
      title: `Toggle Column Selector`,
      disableHeaders: [CellIDS.Full_Name, CellIDS.HR_ID],
      selectedHeaders: toggleData.showHide || getAllHeadersAsTrue(DEFAULT_CHANGE_REPORT_CELLIDS)
    }));
  }

  const handleResetClick = () => {
    linkInteraction("Reset", undefined!, HEADRES.Admin_Options, SUB_HEADERS.PAAF_Users);
    setIsFilterApplied(false);
    setSearchParams({});
  }


  // Changed search to searchParams
  useEffect(() => {

    const searchParamsString = searchParams.toString();
    let tableState = transformStringToTableState(searchParamsString);
    let filter: any = transformValuesToFormValues(searchParamsString, FilterTypes);

    if (currentFilterState === undefined) {
      setCurrentFilterState(filter || DEFAULT_FILTER_VALUE);
    }

    if (currentTableState === undefined) {
      setCurrentTableState(tableState);
      setDefaultTableState(tableState);
    }

    if (!showNoSearchBanner || (showNoSearchBanner && Object.keys(filter).length)) {
      setIsFilterApplied(true);
      console.log(tableState)
      fetchUsers(tableState, filter);
    } else {
      setIsFilterApplied(false);
    }
  }, [searchParams])

  useEffect(() => {
    setGlobalPageLoad("Change Report");
    dispatch(getToggleColumns("CHANGE_REPORT"))
  }, [dispatch])

  useEffect(() => {
    if (isOperationInProgress && !currentPageState.opertionInProgress && !currentPageState.operationError) {
      handleChange(CHANGE_EVENTS.PAGE_NUMBER, { ...currentTableState, pageNumber: 0 });
    }
  }, [isOperationInProgress, currentPageState.opertionInProgress])



  return (
    <Fragment>

      {
        currentFilterState && <FilterReport
          filterState={currentFilterState}
          isFilterApplied={isFilterApplied}
          handleFilterApplied={handleFilterApplied}
          showNoFilterBanner={showNoSearchBanner}
          handleResetClick={handleResetClick}
          hideResetButton={true}
          submitButtonText="EXECUTE REPORT"
          noFilterMessage="Use the above filters to find change report options."
        />
      }

        

      { isFilterApplied && <div className="padding-l-10 padding-r-10">
        <div className="flex flex-row">
          <CustomButton
            round
            outline
            primaryButton
            disabled={currentPageState.fetching}
            buttonText="Export To Excel"
            handleClick={handleExport}
          />

          <CustomButton
            round
            outline
            primaryButton
            disabled={currentPageState.fetching}
            buttonText="Toggle columns"
            handleClick={handleToggleColumns}
            baseclassname={"margin-l-4"}
          />
        </div>

        <div className="flex flex-row flex-justify-between  padding-t-4 padding-b-4">
          <div className="margin-r-2 padding-t-1 text-inline">{currentPageState.totalElements} Search Results: {totalfilterApplied} Filters applied</div>
        </div>
        <ReactTable ref={tableRef} primarykey="paafId"
          defaultState={defaultTableState}
          isLoading={currentPageState.fetching}
          data={currentPageState.data}
          isPaginationAllowed
          noDataMessage={"Please try with some other criteria."}
          handleChange={handleChange}
          totalElements={currentPageState.totalElements}
          totalNumberOfPages={currentPageState.totalNumberOfPages}
          headers={gridView} baseClassName="margin-t-4 paaf-user-table-container"
        />
      </div>
      }

      {
        isUpdated && <Loading isGlobal />
      }
    </Fragment >
  )
}


export default ChangeReport;