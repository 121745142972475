import { Fragment, useEffect, useRef } from 'react';
import { useOktaAuth } from '@okta/okta-react';
import { toRelativeUrl } from '@okta/okta-auth-js';
import { Outlet } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

import Header from "components/Header";
import Footer from "components/Footer";
import ModalManager from 'containers/ModalManager';

import 'react-toastify/dist/ReactToastify.css';
import Loading from 'components/Loading';
import { useDispatch, useSelector } from 'react-redux';
import { getFeaturesState } from 'store/selectors';
import { getFeaturesRequest } from 'store/rext';

export const RequiredAuth = () => {
  const { oktaAuth, authState } = useOktaAuth();
  const { data, fetching } = useSelector(getFeaturesState);
  const dispatch = useDispatch();
  const headerRef = useRef(null);

  useEffect(() => {
    if (!authState) {
      return;
    }

    if (!authState?.isAuthenticated) {
      const originalUri = toRelativeUrl(window.location.href, window.location.origin);
      oktaAuth.setOriginalUri(originalUri);
      oktaAuth.signInWithRedirect();
    }

    if (authState?.isAuthenticated && !fetching && !data) {
      dispatch(getFeaturesRequest());
    }

  }, [oktaAuth, !!authState, authState?.isAuthenticated, data]);

  if (!authState || !authState?.isAuthenticated || fetching) {
    return (<Loading isGlobal />);
  }

  return (
    <Fragment>
      <Header ref={headerRef}/>
      <div className='min-content-height position-relative'>
        <Outlet context={{headerRef}} />
      </div>
      <Footer />
      <ModalManager />
      <ToastContainer />
    </Fragment>
  );
}