import { checkAndAppendHeadersForPeople } from "Api";
import Loading from "components/Loading";
import usePrevious from "HOC/UsePrevious";
import { MutableRefObject, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { getBaseUrl, getBaseUrlAPIKEY, getToken } from "store/selectors";
import { arrayBufferToBase64 } from "utils";

interface Props {
  profileUrl: string;
  baseClassName?: any;
  imageClassName?: any;
}

function ProfilePicture({ profileUrl, baseClassName, imageClassName }: Props) {
  const baseUrl = useSelector(getBaseUrl(true));
  const token = useSelector(getToken);
  const xApi = useSelector(getBaseUrlAPIKEY(true));
  const [profileError, setProfileError] = useState<any>(undefined!);
  const [fetching, setFetching] = useState<any>(false);
  const profileImageRef: MutableRefObject<HTMLImageElement> = useRef(null!);
  const previousProfile = usePrevious(profileUrl);

  const fetchProfilePicture = async () => {
    try {
      setFetching(true);
      let headers = new Headers();
      headers = checkAndAppendHeadersForPeople(headers, token, xApi);
      const response = await fetch(`${baseUrl}/domain/v2/people${profileUrl}`, {
        method: "get",
        headers
      });

      if (response.status === 500) {
        throw new Error("Internal Server Error");
      }

      
      const arrayBuffer = await response.arrayBuffer();
      profileImageRef.current.src = `data:image/png;base64,${arrayBufferToBase64(arrayBuffer)}`
      setFetching(false);
      return true;
    } catch (error) {
      setProfileError(error);
      setFetching(false);
      return false;
    }
  }

  useEffect(() => {
    if(previousProfile !== profileUrl && !profileError) {
      fetchProfilePicture();
    }
  }, [profileUrl, previousProfile]);

  return (
    <div className={baseClassName || ""}>
      <img ref={profileImageRef} style={{display: fetching ? "none" : ""}} alt="" className={imageClassName || "avatar border-radius-50"}/>
      {
        fetching && <Loading/>
      }
    </div>
  );
}

export default ProfilePicture;