import { combineReducers } from 'redux';
import { reducer as formReducer, FormStateMap } from 'redux-form';

import modalState, { ModalState } from "./modal";
import applicationContextReducer, { ApplicationContextState } from "./applicationContext"
import { IRextReducer } from 'baseProvider/rext';

import { getActiveAffiliations, getLeadAffiliations, getLocations, getGlobalTitle, getCarrerStep, getCarrerTrack,
  getPracticeArea, getAffiliation, getPracticeAreaForUser, getFeatures, getAllRoles, searchBCGUsers, archiveReports,
  toggleColumnRext,
  userRoleRext,
  practiceTopicsPermissionsRext,
  getSnoFlakeRegions,
  getOrganization,
  getChort,
  getJobTitles} from "store/rext";
import { getIntrestedAffiliations, presetFiltersList, previousCommentsList, userAllAffiliationsList } from 'store/list';
import { IListReducer } from 'baseProvider/list';
import peopleReducer, { PeopleState } from "./people";
import { IPaginationReducer } from 'baseProvider/pagination';
import { allAffiliationPagination, changeReportPagination, paafUserPagination, snowflakeAllAffiliationPagination, snowflakeAllAffiliationPaginationMatrixView, snowflakeAllAffiliationPaginationTableView } from 'store/pagination';

export type { ModalState } from "./modal";
export type { IPeople, IPeopleUser } from "./people";

export interface IAffiliations {
  activeAffiliations: IRextReducer;
  leadAffiliations: IRextReducer;
  intrestedAffiliations: IListReducer;
  practiceAreaState: IRextReducer;
}

export interface ICommonReducers {
  locationsState: IRextReducer;
  globalTitleState: IRextReducer;
  carrerStepState: IRextReducer;
  carrerTrackState: IRextReducer;
  practiceAreaState: IRextReducer;
  affiliationState: IRextReducer;
  snowflakeRegionState: IRextReducer;
  snowflakeOrganizationState: IRextReducer;
  cohortDropdownState: IRextReducer;
  jobTitleState: IRextReducer;
}

export interface ApplicationState {
  form: FormStateMap;
  modalState: ModalState;
  applicationContext: ApplicationContextState;
  allAffiliationsState: IAffiliations;
  common: ICommonReducers;
  presetFilterState: IListReducer;
  peopleState: PeopleState;
  userAllAffiliationsState: IListReducer;
  featuresState: IRextReducer;
  userPaginationState: IPaginationReducer;
  allRolesState: IRextReducer;
  serachBcgUserState: IRextReducer;
  archiveReportState: IRextReducer;
  changeReportState: IPaginationReducer;
  toggleColumnState :IRextReducer;
  allAffiliationState:IPaginationReducer;
  userRoleState: IRextReducer;
  previousCommentsState: IListReducer;
  practiceTopicsPermissionsState: IRextReducer;
  snowflakeAllAffiliationState:IPaginationReducer;
  snowflakeAllAffiliationTableViewState:IPaginationReducer;
  snowflakeAllAffiliationMatrixViewState:IPaginationReducer;

}

const rootReducer = () => combineReducers<ApplicationState>({
  form: formReducer,
  modalState: modalState,
  applicationContext: applicationContextReducer,
  allAffiliationsState: combineReducers<IAffiliations>({
    activeAffiliations: getActiveAffiliations.reducers,
    leadAffiliations: getLeadAffiliations.reducers,
    intrestedAffiliations: getIntrestedAffiliations.reducers,
    practiceAreaState: getPracticeAreaForUser.reducers
  }),
  common: combineReducers<ICommonReducers>({
    locationsState: getLocations.reducers,
    globalTitleState: getGlobalTitle.reducers,
    carrerStepState: getCarrerStep.reducers,
    carrerTrackState: getCarrerTrack.reducers,
    practiceAreaState: getPracticeArea.reducers,
    affiliationState: getAffiliation.reducers,
    snowflakeRegionState: getSnoFlakeRegions.reducers,
    snowflakeOrganizationState: getOrganization.reducers,
    cohortDropdownState: getChort.reducers,
    jobTitleState: getJobTitles.reducers
  }),
  presetFilterState: presetFiltersList.reducers,
  peopleState: peopleReducer,
  userAllAffiliationsState: userAllAffiliationsList.reducers,
  featuresState: getFeatures.reducers,
  userPaginationState: paafUserPagination.reducers,
  allRolesState: getAllRoles.reducers,
  serachBcgUserState: searchBCGUsers.reducers,
  archiveReportState: archiveReports.reducers,
  changeReportState: changeReportPagination.reducers,
  allAffiliationState: allAffiliationPagination.reducers,
  toggleColumnState: toggleColumnRext.reducers,
  userRoleState: userRoleRext.reducers,
  previousCommentsState: previousCommentsList.reducers,
  practiceTopicsPermissionsState: practiceTopicsPermissionsRext.reducers,
  snowflakeAllAffiliationState: snowflakeAllAffiliationPagination.reducers,
  snowflakeAllAffiliationTableViewState: snowflakeAllAffiliationPaginationTableView.reducers,
  snowflakeAllAffiliationMatrixViewState: snowflakeAllAffiliationPaginationMatrixView.reducers
});

export default rootReducer;