import { createList, IList } from "baseProvider/list";
import { multiPeopleInfoActions } from "store/actions/people";
import { getBaseUrl, getBaseUrlAPIKEY, getToken } from "store/selectors";

export const getIntrestedAffiliations: IList = createList({
    identity: "getIntrestedAffiliations",
    getBaseUrl: getBaseUrl(false),
    getXAPIToken: getBaseUrlAPIKEY(false),
    getToken: getToken,
    primaryKey: "topicId",
    deleteMessage: "Affiliation has been deleted.",
    multiAddMessage: "You are successfully affiliated with the topics.",
    callPeopleApi: multiPeopleInfoActions.request,
    hrIdKey: ["updatedByHrId"],
})


export const presetFiltersList: IList = createList({
    identity: "presetFiltersList",
    getBaseUrl: getBaseUrl(false),
    getXAPIToken: getBaseUrlAPIKEY(false),
    getToken: getToken,
    primaryKey: "id",
    deleteMessage: "Preset has been deleted.",
    addMessage: "New Preset has been added",
    editMessage: "Preset has been updated"
})


export const userAllAffiliationsList: IList = createList({
    identity: "userAllAffiliations",
    getBaseUrl: getBaseUrl(false),
    getXAPIToken: getBaseUrlAPIKEY(false),
    getToken: getToken,
    primaryKey: "topicId",
    multiEditMessage: "Affiliations Updated Successfully",
    transformationOfItem: (item) => {
        return {
            ...item,
            affiliationLevelId: item.affiliationLevelId.toString()
        }
    }
  })

export const previousCommentsList: IList = createList({
    identity: "previousComments",
    getBaseUrl: getBaseUrl(false),
    getXAPIToken: getBaseUrlAPIKEY(false),
    getToken: getToken,
    primaryKey: "id",
    callPeopleApi: multiPeopleInfoActions.request,
    hrIdKey: ["updatedByHrId"],
})