import classNames from 'classnames';
import SvgIcon, { CUSTOM_SVG_ICON, SVGType } from 'components/SvgIcon';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import "./FormInput.scss";
import DateRangeHeader from './DateRangeHeader';

export function FormDateRange({ input, ...props }: any) {
  const { placeholder, baseClassName, meta, iconName } = props;
  const { value, onChange } = input;
  return (
    <div className={classNames([baseClassName, "position-relative form-date-picker"])}>
      <div className='position-relative'>
        <DatePicker
          renderCustomHeader={DateRangeHeader}
          className="datepicker"
          selectsRange={true}
          value={value}
          isClearable
          clearButtonClassName='datepicker-clear'
          dateFormat={"MM/dd/yyyy"}
          placeholderText={placeholder || ""}
          startDate={(value && value[0]) || null}
          endDate={(value && value[1]) || null}
          onChange={(update: Array<any>) => {
            onChange(update.filter(updateValue => updateValue !== null));
          }}
          onCalendarClose={() => {
            if(value && value.length) {
              onChange(value?.map((val: Date) => val || new Date()))
            }
          }}
        />
        <SvgIcon svgType={SVGType.CUSTOM} name={iconName || CUSTOM_SVG_ICON.CaretDown } size="small" baseclassname={"dropdown-icon text-primary-color"} />
        {meta.error && meta.touched && <div className="text-danger-color text-sm padding-l-4">{meta.error}</div>}
      </div>
    </div>
  )
}