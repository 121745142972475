import CustomButton from "components/CustomButton";
import { ICON_POSITION } from "components/CustomButton/CustomButton";
import { CUSTOM_SVG_ICON, SVGType } from "components/SvgIcon";
import AffiliationHistory from "./History";
import AffiliationDetail from "./Detail";
import AffiliationShortDetail from './UserDetail'
import { useEffect, useState } from "react";

import "./AffiliationCard.scss";
import UsePeople from "HOC/UsePeople";
import { expandCollapseInteraction } from "Analytics";
import { HEADRES } from "components/Header";
import { getUserName } from "utils";

function AffiliationCard({ expandAllAffiliation, handleEdit, hrId, data, handleChangeActivity }: any) {
  const { user } = UsePeople(hrId, true)
  const [isExpanded, setIsExapnded] = useState<boolean>(expandAllAffiliation || false);

  useEffect(() => {
    setIsExapnded(expandAllAffiliation);
  }, [expandAllAffiliation])

  const toggleExpandCollpase = () => {
    expandCollapseInteraction(HEADRES.Search_PAAF, undefined!, !isExpanded ? "Expand" : "Collapse", data.employeeRecords?.preferredName);
    setIsExapnded(!isExpanded);
  }

  return (
    <div className="flex flex-row background-white-color affiliation-card">
      <div className="flex flex-column affiliation-card__details">
        <AffiliationShortDetail user={data.employeeRecords} />
        {isExpanded && <AffiliationDetail user={data.employeeRecords} data={data}/>}
      </div>
      <AffiliationHistory isExpanded={isExpanded} data={data} />
      <div className="flex flex-column flex-justify-between flex-align-end affiliation-card__action">
        <div className="flex flex-row">
          <CustomButton
            noOutline
            transparent
            primaryButton
            iconPosition={ICON_POSITION.LEFT}
            iconProps={{
              svgType: SVGType.CUSTOM,
              name: CUSTOM_SVG_ICON.Reload,
              size: "huge"
            }}
            handleClick={() => handleChangeActivity(data.employeeRecords)} />
          {handleEdit && <CustomButton
            noOutline
            transparent
            primaryButton
            iconPosition={ICON_POSITION.LEFT}
            iconProps={{
              svgType: SVGType.CUSTOM,
              name: CUSTOM_SVG_ICON.Edit,
              size: "huge"
            }}
            handleClick={() => {
              handleEdit(data.employeeRecords)
            }} />
          }
        </div>

        <CustomButton
          noOutline
          transparent
          primaryButton
          iconPosition={ICON_POSITION.RIGHT}
          iconProps={{
            svgType: SVGType.CUSTOM,
            name: isExpanded ? CUSTOM_SVG_ICON.TriangleUp : CUSTOM_SVG_ICON.TriangleDown,
            size: "huge"
          }}
          buttonText={isExpanded ? "Collapse" : "Expand"}
          noPadding
          handleClick={toggleExpandCollpase}
        />
      </div>
    </div>
  )
}

export default AffiliationCard;