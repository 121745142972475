import { getDateRangeValue, transformStringToSmartOptions } from "utils/common";
import { FILTER_TYPES, IFilterType, URL_OPTTION_SUBFIX, IFilterTypes } from "./utils";
import queryString from "query-string";

function convertArrayOptions(filterValue: any, filterKey: string, { type, isNumeric }: IFilterType) {
  if (filterValue?.length >= 1) {
    return  { [filterKey] : transformStringToSmartOptions( !!isNumeric, filterValue, URL_OPTTION_SUBFIX, true)};
  }
  return undefined!;
}

const getValues = {
  [FILTER_TYPES.MULTI_SELECT]: convertArrayOptions,
  [FILTER_TYPES.TREE_SELECT]: convertArrayOptions,
  [FILTER_TYPES.SINGLE_SELECT]: (filterValue: any, filterKey: string, { type }: IFilterType) => {
    if (filterValue) {
      return  { [filterKey] : filterValue};
    }
    return undefined;
  },
  [FILTER_TYPES.DATE_RANGE]: (filterValue: any, filterKey: string,{ type }: IFilterType) => {
    if (filterValue?.length >= 1) {
      return {
        [filterKey]:getDateRangeValue(filterValue, URL_OPTTION_SUBFIX)
      }
    }
    return undefined;
  },
  [FILTER_TYPES.DATE]: (filterValue: any, filterKey: string, { type }: IFilterType) => {
    if (filterValue) {
      return  { [filterKey] : new Date(parseInt(filterValue,10))};
    }
    return undefined;
  },
  [FILTER_TYPES.BOOLEAN]: (filterValue: any, filterKey: string, { type }: IFilterType) => {
    if (filterValue) {
      return  { [filterKey] : filterValue === "true" ? true : false};
    }
    return false;
  },
  [FILTER_TYPES.DEFAULT]: (filterValue: any, filterKey: string, { type, isNumeric }: IFilterType) => {
    if (filterValue.length) {
      return  { [filterKey] : !!isNumeric ? parseInt(filterValue, 10) : filterValue};
    }
    return undefined;
  }
}


export function transformValuesToFormValues(query: string, filterTypes: IFilterTypes): any {
    const values: any = queryString.parse(query);
    const filterKeys = Object.keys(values);
    let filterState: any = {};

    filterKeys.forEach((filterKey: string) => {
      const filterValue = values[filterKey];
      const { type } = filterTypes[filterKey] || {};
      if (type) {
        const filterKeyValue = getValues[type](filterValue, filterKey, filterTypes[filterKey]);
        filterState = {
          ...filterState,
          ...filterKeyValue
        }
      }
    });
    return filterState;
  }