import { IPaginationReducer, IPaginationState, IPaginationInfo, IPaginationItems } from './interface';
export function getPaginationState(pagination: IPaginationReducer, defaultValue: any): IPaginationState {
  const info: IPaginationInfo = pagination.info;
  const items: IPaginationItems = pagination.items;
  const currentPageNumber: number = info.currentPageNumber || 0;
  const values = (items.ids[currentPageNumber] || []).map(item => items.raw[item]);
  return {
    fetching: info.fetching[currentPageNumber],
    error: info.hasError[currentPageNumber] || false,
    message: info.message[currentPageNumber],
    data: values || defaultValue,
    totalNumberOfPages: info.totalNumberOfPages || 0,
    totalElements: info.totalElements || 0,
    opertionInProgress: info.operation.fetching,
    operationError: info.operation.error,
  };
}


export function getPageFetchedState(pagination: IPaginationReducer): {[key: number]: boolean} {
  const info: IPaginationInfo = pagination.info;
  return info.hasFetched;
}