import { forwardRef, Fragment, MutableRefObject, Ref, useEffect, useImperativeHandle, useMemo, useRef, useState } from "react";
import { Loader } from "semantic-ui-react";
import classNames from "classnames";

import Header from "./Header";
import Rows from "./Rows";
import Scroll from "./Scroll";
import { calculateGroupingWidths } from "./utils";
import { CHANGE_EVENTS, DIRECTION, IColumn, IGroupingColumns, IReactTableRef, ISortState, RefProps, SortOrder } from "./IReactTable";
import Pagination from 'components/Pagination'

import "./ReactTable.scss";

export interface ReactTableDefaultState {
  filter?: any,
  sort?: ISortState,
  pageNumber: number;
  pageSize: number;
}

interface Props {
  type?:string;
  headers: IColumn[];
  data: Array<any>;
  baseClassName?: string;
  noDataMessage?: string;
  isLoading?: boolean;
  uncontrolledHeight?: boolean;
  totalElements?: number;
  totalNumberOfPages?: number;
  handleChange?: (eventType: CHANGE_EVENTS, data: any) => void;
  isPaginationAllowed?: boolean;
  defaultState?: ReactTableDefaultState
  primarykey?: string;
  paginationSize?: number[];
}

function useScreenSize() {
  const isClient = typeof window === "object";

  const getSize = () => {
    return {
      width: isClient ? window.innerWidth : undefined,
      height: isClient ? window.innerHeight : undefined
    };
  }

  const [windowSize, setWindowSize] = useState(getSize);

  useEffect(() => {
    if (!isClient) {
      return undefined!;
    }

    function handleResize() {
      setWindowSize(getSize());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []); // Empty array ensures that effect is only run on mount and unmount

  return windowSize;
}



function ReactTable(props: Props, ref: Ref<RefProps>) {
  const { headers, data, baseClassName, noDataMessage, isLoading,
    uncontrolledHeight, totalElements, totalNumberOfPages, defaultState, primarykey,
    handleChange, isPaginationAllowed, paginationSize, type
  }: any = props;
  const [currentPageNumber, setCurrentPageNumber] = useState<number>(0);
  const [currentPageSize, setCurrentPageSize] = useState<number>(100);
  const [filterState, setFilterState] = useState<any>({});
  const [sortState, setSortState] = useState<ISortState>(undefined!);
  const [ defaultFilterState, setDefaultFilterState ] = useState<any>({});

  const [showFilters, setShowFIlters] = useState<boolean>(false);
  const leftHeaderRef: MutableRefObject<IReactTableRef> = useRef(undefined!);
  const centerHeaderRef: MutableRefObject<IReactTableRef> = useRef(undefined!);
  const rightHeaderRef: MutableRefObject<IReactTableRef> = useRef(undefined!);

  const leftContainerRef: MutableRefObject<IReactTableRef> = useRef(undefined!);
  const centerContainerRef: MutableRefObject<IReactTableRef> = useRef(undefined!);
  const rightContainerRef: MutableRefObject<IReactTableRef> = useRef(undefined!);

  const leftScrollRef: MutableRefObject<any> = useRef(undefined!);
  const centerScrollRef: MutableRefObject<any> = useRef(undefined!);
  const rightScrollRef: MutableRefObject<any> = useRef(undefined!);

  const bodyRef: MutableRefObject<HTMLDivElement> = useRef(undefined!);
  const tableRef: MutableRefObject<HTMLDivElement> = useRef(undefined!);
  const { width } = useScreenSize();

  const toggleSearch = () => {
    setShowFIlters(!showFilters);
  }

  const updateDefaultState = (newDefaultState: any) => {
    const { filter, sort, pageNumber, pageSize  } = newDefaultState || {};
    setFilterState(filter || {});
    setDefaultFilterState(filter);
    if (sort) {
      setSortState(sort);
    }
    setCurrentPageNumber(pageNumber || 0);
    setCurrentPageSize(pageSize || 100);
  }

  useEffect(() => {
    updateDefaultState(defaultState);
  }, [defaultState])

  const handleFilterChange = (key: string, value: string) => {
    value = value.trim();
    let filter = filterState;
    if (value.length === 0) {
      delete filter[key];
    } else {
      filter = { ...filter, [key]: value }
    }
    setFilterState({ ...filter });
    handleChangeForAllEvent(CHANGE_EVENTS.FILTER, "filter", filter);
  }

  const handleChangeForAllEvent = (event: CHANGE_EVENTS, keyName: string, value: any) => {
    const change: any = {
      pageNumber: currentPageNumber,
      pageSize: currentPageSize,
      filter: filterState,
      sort: sortState
    };
    change[keyName] = value;
    bodyRef?.current?.scrollTo({
      top: 0
    })
    handleChange && handleChange(event, change)
  }

  const getSortOrder= (newKey: string, { key, sortOrder}: ISortState): SortOrder => {
    if(key !== newKey) {
      return SortOrder.ASSCENDING;
    } else if(sortOrder === SortOrder.ASSCENDING) {
      return SortOrder.DESCENDING
    } else {
      return SortOrder.ASSCENDING
    }
  }

  const handleSortChange = (key: string) => {
    const sort = {
      key,
      sortOrder: getSortOrder(key, sortState)
    }
    setSortState(sort)
    handleChangeForAllEvent(CHANGE_EVENTS.SORTING, "sort", sort);
  }

  const handlePageChange = (pageNo: number) => {
    setCurrentPageNumber(pageNo);
    handleChangeForAllEvent(CHANGE_EVENTS.PAGE_NUMBER, "pageNumber", pageNo);
  }

  const handlePageSizeChange = (pageSize: number) => {
    setCurrentPageSize(pageSize);
    handleChangeForAllEvent(CHANGE_EVENTS.PAGE_SIZE, "pageSize", pageSize);
  }


  const { centerColumns, leftColumns, rightColumns }: IGroupingColumns = useMemo(() => {
    centerScrollRef?.current?.scrollTo(0);
    centerHeaderRef?.current?.leftScroll(0);
    return calculateGroupingWidths(headers,  tableRef?.current?.clientWidth - 10, uncontrolledHeight)
  }, [headers, tableRef?.current?.clientWidth, width, isLoading]);


  useImperativeHandle(ref, () => {
    return {
      changePageNumber: (pageNumber: number) => {
        setCurrentPageNumber(pageNumber);
      },
      changePageSize: (pageSize: number) => {
        setCurrentPageSize(pageSize);
      },
      changeSortState: (newSortState: ISortState) => {
        setSortState(newSortState);
      },
      intializeFilterState: (filter: any) => {
        setDefaultFilterState(filter)
        setFilterState(filter);
      },
      setDefaultState:(updDefaultState: any) => {
        updateDefaultState(updDefaultState);
      }
    }
  }, [])


  return (
    <div ref={tableRef} className={classNames(["flex flex-column react-table", baseClassName || ""])}>
      <div id="react-table-header" className={classNames(["flex flex-row react-table--header", { "search-active": showFilters }])} >
        <Header ref={leftHeaderRef} grouping={leftColumns} direction={DIRECTION.LEFT} showFilters={showFilters}
          toggleFilters={toggleSearch} handleSortChange={handleSortChange} handleFilterChange={handleFilterChange} sortState={sortState} defaultFilterState={defaultFilterState} />
        <Header ref={centerHeaderRef} grouping={centerColumns} direction={DIRECTION.CENTER} showFilters={showFilters}
          toggleFilters={toggleSearch} handleSortChange={handleSortChange} handleFilterChange={handleFilterChange} sortState={sortState} defaultFilterState={defaultFilterState} />
        <Header ref={rightHeaderRef} grouping={rightColumns} direction={DIRECTION.RIGHT} showFilters={showFilters}
          toggleFilters={toggleSearch} handleSortChange={handleSortChange} handleFilterChange={handleFilterChange} sortState={sortState} defaultFilterState={defaultFilterState} />
      </div>


      <div id="react-table-body" ref={bodyRef} className={classNames(["flex flex-row react-table--body", { "search-active": showFilters, "pagination-active": isPaginationAllowed }])} >
        {
          isLoading && <div id="react-table-loading" className="react-table--loading">
            <Loader active inline="centered" />
          </div>
        }

        {!isLoading && data?.length >= 1 && <Fragment>
          <Rows type={type} primarykey={primarykey!} data={data} ref={leftContainerRef} scrollRef={leftScrollRef} grouping={leftColumns} direction={DIRECTION.LEFT} uncontrolledHeight={uncontrolledHeight} />
          <Rows type={type} primarykey={primarykey!} data={data} ref={centerContainerRef} scrollRef={centerScrollRef} grouping={centerColumns} direction={DIRECTION.CENTER} uncontrolledHeight={uncontrolledHeight} />
          <Rows type={type} primarykey={primarykey!} data={data} ref={rightContainerRef} scrollRef={rightScrollRef} grouping={rightColumns} direction={DIRECTION.RIGHT} uncontrolledHeight={uncontrolledHeight} />
        </Fragment>
        }
        {
          !isLoading && !data?.length && <div id="react-table-no-data" className="react-table--no-data">
            {noDataMessage}
          </div>
        }
      </div>

      {!isLoading && data?.length >= 1 &&
        <Fragment>
          <div id="react-table-scroll" className={classNames(["flex flex-row react-table--scroll", {"pagination": isPaginationAllowed}])}>
            <Scroll headerRef={leftHeaderRef} rowsRef={leftContainerRef} ref={leftScrollRef} grouping={leftColumns} direction={DIRECTION.LEFT} />
            <Scroll headerRef={centerHeaderRef} rowsRef={centerContainerRef} ref={centerScrollRef} grouping={centerColumns} direction={DIRECTION.CENTER} />
            <Scroll headerRef={rightHeaderRef} rowsRef={rightContainerRef} ref={rightScrollRef} grouping={rightColumns} direction={DIRECTION.RIGHT} />
          </div>
        </Fragment>
      }

      {isPaginationAllowed && <Pagination pageSize={currentPageSize} totalNoOfElements={totalElements || 0} pageNumber={currentPageNumber} totalNoOfPage={totalNumberOfPages || 0}
        handlePageChange={handlePageChange} paginationSize={paginationSize} handlePageSizeChange={handlePageSizeChange} isLoading={!!isLoading} />
      }
    </div>
  )
}

export default forwardRef(ReactTable);