import React from "react";
import { ReactComponent as EasterEgg } from "assets/easter-egg-hunt.svg";
import classNames from "classnames";

interface Props {
    message: string
    baseclassname?: any;
}


function NoFilterBanner({ message, baseclassname }: Props) {
    return (
        <div className={classNames([baseclassname || "", "background-grey-shade-7  flex flex-row flex-align-center flex-justify-center"])}>
            <div className="margin-r-4 padding-t-4">{message}</div>
            <EasterEgg />
        </div>
    )
}
export default NoFilterBanner;