import MultiSelectDropdown from "../MultiSelectDropdown/MultiSelect";

export function FormSelect({ input, meta, dropdownRef, baseClassName, ...props }: any) {
  return (
    <div className={baseClassName || ""}>
    <MultiSelectDropdown
      {...props}
      ref={dropdownRef || null}
      value={input.value}
      onChange={(data: any) => input.onChange(data)}
    />
    {meta.error && meta.touched && <div className="text-danger-color text-sm padding-l-4">{meta.error}</div>}
    </div>
  );
}