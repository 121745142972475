export enum FEATURES {
    MY_AFFILIATION = "MY_AFFILIATION",
    SEARCH_PAAF = "SEARCH_PAAF",
    SP_EDIT_USER = "SP_EDIT_USER",
    SP_EXPORT_EXCEL = "SP_EXPORT_EXCEL",
    CHANGE_REPORT = "CHANGE_REPORT",
    ARCHIVED_REPORT = "ARCHIVED_REPORT",
    PRACTICE_AREA_TOPIC_MAPPING = "PRACTICE_AREA_TOPIC_MAPPING",
    PAAF_USER = "PAAF_USER",
    PAAF_ADD_USER = "PAAF_ADD_USER",
    PAAF_EXPORT_USER = "PAAF_EXPORT_USER",
    PAAF_EDIT_USER = "PAAF_EDIT_USER",
    PAAF_DELETE_USER = "PAAF_DELETE_USER",
    ACCESS_HIERARCHY = "ACCESS_HIERARCHY",
    LEARN_MORE = "LEARN_MORE",
}