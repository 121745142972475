import xlsx from "json-as-xlsx";
import CheckboxLabel from "components/CheckboxLabel";
import HirerarchyLevel from "components/HirerarchyLevel";
import { IHirerarchy } from "components/HirerarchyLevel/HirerarchyLevel";
import Loading from "components/Loading";
import { IOption } from "components/TreeDropdown";
import { IExcelCell } from "interface";
import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getPracticeAreaRequest } from "store/rext";
import { getPracticeAreaState } from "store/selectors";
import CustomButton from "components/CustomButton/CustomButton";

export const exportExcelItems: IExcelCell[] = [
  {
    id: "topicType",
    label: "Practice Area Type"
  },
  {
    id:"parentID",
    label:"Practice Area Name"
  },
  {
    id:"bstLabel",
    label:"Topic Name"
  },
  {
    id:"bstGuid",
    label:"GUID"
  }
]

function createItem(option: IOption, parents: string[], items: Array<Array<any>>, isIndex: number, isLastIndex?: boolean) {
  const item: Array<any> = [];
  if(!parents.length) {
    item[0] = option.value
  } else {
    parents.forEach((parentItem, index) => {
      item[index] = parentItem;
    })
    item[parents.length] = option.value;
  }

  if(option.childrens?.length) {
    parents.push(option.value);
  } else {
    if(parents.length && isLastIndex) {
      parents.splice(parents.length -1, 1);
    }
    item[isIndex] = option.raw
  }

  items.push(item);
  option.childrens?.forEach((child, childIndex) => createItem(child, parents, items, isIndex, childIndex === (option.childrens?.length || 0)-1))
}

function PracticeAreaTopic() {
  const [expandAll, setExpandAll] = useState(false);
  const { data, fetching } = useSelector(getPracticeAreaState);
  const dispatch = useDispatch();

  const handleExpandAll = ({ target: { checked } }: any) => {
    setExpandAll(checked);
  }

  const handleExport = () => {
    const fileName = "PracticeAreaMapping"
    const records: Array<Array<string>> = [];
    data?.dropdown?.forEach((item: IOption) => {
      let parent: string[] = [];
      let items: Array<Array<any>> = [];
      createItem(item, parent, items, 3);
      records.push(...items);
    })

    const excelData: Array<any> = [];
    records.forEach((record: Array<string>) => {
      const excelRecord: any= {};
      record.forEach((item: string, index: number) => {
        excelRecord[exportExcelItems[index].id] = item;
      });
      excelData.push(excelRecord)
    })

    xlsx([{
      sheet: fileName,
      columns: exportExcelItems.map(({ id, label}) => ({
        label,
        value: id
      })),
      content: excelData
    }],
    {
      fileName: `${fileName}${new Date().getTime()}`, // Name of the resulting spreadsheet
      // extraLength: 3, // A bigger number means that columns will be wider
      writeMode: "writeFile", // The available parameters are 'WriteFile' and 'write'. This setting is optional. Useful in such cases https://docs.sheetjs.com/docs/solutions/output#example-remote-file
      writeOptions: {}, // Style options from https://docs.sheetjs.com/docs/api/write-options
      RTL: false, // Display the columns from right-to-left (the default value is false)
    })
  }

  const itemsHirearchy: IHirerarchy[] = useMemo(() => {
    const createHirerarchy = ({ value, childrens }: IOption): IHirerarchy => {
      return {
        title: value,
        childrens: childrens?.map(createHirerarchy) || []
      };
    }
    return data?.dropdown?.map(createHirerarchy);
  }, [data]);

  useEffect(() => {
    if(data?.dropdown.length === 0) {
      dispatch(getPracticeAreaRequest())
    }
  }, [])

  return (
    <div className="padding-l-10 padding-r-10 padding-t-8">
      <div className="text-lg text-bold text-uppercase">Practice Area and Topic Mapping INformation</div>
      <div className="flex flex-row flex-justify-between padding-b-4 flex-align-center">
        <CheckboxLabel labelTxt={"Expand All"} checked={expandAll} handleChange={handleExpandAll} baseClassName="left-align width-15" />
        <CustomButton round primaryButton buttonText="Export to Excel" handleClick={handleExport}/>
      </div>
      <HirerarchyLevel items={itemsHirearchy} forceExpand={expandAll} />
      {fetching && <Loading isGlobal />}
    </div>
  );
}
export default PracticeAreaTopic;