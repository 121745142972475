import React from "react";
import { ReactComponent as BannerSvg } from "assets/banner.svg";
import { ReactComponent as WavesSvg } from "assets/waves.svg";

import "./Banner.scss";
import CustomButton from "components/CustomButton";
import { ICON_POSITION } from "components/CustomButton/CustomButton";
import { CUSTOM_SVG_ICON, SVGType } from "components/SvgIcon";

const Banner = ({ handleStartTutorial }: any) => {

  const handleManualStart = () => {
    window.open("https://bcgcloud.sharepoint.com/:p:/s/RC_DRS/Ec35Uhhy7OBJkpBkN1hdSsABvndeND5QX-uZRLIXNo6nWw?e=7gxUUH", "_blank");
  }

  return (
    <div className="banner-container position-relative overflow-hidden width-100 ">
      <div className="banner-logo position-absolute width-100"><BannerSvg /></div>
      <div className="banner-waves-logo position-absolute"><WavesSvg /></div>
      <CustomButton
        type="button"
        baseclassname="position-absolute tutorial-start-button width-20"
        primaryButton 
        buttonText={"TAKE A TOUR OF NEW PAAF DATABASE"}
        round
        handleClick={handleStartTutorial}
        iconPosition={ICON_POSITION.LEFT}
        iconProps={{
          name: CUSTOM_SVG_ICON.HandPointer,
          svgType: SVGType.CUSTOM,
          size: "small",
        }}
      />

      <CustomButton
        type="button"
        baseclassname="position-absolute manual-start-button"
        primaryButton
        round
        buttonText={"USER MANUAL FOR PAAF"}
        handleClick={handleManualStart}
        iconPosition={ICON_POSITION.LEFT}
        iconProps={{
          name: CUSTOM_SVG_ICON.FileOutline,
          svgType: SVGType.CUSTOM,
          size: "small",
        }}
      />

      <div className="position-relative flex flex-column text-white-color height-100 flex-justify-center padding-l-10 padding-r-10">
        <div className="text-x4lg margin-b-4"><span className="title-1 padding-b-2 padding-t-2">Welcome to</span></div>
        <div className="flex flex-row flex-align-baseline">
          <span className="text-uppercase title-2">
            <span>Practice Area </span>
            <span>AFFILIATION Database</span>
          </span>
          <span className="text-uppercase title-3 text-primary-color text-x3lg padding-l-4 padding-r-4 padding-t-2 padding-b-2 margin-l-2">By bcg</span>
        </div>
      </div>
    </div>
  )
}

export default Banner;