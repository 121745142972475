import { IColumn, IGrouping, IGroupingColumns, IKeyOfString } from "./IReactTable";

export function calculateGroupWidth({ headers, originalWidth, totalWidth}: IGrouping, tableWidth: number) {
    const actualWidths: IKeyOfString = {};
    let threshhold: number = 0;
    let totalThreshhold: number = 0;
    const actualRowWidth: number = (originalWidth * (tableWidth/100));

    if(actualRowWidth < totalWidth) {
      totalThreshhold  = totalWidth - actualRowWidth;
      threshhold = totalThreshhold / headers.length;
    }

    headers.forEach(header => {
      const actualWidth = (header.width* (tableWidth/100));
      actualWidths[header.id] = `${(actualWidth + threshhold).toFixed(2)}px`;
    });
    actualWidths["originalWidth"] = `${((originalWidth + totalThreshhold) * (tableWidth/100)).toFixed(2)}px`;
    return actualWidths;
}


export function calculateGroupingWidths(headers: IColumn[], tableWidth: number, uncontrolledHeight?: boolean): IGroupingColumns {
    const leftColumns: IGrouping = {
        headers: [],
        totalWidth: 0,
        originalWidth: 0,
        totalWidthStr: "0%",
        groupingWidth: {}
      };
      const centerColumns: IGrouping = {
        headers: [],
        totalWidth: 0,
        originalWidth: 0,
        totalWidthStr: "0%",
        groupingWidth: {}
      };
      const rightColumns: IGrouping = {
        headers: [],
        totalWidth: 0,
        originalWidth: 0,
        totalWidthStr: "0%",
        groupingWidth: {}
      };
  
      headers.forEach((header) => {
        const { isFixed, fixedDirection, width } = header;
        if (isFixed && !uncontrolledHeight) {
          if (fixedDirection === "left") {
            leftColumns.headers.push(header);
            leftColumns.originalWidth += width;
          } else {
            rightColumns.headers.push(header);
            rightColumns.originalWidth += width;
          }
        } else {
          centerColumns.headers.push(header);
          centerColumns.originalWidth += width;
        }
      });
      centerColumns.totalWidth = (100 - (leftColumns.originalWidth + rightColumns.originalWidth)) * (tableWidth/100);
      leftColumns.totalWidth = leftColumns.originalWidth * (tableWidth/100);
      rightColumns.totalWidth = rightColumns.originalWidth * (tableWidth/100);
  
      leftColumns.groupingWidth = calculateGroupWidth(leftColumns, tableWidth);
      rightColumns.groupingWidth = calculateGroupWidth(rightColumns, tableWidth);
      centerColumns.groupingWidth = calculateGroupWidth(centerColumns, tableWidth);
      leftColumns.totalWidthStr = `${leftColumns.totalWidth}px`;
      rightColumns.totalWidthStr = `${rightColumns.totalWidth}px`;
      centerColumns.totalWidthStr = `${centerColumns.totalWidth}px`;


      return {
        leftColumns,
        centerColumns,
        rightColumns
      }
}
