
export const tableheader = [
    {
      name: "Parent Practice Area",
      id: "ad",
      width: "20%"
    },
  
    {
      name: "PA/Topic/Sector",
      id: "aad",
      width: "20%"
    },
    {
      name: "Type",
      id: "adsd",
      width: "10%"
    },
    {
      name: "Level",
      id: "sdvf",
      width: "15%"
    },
    {
      name: "Effective Start Date",
      id: "hjj",
      width: "20%"
    },
    {
      name: "Primary PA",
      id: "njbhj",
      width: "15%"
    },
  ]