import { forwardRef, MutableRefObject, useImperativeHandle, useRef } from "react";
import TooltipText from "components/TooltipText";
import { IColumn, IGrouping } from "./IReactTable";
import classNames from "classnames";
import { getRowValue } from "utils";

interface Props {
  type?: string;
  grouping: IGrouping;
  data: Array<any>;
  direction: "left" | "right" | "center";
  scrollRef: any;
  uncontrolledHeight?: boolean;
  primarykey: string;
}

function Rows(props: Props, ref: any) {
  const { grouping: { headers, totalWidthStr, groupingWidth }, data, direction, scrollRef, uncontrolledHeight, primarykey, type } = props;
  const rowContainerRef: MutableRefObject<HTMLDivElement> = useRef(undefined!);

  useImperativeHandle(ref, () => {
    return {
      leftScroll: handleHorizontalScroll
    }
  }, [rowContainerRef, rowContainerRef?.current]);


  const handleHorizontalScroll = (scrollValue: number) => {
    rowContainerRef.current.style.transform = `translateX(-${scrollValue}px)`;
  }


  const renderHeaderData = (rowValues: any, key: string) => (header: IColumn, index: number) => {
    const { id, CustomRowComponent } = header;
    const value = type === "snow-flake-Data" ? rowValues?.employeeRecords[id] : rowValues[id];
    return (
      <div key={`${key}-${index}`} id={`${key}-${index}`} className="body-cell" style={{width: groupingWidth[id]}}>
        {CustomRowComponent && <CustomRowComponent header={header}  data={rowValues} /> }
        {!CustomRowComponent && !uncontrolledHeight && <TooltipText value={getRowValue(value)}/>}
        {!CustomRowComponent && uncontrolledHeight && getRowValue(value)}
      </div>
    )
  }

  const renderRow = (record: any, index: number) => {
    const key = `row-${direction}-${record[primarykey] || index}-${index}`;
    return (
      <div key={key} id={key} className={classNames(["flex flex-row body-row", {"no-static-height": uncontrolledHeight}])}>
        {
          headers.map(renderHeaderData(record, key))
        }
      </div>
    )
  }

  const handleRowScroll = ({deltaX}: any) => {
    scrollRef.current.scroll(deltaX*2);
  }

  const handleShiftScroll = ({shiftKey, deltaY}: any) => {
    if(shiftKey) {
      scrollRef.current.scroll(deltaY/10);
    }
  }

  
  return (
    <div style={{width: totalWidthStr, zIndex: direction === "left" ? 1 :0}} id={`${direction}-container`}
    onWheelCapture={handleRowScroll}
    onWheel={handleShiftScroll}
    >
      {
        !!headers.length && <div className="flex flex-column react-table--body-overflow" style={{width: groupingWidth["originalWidth"]}} ref={rowContainerRef} >
            {
              data.map(renderRow)
            }
        </div>
      }
    </div>
  )
}

export default forwardRef(Rows);