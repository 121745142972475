import classNames from 'classnames';
import CheckboxLabel from 'components/CheckboxLabel';
import type { Identifier, XYCoord } from 'dnd-core'
import type { FC } from 'react'
import { useRef } from 'react'
import { useDrag, useDrop } from 'react-dnd';

export interface CardProps {
  id: any
  text: string;
  isSelected: boolean;
  index: number
  isDisable: boolean;
  moveCard: (dragIndex: number, hoverIndex: number) => void
  handleCheckbox: (name: string, checked: boolean, index: number) => void;
}

interface DragItem {
  index: number
  id: string
  type: string
}

export const Card: FC<CardProps> = ({ id, text, index, isSelected, isDisable, handleCheckbox, moveCard }) => {
  const ref = useRef<HTMLDivElement>(null)
  const [{ handlerId }, drop] = useDrop<
    DragItem,
    void,
    { handlerId: Identifier | null }
  >({
    accept: "CARD",
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      }
    },
    canDrop: () => {
      return !isDisable
    },
    hover(item: DragItem, monitor) {
      if (!ref.current || isDisable) {
        return
      }
      const dragIndex = item.index
      const hoverIndex = index

      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return
      }

      // Determine rectangle on screen
      const hoverBoundingRect = ref.current?.getBoundingClientRect()

      // Get vertical middle
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2

      // Determine mouse position
      const clientOffset = monitor.getClientOffset()

      // Get pixels to the top
      const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top

      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%

      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return
      }

      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return
      }

      // Time to actually perform the action
      moveCard(dragIndex, hoverIndex)

      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex
    },
  })

  const [{ isDragging }, drag] = useDrag({
    type: "CARD",
    item: () => {
      return { id, index }
    },
    collect: (monitor: any) => ({
      isDragging: monitor.isDragging(),
    }),
    canDrag: () => {
      return !isDisable
    }
  })

  drag(drop(ref))
  return (
    <div ref={ref} className={classNames(["dnd-card", {"disable": isDisable, "move": isDragging}])}  data-handler-id={handlerId}>
      <CheckboxLabel labelTxt={text} checked={isSelected || false} handleChange={({target: {checked}}: any) => {
        handleCheckbox(id, checked, index);
      }} baseClassName=""/>
    </div>
  )
}
