
import classNames from 'classnames';
import FormLabel from './FormLabel';
import './FormTextArea.scss';

export function FormTextArea({ input, ...props }: any) {
    const { label, required, roundedCorner, meta, disabled } = props;
    return (
        <div className='width-100'>
            <FormLabel label={label} required={required} />
            <textarea className={classNames(["customTextArea width-100", { "rounded-corner": roundedCorner }])} {...input} placeholder="Enter Description" rows="1" cols="40" disabled={disabled} />
            {meta?.error && meta?.touched && <div className="text-danger-color text-sm padding-l-4">{meta?.error}</div>}
        </div>
    )
}

