
import { createRext, IRext } from "baseProvider/rext";
import { getBaseUrl, getBaseUrlAPIKEY, getToken } from "store/selectors";

export const getLeadAffiliations: IRext = createRext({
  identity: "getLeadAffiliations",
  getBaseUrl: getBaseUrl(false),
  getXAPIToken: getBaseUrlAPIKEY(false),
  getToken: getToken
})

export const getActiveAffiliations: IRext = createRext({
  identity: "getActiveAffiliations",
  getBaseUrl: getBaseUrl(false),
  getXAPIToken: getBaseUrlAPIKEY(false),
  getToken: getToken
})


export const getLocations: IRext = createRext({
  identity: "getLocations",
  getBaseUrl: getBaseUrl(true),
  getXAPIToken: getBaseUrlAPIKEY(true),
  getToken: getToken
})

export const getGlobalTitle: IRext = createRext({
  identity: "getGlobalTitle",
  getBaseUrl: getBaseUrl(true),
  getXAPIToken: getBaseUrlAPIKEY(true),
  getToken: getToken
})

export const getCarrerTrack: IRext = createRext({
  identity: "getCarrerTrack",
  getBaseUrl: getBaseUrl(false),
  getXAPIToken: getBaseUrlAPIKEY(false),
  getToken: getToken
})


export const getCarrerStep: IRext = createRext({
  identity: "getCarrerStep",
  getBaseUrl: getBaseUrl(false),
  getXAPIToken: getBaseUrlAPIKEY(false),
  getToken: getToken
})

export const getPracticeArea: IRext = createRext({
  identity: "getPracticeArea",
  getBaseUrl: getBaseUrl(false),
  getXAPIToken: getBaseUrlAPIKEY(false),
  getToken: getToken
})

export const getPracticeAreaForUser: IRext = createRext({
  identity: "getPracticeAreaForUser",
  getBaseUrl: getBaseUrl(false),
  getXAPIToken: getBaseUrlAPIKEY(false),
  getToken: getToken
})

export const getAffiliation: IRext = createRext({
  identity: "getAffiliation",
  getBaseUrl: getBaseUrl(false),
  getXAPIToken: getBaseUrlAPIKEY(false),
  getToken: getToken
})


export const getFeatures: IRext = createRext({
  identity: "getFeatures",
  getBaseUrl: getBaseUrl(false),
  getXAPIToken: getBaseUrlAPIKEY(false),
  getToken: getToken
})


export const getAllRoles: IRext = createRext({
  identity: "getAllRoles",
  getBaseUrl: getBaseUrl(false),
  getXAPIToken: getBaseUrlAPIKEY(false),
  getToken: getToken
})


export const searchBCGUsers: IRext = createRext({
  identity: "searchBCGUsers",
  getBaseUrl: getBaseUrl(true),
  getXAPIToken: getBaseUrlAPIKEY(true),
  getToken: getToken
})


export const archiveReports: IRext = createRext({
  identity: "archiveReports",
  getBaseUrl: getBaseUrl(false),
  getXAPIToken: getBaseUrlAPIKEY(false),
  getToken: getToken
})

export const toggleColumnRext: IRext = createRext({
  identity: "toggleColumnRext",
  getBaseUrl: getBaseUrl(false),
  getXAPIToken: getBaseUrlAPIKEY(false),
  getToken: getToken
})

export const userRoleRext: IRext = createRext({
  identity: "userRoleRext",
  getBaseUrl: getBaseUrl(false),
  getXAPIToken: getBaseUrlAPIKEY(false),
  getToken: getToken,
  transformation: (item: any) => {
    const roles: Array<string> = [];
    let topics: Array<string> = [];
    let offices: Array<string> = [];
    let officesPap: Array<string> = [];
    item.roles.forEach(({ role, canEdit }: { role: string, canEdit: Array<string> }) => {
      roles.push(role);
      if (role === "PAC") {
        topics = [
          ...topics,
          ...canEdit
        ]
      } else if (role === "CDS") {
        offices = [
          ...offices,
          ...canEdit
        ]
      } else if (role === "PAP") {
        officesPap = [
          ...officesPap,
          ...canEdit
        ]
      }
    })
    return {
      hrId: item.hrId,
      roles,
      topics,
      offices,
      officesPap,
      isLeadAllocation: item?.isLeadAllocation
    };
  }
})

export const practiceTopicsPermissionsRext: IRext = createRext({
  identity: "practiceTopicsPermissionsRext",
  getBaseUrl: getBaseUrl(false),
  getXAPIToken: getBaseUrlAPIKEY(false),
  getToken: getToken,
  transformation: (items: any) => {
    const newItems: any = {};
    items?.topicIds?.forEach((item: string) => {
      newItems[item] = true;
    })
    return newItems;
  }
})

export const getSnoFlakeRegions: IRext = createRext({
  identity: "getSnoFlakeRegions",
  getBaseUrl: getBaseUrl(false),
  getXAPIToken: getBaseUrlAPIKEY(false),
  getToken: getToken
})

export const getOrganization: IRext = createRext({
  identity: "getOrganization",
  getBaseUrl: getBaseUrl(false),
  getXAPIToken: getBaseUrlAPIKEY(false),
  getToken: getToken
})

export const getChort: IRext = createRext({
  identity: "getChort",
  getBaseUrl: getBaseUrl(false),
  getXAPIToken: getBaseUrlAPIKEY(false),
  getToken: getToken
})

export const getJobTitles: IRext = createRext({
  identity: "getTobTitles",
  getBaseUrl: getBaseUrl(false),
  getXAPIToken: getBaseUrlAPIKEY(false),
  getToken: getToken
})