import { IPeopleUser } from "store/reducers";
import { useEffect, useState } from "react";
import { getRowValue } from "utils";
import { useSelector } from "react-redux";
import { getUserRoleState } from "store/selectors";
import { checkIfPapAdminRolesExists, hasPAPOnlyAccess } from "../utils";

function ShortDetail({ label, value }: any) {
    return (
        <div className={`${label !== 'Lead Allocation' ? 'affiliation-card__details--tab' : ''} flex flex-column`}>
            <div className='text-sm'>{label}</div>
            <div className='text-md text-word-break'>{value || "-"}</div>
        </div>
    )
}

function Detail({ user, data }: { user: IPeopleUser, data: any }) {
    const { hrId, tandbId, businessTitle, gender, lateralHire, jobCategoryId, cohortStep, cohort, defaultAllocation, globalTitle, monthsOfEmployment, monthsOfExperience
    } = user || {};
    const { data: userRoleState } = useSelector(getUserRoleState);

    // Role state
    const [isPapRole, setIsPapRole] = useState<boolean>(false);
    const [isAdminPapRole, setIsAdminPapRole] = useState<boolean>(false);

    useEffect(() => {
        if (userRoleState !== undefined && Object.keys(userRoleState).length) {
            const papAccessOnly = hasPAPOnlyAccess(userRoleState);
            const papAdminAccess = checkIfPapAdminRolesExists(userRoleState);
            setIsPapRole(papAccessOnly);
            setIsAdminPapRole(papAdminAccess);
        }
    }, [userRoleState])

    return (
        <div className='affiliation-card__details--full-detail margin-t-4'>
            <div className='flex flex-wrap flex-justify-between padding-2'>
                <ShortDetail label="HR ID" value={hrId} />
                <ShortDetail label="T&B ID" value={getRowValue(tandbId)} />
                <ShortDetail label="Job Title" value={getRowValue(globalTitle)} />
                <ShortDetail label="Cohort" value={getRowValue(cohort)} />
                <ShortDetail label="Months of Employment" value={getRowValue(monthsOfEmployment)} />
                <ShortDetail label="Cohort Step" value={getRowValue(cohortStep)} />
                <ShortDetail label="Business title" value={getRowValue(businessTitle)} />
                <ShortDetail label="Months of Experience" value={getRowValue(monthsOfExperience)} />
                <ShortDetail label="Gender" value={getRowValue(gender)} />
                <ShortDetail label="Allocation" value={getRowValue(defaultAllocation)} />
                <ShortDetail label="MDP Archetype" value={getRowValue(data?.mdpArchetype)} />
                <ShortDetail label="Lateral Hire" value={lateralHire ? "Yes" : "No"} />
                <ShortDetail label="Job Category" value={getRowValue(jobCategoryId)} />
                <ShortDetail label="Assistant Email" value={getRowValue(data?.assistantEmail)} />
                {isAdminPapRole || isPapRole ? (
                    <ShortDetail label="Lead Allocation" value={getRowValue(data?.leadAllocationDetails)} />
                ) : null}
            </div>
        </div>
    )
}
export default Detail;